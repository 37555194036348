
import { Component, OnInit, Input , Inject} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { PagesService } from 'src/app/pages/pages.service';
import { AuthService } from 'src/app/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { KanbanService } from '../../../../kanban.service';
import { SettingService } from 'src/app/settings/setting.service';

@Component({
  selector: 'app-lane-task-documents-tab',
  templateUrl: './lane-task-documents-tab.component.html',
  styleUrls: ['./lane-task-documents-tab.component.scss']
})
export class LaneTaskDocumentsTabComponent implements OnInit {
  laneTaskIDDetail : any;

  constructor(private formBuilder: FormBuilder, public toasterService: ToastrService, public dialog: MatDialog, private activatedRoute: ActivatedRoute, public router: Router, public settingServ: SettingService, public _kService: KanbanService,
    private pagesService: PagesService, private authService: AuthService , @Inject(MAT_DIALOG_DATA) public data: any) { 
      console.log(data);
      this.laneTaskIDDetail = data.laneTask;
  
    }
  
  ngOnInit() {
    if (this.laneTaskIDDetail != null) {
      
    }
  }

}
