import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LabelConstants } from 'src/app/LabelConstants';

@Component({
  selector: 'app-custom-single-select',
  templateUrl: './custom-single-select.component.html',
  styleUrls: ['./custom-single-select.component.scss']
})
export class CustomSingleSelectComponent implements OnInit {

  constructor() { }
  
  @Input() formItem: any;
  @Input() searchControl: string;
  @Input() optionList: string[];
  @Output() selectionChange = new EventEmitter<string[]>();
  @Output() searchChange = new EventEmitter<string>();
  ngOnInit() {
  }

  shouldDisableField(field: string): boolean {
    return false;
  }

  applySelection(formItem: any) {
    this.selectionChange.emit(formItem);
  }
}
