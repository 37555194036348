<div>
  <div class="d-flex-rw">
    <section class="pb-15 dialog-heading light-grey-bg border-0" aria-label="heading">
      <div fxLayout="row " fxLayoutAlign="space-between center">
        <h1 *ngIf="isValidLane()" class="modal-heading-2 text-capitalize mb-0">
          Stage: {{laneName}} | {{count}} Deals ({{sum | number: '1.2-2'}} Cr)
        </h1>
        <h1 *ngIf="!isValidLane()" class="modal-heading-2 text-capitalize mb-0">
          {{laneName}} | {{count}} Deals
        </h1>

        <button mat-mini-fab class="modal-close-btn" mat-dialog-close>
          <i class="ri-close-circle-fill"></i>
        </button>
      </div>
    </section>
    <!-- <div fxLayout="row" fxLayoutAlign="flex-end center" style="width: 98%;" >
      <mat-icon class="material-icons close-icon" (click)="dialogRef.close();">close</mat-icon>
    </div> -->
    <div class="table-overflow mog3_msnsearchtable_sticky deal-funnel-table bg-dark-grey">
      <table mat-table matSort class="mat-elevation-z8 table-1 m" [dataSource]="dataSource"
        (matSortChange)="sortLaneTasks($event)" [@.disabled]="true">
        <ng-container>
          <!-- tag Column -->
          <ng-container *ngFor="let disCol of displayedColumns; let colIndex = index" matColumnDef="{{disCol.field}}">
            <ng-container *ngIf="disCol.type != 'date' && disCol.type != 'currency' && disCol.type != 'number'">
              <th mat-header-cell *matHeaderCellDef class="table-header">
                <span class="col-name"> {{disCol.displayName}}</span>
              </th>
            </ng-container>
            <ng-container *ngIf="disCol.type == 'date' || disCol.type == 'currency' ||  disCol.type == 'number'">
              <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header>
                <span class="col-name"> {{disCol.displayName}}</span>
              </th>
            </ng-container>
            <td mat-cell *matCellDef="let element; let i = index;" style=" cursor: pointer;"
              (click)="openCardDetails(element)">
              <div fxLayout="column">
                <div *ngIf="disCol.field !== 'closing-date'" fxLayout="row" class="field-value">
                  <span [ngClass]="{'blue-text': (colIndex == 0)}"> {{ getFieldValue(element, disCol.field) }} </span>
                </div>
                <div *ngIf="disCol.field === 'closing-date'" fxLayout="row" class="field-value">
                  <span [ngClass]="{'past-closing-date': checkPastClosingDate(element, disCol.field)}"> {{
                    getFieldValue(element, disCol.field) }} </span>
                </div>
                <div fxLayout="column" *ngIf="disCol.field == 'deal-name'" fxLayoutAlign="center">
                  <p class="m-0 cell-sub-text"><span class="time-label"> Created</span> {{ getFieldValue(element,
                    'createdAt') }}</p>
                  <p class="m-0 cell-sub-text"><span class="time-label"> Modified</span> {{ getFieldValue(element,
                    'updatedAt') }}</p>
                </div>
                <div fxLayout="column" *ngIf="disCol.field == 'deal-owner'" fxLayoutAlign="center">
                  <p class="m-0 cell-sub-text">{{ getFieldValue(element, 'secondary-owner') }}</p>
                </div>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="lane">
            <th mat-header-cell *matHeaderCellDef class="table-header">
              <div style=" flex-direction: column;
              display: flex; width: 100%; height: 100%;">
                <span class="col-name">
                  Stage
                </span>
              </div>
            </th>
            <td mat-cell *matCellDef="let element" style=" cursor: pointer;">
              <div>
                <span> <strong> {{ element.lane?.displayName }}</strong></span>
              </div>
              <div fxLayout="column" fxLayoutAlign="center">
                <p class="m-0 cell-sub-text">{{ calculateAge(element)}}</p>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="actionItem">
            <th mat-header-cell *matHeaderCellDef class="table-header">
              <div style=" flex-direction: column;
              display: flex; width: 100%; height: 100%;">
                <span class="col-name">
                  Next Action Item
                </span>
              </div>
            </th>
            <td mat-cell *matCellDef="let element" style=" cursor: pointer;">
              <div *ngIf="element.nextActionItem != null">

                <p [ngClass]="{'limitTextHeight': isReadMore}" [innerHtml]="element.nextActionItem.message"
                  class="comment-message overflow-auto"></p>

                <button type="button" class="read-more-cta" *ngIf="element.nextActionItem.message.length > 50"
                  (click)="isReadMore=!isReadMore">
                  {{ isReadMore ? 'Read More': 'Read Less' }}
                </button>
                <div fxLayout="column" fxLayoutAlign="center">
                  <p class="m-0 cell-sub-text">{{ getFormattedDate(element.nextActionDate) }}</p>
                </div>
              </div>
              <div *ngIf="element.nextActionItem == null">

                <span class="comment-message">N/A</span>
                <div fxLayout="column" fxLayoutAlign="center">
                  <p class="m-0 cell-sub-text">{{ getFormattedDate(element.nextActionDate) }}</p>
                </div>
              </div>

            </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>
              <div>
                <span class="col-name">
                  Action
                </span>
              </div>
            </th>
            <td mat-cell *matCellDef="let element" style=" cursor: pointer;">
              <div class="actions-list">
                <i class="ri-edit-box-line" [matTooltip]="getToolTipText('Edit', false)" matTooltipClass="new-tooltip"
                  (click)="openCardDetails(element)" *ngIf="element.canEdit"></i>

                <i class="ri-eye-line" [matTooltip]="getToolTipText('Summary',true)" matTooltipClass="new-tooltip"
                  (click)="viewLaneTask(element)"></i>
                <i class="ri-history-line" [matTooltip]="getToolTipText('History', true)" matTooltipClass="new-tooltip"
                  (click)="showHistory(element)"></i>
              </div>
            </td>
          </ng-container>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns1; sticky: true">

        </tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns1; let i = index"
          [ngClass]="{ 'active-row': i === selectedTrIndex }">

        </tr>
      </table>
      <div *ngIf="isLoading" fxLayout="row" style="position: absolute; top: 50%; left: 50%"
        fxLayoutAlign="center center">
        <mat-spinner strokeWidth="2" [diameter]="60" [color]="red"></mat-spinner>
      </div>
      <div fxLayout="row" fxLayoutAlign="end center" style="width: 100%">
        <div class="d-flex-rw" style="width: 100%;">
          <div class="pagination-wrapper" style="width: 100%;">
            <mat-paginator #paginator [hidden]="!dataSource.length" [length]="count" [pageIndex]="pageNumber"
              class="custom-pagintor" [pageSize]="pageSize" showFirstLastButtons (page)="loadData()">
              <!--  (page)="onScrollDown($event, lane?.laneDTO.id, lane['taskList'].length, lane?.totalRecords, lane['taskList'])" -->
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>