import { Output } from "@angular/core";

import {
  Component, Input, OnInit, SimpleChanges, ChangeDetectorRef, OnChanges
  , ChangeDetectionStrategy, EventEmitter
} from '@angular/core';

import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import * as dayjs from "dayjs";
import  { Dayjs } from 'dayjs/esm';
import * as moment from 'moment';
import { P } from "@angular/cdk/keycodes";
export interface TimePeriod {
  [index: string]: Dayjs;

  startDate: Dayjs;
  endDate: Dayjs;
}
export interface DateRanges {
  [index: string]: [moment.Moment, moment.Moment];
}
export interface DateRange {
  label: string;
  dates: [Dayjs, Dayjs];
}

@Component({
  selector: 'crm-date-picker',
  templateUrl: './crm-date-picker.component.html',
  styleUrls: ['./crm-date-picker.component.scss']
})
export class CrmDatePickerComponent implements OnInit {
  @Input() selectDefault;
  @Input() resetDates;
  @Input() placeholder: string = 'Time Duration'; // Default placeholder
  @Output() dateFilterApplied = new EventEmitter<TimePeriod>();
  selected: any;
  mom = moment();
  alwaysShowCalendars: boolean;
  showRangeLabelOnInput: boolean;
  keepCalendarOpeningWithRange: boolean;
  maxDate: dayjs.Dayjs;
  minDate: dayjs.Dayjs;
  invalidDates: dayjs.Dayjs[] = [];
  tooltips = [
    { date: dayjs(), text: 'Today is just unselectable' },
    { date: dayjs().add(2, 'days'), text: 'Yeeeees!!!' }
  ];

  inlineDateTime: TimePeriod;
  ranges: DateRanges = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],    
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 3 Months': [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };
  constructor(public cRef: ChangeDetectorRef) { 
    this.maxDate = dayjs().add(2, 'weeks');
    this.minDate = dayjs().subtract(3, 'days');

    this.alwaysShowCalendars = true;
    this.keepCalendarOpeningWithRange = false;
    this.showRangeLabelOnInput = true;

   
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.selectDefault) { this.selected = this.selectDefault; }

    if(this.resetDates){
      this.selected = null;
    } 
  }
  ngOnInit() {
    this.cRef.detectChanges();
  }
  isInvalidDate = (m: dayjs.Dayjs): boolean => {
    return this.invalidDates.some((d) => d.isSame(m, 'day'));
  };

  isTooltipDate = (m: Dayjs): string | boolean | null => {
    const tooltip = this.tooltips.find((tt) => tt.date.isSame(m, 'day'));
    if (tooltip) {
      return tooltip.text;
    } else {
      return false;
    }
  };

  rangeClicked(range: DateRange): void {
    // eslint-disable-next-line no-console
   
  }
  clearDate() {
    console.log('clear date shiva')
    this.selected = null;
  }
  datesUpdated(range: TimePeriod): void {
    if (range.startDate && range.endDate) {
      this.onApply(range);
    }
    // eslint-disable-next-line no-console
  }
  onApply = (range: TimePeriod) => {
    console.log('app;lu date shiva',range)
  this.dateFilterApplied.emit(range);
}
  

  chosenDateTime(e: TimePeriod): void {
    this.inlineDateTime = e;
  }

  clearAll() {
    this.resetDates = true;
    this.selected = null;
  }
}
