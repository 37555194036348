<div class="dialog-section">
  <section class="pb-15 dialog-heading" aria-label="heading">
      <div fxLayout="row mb-30" fxLayoutAlign="space-between flex-center">
          <h1 class="modal-heading-1 text-capitalize">{{boardName}}</h1>
          <button mat-mini-fab class="modal-close-btn" (click)="closeDialog()"><i class="ri-close-circle-fill"></i></button>
      </div>
    <div fxLayout="row" fxLayoutAlign="space-between flex-center">
      <div style="width: 40%;
      font-size: 14px;" fxLayout="row" fxLayoutAlign="start center">
        <div class="modal-heading-2 text-capitalize">
            Create New {{boardName}}
        </div>
      </div>
      <div style="width: 60%;" fxLayout="row" fxLayoutAlign="flex-end">
        <button mat-stroked-button class="mog3_secondary_flat_btn mr-20" (click)="closeDialog()"  color="warn">Cancel</button>
        <button mat-flat-button class="mog3_primary_flat_btn" (click)="saveLaneTask()">
          Save {{boardName}}
        </button>
      </div>

    </div>
  </section>
  <section aria-label="lead-info" class="dialog-body" fxLayout="row" fxLayoutAlign="start">

    <div fxLayout="column" fxLayoutAlign="start center" style=" width: 100%;">
      <ul class="card-detail-list">
        <ng-container *ngFor="let formItem of laneTaskConfig; let i = index">
          <ng-container *ngIf="formItem.display">
            <li class="lane-grid field-container" *ngIf="formItem.optional || (!formItem.optional && (formItem.field === 'mobile-number' || formItem.field === 'email') )">
              <div fxLayout="row" fxLayoutAlign="start space-around"   [ngClass]="{'label-field': i%2 == 0}" >
                <div fxLayout="row" fxLayoutAlign="start center"  [ngClass]="{'align-items-start mt-15' : formItem.field !== 'plant' && formItem.field === 'account' && boardId === boardIdForSobContacts}" class="label-width"  style="width: 40%;" >
                  <label class="modal-input-label" [ngClass]="{'required': formItem.optional}">{{formItem.displayName}} :</label>
                </div>
                <div fxLayout="row" [ngSwitch]="formItem.type" class="field-width" style="width: 60%;
                  justify-content: center;
                  align-items: center;" >
                  <div *ngSwitchCase="'string'" fxLayout="column" class="field-container">
                    <div  fxLayout="row">
                      <mat-form-field class="mat-input" appearance="outline" [ngClass]="{'error-border': formItem.error}">
                      <input matInput [id]="formItem.field" type="text" 
                        [(ngModel)]="formItem.value">
                    </mat-form-field>

                  </div>
                  </div>
                  <div *ngSwitchCase="'email'" fxLayout="row" class="field-container">
                    <mat-form-field class="mat-input" appearance="outline"  [ngClass]="{'error-border': formItem.error}">
                      <input matInput [id]="formItem.field" type="email" name="email" [email]="true" 
                        [(ngModel)]="formItem.value" (input)="validateEmail(formItem);">
                    </mat-form-field>
                  </div>
                  <div *ngSwitchCase="'number'" fxLayout="row" class="field-container">
                    <mat-form-field *ngIf="formItem.field != 'probability-of-conversion'"  class="mat-input" appearance="outline"  [ngClass]="{'error-border': formItem.error}">
                      <input matInput [id]="formItem.field" type="number" 
                        [(ngModel)]="formItem.value">
                    </mat-form-field>
                    <mat-form-field *ngIf="formItem.field == 'probability-of-conversion'"  class="mat-input" appearance="outline"  [ngClass]="{'error-border': formItem.error}">
                      <input matInput [id]="formItem.field" type="number" 
                        [(ngModel)]="formItem.value" max="100"  [max]="100"  pattern="\d*"  >
                    </mat-form-field>
                  </div>
                  <div *ngSwitchCase="'phone'" fxLayout="row" class="field-container">
                    <mat-form-field class="mat-input prefix-input" appearance="outline" [ngClass]="{'error-border': formItem.error}">
                      <input matInput [id]="formItem.field" *ngIf="!isCountryIndia && boardId==boardIdForCRMContacts" type="text" pattern="\d*" placeholder="0000000000" 
                            [(ngModel)]="formItem.value"
                            (input)="validateLength(formItem, formItem.value.length)">
                      <input matInput [id]="formItem.field" type="text" *ngIf="isCountryIndia && boardId==boardIdForCRMContacts" pattern="\d*" placeholder="0000000000" 
                            [(ngModel)]="formItem.value"
                            (input)="validateLength(formItem, 10);"
                            [minLength]="isCountryIndia ? 10 : null"
                            [maxlength]="isCountryIndia ? 10 : null">
                      <input matInput [id]="formItem.field" type="text" *ngIf="boardId!=boardIdForCRMContacts" pattern="\d*" 
                            [(ngModel)]="formItem.value" (input)="validateLength(formItem, 10);" [maxlength]="10" [max]="10" >
                    </mat-form-field>
                </div>
                  <div *ngSwitchCase="'currency'" fxLayout="row" class="field-container">
                    <mat-form-field class="mat-input" appearance="outline"  [ngClass]="{'error-border': formItem.error}" >
                      <input matInput [id]="formItem.field" type="number" 
                        [(ngModel)]="formItem.value">
                    </mat-form-field>
                  </div>
                  <div *ngSwitchCase="'dropdown'" fxLayout="row" class="field-container">
                    <mat-form-field appearance="outline" class="mat-select"  [ngClass]="{'error-border': formItem.error}">
                      <mat-select [id]="formItem.field" [(ngModel)]="formItem.value">
                        <mat-option *ngFor="let opt of formItem.dataList" [value]="opt">{{opt}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div  *ngSwitchCase="'date'" fxLayout="row" class="field-container">
                    <mat-form-field  class="mat-select" appearance="outline"  [ngClass]="{'error-border': formItem.error}" >
                      <input matInput [matDatepicker]="effectivePicker" placeholder="Select Date"
                        [(ngModel)]="formItem.value">
                      <mat-datepicker-toggle matSuffix [for]="effectivePicker"></mat-datepicker-toggle>
                      <mat-datepicker #effectivePicker></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div *ngSwitchCase="'multiSelect'" fxLayout="row" class="field-container">
                      
                    <mat-form-field appearance="outline"  class="mat-select"
                        *ngIf="(formItem.key == 'string' || formItem.key == 'number') && formItem.field !== 'tag-type'"  [ngClass]="{'error-border': formItem.error}">
                        <mat-select multiple [id]="formItem.field"  [(ngModel)]="formItem.values">

                          <mat-option *ngFor="let opt of formItem.dataList" [value]="opt">{{opt}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <ng-container *ngIf="formItem.field === 'tag-type'" >
                      <div class="mat-input lane-card-input">
                        <div class="input-group-1">
                          <app-custom-multiple-select [label]="formItem.displayName" [ngClass]="{'error-border': formItem.error}"
                              [selectedValues]="formItem.values"
                              [selectedHighlight]="false"
                              [valueCountView]="true"
                              [availableOptions]="getFilteredOptions(formItem)" 
                              [searchControl]="searchControl"
                              (selectionChange)="getSelectionChange($event, formItem)"
                              (searchChange)="searchChange($event, formItem)">
                            </app-custom-multiple-select>
                        </div>
                      </div>
                    </ng-container>

                  </div>
                    <div *ngSwitchCase="'select'" fxLayout="row" class="field-container">
                      
                      <mat-form-field *ngIf="formItem.field !== 'role-type' && formItem.field !== 'lead-source' && ( formItem.key == 'string' || formItem.key == 'number' )" appearance="outline"  class="mat-select"
                        [ngClass]="{'error-border': formItem.error}">
                        <mat-select [id]="formItem.field" [(ngModel)]="formItem.value" (selectionChange)="onSelectOption(formItem,formItem.value)">
                          <mat-option *ngFor="let opt of getOptionList(formItem.dataList)" [value]="opt">{{opt}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                  
                      <mat-form-field  *ngIf="formItem.field == 'role-type' && ( formItem.key == 'string' || formItem.key == 'number' )" appearance="outline"  class="mat-select"
                        [ngClass]="{'error-border': formItem.error}">
                        <mat-select [id]="formItem.field" [(ngModel)]="formItem.value" (selectionChange)="onSelectOption(formItem,formItem.value)" [disabled] = "roleTypeValues.length < 1">
                          <mat-option *ngFor="let opt of roleTypeValues" [value]="opt">{{opt}}</mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field *ngIf="formItem.field === 'lead-source'" appearance="outline"  class="mat-select"
                        [ngClass]="{'error-border': formItem.error}">
                        <mat-select [id]="formItem.field" [(ngModel)]="formItem.value" (selectionChange)="onSelectOption(formItem,formItem.value)">
                          <mat-option *ngFor="let opt of getOptionList(formItem.dataList)" [value]="opt">{{opt}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                  
                    </div>

                    <div *ngSwitchCase="'boolean'" fxLayout="row" class="field-container">
                      <mat-form-field appearance="outline"  class="mat-select"
                        *ngIf="formItem.key == 'string' || formItem.key == 'number'"  [ngClass]="{'error-border': formItem.error}">
                        <mat-select [id]="formItem.field" [(ngModel)]="formItem.value">
                          <mat-option *ngFor="let opt of ['','Yes', 'No']" [value]="opt">{{opt}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div *ngSwitchCase="'belongsTo'" fxLayout="column" class="field-container" >
                      <app-field-autocomplete *ngIf="formItem.field !== 'plant' && formItem.field !== 'add-ceo' && formItem.field !== 'private-owner'" class="mb-5" style="width: 100% !important;" [ngClass]="{'error-border': formItem.error}"
                        [fieldData]="{
                          'config' : formItem,
                          'selectedHighlight':false
                        }" [modelRef]="formItem.value"
                        >
                      </app-field-autocomplete>

                      <!-- <mat-checkbox *ngIf="formItem.field !== 'plant' && formItem.field === 'account'  && formItem.field !== 'add-ceo' && boardId === boardIdForSobContacts"
                        class="checkbox" [(ngModel)]="isChecked" (change)="onCheckboxChange()">
                        <span class="blue-anchor">is Central Contact?</span>
                      </mat-checkbox> -->

                      <mat-form-field *ngIf="formItem.field=='plant'" appearance="outline" class="mat-select mat-select-search">
                        <mat-select [panelClass]="'select-panel-class'" #sl2 placeholder="Plants" multiple [(ngModel)]="selectedPlantIds"
                          [disabled]="!accountContactList || accountContactList.length === 0"
                          (click)="openPlantSelect()">
                          <mat-form-field class="mat-input icon" appearance="outline">
                            <i class="ri-search-line"></i>
                            <input type = "text" matInput placeholder="Search" [(ngModel)]="searchControl" (keyup) = "filterOptions($event)">
                          
                          </mat-form-field>

                          <div class="select-options">
                            <mat-option *ngFor="let contact of accountContactListSearch" [value]="contact.id">{{contact.name}}</mat-option>
                          </div>
                          <div class="select-footer">
                            <button class="filter-btn clear" (click)="selectedPlantIds=[];searchControl='';clearSelections();">Clear</button>
                            <button class="filter-btn apply" (click)="applySelections();sl2.close();">Apply</button>
                          </div>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field *ngIf="formItem.field=='add-ceo'" appearance="outline" class="mat-select mat-select-search">
                        <mat-select [panelClass]="'select-panel-class'" #sl2 placeholder="Select CEO" multiple [(ngModel)]="selectedCEOIds"
                          [disabled]="!accountContactCeoList || accountContactCeoList.length === 0"
                          (click)="openAddCEOSelect()">
                          <mat-form-field class="mat-input icon" appearance="outline">
                            <i class="ri-search-line"></i>
                            <input type = "text" matInput placeholder="Search" [(ngModel)]="searchControl" (keyup) = "filterOptions($event)">
                          
                          </mat-form-field>

                          <div class="select-options">
                            <mat-option *ngFor="let contact of accountContactCeoListSearch" [value]="contact.id">{{contact.name}}</mat-option>
                          </div>
                          <div class="select-footer">
                            <button class="filter-btn clear" (click)="selectedCEOIds=[];searchControl='';">Clear</button>
                            <button class="filter-btn apply" (click)="applyCeo();sl2.close();">Apply</button>
                          </div>
                        </mat-select>
                      </mat-form-field>

                      <ng-container *ngIf="formItem.field == 'private-owner'">
                      
                        <app-custom-multiple-select [label]="'Private Owner'" class="mat-select mat-select-search"
                                    [selectedValues]="selectedUsers"
                                    [selectedHighlight]="false"
                                    [valueCountView]="true"
                                    [availableOptions]="userListSearch" 
                                    [searchControl]="searchControlOwner"
                                    (selectionChange)="onPrivateOwnerSelectionChange($event)"
                                    (searchChange)="filterOptionsPrivateOwner($event)">
                        </app-custom-multiple-select>

                      </ng-container>

                  </div>
                    <div *ngSwitchCase="'textarea'" fxLayout="row" class="field-container">
                      <mat-form-field class="mog3_matinput" appearance="outline" style="width: 100%;"  [ngClass]="{'error-border': formItem.error}">
                        <textarea matInput [(ngModel)]="formItem.value" rows="10">     </textarea>
                      </mat-form-field>
                    </div>
                </div>
              </div>
            </li>
          </ng-container>
      </ng-container>
      </ul>
    </div>

  </section>

</div>
