<ng-container>
  <div class="filter-div border-top-1 pb-0">
    <section class="section mb-0">
      <div class="atom-row m-0">
        <div class="w-p108 mr-10 mb-0">
          <div class="input-group">
            <app-custom-multiple-select [label]="huntersLabel" [selectedValues]="selectedUsers" [availableOptions]="allUserListSearch" [searchControl]="searchControlOwner" (selectionChange)="onKAMSelectionChange($event)" (searchChange)="filterOptions($event)"></app-custom-multiple-select>
          </div>
        </div>
        <div class="w-p108 mr-10 mb-0">
          <div fxLayout="column" class="roleadd" *ngIf="teams != null">
            <div class="input-group">
              <app-custom-multiple-select [label]="teamsLabel" [selectedValues]="selectTeams" [availableOptions]="teamsSearch" [searchControl]="searchControlRegion" (selectionChange)="onRegionSelectionChange($event)" (searchChange)="filterOptionsTeams($event)"></app-custom-multiple-select>
            </div>
          </div>
        </div>
        <div class="w-p131 mr-10 mb-0" *ngIf="roles != null">
          <div fxLayout="column" class="roleadd">
            <div class="input-group">
              <mat-form-field appearance="outline" class="mat-select deep-0 selected-highlight" id="inputdefault">
                <mat-select #sl3 placeholder="Select Role" [(ngModel)]="selectedRole">
                  <mat-option *ngFor="let role of roles" [value]="role" (click)="onApply();sl3.close();">{{ role }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
        <!-- Account filter -->
        <div class="w-p122 mr-10 mb-0">
          <div class="input-group">
            <app-custom-select #cusComComplete [fieldData]="{
                'config' : accountConfig,
                'isFilter' : true
              }" (selectedAccountIdsChange)="handleApplySelections($event)">
            </app-custom-select>
         </div> 
        </div>
        <div class="w-p158 mr-10 mb-0">
          <div class="input-group">
            <app-custom-multiple-select [label]="businessVerticalLabel" [selectedValues]="selectedBusinessVertical" [availableOptions]="defaultBusinessVerticalSearch" [searchControl]="searchControlBusinessVertical" (selectionChange)="onBUSelectionChange($event)" (searchChange)="filterOptionsVertical($event)"></app-custom-multiple-select>
          </div>
        </div>
        <div class="w-p122 mr-10 mb-0">
          <div fxLayout="column" class="roleadd">
            <div class="input-group">
              <mat-form-field appearance="outline" class="mat-select deep-0 selected-highlight">
                <mat-select #sl4 placeholder="Select Status" [(ngModel)]="selectedStatus">
                  <mat-option value="All" (click)="onApply();sl4.close();">All</mat-option>
                  <mat-option value="Active" (click)="onApply();sl4.close();">Active</mat-option>
                  <mat-option value="inactive" (click)="onApply();sl4.close();">In Active</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="w-p215 mr-10 mb-0">
          <div class="input-group">
            <app-date-picker-custom [selectDefault]="selected" [resetDates]="resetDates" [inputData]="inputData" [placeholder]="'Time Duration'" (filterApplied)="onApplyDates($event)"></app-date-picker-custom>
          </div>
        </div>
        <div class="w-p204 mr-10 mb-0 flex items-center">
          <button (click)="clearAll();" mat-button class="btn-basic btn-text-black btn-md" color="primary">Clear All</button>
        </div>
      </div>
    </section>
  </div>
  
  <div fxLayout="row" fxLayoutAlign="start start" >
     <div fxFlex="100" fxLayout="column" >
      <div class="table-heading">
        <h2> Hunter Details </h2>
      </div>
      <mat-tab-group class="table-tab" animationDuration="0ms" mat-align-tabs="start">
        <mat-tab label="Deal Tracker"> 
            <div class="table-overflow mog3_msnsearchtable_sticky hunter-listing-table">
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 table-1 m" cdkDropList [ngClass]="{'deal-table' : boardId == '63bfa8ccade7bc466d1c780e'}"
            *ngIf="displayedColumns && displayedColumns.length > 0"  matSort (matSortChange)="sortLaneTasks($event)">
            <ng-container  matColumnDef="name" sticky>
              <th mat-header-cell *matHeaderCellDef class="table-header"  >
                <span class="col-name"> Name </span>
              </th>
              <td mat-cell *matCellDef="let element; let i = index;" style=" cursor: pointer;">
                <div fxLayout="column">
                  <div fxLayout="row" class="field-value">
                    <span class = "" [ngClass]="{'table-title': (colIndex == 0)}"> {{ element.user.name }} </span>
                  </div>
                </div>
              </td>
            </ng-container>
            <ng-container  matColumnDef="login" sticky>
              <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header  >
                <span class="col-name white-space-pre"> Last Login </span>
              </th>
              <td mat-cell *matCellDef="let element; let i = index;" style=" cursor: pointer;">
                <div fxLayout="column">
                  <div fxLayout="row" class="field-value">
                    <i class="ri-history-line last-login-history" (click)="lastLoginDialog(element)"></i>
                    <span class="w-78 time-span" [ngClass]="{'table-title': (colIndex == 0)}"> {{element.lastLoginCount}} </span>
                   
    
                  </div>
                  <div fxLayout="column" fxLayoutAlign ="center">
                    <p class="m-0 cell-sub-text">{{ element.lastLoginAt}}</p>
                  </div>
                </div>
              </td>
            </ng-container>
            <ng-container  matColumnDef="business_vertical" sticky>
              <th mat-header-cell *matHeaderCellDef class="table-header"   >
                <span class="col-name"> Business Veritcal </span>
              </th>
              <td mat-cell *matCellDef="let element; let i = index;" style=" cursor: pointer;">
                <div fxLayout="column">
                 <div  class="field-value" *ngIf="element.businessVertical != null">
                   <span [ngClass]="{'table-title': (boardDatacolIndex == 0)}"> {{stringToList(element.businessVertical)[0]}} </span>
                   
                   <!-- <span class="blue-text block" *ngIf="stringToList(element.businessVertical).length > 1" 
                     [matTooltip]="stringToList(element.businessVertical).slice(1)"> +{{ stringToList(element.businessVertical).length - 1 }} More </span> -->
  
                     <a class="blue-text block" *ngIf="stringToList(element.businessVertical).length > 1" (click)="$event.stopPropagation();" 
                        [matMenuTriggerFor]="menu"> +{{ stringToList(element.businessVertical).length - 1 }} More </a>
                          <mat-menu class="more-details-menu" #menu="matMenu">
                           
                           <p>{{ stringToList(element.businessVertical).length }} Business Vertical Assigned</p>
                           
                           <ul>
                             <li *ngFor="let bv of stringToList(element.businessVertical)">
                                <button mat-menu-item>{{bv}}</button>
                             </li>
                           </ul>
                          </mat-menu>
  
                 </div>
                </div>
              </td>
            </ng-container>
            
            <ng-container  matColumnDef="region" sticky>
              <th mat-header-cell *matHeaderCellDef class="table-header sticky-left"    >
                <span class="col-name"> Region </span>
              </th>
              <td mat-cell *matCellDef="let element; let i = index;" style=" cursor: pointer;" class="sticky-left">                
                <div  class="field-value" *ngIf="element.regions != null">
                 <span [ngClass]="{'table-title': (boardDatacolIndex == 0)}"> {{stringToList(element.regions)[0]}} </span>
                 
                 <!-- <span class="blue-text block" *ngIf="stringToList(element.regions).length > 1" 
                   [matTooltip]="stringToList(element.regions).slice(1)"> +{{ stringToList(element.regions).length - 1 }} More </span> -->
  
                   <a class="blue-text block"  *ngIf="stringToList(element.regions).length > 1" (click)="$event.stopPropagation();" 
                      [matMenuTriggerFor]="menu"> +{{ stringToList(element.regions).length - 1 }} More </a>
                        <mat-menu class="more-details-menu" #menu="matMenu">
                         <p>{{ stringToList(element.regions).length }} Regions Assigned</p>
                         <ul>
                           <li *ngFor="let region of stringToList(element.regions)">
                              <button mat-menu-item>{{region}}</button>
                           </li>
                         </ul>
                        </mat-menu>
  
  
               </div>
              </td>
            </ng-container>

            <ng-container  matColumnDef="user_head">
              <th mat-header-cell *matHeaderCellDef class=" table-header "    >
                <span class="col-name"> Head </span>
              </th>
              <td mat-cell *matCellDef="let element; let i = index;" style=" cursor: pointer;" class="sticky-right">
                <div fxLayout="column">
                  <div fxLayout="row" class="field-value" *ngIf="element.head != null">
                    <span [ngClass]="{'table-title': (colIndex == 0)}"> {{element.head.name}} </span>
                  </div>
                </div>
              </td>
            </ng-container>

            <!------------sdff-->
            <ng-container  matColumnDef="total_deals">
              <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header  >
                <span class="col-name"> Total Deals </span>
              </th>
              <td mat-cell *matCellDef="let element; let i = index;" style=" cursor: pointer;">
                <div fxLayout="column">
                  <div fxLayout="row" class="field-value">
                    <span (click)="openDealDialog(element,'totaldeal')" class = "blue-text" [ngClass]="{'table-title': (colIndex == 0)}" > {{ element.totalCount > 0 ? element.totalCount : '-' }} </span>
                  </div>
                </div>
              </td>
            </ng-container>
            <ng-container  matColumnDef="worked_on">
              <th mat-header-cell *matHeaderCellDef class="table-header"  mat-sort-header >
                <span class="col-name"> Modified Deals </span>
              </th>
              <td mat-cell *matCellDef="let element; let i = index;" style=" cursor: pointer;">
                <div fxLayout="column">
                  <div fxLayout="row" class="field-value">
                    <span (click)="openDealDialog(element,'modifieddeal')" class = "blue-text" [ngClass]="{'table-title': (colIndex == 0)}"> {{element.updatedCount > 0 ? element.updatedCount : '-'}}  </span>
                  </div>
                </div>
              </td>
            </ng-container>
            <ng-container  matColumnDef="closing_date_exceeded">
              <th mat-header-cell *matHeaderCellDef class="table-header"  mat-sort-header >
                <span class="col-name"> Closing Date Exceeded </span>
              </th>
              <td mat-cell *matCellDef="let element; let i = index;" style=" cursor: pointer;">
                <div fxLayout="column">
                  <div fxLayout="row" class="field-value">
                    <span (click)="openDealDialog(element,'closingDeal')" class = "blue-text" [ngClass]="{'table-title': (colIndex == 0)}"> {{element.closingDateExceeded > 0 ? element.closingDateExceeded : '-'}}  </span>
                  </div>
                </div>
              </td>
            </ng-container>
            <ng-container  matColumnDef="kams_handover">
              <th mat-header-cell *matHeaderCellDef class="table-header"  mat-sort-header >
                <span class="col-name"> KAMs Handover </span>
              </th>
              <td mat-cell *matCellDef="let element; let i = index;" style=" cursor: pointer;">
                <div fxLayout="column">
                  <div fxLayout="row" class="field-value">
                    <span (click)="openDealDialog(element,'kams_handover')" class = "blue-text" [ngClass]="{'table-title': (colIndex == 0)}"> {{element.handOverDeal > 0 ? element.handOverDeal : '-'}}  </span>
                  </div>
                  <div fxLayout="column" fxLayoutAlign ="center">
                    <p class="m-0 cell-sub-text">{{ element.kamHandOverSum}} cr</p>
                  </div>
                </div>
              </td>
            </ng-container>
            <ng-container  matColumnDef="action_date_exceeded">
              <th mat-header-cell *matHeaderCellDef class="table-header"  mat-sort-header >
                <span class="col-name"> Action Date Exceeded </span>
              </th>
              <td mat-cell *matCellDef="let element; let i = index;" style=" cursor: pointer;">
                <div fxLayout="column">
                  <div fxLayout="row" class="field-value">
                    <span (click)="openDealDialog(element,'nextActionDateDeal')" class = "blue-text" [ngClass]="{'table-title': (colIndex == 0)}"> {{element.nextActionDateExceeded > 0 ? element.nextActionDateExceeded : '-'}}  </span>
                  </div>
                </div>
              </td>
            </ng-container>
            <ng-container  matColumnDef="duration">
              <th mat-header-cell *matHeaderCellDef class="table-header"   mat-sort-header>
                <span class="col-name"> Duration </span>
              </th>
              <td mat-cell *matCellDef="let element; let i = index;" style=" cursor: pointer;">
                <div fxLayout="column">
                  <div fxLayout="row" class="field-value">
                    <span [ngClass]="{'table-title': (colIndex == 0)}"> {{durations[1].title}}  </span>
                  </div>
                </div>
              </td>
            </ng-container>
            <ng-container  matColumnDef="deal_size">
              <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header   >
                <span class="col-name"> Deal Worth (Cr)</span>
              </th>
              <td mat-cell *matCellDef="let element; let i = index;" style=" cursor: pointer;">
                <div fxLayout="column">
                  <div fxLayout="row" class="field-value">
                    <span [ngClass]="{'table-title': (colIndex == 0)}"> {{nFormatter(element.totalSum, 3)}} Cr </span>
                  </div>
                </div>
              </td>
            </ng-container>
            <ng-container  matColumnDef="primary_deals">
              <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header   >
                <span class="col-name"> Primary Deals</span>
              </th>
              <td mat-cell *matCellDef="let element; let i = index;" style=" cursor: pointer;">
                <div fxLayout="column">
                  <div fxLayout="row" class="field-value">
                    <span (click)="openDealDialog(element,'primary')" class = "blue-text" [ngClass]="{'table-title': (colIndex == 0)}"> {{element.primaryDeal > 0 ? element.primaryDeal : '-'}} </span>
                  </div>
                  <div fxLayout="column" fxLayoutAlign ="center">
                    <p class="m-0 cell-sub-text">{{ element.primaryAddedDeal}} New</p>
                  </div>
                </div>
              </td>
            </ng-container>
            <ng-container  matColumnDef="secondary_deals">
              <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header   >
                <span class="col-name"> Secondary Deals</span>
              </th>
              <td mat-cell *matCellDef="let element; let i = index;" style=" cursor: pointer;">
                <div fxLayout="column">
                  <div fxLayout="row" class="field-value">
                    <span (click)="openDealDialog(element,'secondary')" class = "blue-text" [ngClass]="{'table-title': (colIndex == 0)}"> {{element.secondaryDeal > 0 ? element.secondaryDeal :'-'}} </span>
                  </div>
                  <div fxLayout="column" fxLayoutAlign ="center">
                    <p class="m-0 cell-sub-text">{{ element.secondaryAddedDeal}} New</p>
                  </div>
                </div>
              </td>
            </ng-container>
            <ng-container  matColumnDef="active_deals">
              <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header   >
                <span class="col-name"> Active Deals</span>
              </th>
              <td mat-cell *matCellDef="let element; let i = index;" style=" cursor: pointer;">
                <div fxLayout="column">
                  <div fxLayout="row" class="field-value">
                    <span (click)="openDealDialog(element,'activedeal')" class = "blue-text" [ngClass]="{'table-title': (colIndex == 0)}"> {{element.activeDeal > 0 ? element.activeDeal : '-'}} </span>
                  </div>
                  <div fxLayout="column" fxLayoutAlign ="center">
                    <p class="m-0 cell-sub-text">{{ element.activeDealSum}} cr </p>
                  </div>
                </div>
              </td>
            </ng-container>
            <ng-container  matColumnDef="won_deals">
              <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header   >
                <span class="col-name"> Won Deals</span>
              </th>
              <td mat-cell *matCellDef="let element; let i = index;" style=" cursor: pointer;">
                <div fxLayout="column">
                  <div fxLayout="row" class="field-value">
                    <span (click)="openDealDialog(element,'wondeal')" class = "blue-text" [ngClass]="{'table-title': (colIndex == 0)}"> {{element.wonDeal > 0 ? element.wonDeal : '-'}} </span>
                  </div>
                  <div fxLayout="column" fxLayoutAlign ="center">
                    <p class="m-0 cell-sub-text">{{ element.wonDealSum}} cr </p>
                  </div>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef>
                <div style="text-align: center;">
                 <span class="col-name">
                View Deals
              </span>
                </div>
              </th>
              <td mat-cell *matCellDef="let element">
                <div class="actions-list" (click)="openDialog(element)" style="text-align: center; cursor: pointer;">
                  <i class="ri-eye-line" [matTooltip]="View" matTooltipClass="new-tooltip"
                    ></i>
                </div>
              </td>

            </ng-container>
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>
                <div style="text-align: center;">
                 <span class="col-name">
                Status
              </span>
                </div>
              </th>
              <td mat-cell *matCellDef="let element">
                <span *ngIf="element.user.activeLogin" class="status active">Active</span>
                <span *ngIf="!element.user.activeLogin" class="status inactive">In Active</span>
              </td>

            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns2; sticky: true">

            </tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns2; let i = index"
              [ngClass]="{ 'active-row': i === selectedTrIndex }">

            </tr>
          </table>
        </div>
        </mat-tab>
        <mat-tab label="Meeting Tracker"> 
          <div class="table-overflow mog3_msnsearchtable_sticky hunter-listing-table">
            <table mat-table class="w-100 table-1" [dataSource]="dataSource" [ngClass]="{'deal-table' : boardId == '63bfa8ccade7bc466d1c780e'}"
            matSort (matSortChange)="sortLaneTasks($event)"
            >  
            <ng-container  matColumnDef="name" sticky>
              <th mat-header-cell *matHeaderCellDef class="table-header"  >
                <span class="col-name"> Name </span>
              </th>
              <td mat-cell *matCellDef="let element; let i = index;" style=" cursor: pointer;">
                <div fxLayout="column">
                  <div fxLayout="row" class="field-value">
                    <span class = "" [ngClass]="{'table-title': (colIndex == 0)}"> {{ element.user.name }} </span>
                  </div>
                </div>
              </td>
            </ng-container>
            <ng-container  matColumnDef="login" sticky>
              <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header  >
                <span class="col-name white-space-pre"> Last Login </span>
              </th>
              <td mat-cell *matCellDef="let element; let i = index;" style=" cursor: pointer;">
                <div fxLayout="column">
                  <div fxLayout="row" class="field-value">
                    <i class="ri-history-line last-login-history" (click)="lastLoginDialog(element)"></i>
                    <span class="w-78 time-span" [ngClass]="{'table-title': (colIndex == 0)}"> {{element.lastLoginCount}} </span>
                   
    
                  </div>
                  <div fxLayout="column" fxLayoutAlign ="center">
                    <p class="m-0 cell-sub-text">{{ element.lastLoginAt}}</p>
                  </div>
                </div>
              </td>
            </ng-container>
            <ng-container  matColumnDef="business_vertical" sticky>
              <th mat-header-cell *matHeaderCellDef class="table-header"   >
                <span class="col-name"> Business Veritcal </span>
              </th>
              <td mat-cell *matCellDef="let element; let i = index;" style=" cursor: pointer;">
                <div fxLayout="column">
                 <div  class="field-value" *ngIf="element.businessVertical != null">
                   <span [ngClass]="{'table-title': (boardDatacolIndex == 0)}"> {{stringToList(element.businessVertical)[0]}} </span>
                   
                   <!-- <span class="blue-text block" *ngIf="stringToList(element.businessVertical).length > 1" 
                     [matTooltip]="stringToList(element.businessVertical).slice(1)"> +{{ stringToList(element.businessVertical).length - 1 }} More </span> -->
  
                     <a class="blue-text block" *ngIf="stringToList(element.businessVertical).length > 1" (click)="$event.stopPropagation();" 
                       [matMenuTriggerFor]="menu"> +{{ stringToList(element.businessVertical).length - 1 }} More </a>
                          <mat-menu class="more-details-menu" #menu="matMenu">
                           
                           <p>{{ stringToList(element.businessVertical).length }} Business Vertical Assigned</p>
                           
                           <ul>
                             <li *ngFor="let bv of stringToList(element.businessVertical)">
                                <button mat-menu-item>{{bv}}</button>
                             </li>
                           </ul>
                          </mat-menu>
  
                 </div>
                </div>
              </td>
            </ng-container>
            <ng-container  matColumnDef="region" sticky>
              <th mat-header-cell *matHeaderCellDef class="table-header sticky-left">
                <span class="col-name"> Region </span>
              </th>
              <td mat-cell *matCellDef="let element; let i = index;" style=" cursor: pointer;" class="sticky-left">                
                <div  class="field-value" *ngIf="element.regions != null">
                 <span [ngClass]="{'table-title': (boardDatacolIndex == 0)}"> {{stringToList(element.regions)[0]}} </span>
                 
                 <!-- <span class="blue-text block" *ngIf="stringToList(element.regions).length > 1" 
                   [matTooltip]="stringToList(element.regions).slice(1)"> +{{ stringToList(element.regions).length - 1 }} More </span> -->
  
                   <a class="blue-text block"  *ngIf="stringToList(element.regions).length > 1" (click)="$event.stopPropagation();" 
                     [matMenuTriggerFor]="menu"> +{{ stringToList(element.regions).length - 1 }} More </a>
                        <mat-menu class="more-details-menu" #menu="matMenu">
                         <p>{{ stringToList(element.regions).length }} Regions Assigned</p>
                         <ul>
                           <li *ngFor="let region of stringToList(element.regions)">
                              <button mat-menu-item>{{region}}</button>
                           </li>
                         </ul>
                        </mat-menu>
  
  
               </div>
              </td>
            </ng-container>
            <ng-container  matColumnDef="user_head">
              <th mat-header-cell *matHeaderCellDef class=" table-header sticky-right"   >
                <span class="col-name"> Head </span>
              </th>
              <td mat-cell *matCellDef="let element; let i = index;" style=" cursor: pointer;" class="sticky-right">
                <div fxLayout="column">
                  <div fxLayout="row" class="field-value" *ngIf="element.head != null">
                    <span [ngClass]="{'table-title': (colIndex == 0)}"> {{element.head.name}} </span>
                  </div>
                </div>
              </td>
            </ng-container>

         
              <!-- Conducted Meeting -->
              <ng-container matColumnDef="conducted_meeting">  
              
              <th mat-header-cell *matHeaderCellDef>Conducted Meeting</th>  
              
              <td mat-cell *matCellDef="let element">
                <span class="blue-text hyperlinktxt" (click)="openMeetingDialog(element, 'conductedMeeting')">   {{ element.conductedMeeting > 0 ? element.conductedMeeting : '-' }} </span>
                 </td>  
              
              </ng-container>  
              
              <!-- Upcoming Meetings -->
              <ng-container matColumnDef="upcoming_meetings">  
              
                <th mat-header-cell *matHeaderCellDef> Upcoming Meetings </th>  
                
                <td mat-cell *matCellDef="let element">  
                  <span class="blue-text hyperlinktxt" (click)="openMeetingDialog(element, 'upcomingList')">
                    {{ element.futureMeeting > 0 ? element.futureMeeting : '-' }} </span>
                
                </td>  
                
                </ng-container>  

              <!-- MOM  -->
              <ng-container matColumnDef="mom">  
              
              <th mat-header-cell *matHeaderCellDef> MOM </th>  
              
              <td mat-cell *matCellDef="let element">
                <span class="blue-text hyperlinktxt" (click)="openMeetingDialog(element, 'momList')"> {{ element.momCount > 0 ? element.momCount : '-' }}  </span>
                 </td>  
              
              </ng-container>  
              
              <!-- Next Action Items -->
              <ng-container matColumnDef="next_action_items">  
              
              <th mat-header-cell *matHeaderCellDef>
                
                Next Action Items </th>  
              
                <td mat-cell *matCellDef="let element">
                  <ng-container *ngIf="element.actionItem; else showDash">
                    <p style="width: 200px; cursor: pointer;" [innerHtml]="element.actionItem.message" class="comment-message overflow-auto" (click)="moreActionItemsDialog(element);"></p>
                    <button type="button" class="read-more-cta" (click)="moreActionItemsDialog(element);" *ngIf="element.actionItem !== null && element.actionItemCount > 1">
                      + {{ element.actionItemCount - 1 }} More
                    </button>
                  </ng-container>
                  <ng-template #showDash>N/A</ng-template>
                </td>
              </ng-container>  
              
              <tr mat-header-row *matHeaderRowDef="displayColumns5;sticky:true;"></tr>  
              
              <tr mat-row *matRowDef="let row; columns: displayColumns5;"></tr>  
              
              </table>  
           </div>  
        </mat-tab>
      </mat-tab-group>
     </div>
  </div>     
      <ng-container *ngIf="((laneTaskList != null && laneTaskList.length < 1)) && !isLoading" style="background: #ffffff;">
        <div  fxLayout="row"  fxLayoutAlign="center center">
          No Document to Show.
        </div>
    </ng-container>
      <div fxLayout="row" class="section-width  divider">
        <mat-divider></mat-divider>
      </div>
    

    <div fxLayout="row" fxLayoutAlign="end center" class="section-width ">
      <div fxLayout="row">
        <div fxLayout="row" class="pagination-wrapper" >
          <!-- <mat-paginator #paginator [length]="totalRecords" class="custom-pagintor" [pageSize]="pageSize"
            (page)="onScrollDown(false)" showFirstLastButtons>
          </mat-paginator> -->
        </div>
      </div>
    </div>

    <div *ngIf="isLoading" class="overlay">
      <mat-spinner class="spinner" [diameter]="60"></mat-spinner>
    </div>
</ng-container>