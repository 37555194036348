import { Component, OnInit, Inject, ElementRef, ViewChild, HostBinding, AfterViewInit, AfterContentInit, ChangeDetectorRef, AfterContentChecked, ChangeDetectionStrategy, NgZone, Input, QueryList, ViewChildren } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { KanbanService } from '../../../kanban.service';
import { SettingService } from 'src/app/settings/setting.service';
import { SelectAutocompleteComponent } from 'mat-select-autocomplete';
import { COMMA, ENTER, F } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent, MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as lodash from 'lodash';
import * as moment from "moment";
import { LaneMoveComponent } from '../../lanes-list/lane-move/lane-move.component';
import { UploadMappingComponent } from 'src/app/settings/upload-mapping/upload-mapping/upload-mapping.component';
import { PagesService } from 'src/app/pages/pages.service';
import { AuthService } from 'src/app/auth/auth.service';
import { isEmpty } from "lodash"
import { CommentsTabComponent } from '../card-details/comments-tab/comments-tab.component';
import { LaneTaskUsersTabComponent } from '../card-details/lane-task-users-tab/lane-task-users-tab.component';
import { LaneTaskDocumentsTabComponent } from '../card-details/lane-task-documents-tab/lane-task-documents-tab.component';
import { HistoryTabComponent } from '../card-details/history-tab/history-tab.component';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { take } from 'rxjs/operators';
import 'quill-mention';
import { QuillEditorComponent } from 'ngx-quill'
import {
  animate, state, style, transition, trigger
} from '@angular/animations';
import { Constants, generateQuery } from 'src/app/Constants';
import { MatSelect, MatTableDataSource } from '@angular/material';
import { ErrorToastComponent } from 'src/app/shared-files/error-toast/error-toast.component';
import { SuccessToastComponent } from 'src/app/shared-files/success-toast/success-toast.component';
import { MatSnackBar } from '@angular/material';
import { FormArrayExampleComponent } from '../../../form-array-example/form-array-example.component';
import { FieldAutocompleteComponent } from '../field-autocomplete/field-autocomplete.component';
@Component({
  selector: 'app-create-lane-task',
  templateUrl: './create-lane-task.component.html',
  styleUrls: ['./create-lane-task.component.scss']
})
export class CreateLaneTaskComponent implements OnInit {
  @Input() selectedLane: any;
  currentUser: string;
  laneTaskIdByUrl: any;
  laneTaskIDDetail: any;
  laneArrayKeys: any;
  boardId: string;
  formDetails: FormGroup;
  currentTabId: string;
  leadName: string;
  laneTaskConfig: any;
  selectedBoardLane: any;
  boardName: any;
  boardLane: any;
  isEditableAccess: any;
  selectedLanePosition: any;
  selectedlane: any;
  breakpoint: any;
  _: any = lodash;
  content = ''
  matContent = ''
  boardIdForSobContacts: string = Constants.boardIdForSobContacts;
  boardIdForCRMContacts: string = Constants.boardIdForCRMContacts;
  boardIdForCRMAccount: string = Constants.boardIdForCRMAccount;
  boardIdForSobPlant: string = Constants.boardIdForSobPlant;
  rateControl = new FormControl("", [Validators.max(100), Validators.min(0)])
  console = console;
  isChecked: boolean = false;
  isCountryIndia: boolean = false;
  saveError: any = {};
  accountId: any;
  laneId: any;
  pageFrom = 0;
  pageSize = 200;
  totalRecords: any;
  laneTaskList = [];
  leadListing: any = [];
  dataSource = new MatTableDataSource(this.leadListing);
  isLoading: boolean = true;
  pageIndex = -1;
  accountContactList: any = [];
  accountContactListSearch: any = [];
  accountCEOList: any = [];
  accountCEOListSearch: any = [];

  @ViewChild('sl2') sl2: MatSelect;
  selectedPlantIds: string[] = [];
  selectedCEOIds: string[] = [];
  roleTypeValues = [];
  @ViewChildren(FormArrayExampleComponent) formArrayComponent!: QueryList<FormArrayExampleComponent>;
  @ViewChildren(FieldAutocompleteComponent) fieldAutoComponent!: QueryList<FieldAutocompleteComponent>;
  designationQuery: any = [];
  accountContactCeoList: any;
  accountContactCeoListSearch: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder, public toasterService: ToastrService, public dialog: MatDialog, private cd: ChangeDetectorRef, private activatedRoute: ActivatedRoute, public router: Router, public settingServ: SettingService, public _kService: KanbanService,
    private pagesService: PagesService, private authService: AuthService, private _ngZone: NgZone, private dialogRef: MatDialogRef<CreateLaneTaskComponent>, private _snackBar: MatSnackBar) {
    this.console.log('CreateLaneTaskComponent', this.data, " data: ", data);
    this.data = data
  }

  ngAfterViewChecked() {
    this.cd.detectChanges();
  }

  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  ngOnInit() {
    this.console.log("ngOnInit data: ", this.data)
    let getSessionStorage = JSON.parse(localStorage.getItem("USERINFO"));
    if (getSessionStorage && getSessionStorage['role']) {
      this.currentUser = getSessionStorage['role']['userName'];
    }
    let masterAccId = getSessionStorage['role']['masterAccount']['id'];
    // this.pagesService.getHeaderList(masterAccId).subscribe(list => {
    //   this.authService.storageListHeaders(list['result']);
    // })
    if (this.currentUser) {
      if (this.data['boardName']) {
        this.boardName = this.data['boardName'];
      }
      if (this.data.selectedLane) {
        this.selectedLane = this.data.selectedLane;
      }
      this.boardId = this.data['boardId'];
      this.getLaneConfigById();
    }
  }
  closeDialog() {
    this.dialogRef.close()
  }
  getOptionList(list) {
    if (!list) {
      return [];
    }
    list.sort(function (a, b) {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });
    return list;
  }
  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }
  formatPaymentType(value) {
    return this._.startCase(value);
  }
  getBoardLaneById() {
    this.setBreakPoint();
    this._kService.getLaneWithUserAccess(this.boardId, '').subscribe(res => {
      this.boardLane = res['result']['levelAccess'];
      this.changeLaneTask(this.laneTaskIDDetail.lane.id);
      console.log(this.selectedBoardLane);
    });

  }
  changeLaneTask(laneTaskDetailsId) {
    let currentLaneStatus: string
    currentLaneStatus = this.laneTaskIDDetail.lane.name
    this.selectedBoardLane = [];
    let forwardMove;
    let backMove
    for (let i = 0; i < this.boardLane.length; i++) {
      this.boardLane[i]['laneDTO']['backMove'] = false;
      this.boardLane[i]['laneDTO']['forwardMove'] = false;
      this.boardLane[i]['laneDTO']['selected'] = false;
      this.boardLane[i]['laneDTO']['current'] = false;
      this.boardLane[i]['laneDTO']['cold'] = false;
      if (this.boardLane[i]['laneDTO'].id == laneTaskDetailsId) {
        let laneAccessTypeDTO = this.boardLane[i]['accessType'];
        if (laneAccessTypeDTO.length == 1) {
          if (laneAccessTypeDTO.includes('VIEW')) {
            this.isEditableAccess = false
          }
        }
        this.selectedLanePosition = this.boardLane[i]['laneDTO'].position;
        forwardMove = this.selectedLanePosition + 1;
        backMove = this.selectedLanePosition - 1;
        this.selectedBoardLane.push(this.boardLane[i]['laneDTO']);
        this.boardLane[i]['laneDTO']['current'] = true;
        this.boardLane[i]['laneDTO']['selected'] = true;
        this.boardLane[i]['laneDTO']['isEditable'] = this.boardLane[i]['accessType'][0]
        this.selectedlane = this.boardLane[i]['laneDTO'].name;
      }
    }

    if (currentLaneStatus == 'cold-leads') {
      let laneResultPosition = this.laneTaskIDDetail.fromLane.position - 1
      this.boardLane[laneResultPosition]['laneDTO']['backMove'] = true;
      this.boardLane[laneResultPosition]['laneDTO']['selected'] = false;
      this.selectedBoardLane.push(this.boardLane[laneResultPosition]['laneDTO']);
    } else {
      for (let i = 0; i < this.boardLane.length; i++) {
        if (this.selectedlane != 'cold-leads') {
          if (this.boardLane[i]['laneDTO'].name == 'cold-leads') {
            this.boardLane[i]['laneDTO']['isEditable'] = this.boardLane[i]['accessType'][0]
            this.boardLane[i]['laneDTO']['cold'] = true;
            this.selectedBoardLane.push(this.boardLane[i]['laneDTO'])
          }
        }
        if (this.selectedlane != 'cold-leads') {
          if (this.boardLane[i]['laneDTO'].position == forwardMove) {
            if (this.selectedlane != 'buyer-acceptance') {
              this.boardLane[i]['laneDTO']['forwardMove'] = true;
              this.boardLane[i]['laneDTO']['selected'] = false;
              this.boardLane[i]['laneDTO']['isEditable'] = this.boardLane[i]['accessType'][0]
              this.selectedBoardLane.push(this.boardLane[i]['laneDTO'])
            }

          }
          else if (this.boardLane[i]['laneDTO'].position == backMove) {
            this.boardLane[i]['laneDTO']['backMove'] = true;
            this.boardLane[i]['laneDTO']['selected'] = false;
            this.boardLane[i]['laneDTO']['isEditable'] = this.boardLane[i]['accessType'][0]
            this.selectedBoardLane.push(this.boardLane[i]['laneDTO'])
          }
        }
      }
    }
  }

  onResize(event) {
    //this.breakpoint = (event.target.innerWidth <= 400) ? 1 : 6;
    this.setBreakPoint();
  }
  setBreakPoint() {
    if ((window.innerWidth <= 400)) {
      this.breakpoint = 3
    } else if ((window.innerWidth > 400) && (window.innerWidth <= 768)) {
      this.breakpoint = 3
    } else if ((window.innerWidth > 768) && (window.innerWidth <= 1390)) {
      this.breakpoint = 3;
    } else if ((window.innerWidth > 1390)) {
      this.breakpoint = 3;
    }
  }
  validateEmail(formItem) {
    if (!formItem.value.toString()
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )) {
      formItem.error = true;
    } else {
      formItem.error = false;
    }
    return formItem;
  };
  valideateWithDebounce(formItem, allowedLength) {
    this._.debounce(this.validateLength(formItem, allowedLength), 100);
  }
  validateLength(formItem, allowedLength) {
    const errorMessage = "Phone number should have a length of 10 characters.";
    if (formItem.value == null || formItem.value.toString().trim().length != allowedLength) {
      formItem.error = errorMessage;
    } else {
      formItem.error = false;
    }
  }

  onSelectOption(formItem: any, selectedValue: any) {
    const fieldName = formItem.field;

    if (formItem.field === 'country') {
      this.isCountryIndia = formItem.value === 'India';
      const eventNameElement = this.laneTaskConfig.find(element => element.field === 'mobile-number');
      if (eventNameElement) {
        eventNameElement.value = '';
      }
    }

    if (formItem.field === 'lead-source') {
      const isEvent = formItem.value === 'Event';
      const eventNameElement = this.laneTaskConfig.find(element => element.field === 'event-name');
      const eventDateElement = this.laneTaskConfig.find(element => element.field === 'event-date');

      if (eventNameElement) {
        eventNameElement.optional = isEvent;
      }
      if (eventDateElement) {
        eventDateElement.optional = isEvent;
      }
    }

    if (formItem.field == "role" && (this.boardId == this.boardIdForSobContacts || this.boardId == this.boardIdForCRMContacts)) {
      this.selectedCEOIds = [];
      this._kService.getRoleTypeByRoleName(formItem.value).subscribe(res => {
        if (res && res['result']) {
          this.roleTypeValues = res['result'];
        }
        this.laneTaskConfig.forEach(element => {
          if (element.field === 'role-type') {
            element.value = '';
          }
          if (element.field === 'add-ceo') {
            element.values = null;
          }
          if (element.field === 'private-owner') {
            element.values = null;
            element.optional = false;
          }
        });
      });
    }
    if (formItem.field === 'role-type') {
      const isEA = formItem.value === 'EA';
      const isCEO = formItem.value === 'CEO';
      this.loadPrivateOwners();
      this.laneTaskConfig.forEach(element => {
        if (element.field === 'add-ceo') {
          element.optional = isEA;
        }
        if (element.field === 'private-owner') {
          element.optional = isEA || isCEO;
        }
        if (formItem.value === 'EA' || formItem.value === 'CEO') {
          if (element.field === 'mobile-number') {
            element.optional = false;
          }
          if (element.field === 'email') {
            element.optional = false;
          }
        }
        else {
          if (element.field === 'mobile-number') {
            element.optional = true;
          }
          if (element.field === 'email') {
            element.optional = true;
          }
        }
      });

      if (isEA) {
        this.openAddCEOSelect();
      }
    }
  }


  saveLaneTask() {
    let isValidResp = this.validateLaneTask();
    if (!isValidResp || !isValidResp['isValid']) {
      this.saveError = isValidResp;
      this._snackBar.openFromComponent(ErrorToastComponent, {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: ['atom-toast', 'error'],
        duration: 6000,
        data: isValidResp.message,
      });

      // this.toasterService.error(isValidResp.message);

      return;
    }
    let obj =
    {
      "laneId": this.selectedLane.id,
      "name": name,
      "pinned": true,
      "leadSource": "SYSTEM",
      "data": this.laneTaskConfig
    }

    this._kService.createLaneTask(obj).subscribe(res => {
      this._snackBar.openFromComponent(SuccessToastComponent, {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: ['atom-toast', 'success'],
        duration: 6000,
        data: "Details Saved Successfully"
      });

      // this.toasterService.success("Details Saved Successfully");

      this.dialogRef.close({
        "obj": obj,
        "res": res
      });
    });
  }
  validateLaneTask() {
    let kp = [];
    let error = false;
    let errorMessage = null;

    let name = "";
    for (let i = 0; i < this.laneTaskConfig.length; i++) {
      let tmp = this.laneTaskConfig[i];
      if (tmp.error) {
        tmp.error = null;
      }
      this.console.log("laneTask config ", tmp.field, tmp)

      if (tmp.display) {
        if (tmp.optional && tmp.type == "string") {
          if (!tmp.value || tmp.value.toString().trim() == "") {
            tmp.error = tmp.displayName + ' :  Required';
            error = true;
          }
        }
        else if (tmp.optional && tmp.type == "number") {
          if (!tmp.value || tmp.value.toString().trim() == "") {
            tmp.error = tmp.displayName + ' :  Required';
            error = true;
          }
        }
        else if (tmp.optional && tmp.type == "multiSelect") {
          if (tmp.values == null || tmp.values.length < 1) {
            tmp.error = tmp.displayName + ' :  Required';
            error = true;
          }
        }
        else if (tmp.type == "date") {
          if (tmp.optional && (tmp.value == null || tmp.value.toString().trim() == "")) {
            tmp.error = tmp.displayName + ' : Required';
            error = true;
          }
        }
        else if (tmp.optional && tmp.type == "currency") {
          if (!tmp.value || tmp.value.toString().trim() == "") {
            tmp.error = tmp.displayName + ' :  Required';
            error = true;
          }
        }
        else if (tmp.optional && tmp.type == "textarea") {
          if (!tmp.value || tmp.value.toString().trim() == "") {
            tmp.error = tmp.displayName + ' :  Required';
            error = true;
          }
        }
        else if (tmp.optional && tmp.type == "select") {
          if (!tmp.value || tmp.value.toString().trim() == "") {
            tmp.error = tmp.displayName + ' :  Required';
            error = true;
          }
        }
        else if (tmp.optional && tmp.type == "phone") {
          const phoneNumber = tmp.value.toString().trim();
          var pattern = /^[0-9]+$/;
          const commonErrorMessage = "Phone number should be numeric.";
          const indiaErrorMessage = "Please enter 10 digit mobile number.";
          if (this.boardId === this.boardIdForCRMContacts) {
            if (phoneNumber && phoneNumber.length > 0) {
              if (!phoneNumber.match(pattern)) {
                tmp.error = commonErrorMessage;
                error = true;
              }
              else if (this.isCountryIndia && phoneNumber.length !== 10) {
                tmp.error = indiaErrorMessage;
                error = true;
              }
            } else {
              tmp.error = tmp.displayName + ' :  Required';
              error = true;
            }
          }
          else {
            if (phoneNumber && phoneNumber.length > 0) {
              if (!phoneNumber.match(pattern)) {
                tmp.error = commonErrorMessage;
                error = true;
              } else if (phoneNumber.length !== 10) {
                tmp.error = indiaErrorMessage;
                error = true;
              }
            } else {
              tmp.error = tmp.displayName + ' :  Required';
              error = true;
            }
          }
        }
        else if (tmp.optional && tmp.type == "email") {
          if (!tmp.value || tmp.value.toString().trim() == "" || this.validateEmail(tmp)['error']) {
            tmp.error = tmp.displayName + " Invalid Email  : ";
            error = true;
          }
        }
        else if (tmp.optional && tmp.type == "belongsTo") {
          if (tmp.field == "plant") {
            if (!tmp.values || tmp.values.length == 0) {
              tmp.error = tmp.displayName + ' : Required';
              error = true;
            }
          }
          else if (tmp.key == "userTeam") {
            if (!tmp.value || tmp.value.toString().trim() == "") {
              tmp.error = tmp.displayName + ' :  Required';
              error = true;
            }
          }
          else if (tmp.field == "add-ceo" && (this.boardId == Constants.boardIdForSobContacts || this.boardId == Constants.boardIdForCRMContacts)) {
            if (!tmp.values || (tmp.values && tmp.values.length === 0)) {
              tmp.error = tmp.displayName + ' : Required';
              error = true;
            }
          }
          else if (tmp.field == "private-owner" && (this.boardId == Constants.boardIdForSobContacts || this.boardId == Constants.boardIdForCRMContacts)) {
            if (!tmp.values || (tmp.values && tmp.values.length === 0)) {
              tmp.error = tmp.displayName + ' : Required';
              error = true;
            }
          }
          else if (this.fieldAutoComponent) {
            this.fieldAutoComponent.some(child => {
              if (child.fieldData.config.field == tmp.field) {
                let valid = child.validate();
                console.log('valid check for field', child.fieldData.config.displayName, valid);
                if (!valid) {
                  tmp.error = child.fieldData.config.displayName + ' : Required';
                  error = true;
                  return true;
                }
              }
            });
          }
        }
        else if (tmp.optional && tmp.type == "form") {
          if (this.formArrayComponent) {
            this.formArrayComponent.forEach(child => {
              let isValidForm = child.onSubmit();
              console.log('valid check', isValidForm);
              if (!isValidForm) {
                tmp.error = tmp.displayName + " Invalid Form  : ";
                error = true;
                return;
              }
            });
          }
        }
        else {
          tmp.error = null;
        }

        if (tmp.nameField) {
          name = tmp.value;
        }
        if (tmp.error) {
          if (!errorMessage) {
            errorMessage = tmp.error;
          }
          error = true;
        }
        kp.push(tmp);
      }
    }

    if (error) {
      this.laneTaskConfig = kp;
      return {
        "message": errorMessage,
        "isValid": false
      };
    } else {
      return {
        "message": "",
        "isValid": true
      };
    }
  }
  showComments() {
    const dialogRef = this.dialog.open(CommentsTabComponent, {
      disableClose: false,
      width: '40%',
      height: '80%',
      position: { bottom: '0px', right: '0px' },
      data: {
        laneTask: this.laneTaskIDDetail,
        userList: []
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result)

    });
  }
  showHistory(task) {
    const dialogRef = this.dialog.open(HistoryTabComponent, {
      disableClose: false,
      width: '70%',
      height: '80%',
      panelClass: 'right-side-dialog',
      //  position : {bottom: '0px', right:'0px'} ,
      data: {
        laneTask: this.laneTaskIDDetail
      }
    });
  }
  getLaneConfigById() {
    this._kService.getLaneBoardConfigById(this.boardId).subscribe(res => {

      let result = res['result'];
      result.sort(function (a, b) {
        return a.index - b.index;
      });
      this.laneTaskConfig = result;
      // this.onCheckboxChange();
    });
  }

  onCheckboxChange() {
    if (this.laneTaskConfig) {
      for (let i = 0; i < this.laneTaskConfig.length; i++) {
        if (this.laneTaskConfig[i].field === 'plant') {
          this.laneTaskConfig[i].optional = !this.isChecked;
        }
      }
    }
  }

  openPlantSelect(formItem: any) {
    this.getPicksList();
    if (this.sl2) {
      this.sl2.open();
    }
  }

  openAddCEOSelect() {
    let obj = {
      "where": {
        "boardId": {
          "type": "search",
          "operator": "or",
          "value": [
            {
              "field": "boardId.raw",
              "value": this.boardId
            }
          ]
        }
      },
      "sort": { "field": "updatedAt", "order": "desc" },

      "from": this.pageFrom,
      "size": this.pageSize
    }
    let query = {
      "query": {
        "nestedWhere": {}
      }
    };
    let tQuery = [];
    tQuery.push({
      "field": "data.roletype.raw",
      "value": "CEO"
    })
    query['query']['nestedWhere']["roleType_query"] = {};
    query['query']['nestedWhere']["roleType_query"]["type"] = "search";
    query['query']['nestedWhere']["roleType_query"]["operator"] = "or";
    query['query']['nestedWhere']["roleType_query"]["value"] = tQuery;


    for (let index = 0; index < this.laneTaskConfig.length; index++) {
      const element = this.laneTaskConfig[index];
      if (element.field == 'account' && element.value !== '') {
        this.accountId = element.value;
      }
    }
    if (!this.accountId) {

      this._snackBar.openFromComponent(ErrorToastComponent, {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: ['atom-toast', 'error'],
        duration: 6000,
        data: "Please select Account first."
      });
      return;
    }
    else {
      let tQuery = [];
      tQuery.push({
        "field": "data.account.raw",
        "value": this.accountId
      })
      query['query']['nestedWhere']["account_query"] = {};
      query['query']['nestedWhere']["account_query"]["type"] = "search";
      query['query']['nestedWhere']["account_query"]["operator"] = "or";
      query['query']['nestedWhere']["account_query"]["value"] = tQuery;

    }
    obj['nestedWhere'] = query['query']['nestedWhere'];
    this._kService.getLaneTask(obj).subscribe(res => {
      if (res == null) {
        return;
      }
      else {
        this.accountContactCeoList = res['result'];
        this.accountContactCeoListSearch = this.accountContactCeoList
      }
      this.pageIndex++;
    },
      (error) => {
        console.log('getLaneTask error', error)
      }
    )

  }

  getPicksList() {
    for (let index = 0; index < this.laneTaskConfig.length; index++) {
      const element = this.laneTaskConfig[index];
      if (element.field == 'account' && element.value !== '') {
        this.accountId = element.value;
      }
    }
    if (!this.accountId) {

      this._snackBar.openFromComponent(ErrorToastComponent, {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: ['atom-toast', 'error'],
        duration: 6000,
        data: "Please select Account first."
      });
      return;
    }
    let obj = {
      "where": {
        "boardId": {
          "type": "search",
          "operator": "or",
          "value": [
            {
              "field": "boardId.raw",
              "value": this.boardIdForSobPlant
            }
          ]
        }
      },
      "sort": { "field": "updatedAt", "order": "desc" },

      "from": this.pageFrom,
      "size": this.pageSize
    }
    let tQuery = [];
    tQuery.push({
      "field": "data.account.raw",
      "value": this.accountId
    })
    let query = {
      "query": {
        "nestedWhere": {}
      }
    };
    query['query']['nestedWhere']["account_query"] = {};
    query['query']['nestedWhere']["account_query"]["type"] = "search";
    query['query']['nestedWhere']["account_query"]["operator"] = "or";
    query['query']['nestedWhere']["account_query"]["value"] = tQuery;

    obj['nestedWhere'] = query['query']['nestedWhere'];
    this.getLaneTask(obj);
  }

  getLaneTask(obj) {
    this._kService.getLaneTask(obj).subscribe(res => {
      if (res == null) {
        return;
      }
      else {
        this.accountContactList = res['result'];
        this.accountContactListSearch = this.accountContactList
      }
      this.pageIndex++;
    },
      (error) => {
        console.log('getLaneTask error', error)
      }
    )
  }

  applySelections() {
    console.log(this.selectedPlantIds);
    this.laneTaskConfig.forEach(element => {
      if (element.field === 'plant') {
        element.values = this.selectedPlantIds;
      }
    });
  }

  clearSelections() {
    this.selectedPlantIds = [];
  }

  applyCeo() {
    console.log("this.selectedCEOIds : ", this.selectedCEOIds);
    this.laneTaskConfig.forEach(element => {
      if (element.field === 'add-ceo') {
        element.values = this.selectedCEOIds;
      }
    });
  }

  filterOptions(event) {
    this.accountContactListSearch = this.accountContactList;
    const filterValue = event.target.value.toLowerCase();
    this.accountContactListSearch = this.accountContactList.filter(option => (option.name).toLowerCase().includes(filterValue));
  }

  getLaneDetailsById(isFromInit) {
    this._kService.detailsByLaneId(this.laneTaskIdByUrl).subscribe(res => {
      this.laneArrayKeys = [];
      this.laneTaskIDDetail = res['result'];
      this.laneTaskIDDetail['laneType'] = "form";
      // this.boardId = res['result'].boardId;
      this.getBoardLaneById();
      this.currentTabId = this.laneTaskIDDetail.lane.id;
      this.console.log("this.currentTabId:::", this.currentTabId)
      this.leadName = this.laneTaskIDDetail.name
      // if (res['result'])
      //   this.demo1TabIndex = this.laneTaskIDDetail.lane.position
      this.laneTaskConfig = res['result']['laneWiseData'];
      this.console.log("this.laneTaskConfig:::::::: ", this.laneTaskConfig)
      let formObject = {};
      let configObject = {};
      let key = Object.keys(this.laneTaskIDDetail.laneWiseData);

      if (key && key.length > 0) {
        let readKey = key[0];
        for (let k = 0; k < this.laneTaskIDDetail.laneWiseData[readKey].length; k++) {
          let formField = this.laneTaskIDDetail.laneWiseData[readKey][k];
          formObject[formField['field']] = [formField['value'], !formField['optional'] ? [Validators.required] : []];

          configObject[formField['field'] + "_config"] = formField;
        }
        this.formDetails = this.formBuilder.group(formObject);

        let keys = Object.keys(formObject);

        for (let i = 0; i < keys.length; i++) {
          let obj = {
            keys: keys[i],
            config: configObject[keys[i] + "_config"]
            //  value: this.laneTaskConfig[keys[i]]
          }

          this.laneArrayKeys.push(obj);
        }
      }
    });
  }


  selectedBusinessId: any = '';
  selectedUsers = [];
  searchControlOwner = '';
  userList: any = [];
  userListSearch: any = [];

  loadPrivateOwners() {
    if (this.boardId === this.boardIdForCRMContacts) {
      this.selectedBusinessId = Constants.businessIdForCRM;
    } else if (this.boardId === this.boardIdForSobContacts) {
      this.selectedBusinessId = Constants.businessIdForSOB;
    }
    let obj = {};
    obj['from'] = 0;
    obj['size'] = 100;
    obj['businessId'] = this.selectedBusinessId;
    this.settingServ.getUserDetailsForOwnerDropDown(obj).subscribe(list => {
      let userList = [];
      this.selectedUsers = [];
      for (let i = 0; i < list['result'].length; i++) {
        let obj = list['result'][i];
        let tmp = list['result'][i].userTeams;
        let teamList = [];
        if (tmp) {
          for (let k = 0; k < tmp.length; k++) {
            teamList.push(tmp[k].id);
          }
          obj.userTeams = teamList;
          userList.push(obj)
        } else {
          obj.userTeams = [];
          userList.push(obj)
        }
      }
      userList.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      this.userList = userList;
      this.userListSearch = this.userList;
    });
  }

  onPrivateOwnerSelectionChange(selection: any) {
    this.laneTaskConfig.forEach(element => {
      if (element.field === 'private-owner') {
        element.values = selection.map(value => value.id);
      }
    });
  }

  filterOptionsPrivateOwner(event) {
    this.userListSearch = this.userList.filter(option => (option.name).toLowerCase().includes(event.toLowerCase()));
  }

  getFilteredOptions(formItem: any): string[] {
    if (formItem.filteredOptions && formItem.filteredOptions.length > 100) {
      formItem.filteredOptions = formItem.filteredOptions.slice(0, 100);
    }
    return formItem.filteredOptions || formItem.dataList.slice(0, 100);
  }

  getSelectionChange(event: any, formItem) {
    console.log('getSelectionChange', event);
    formItem.values = event;
  }

  searchChange(event: any, formItem) {
    formItem.filteredOptions = formItem.dataList.filter(option =>
      option.toLowerCase().includes(event.toLowerCase())
    );

    if (formItem.filteredOptions.length > 100) {
      formItem.filteredOptions.slice(0, 100);
    }
  }

}
