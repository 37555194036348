export const Constants = {

  /* contactTemplateUrl:"https://s3.ap-southeast-1.amazonaws.com/img.moglix.com/bulk_contact_upload_template+(1).xls", */
  contactTemplateUrl: "https://purchase-order-moglix.s3.ap-south-1.amazonaws.com/uat-moglix/Bulk_upload_contact_Template_v1.xls",
  plantTableDataUrl: "https://account-management-app.s3.ap-south-1.amazonaws.com/create-table-data-plant-template-crm.xlsx",

  /*QA Constants Board Id*/
  boardIdForSobContacts: "64934c1b7dc1e549e1262268",
  boardIdForSobAccount: "64934b177dc1e549e1262267",
  boardIdForSobPlant: "6494a60df92a8255c71e39b5",
  boardIdForCRMDeals: "63bfa8ccade7bc466d1c780e",
  boardIdForCRMAccount:"63bfa8c3ade7bc466d1c780d",
  boardIdForCRMContacts: "63bfa88dade7bc466d1c780c",
  boardIdForSobOpportunity:"650950b1127b30795b122eb6",
  laneIdForHuntingAccount: "63ce5630a5c09f3f171460b2",
  boardIdForSrmSupplier: "662567f2b4d3125c3f5366d8",

  /* non-active deals */
  nonActiveDealIds:["63e50f29dfb86b637fee8abd","63ce39cba5c09f3f171460a8","64560e0a24107751707950ed","65b8e1b60ecf5554c924ad8c"],
  nonActiveOppIds:["65095445127b30795b122ebe","6509543b127b30795b122ebd","65095450127b30795b122ebf"],

  businessIdForSOB:"6492cd17a056ce1c2860f874",
  businessIdForCRM:"6492b7cc46b1b677c408d556",
  businessIdForSRM:"6625664d52cca62cab45b163",
  businessCRM:"CRM Management",
  businessSOB:"SOB Management",
  businessABM:"ABM Management",

  /* laneIds for QA */
  C0:"63ce39b2a5c09f3f171460a3",
  C1:"63ce39b7a5c09f3f171460a4",
  C2:"63ce39b9a5c09f3f171460a5",
  C3:"63ce39bca5c09f3f171460a6",
  C4:"63ce39bfa5c09f3f171460a7",
  coldDealLaneId:"63e50f29dfb86b637fee8abd",
  poRecievedLaneId:"63ce39cba5c09f3f171460a8",
  lostDealLaneId:"64560e0a24107751707950ed",
  kamHandoverLaneId:"65b8e1b60ecf5554c924ad8c",

  S0:"650953ef127b30795b122eb8",
  S1:"6509540d127b30795b122eb9",
  S2:"65095418127b30795b122eba",
  S3:"65095423127b30795b122ebb",
  S4:"6509542e127b30795b122ebc",
  dealWon:"6509543b127b30795b122ebd",
  coldDeal:"65095445127b30795b122ebe",
  lostDeal:"65095450127b30795b122ebf",
  laneIdForSOBPlant: "6494a66bf92a8255c71e39b6",

  salesManagerCRMId : "63dcc0ff230c7d007058e926",
  leadHunterCRMId: "64d1de9cb70a0e34f434c039",
  salesManagerSOBId : "64a5004c1f45372ea125f882",
  leadHunterSOBId :"64a500301f45372ea125f881",
  
  /* vedanta role id*/ 
  areasalemanagerroleId:"66d822cdf803645157712ffb",


  serviceAbilitySubLaneId:"660bd27cf3a183656c2aeda0",
  selectionCriteriaSubLaneId:"660bd216f3a183656c2aed9e",
  customerProfileSubLaneId:"660bd265f3a183656c2aed9f",

  SRM_S0: "6625eda965750f44040ed198",
  SRM_S1: "6625edb1fa617241909d3cd3",
  SRM_S2: "6625edb565750f44040ed199",
  SRM_S3: "6625edb9fa617241909d3cd4",
  SRM_S4: "6625edbd65750f44040ed19a",

  /* Prod board Id */
  // boardIdForSobContacts: "64a845a17f90e66e3fde1f36",
  // boardIdForSobAccount: "64a845bf7f90e66e3fde1f37",
  // boardIdForSobPlant: "64a843887f90e66e3fde1f35",
  // boardIdForCRMDeals: "63bfa8ccade7bc466d1c780e",
  // boardIdForCRMAccount:"63bfa8c3ade7bc466d1c780d",
  // boardIdForCRMContacts: "63bfa88dade7bc466d1c780c",
  // boardIdForSobOpportunity:"65167a4ce8348d00d33a0967",
  // laneIdForHuntingAccount: "63ce5630a5c09f3f171460b2",
  // boardIdForSrmSupplier: "662567f2b4d3125c3f5366d8",
  
  /* non-active deals */
  // nonActiveDealIds:["63e50f29dfb86b637fee8abd","63ce39cba5c09f3f171460a8","64560e0a24107751707950ed"],
  // nonActiveOppIds:["65167b66e8348d00d33a0971","65167b75e8348d00d33a0972","65167b80e8348d00d33a0973"],

  /*laneIds for PROD */
  // C0:"63ce39b2a5c09f3f171460a3",
  // C1:"63ce39b7a5c09f3f171460a4",
  // C2:"63ce39b9a5c09f3f171460a5",
  // C3:"63ce39bca5c09f3f171460a6",
  // C4:"63ce39bfa5c09f3f171460a7",
  // coldDealLaneId:"63e50f29dfb86b637fee8abd",
  // poRecievedLaneId:"63ce39cba5c09f3f171460a8",
  // lostDealLaneId:"64560e0a24107751707950ed",
  // kamHandoverLaneId:"65b8e1b60ecf5554c924ad8c",

  // S0:"65167b01e8348d00d33a096c",
  // S1:"65167b31e8348d00d33a096d",
  // S2:"65167b35e8348d00d33a096e",
  // S3:"65167b3ae8348d00d33a096f",
  // S4:"65167b3ee8348d00d33a0970",
  // dealWon:"65167b66e8348d00d33a0971",
  // coldDeal:"65167b75e8348d00d33a0972",
  // lostDeal:"65167b80e8348d00d33a0973",
  // laneIdForSOBPlant: "64a846a27f90e66e3fde1f38",
  
  // salesManagerId : "63dcc0ff230c7d007058e926",
  // leadHunterId : "64d2855f6d9c6b7218c65b98",
  // salesManagerSOBId :"64a8406d7f90e66e3fde1f2f",
  // leadHunterSOBId :"64a840de7f90e66e3fde1f30",
  // serviceAbilitySubLaneId:"660bd27cf3a183656c2aeda0",
  // selectionCriteriaSubLaneId:"660bd216f3a183656c2aed9e",
  // customerProfileSubLaneId:"660bd265f3a183656c2aed9f",

  // SRM_S0: "6625eda965750f44040ed198",
  // SRM_S1: "6625edb1fa617241909d3cd3",
  // SRM_S2: "6625edb565750f44040ed199",
  // SRM_S3: "6625edb9fa617241909d3cd4",
  // SRM_S4: "6625edbd65750f44040ed19a",


}

//Check Deal-funnel-modal.component.ts to make Query using this function;
export function generateQuery(boardId: string, laneId: string, from: number, size: number): any {
  return {
    "query": {
      "where": {
        "boardId": {
          "type": "search",
          "operator": "or",
          "value": [
            {
              "field": "boardId.raw",
              "value": boardId
            }
          ]
        },
        "laneId.raw": {
          "type": "search",
          "field": "laneId.raw",
          "operator": "or",
          "value": [
            {
              "field": "laneId.raw",
              "value": laneId
            }
          ]
        }
      },
      "nested_sort": [{ "field": "data.realisticdealsizecr", "order": "desc" }],
      "nestedWhere": {},
      "from": from,
      "size": size
    }
  };
}
