import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Output } from "@angular/core";
import {
  SimpleChanges, ChangeDetectorRef, OnChanges
  , ChangeDetectionStrategy, EventEmitter,
} from '@angular/core';
import { MatPaginator, MAT_DIALOG_DATA } from '@angular/material';
import { KanbanService } from '../../../kanban.service';
import { Constants } from 'src/app/Constants';
@Component({
  selector: 'app-deals-history-view',
  templateUrl: './deals-history-view.component.html',
  styleUrls: ['./deals-history-view.component.scss']
})
export class DealsHistoryViewComponent implements OnInit {
  @Output() dateFilterApplied = new EventEmitter<string>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  pageNumber: number = 0;
  pageSize: number = 20;
  totalRecords: number = 0;
  dealHistories: any[] = [];
  currentUser: any = {};
  accountConfigCheck: any = {};
  resetDates: boolean = false;
  inputData: any;

  displayColumns: Array<string> = ['CreationDate', 'DealName', 'ClosingDate', 'Activity ', 'NextActionItem '];
  accountConfig = {
    "key": "laneTask",
    "field": "account-name",
    "rawValue": null,
    "type": "belongsTo",
    "value": "",
    "optional": true,
    "display": true,
    "displayWhileListing": true,
    "showTotal": false,
    "displayName": "Account Name",
    "dataList": [],
    "values": null,
    "additionalData": null,
    "operationsData": null,
    "operations": null,
    "index": 6,
    "icon": "",
    "relationKey": "63bfa8c3ade7bc466d1c780d",
    "nameField": false
  };
  accountConfigOpportunity = {
    "key": "laneTask",
    "field": "account-name",
    "rawValue": null,
    "type": "belongsTo",
    "value": "",
    "optional": true,
    "display": true,
    "displayWhileListing": true,
    "showTotal": false,
    "displayName": "Account Name",
    "dataList": [],
    "values": null,
    "additionalData": null,
    "operationsData": null,
    "operations": null,
    "index": 6,
    "icon": "",
    "relationKey": Constants.boardIdForSobOpportunity,
    "nameField": false
  };

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public _kService: KanbanService) {

  }
  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem("USERINFO"));
    this.accountConfigCheck = localStorage.getItem("selected_business");
    if (this.accountConfigCheck === "SOB Management") {
      this.accountConfig = this.accountConfigOpportunity;
    }
    this.getPicksList();
  }
  onRelationFilter(config) {
    if (config || config.value || config.value.trim() !== "") {
      this.getPicksList();
    }
  }

  getPicksList(reset?) {
    if (reset) {
      this.pageNumber = 0;
    }
    let obj = {
      from: this.pageNumber,
      size: this.pageSize
    }
    this._kService.getLanetaskHistoryByUser(this.data.user.id, obj, this.accountConfig.value).subscribe(res => {
      this.totalRecords = res['total'];
      this.pageNumber = this.pageNumber;
      if (this.pageNumber != 0) {
        this.dealHistories = this.dealHistories.concat(res['result']);
      }
      else {
        this.dealHistories = res['result'];
      }
    })

  }
  onApplyDates($event) {
    this.resetDates = false;

  }

}
