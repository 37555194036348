<div>
  <div class="d-flex-rw">
    <section class="pb-15 dialog-heading light-grey-bg" aria-label="heading">
      <div fxLayout="row mb-30 " fxLayoutAlign="space-between flex-center">
        <div class="mb-15">
          <h1 class="modal-heading-2 text-capitalize">
            {{ userIdElement.user.name }}
          </h1>
          <h2 class="modal-heading-4">Login History</h2>
        </div>

        <button mat-mini-fab class="modal-close-btn" mat-dialog-close>
          <i class="ri-close-circle-fill"></i>
        </button>
      </div>
      <div class="history-filter" fxLayout="row">
        <div fxLayout="row" fxLayoutAlign="start center">
          <div class="formfield history-date-picker" style="width: 204px">
            <label>Time Duration</label>
            <crm-date-picker
              class="last-login-datepicker"
              [selectDefault]="selected"
              [resetDates]="resetDates"
              (dateFilterApplied)="onApplyDates($event)"></crm-date-picker>
          </div>
        </div>
        <div class="filter-sec">
          <button (click)="clearAll();" mat-raised-button class="clear-all">Clear All</button>
        </div>
      </div>

    </section>

    <div class="mog3_msnsearchtable_sticky last-login-history-table bg-dark-grey">
      <table mat-table class="w-100" [dataSource]="dataSource">
        <ng-container matColumnDef="CreationDate">
          <th mat-header-cell *matHeaderCellDef>Creation Date</th>
          <td mat-cell *matCellDef="let element">
            <span> {{ element.CreationDate }} </span> <br />
            <p class="m-0 cell-sub-text">{{ element.CreationTime }}</p>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
      </table>
      <div fxLayout="row" fxLayoutAlign="end center" style="width: 100%">
        <div class="d-flex-rw" style="width: 100%">
          <div class="pagination-wrapper" style="width: 100%">
            <mat-paginator #paginator [length]="totalRecords" class="custom-pagintor" [pageSize]="pageSize" (page)="onScrollDown(false)" showFirstLastButtons>
          </mat-paginator>
        </div>
        </div>
      </div>
    </div>
  </div>
</div>
