<div class="dialog-section lane-movement-dialog" >
  <section class="pb-15 dialog-heading border-unset">
    <div class="d-flex justify-content-between mb-15">
      <h1 class="modal-heading-1">RFQ Won Confirmation</h1>
      <button mat-mini-fab class="modal-close-btn" (click)="closeDialog();"><i
          class="ri-close-circle-fill"></i></button>
    </div>
    <h2 class="modal-heading-3 d-flex font-roboto"><span>Here are the details for your first RFQ won kindly confirm and update in the deals stages.</span></h2>
  </section>
  <section class="dialog-body pt-0">
    <ul class="won-confirmation-list">
      <li>
        <div class="confirmation-label">Deals stage :</div>
        <div class="confirmation-content">{{stage}}</div>
      </li>

      <li>
        <div class="confirmation-label">Customer Id :</div>
        <div class="confirmation-content">{{customerId}}</div>
      </li>
      <li>
        <div class="confirmation-label">W1 Date & Time :</div>
        <div class="confirmation-content">{{currentdate | date }}</div>
      </li>
      <li>
        <div class="confirmation-label">Plant ID :</div>
        <div class="confirmation-content">{{plantId}}</div>
      </li>
    </ul>
    <div fxLayout="row" fxLayoutAlign="flex-end center">

      <div>
      <button mat-stroked-button class="mog3_secondary_flat_btn mr-20" (click)="cancelDialog()" color="warn">Cancel</button>
      <button mat-flat-button class="mog3_primary_flat_btn" (click)="submit()" > Confirm </button>
    </div>
    </div>
  </section>
</div>
