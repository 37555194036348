<div class="d-flex-rw">
  <section class="pb-15 dialog-heading light-grey-bg" aria-label="heading">
    <div fxLayout="row mb-30 " fxLayoutAlign="space-between flex-center">
      <div class="mb-15">
        <h1 class="modal-heading-2 text-capitalize">{{title}} History</h1>
        <h2 class="modal-heading-4">{{laneTaskIDDetail?.name}}</h2>
      </div>
      <button mat-mini-fab class="modal-close-btn" (click)="dialogRef.close();">
        <i class="ri-close-circle-fill"></i></button>
    </div>
  </section>

  <div class="mog3_msnsearchtable_sticky history-tab-table bg-dark-grey">
    <table mat-table [dataSource]="dataSource">
      <!-- tag Column -->
      <ng-container matColumnDef="laneTaskId">
        <th mat-header-cell *matHeaderCellDef> Field </th>
        <td mat-cell *matCellDef="let element">
          <div class="table-row-content">
            <p>{{getHistoryField(element.oldValue)}}</p>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="operation">
        <th mat-header-cell *matHeaderCellDef> Operation </th>
        <td mat-cell *matCellDef="let element">
          <span class="table-title">{{toLowerCase(element.operation)}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="oldValue">
        <th mat-header-cell *matHeaderCellDef> Old Value</th>
        <td mat-cell *matCellDef="let element">
          <span class="table-title">{{getHistoryValue(element.oldValue)}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="updatedValue">
        <th mat-header-cell *matHeaderCellDef> Updated Value</th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="element.updatedValue.ActionItem">
            <span class="table-title" [innerHtml]=element.updatedValue.ActionItem.Message></span>
            <p class="m-0 cell-sub-text">
              Next Action Date:
              <span class="table-title" [innerHtml]="formatDate(element.updatedValue.ActionItem.NextActionDate)">Next
                Action Date</span>
            </p>
          </div>
          <div *ngIf="element.updatedValue.MOMAdded">
            <span class="table-title" [innerHtml]=element.updatedValue.MOMAdded.Message></span>
            <p class="m-0 cell-sub-text">
              Next Action Date:
              <span class="table-title" [innerHtml]="formatDate(element.updatedValue.MOMAdded.NextActionDate)">Next
                Action Date</span>
            </p>
          </div>
          <div *ngIf="element.updatedValue.Note">
            <span class="table-title" [innerHtml]=element.updatedValue.Note.Message></span>
          </div>
          <div *ngIf="element.updatedValue.COMMENTS">
            <span class="table-title" [innerHtml]=element.updatedValue.COMMENTS.Message></span>
          </div>
          <div *ngIf="element.updatedValue.deal_won_rfq">
            <span class="table-title">{{element.updatedValue.deal_won_rfq | json }}</span>
          </div>
          <div *ngIf="!element.updatedValue.COMMENTS && !element.updatedValue.Note && !element.updatedValue.MOMAdded && !element.updatedValue.ActionItem && !element.updatedValue.deal_won_rfq">
            <span class="table-title" [innerHtml]="getHistoryValue(element.updatedValue)"></span>
          </div>
          <div fxLayout="column" fxLayoutAlign ="center">
            <ng-container *ngIf="element.attachments && element.attachments.length">
              <div class="file-upload-section">
                  <div class="file-uploading-status d-flex justify-content-between">
                    <a  (click)="downloadSingleDocument(element.attachments[0].id)">
                    <span class="file-name"  >
                      {{element.attachments[0].name}}
                      <mat-icon class="material-icons">download</mat-icon>
                    </span>
                  </a>
                  </div>
              </div>
            </ng-container>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="remarks">
        <th mat-header-cell *matHeaderCellDef> Remarks </th>
        <td mat-cell *matCellDef="let element">
          <div class="table-row-content">
            <p *ngIf="!element.remarks">{{element.field}}</p>
            <p *ngIf="element.remarks">{{element.remarks}}</p>
          </div>

        </td>
      </ng-container>
      <ng-container matColumnDef="By">
        <th mat-header-cell *matHeaderCellDef> Modified By </th>
        <td mat-cell *matCellDef="let element">
          <div class="table-row-content">
            <p>{{element.userName}}</p>
          </div>

        </td>
      </ng-container>
      <ng-container matColumnDef="when">
        <th mat-header-cell *matHeaderCellDef> When </th>
        <td mat-cell *matCellDef="let element">
          <div class="table-row-content">
            <p>{{element.time}}</p>
          </div>

        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index"
        [ngClass]="{ 'active-row': i === selectedTrIndex }"></tr>
    </table>
    <div fxLayout="row" fxLayoutAlign="end center" style="width: 100%;">
      <div class="d-flex-rw" style="width: 100%;">
        <div class="pagination-wrapper" style="width: 100%;">
          <mat-paginator #paginator [hidden]="!dataSource.data.length" [length]="totalRecords" [pageIndex]="pageNumber"
            class="custom-pagintor" [pageSize]="pageSize" showFirstLastButtons (page)="getLaneTaskHistory()">
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>