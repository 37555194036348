import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators,FormControl } from '@angular/forms';

@Component({
  selector: 'app-add-form',
  templateUrl: './add-form.component.html',
  styleUrls: ['./add-form.component.scss']
})
export class AddFormComponent implements OnInit {
  @Input() form: any;
  displayName:any="";
  properties:any=[];
  formGroup: FormGroup;
  constructor(private fb: FormBuilder) {
    
   }

  ngOnInit() {
    if(this.form){
      this.displayName=this.form.displayName;
      this.properties=this.form.properties;
    }
    this.formGroup = this.fb.group({
      formArray: this.fb.array([]) // Initialize an empty FormArray
    });
    console.log('form',this.form);
  }
  addUser() {
    const userFormGroup = this.fb.group({
      name: ['', Validators.required], // Example field with validation
      email: ['', Validators.email] // Example field with validation
      // Add more fields as needed
    });

    this.formArray.push(userFormGroup);
  }
  removeFormGroup(index: number) {
    this.formArray.removeAt(index);
  }
  get formArray() {
    return this.formGroup.get('formArray') as FormArray;
  }


}
