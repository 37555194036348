import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.scss']
})
export class AccountInfoComponent implements OnInit {
  dataSource: Array<any> = []; 
  displayColumns: Array<string> = ['AccountName', 'Owner','HeadOffice','Industry','Action']; 
  accountConfig = {
    "key": "laneTask",
    "field": "account-name",
    "rawValue": null,
    "type": "belongsTo",
    "value": "",
    "optional": true,
    "display": true,
    "displayWhileListing": true,
    "showTotal": false,
    "displayName": "Account Name",
    "dataList": [],
    "values": null,
    "additionalData": null,
    "operationsData": null,
    "operations": null,
    "index": 6,
    "icon": "",
    "relationKey": "63bfa8c3ade7bc466d1c780d",
    "nameField": false
  };
  constructor() { 
    this.getPicksList(); 
  }

  ngOnInit() {
  }
  getPicksList() { 

    this.dataSource = [ 
    
    {AccountName:'Prabha Automotive',Owner:'Vivek Saran', HeadOffice: 'Vivek Saran',Industry: 'Industry',action: 'Action'}, 
    {AccountName:'Prabha Automotive',Owner:'Vivek Saran', HeadOffice: 'Vivek Saran',Industry: 'Industry',action: 'Action'},
    {AccountName:'Prabha Automotive',Owner:'Vivek Saran', HeadOffice: 'Vivek Saran',Industry: 'Industry',action: 'Action'},
    ]; 
    
    } 
}
