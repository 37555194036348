import { Component, OnInit } from '@angular/core';
import { Output } from "@angular/core";
import {EventEmitter
} from '@angular/core';
@Component({
  selector: 'app-plan-info-dialog',
  templateUrl: './plan-info-dialog.component.html',
  styleUrls: ['./plan-info-dialog.component.scss']
})
export class PlanInfoDialogComponent implements OnInit {
  dataSource: Array<any> = []; 
  @Output() dateFilterApplied = new EventEmitter<string>();
  displayColumns: Array<string> = ['accountName', 'location']; 
  accountConfig = {
    "key": "laneTask",
    "field": "account-name",
    "rawValue": null,
    "type": "belongsTo",
    "value": "",
    "optional": true,
    "display": true,
    "displayWhileListing": true,
    "showTotal": false,
    "displayName": "Account Name",
    "dataList": [],
    "values": null,
    "additionalData": null,
    "operationsData": null,
    "operations": null,
    "index": 6,
    "icon": "",
    "relationKey": "63bfa8c3ade7bc466d1c780d",
    "nameField": false
  };
  constructor() {
    this.getPicksList(); 
   }

  ngOnInit() {
  }
  getPicksList() { 

    this.dataSource = [ 
    
    { accountName: 'Prabha Automotive', location: 'Delhi' }, 
  
    
    ]; 
    
    } 
}
