<div class="ngx-date-picker-group">
  <input type="text" 
ngxDaterangepickerMd
startKey="startDate" endKey="endDate"
[(ngModel)]="selected"
[alwaysShowCalendars]="alwaysShowCalendars"
[showCancel]="true"
[ranges]="ranges"
[linkedCalendars]="true" 
[isTooltipDate]="isTooltipDate"
[showClearButton]="true"
(clearClicked)="clearDate()"
[isInvalidDate]="isInvalidDate"
[locale]="{ applyLabel: 'Done' }"
(datesUpdated)="datesUpdated($event)"
[keepCalendarOpeningWithRange]="keepCalendarOpeningWithRange"
[showRangeLabelOnInput]="showRangeLabelOnInput"
class="ngx-date-picker"
[placeholder]="placeholder">
<span class="calender-icon"></span>
</div>