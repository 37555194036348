<div>
  <div class="d-flex-rw">
    <section class="pb-15 dialog-heading light-grey-bg" aria-label="heading">
      <div fxLayout="row mb-30 " fxLayoutAlign="space-between flex-center">
        <div class="mb-15">
          <h1 class="modal-heading-2 text-capitalize">Account Info</h1>
          <!-- <h2 class="modal-heading-4">dssfd</h2> -->
        </div>

        <button mat-mini-fab class="modal-close-btn" mat-dialog-close>
          <i class="ri-close-circle-fill"></i>
        </button>
      </div>
      <div
        class="contact-info-filter"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <div class="formfield mr-10" style="width: 422px;">
          <app-field-autocomplete
            [fieldData]="{
              config: accountConfig,
              isFilter: true
            }"
            [modelRef]="accountConfig.value"
          >
          </app-field-autocomplete>
        </div>
        <button mat-flat-button class="mog3_primary_border_btn">
          <i class="ri-add-line"></i>Add New Contact
        </button>
      </div>
    </section>
    <div
      class="mog3_msnsearchtable_sticky contact-info-table bg-dark-grey bg-grey"
    >
      <table mat-table class="w-100" [dataSource]="dataSource">
        <!-- AccountName -->
        <ng-container matColumnDef="AccountName">
          <th mat-header-cell *matHeaderCellDef>AccountName</th>
          <td mat-cell *matCellDef="let element">
            

            <mat-radio-button value="1">
              <span class="blue-anchor">
                {{ element.AccountName }}
              </span>
            </mat-radio-button>
          </td>
        </ng-container>

        <!-- Owner -->
        <ng-container matColumnDef="Owner">
          <th mat-header-cell *matHeaderCellDef>Owner</th>
          <td mat-cell *matCellDef="let element">
            {{ element.Owner }}
          </td>
        </ng-container>

        <!-- HeadOffice -->
        <ng-container matColumnDef="HeadOffice">
          <th mat-header-cell *matHeaderCellDef>Head Office</th>
          <td mat-cell *matCellDef="let element">
          
        {{ element.HeadOffice }}
          </td>
        </ng-container>

     

        <!-- Industry -->
        <ng-container matColumnDef="Industry">
          <th mat-header-cell *matHeaderCellDef>Industry</th>
          <td mat-cell *matCellDef="let element">
            {{ element.Industry }}
          </td>
        </ng-container>

        <!-- Action -->
        <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef>Action</th>
          <td mat-cell *matCellDef="let element">
            {{ element.Action }}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>

        <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
      </table>
      <div fxLayout="row" fxLayoutAlign="end center" style="width: 100%">
        <div class="d-flex-rw" style="width: 100%">
          <div class="pagination-wrapper" style="width: 100%">
            <!-- <mat-paginator
                  #paginator
                  [hidden]="!dataSource.data.length"
                  [length]="totalRecords"
                  [pageIndex]="pageNumber"
                  class="custom-pagintor"
                  [pageSize]="pageSize"
                  showFirstLastButtons
                  (page)="getLaneTaskcontact-info()"
                 
                >
             
                </mat-paginator> -->
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer" fxLayout="row" fxLayoutAlign="flex-end center">
      <div>
        <button mat-flat-button class="mog3_primary_border_btn mr-20">
          Cancel
        </button>
        <button mat-flat-button class="mog3_primary_flat_btn">
          Select & Update Account
        </button>
      </div>
    </div>
  </div>
</div>
