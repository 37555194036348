<div>
  <div class="d-flex justify-content-between w-100">
    <img class="my-image" src="/assets/images/credlix_logo.svg" />

    <a class="lgt" target="_blank" (click)="logout()"> Logout</a>
  </div>

  <div class="sight">
    <span class="hlt">Hello {{userName}},</span>
    <br><label class="lbl"> Select an account to continue.</label>
  </div>
  <mat-card fxLayout="row" class="slct">
    <div class="scrollable">
      <!-- <div class="frst"> -->
      <mat-card-content>

        <ul *ngFor="let items of userDetail" (click)="checkin(items.id, items.id)" class="accnt"  [ngClass]="{'acc-active': items.default == true}"
        >
          <li >
            <span class="icns" *ngIf="items.roleType=='CREDLIX'"><i class="ri-copyright-line"></i></span>
            <span class="icns" *ngIf="(items.roleType!='CREDLIX' && !items.masterAccount.isExim)"><i
                class="  ri-home-4-line"></i></span>
            <span class="icns" *ngIf="items.roleType!='CREDLIX' && items.masterAccount.isExim"><i
                class=" ri-global-fill spns"></i></span>
            <div class="w-100">
              <span class="qwerty">
                <label *ngIf="items.businessName!=null && items.roleName!='BORROWER'">{{items.businessName | titlecase}} 
                  ({{items.roleName | removeUnderscore | titlecase}})</label>
                <label *ngIf="items.businessName!=null && items.roleName=='BORROWER'">{{items.businessName | titlecase}}
                  (Supplier)</label>
              </span>
              <span *ngIf="items.businessName == null && items.roleType!='BUYER'" class="qwerty">{{items.roleType |
                titlecase}}
                ({{items.roleName | removeUnderscore | titlecase}})</span>
              <span *ngIf="items.businessName == null && items.masterAccount.isExim " class="qwerty">Nuphi
                ({{items.roleName | removeUnderscore | titlecase}})</span>
            
              <div class="d-flex  w-100">
                <!-- <span class="rety" *ngIf="items.gstNumber == null">{{items.userName}}</span> -->
                <span class="rety" *ngIf="items.gstNumber != null">{{items.gstNumber}}</span>
                <span class="rety" *ngIf="items.state != null">({{items.state}})</span>
                <span class="rety" *ngIf="items.masterAccount.isDomestic">Domestic</span>
                <span class="rety" *ngIf="items.masterAccount.isExim">Export</span>
                <span class="rety" *ngIf="items.roleType =='BORROWER'">Supplier</span>
                <span class="rety" *ngIf="items.roleType =='CREDLIX'">Credlix User</span>
                <span class="rety" *ngIf="items.roleType !='BORROWER' && items.roleType!='CREDLIX'">{{items.roleType |
                  titlecase}}</span>

                <!-- <span class="rety" *ngIf="items.masterAccount.displayName">({{items.masterAccount.displayName}})</span> -->
              </div>
            </div>
          </li>
        </ul>
      </mat-card-content>
    </div>
    <!-- </div> -->
  </mat-card>
  <div class="chck">
    <mat-checkbox  [(ngModel)]="isDefaultSelected">By default login to this account from next time </mat-checkbox> 
   </div>
</div>