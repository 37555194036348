import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { SettingService } from 'src/app/settings/setting.service';

@Component({
  selector: 'app-email-communication',
  templateUrl: './email-communication.component.html',
  styleUrls: ['./email-communication.component.scss']
})
export class EmailCommunicationComponent implements OnInit {

  userId:any;
  fromMailId: any;
  displayName: any;
  emailList: any[] = [];
  viewMail: any;
  selectedTabIndex = 0;
  toRecipients: any;
  ccRecipients: any;
  isLoading: boolean = false;
  forEmailDetails: any;
  constructor(private route: ActivatedRoute, public settingService: SettingService) { }


  ngOnInit() {
    console.log('email-communication on initialization');
    this.isLoading = true;
    this.emailList = null;
    this.forEmailDetails = this.settingService.getEmailCommunication();
    const data = this.forEmailDetails['data'];
    if (data) {
      console.log('data', data)
      for (const field of data) {
        if (field.field === 'name') {
          this.displayName = field.value;
        }
        if (field.field === 'email') {
          this.fromMailId = field.value;
        }
        if (field.field === 'contact-owner') {
          this.userId = field.value;
        }
      }
      this.getPickList(0);
    }

  }

  getPickList(tabIndex: number) {
    if (tabIndex === 0) {
      this.settingService.fetchSentEmailDetails(this.userId,this.fromMailId).subscribe((result) => {
        this.emailList = result['result'];
        if (this.emailList.length > 0) {
          this.viewMail = this.emailList[0];
          this.toRecipients = this.viewMail.toRecipients;
          this.ccRecipients = this.viewMail.ccRecipients;
          this.isLoading = false;
        }
        else {
          this.emailList = null;
          this.viewMail = null;
          this.toRecipients=null;
          this.ccRecipients =null;
        }
      });

    }
    else {
      this.settingService.fetchReceivedEmailDetails(this.userId,this.fromMailId).subscribe((result) => {
        this.emailList = result['result'];
        if (this.emailList.length > 0) {
          this.viewMail = this.emailList[0];
          this.toRecipients = this.viewMail.toRecipients;
          this.ccRecipients = this.viewMail.ccRecipients;
          this.isLoading = false;
        }
        else {
          this.emailList = null;
          this.viewMail = null;
          this.toRecipients=null;
          this.ccRecipients =null;
        }
      });
    }

  }


  onListItemClick(mail: any) {
    console.log('List item clicked:', mail);
    this.viewMail = mail;
    this.toRecipients = this.viewMail.toRecipients;
    this.ccRecipients = this.viewMail.ccRecipients;
  }

  selectTab(event: MatTabChangeEvent) {
    this.selectedTabIndex = event.index;
    if (this.selectedTabIndex === 0) {
      this.getPickList(0);
    }
    else {
      this.getPickList(1);
    }
  }
}
