<div >
  <div class="d-flex-rw summary-dialog">
     <section class="pb-15 dialog-heading light-grey-bg" aria-label="heading">
    <div fxLayout="row mb-30 " fxLayoutAlign="space-between flex-center">
        <h1 class="modal-heading-2 text-capitalize mb-0">{{laneTaskIDDetail?.name || laneTaskIDDetail?.displayId}}</h1>
        <button mat-mini-fab class="modal-close-btn" (click)="dialogRef.close();"><i class="ri-close-circle-fill"></i></button>
    </div>
        <!-- <h2 class="modal-heading-3">VC - Adani Wilmar</h2> -->
        <div class="d-flex justify-content-between">
        <h2 class="modal-heading-4">Deal Owner : <span class="link"> {{laneTaskIDDetail.assignee[0].user.name}} </span> &nbsp; | &nbsp; Created: <span>{{laneTaskIDDetail?.createdAt | date}}</span></h2>
        <h2 class="modal-heading-4">Last Modified: {{laneTaskIDDetail?.updatedAt | date}}</h2>
      </div>
  </section>
  <section>
    <!-- <mat-grid-list [cols]="6" rowHeight="fit" gutterSize="0.1rem" style="width: 100%; height: 50vh;">
      <mat-grid-tile class="lane-grid" *ngFor="let dashItem of getFieldsToDisplay(laneTaskIDDetail['data']); let j = index" >
        <div fxLayout="column" style="height: 100%;">
          <p class="lane-field">{{dashItem.displayName}}</p>
          <p class="lane-value">{{dashItem.value || "N/A"}}</p>
        </div>
      </mat-grid-tile>
    </mat-grid-list> -->

    <ul class="deal-summary-list">
      <li *ngFor="let dashItem of getFieldsToDisplay(laneTaskIDDetail['data']); let j = index">
        <p class="lane-heading">{{dashItem.displayName}}</p>
        <p class="lane-value">{{dashItem.value || "N/A"}}</p>
      </li>
    </ul>
    <div class="projection-section" *ngIf="projectionDataAvailable">
      <label class="line-after"><span>6 Month projection ( {{projectionTimeline}})</span></label>
      <ul class="deal-summary-list">
        <li>
          <p class="lane-heading">M1 (Cr)*</p>
          <p class="lane-value">{{m1}}  
          <span *ngIf="!m1 && m1 !== 0">-</span>
          </p>
        </li>
        <li>
          <p class="lane-heading">M2 (Cr)*</p>
          <p class="lane-value">{{m2}}</p>
          <span *ngIf="!m2 && m2 !== 0">-</span>
        </li>
        <li>
          <p class="lane-heading">M3 (Cr)*</p>
          <p class="lane-value">{{m3}}</p>
          <span *ngIf="!m3 && m3 !== 0">-</span>
        </li>
        <li>
          <p class="lane-heading">M4 (Cr)*</p>
          <p class="lane-value">{{m4}}</p>
          <span *ngIf="!m4 && m4 !== 0">-</span>
        </li>
        <li>
          <p class="lane-heading">M5 (Cr)*</p>
          <p class="lane-value">{{m5}}</p>
          <span *ngIf="!m5 && m5 !== 0">-</span>
        </li>
        <li>
          <p class="lane-heading">M6 (Cr)*</p>
          <p class="lane-value">{{m6}}</p>
          <span *ngIf="!m6 && m6 !== 0">-</span>
        </li>
      </ul>
    </div>

  </section>
   
 

</div>
</div>