<div>
  <div class="d-flex-rw">
    <section class="pb-15 dialog-heading light-grey-bg" aria-label="heading">
      <div fxLayout="row mb-30 " fxLayoutAlign="space-between flex-center">
        <div class="mb-15">
          <h1 class="modal-heading-2 text-capitalize">Mirzanoor Rahman</h1>
          <!-- <h2 class="modal-heading-4">dssfd</h2> -->
        </div>

        <button mat-mini-fab class="modal-close-btn" mat-dialog-close>
          <i class="ri-close-circle-fill"></i>
        </button>
      </div>
      <div
        class="history-filter"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <div fxLayout="row" fxLayoutAlign="start center">
          <div class="formfield mr-10" style="width:422px;">
            <label>Search account</label>
            <app-field-autocomplete [fieldData]="{
              'config' : accountConfig,
              'isFilter' : true
            }" [modelRef]="accountConfig.value" >
              </app-field-autocomplete>
          </div>
          <div class="formfield " style="width:150px;">
            <label>Date</label>
            <crm-date-picker
                [selectDefault]="true"
                [resetDates]="resetDates"
                (dateFilterApplied)="onApplyDates($event,'createdDate')"
              ></crm-date-picker>
          </div>
        </div>
      </div>
    </section>
    <div class="mog3_msnsearchtable_sticky history-tab-table bg-dark-grey">
      <table mat-table class="w-100" [dataSource]="dataSource">
        <!-- ActivityOn no. -->
        <ng-container matColumnDef="ActivityOn">
          <th mat-header-cell *matHeaderCellDef>Activity on</th>
          <td mat-cell *matCellDef="let element">{{ element.ActivityOn }}
            <br>
            <span  class="label-3">{{element.ActivityTime}}</span>
          </td>
        </ng-container>

        <!-- PlantField Column -->
        <ng-container matColumnDef="PlantField">
          <th mat-header-cell *matHeaderCellDef>Plant Field</th>
          <td mat-cell *matCellDef="let element">
            <span class="blue-anchor">
              {{ element.PlantField}}  
            </span>
            <br>
            <span  class="label-3">{{element.accountName}}</span>
          </td>
        </ng-container>

        <!-- FieldName Column -->
        <ng-container matColumnDef="FieldName">
          <th mat-header-cell *matHeaderCellDef>Field Name</th>
          <td mat-cell *matCellDef="let element">{{ element.FieldName}}
          </td>
        </ng-container>

        <!-- Activity Column -->
        <ng-container matColumnDef="Activity">
          <th mat-header-cell *matHeaderCellDef>Activity</th>
          <td mat-cell *matCellDef="let element">{{ element.Activity }}

            <i *ngIf="element.FieldName == 'Meeting'" class="ri-external-link-line external-icon"></i>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
      </table>
      <div fxLayout="row" fxLayoutAlign="end center" style="width: 100%">
        <div class="d-flex-rw" style="width: 100%">
          <div class="pagination-wrapper" style="width: 100%">
            <!-- <mat-paginator
                  #paginator
                  [hidden]="!dataSource.data.length"
                  [length]="totalRecords"
                  [pageIndex]="pageNumber"
                  class="custom-pagintor"
                  [pageSize]="pageSize"
                  showFirstLastButtons
                  (page)="getLaneTaskHistory()"
                 
                >
             
                </mat-paginator> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
