export const urls = {

    //QA UI
    userSignup: "users/signup",
    userSignIN: "user/signin",
    userVerification: "users/otp/generate",
    verifyOTP: "users/otp/verify",
    accountSetUp: "users/account",
    checkEmailExist: "users/check-user",
    getUserByToken: "user/token",
    getAllCustomer: "users/getAll",
    getPaymentTerms: "users/payment-terms",
    industryList: "industry",
    states: "country/states",
    country: "country",
    invoiceCreation: "invoice",
    updateAddress: "address",
    accountInfo: "users/account",
    getPurchaseOrders: "entities/purchase-orders/search",
    getSuppliersInvoice: "entities/supplier-invoices/search",
    getCustomerInvoice: "entities/customer-invoices/search",
    getEarlyPaymentInvoices: "entities/early-payment-invoices/search",
    createEarlyPaymentRequest: "entities/early-payment/create",
    downloadPaymentSchedule: "early-payment/export",
    getDocumentListByURl: "invoice/supplier/",
    markEligibility: "invoice/mark-eligibility",
    getDoumentPoListByURL: "purcahseOrders/",
    getSupplierLinks: "entities/suppliers/search",
    addSuppliers: "data-import/sync-moglix-supplier",
    changePassword: "users/change-password",
    forgotPassword: "users/forgot-password",
    resetPassword: "users/verify-reset-password",
    customerMarkPaid: "entities/early-payment/update",
    editSupplierDetails: "users/upload-custom-config",
    editBulkSupplierDetails: "users/upload-bulk-custom-config",
    customAuthentication: "users/authenticate-supplier",
    usedEPAmount: "users/used-ep-amount",
    autoCompleteSearchResult: "entities/autosuggest/search",
    downloadEPInvoiceResults: "entities/early-payment-invoices/search/export",
    financerList: "entities/financiers/search",
    bookingUploader: 'invoice/invoice-booking/v2',
    CIDMappingUploader: 'invoice/bussiness-mapping',
    ledgerMapping: "invoice/invoice-mapping/bussiness/v2",
    lenderConfig: 'account-config/upload/v2',
    lenderUTRMap: 'invoice/utr/upload/v2',
    tradeDiscountReport: "invoice/invoice-debitnote-download",
    downloadCustomerInvoices: "entities/early-payment-invoices/customer/export",
    downloadSupplierInvoices: "entities/search/suppliers/export",
    loadJobs: "entities/early-payment-invoices/scheduledJobs",
    downloadfile: 'entities/early-payment-invoices/search/download',
    refreshStatus: "entities/early-payment-invoices/search/status",
    supplierRefresh: "data-import/sync-vendor-bill-by-supplier",
    reconcileSheet: "invoice/supplier-reco",
    ABRFTemplateDownload: "invoice/invoice-mapping-abfrl/template",
    uploadedBulkUser: "data-import/register-suppliers-bulk",
    getGstPdf: "generate-pdf",
    getReports:"get-gst-reports",
    downloadGst:"get-gst-report/gst",

    //userManagement
    getPlantsByUserId:"lane-task/get-plants-by-userId",
    getAccountsByUserId:"lane-task/get-accounts-by-userId",
    getRecordsByBoardId:"lane-task/records/id-name", // substitue of above two apis used for meeting dropdowns
    getAllMeetings:"calender/getCalendarCountDateWise",
    getMOMMeetings:"calender/getCalendarCountDateWise/mom",
    getSentEmail : "calender/get/sent-mail-to",
    getReceivedEmail : "calender/get/received-mail-from",
    addPlantsToMeetings:"calender/add-plants/to-calendar",
    addOpportunityToMeetings:"calender/add-opportunity/to-calendar",
    associateEntitiesToMeetings:"calender/add-entities/to-calendar",
    createTeamMeetings:"calender/create/teams/meeting",
    calendarMeetingTracker:"calender/tracker",
    calendarMeetingByUser:"calender/by-user",
    momMeetingByUser:"mom/by-user",
    actionItemByUser:"action-item/by-user",
    getAllRoles: "role/roles",
    getTeams: "user/get-user-teams",
    userAssignTeam: "user/assign-user-team",
    userAssignHunter: "user/assign-hunters",
    getAllPermissions: "user-role/get-permissions-list",
    getAllPlants: "user/account",
    adduser: "user/create-user-with-role",
    createUserRole: "user/create-custom-role",
    userDetails: "user/get-users",
    userLoginHistoryDetails: "user/login-details-history",
    userDetailsForOwnerDropDown: "user/get-owners",
    userAssignRoles: "user/assign-role",
    userActiveInActive: "user/assign-active-inactive",
    userOwnerDropDown: "user/assign-owner-dropdown",
    getAllStates: "country/states",
    deleteUserRole: "user-role/user-roles",
    userAutosuggest: "user/search",
    refreshToken: 'user-role/get-token',
    bulkUserAdded: 'user-role/bulk-upload',
    userStatusUpdate: "users/update-status",
    getUserProfile: 'sub-user',
    checkRoleID: 'user-role/check-role-delete',
    importConfig: 'import-config',
    getRequestPayment: 'invoice/invoice-ep-details',
    companyDetails: 'company-info',
    directorDetails: 'company-info/directors',
    uploadDocuments: 'documentinfo/upload',
    verifyDetails: 'company-info/details',
    downloadDocuments: 'documentinfo/download',
    checkOnBoardGSTIN: "onboard-data",
    submitDetails: 'company-info/submit',
    getRequestMultiplePayment: 'invoice/invoice-ep-details/multiple',
    getBuyerListing: "accounts?accountType=BUYER",
    bulkUpload:"import-config/upload-bulk-data",
    tableUploadLaneTask: 'import-config/upload-table-data',
    tableDownloadLaneTask: 'import-config/download-table-data',

    //vedanta API's
    checkVedantaUser: 'users/v2/vedanta/signin',
    vedantaUserDetails: "users/v2/vedanta/userdetails",
    signUpVedantaUser: 'users/v2/vedanta/signup',
    // getBuyerInvoiceListing : "loan-invoice",
    getBuyerInvoiceListing: "loan-request/loan-request",
    getAnchorList: "master-account/anchors",
    getLenderAnchorList: 'financier/anchors',
    getRepayments: "loan-request/get-repayments",
    onBoarding: "company-info/onboarding",
    verifyInfo: "company-info/mark-verified",
    downloadZip: "documentinfo/download-zip",
    deleteDocument: "documentinfo/delete",
    getLeadsLists: "accounts/lender/search",
    addToLead: "accounts/lead",
    getCiData: "system-invoice",



    whatsappMessageContent: "messenger/get-template",
    whatsappInvoiceMessageContent: "messenger/get-invoice-template",
    requestEPOTPVerify: 'messenger/verify-email-request-template',
    requestEPtemplateValues: 'messenger/email/template-values',
    whatsappSubUserList: 'sub-user/contacts',
    getUserList: "accounts/users",
    markWhatsappNumber: 'sub-user/mark-whatsapp',
    sendWhatsappMsg: 'messenger/whatsapp/send-message',
    sendEmailMsg: 'messenger/email/send-message',
    checkWhatsapplogged: 'sub-user/whatsapp',
    supplierOnboard: 'borrower/upload/V2',
    anchorInvoice: 'borrower/invoice/upload/v3',
    debitNoteUpload: 'debit-note/upload',
    EPMarkDelayed: 'early-payment/mark-delayed',
    cancelEPInvoice: 'early-payment/mark-cancelled',
    EPhistory: 'early-payment/history',
    bulkMarkDelayedEP: 'early-payment/mark-delayed/bulk/v2',
    bulkCanceledEP: 'early-payment/mark-cancelled/bulk/v2',
    getSupplierBankDetails: "bank-details",
    getOTPRequest: "otp-request",
    setPrimaryBankAccount: "bank-details/set-primary",
    deleteBankAccount: "bank-details/mark-deleted",
    leadComment: 'comment',
    getLeadComment: 'comment/business-account',


    //Borrower
    getAllBorrowerStats: 'buyer/get-all-buyer-stats',
    getBuyerStats: 'buyer/get-buyer-stats',
    getMetrics: 'borrower/metrics',
    downloadBuyer: 'buyer/export',
    discountPopup: 'abfrl-interest',

    //Escrow Account
    getPayouts: 'transaction-files',
    uploadPayoutFile: 'transaction-files/upload-anchor-transaction-file',
    uploadedJobSchedule: 'scheduled-jobs',
    invoicesPayout: 'transaction-files/file-entries',
    supplierPayouts: 'transaction-files/borrower-wise-entries',
    supplierTransactions: 'virtual-accounts/get-virtual-accounts-transactions',
    reviewPayoutFile: 'transaction-files/mark-reviewed',
    fileTransaction: 'anchor-virtual-account/get-file-transactions',
    verifyTransactions: 'transaction-files/verify-file',
    getEscrowOTP: 'anchor-virtual-account/send-file-transactions-otp',
    sendPaymentOTP: 'anchor-virtual-account/verify-create-file-transactions',
    getEscrowPayments: "virtual-accounts/get-escrow-accounts-transactions",
    getEscrowDetail: 'virtual-accounts/get-escrow-entry-details',
    unmapTransaction: 'transaction-files/unmap-file-entries-escrow',
    mapTransaction: 'transaction-files/map-file-entries-escrow',
    escrowSystemStats: 'dashboard/get-escorw-system-stats',
    downloadUploadedFile: 'transaction-files/download',
    rejectUploadedFile: 'transaction-files/reject-file',
    downloadPayoutSheet: 'transaction-files/payouts/download',
    downloadPayoutTransactionSheet: 'transaction-files/transactions/download',
    ledgerReportDownload: 'reports/get-ledger-reports',
    payoutBalance: 'dashboard/get-escorw-bank-stats',
    getAnchorDashboard: 'dashboard/get-anchor-dashboard',
    balanceSheetDownload: 'transaction-files/payout-match/download',
    supplierInvoicesSheet: 'reports/get-anchor-account-balances',
    unmappedBalanceSheet: 'reports/get-anchor-unsettled-transactions',
    anchorPaymentReport: 'reports/get-anchor-payments-reports',
    lenderPaymentReport: 'reports/get-lender-payments-reports',
    manualCreditForm: 'api/payment/credlix-callback',
    payoutReport: 'reports/report-type',
    multipleAccount: 'users/v2/get-access-token',
    getRolesByAccount: 'users/v2/get-user-roles',
    bydefaultUserRole: 'user-role/update-default-role',

    //upload-mapping
    getUploadMappingHeaders: 'file-config/get-headers-with-records',
     updateImportConfig:"import-config/update-index",
    //updateImportConfig:"import-config/create",

    // Credlix-kanban
    boardList: 'board',
    searchLane: 'lane/search',
    fetchLaneWithAccess:'lane/board',
    laneTask: 'lane-task/search',
    laneTaskWithoutAccess: 'lane-task/search-without-access',
    laneTaskContactDetails: 'lane-task/search/contact-details',
    laneTaskUsers : "lane-task/search-lane-users",
    laneTaskUsersKam : "lane-task/search-lane-users/kam",
    laneTaskUsersVedanta : "lane-task/search-lane-users/vedanta",
    laneTaskStats: 'lane-task/get-stats',
    createLanetask:'lane-task',
    removeAssignee:'lane-task/remove-assignee',
    removeWatchers: 'lane-task/remove-watchers',
    assignLaneTask: 'lane-task/assign-lanetask-to-other-lane',
    laneMovementValidate:'lane-task/validate-lanetask',
    addAssigneToLaneTask: 'lane-task/add-assignee',
    addWatchers: 'lane-task/add-watcher',
    getTags: 'tag/get-boardtags',
    removeTags:'lane-task/remove-tag',
    laneTaskDocumentsUpload: 'documents/upload-attachment',
    downloadZipTaskDocuments:'documents/download-zip',
    downloadSingleDocument: 'documents/download-attachment',
    deleteTaskDocuments:'documents',
    uploadDocumentSearch: 'documents/search',
    laneConfig:'lane-config',
    boadLaneConfig:'lane-config/board-lane-config',
    boadLaneConfigDTO:'lane-config/board-lane-config-dto',
    boardSubdivisionLaneConfig:'lane-config/get-sub-lane-config',
    getUserManagement: 'user/by-authority?authorities=KANBAN',
    saveAuthority: 'authority',
    laneTaskHistory:'lane-task/lanetaskHistory',
    laneTaskHistoryByUser:'lane-task/lanetaskHistoryByUser',
    laneTaskl2category:"lane-task/l2Category/getAll",
    addComment: 'comment',
    addActionItem: 'action-item',
    addCommentsOnActionOrNote:"comments",
    addMOM: 'mom',
    commentTaskSearch: 'comment/search',
    actionItemSearch: 'action-item/search',
    configDocumentUpload: 'documents/upload-attachment',
    getConfigCountry:'country/states',
    getCountryList: "get-countries",
    readBuyerDataFile: 'documents/view-data',
    termSheetGenerate:'generate-pdf',
    termSheetSave:'save-termsheet',
    rpaSheetGenerate: 'generate-rpa-pdf',
    getRPAData: 'rpa',
    getTermSheetData: 'termsheet',
    getHuntingVelocity:'migrate/getHuntingVelocityByWeek',
    // updateBuyerData:'lane-task/update-buyer-data',
    buyerData: 'buyer-data',
    uploadMultipleLead: 'api/upload/panjivaFileUpload',
    deleteTermsheet: 'termsheet/document',
    contactDetails: 'lane-task/contact-details',
    contactDetailsUpdate: 'lane-task/contact-details/update',
    deleteContactDetails: 'lane-task/contact-details/delete',
    // addBuyerDataRow: 'operations/create-buyer-data',
    // deleteBuyerData: 'lane-task/delete-buyer-data',
    validateTermsheet: 'validate-termsheet',
    uploadBuyerDocs: 'buyer-data/upload-document',
    deleteBuyerData: 'buyer-data/delete-document',
    updateBuyerDataConfig:'board/update-index',
    laneAccessType: 'authority/board',
    getAccessByBoardId: 'board/boards',
    supplierBulkTermsheet: 'bulk-lead/upload-supplier-document',
    buyerBulkTermsheet: 'bulk-lead/upload-buyer-document',
    supplierTermsheetData: 'bulk-lead',
    downloadBulkLead: 'bulk-lead/download',
    downloadZipBulkLead: 'bulk-lead/download-zip',
    updateWatcherOnDeal:"lane-task/update-watcher-on-deal",
    getRecentcampaignsfromDb:"zoho/getRecentcampaignsfromDb",
    getRecentcampaignsfromDbV1:"zoho/get-recent-campaigns-from-db",
    reloadRecentcampaignsfromDb:"zoho/reloadRecentcampaignsfromDb",
    reloadRecentcampaignsfromDbV1:"zoho/reload-recent-campaigns-from-db",
    getAccountWiseReport:"zoho/get-account-wise-report",
    getAccountWiseContact:"zoho/get-account-wise-contact-list",
    reloadAccountWiseReport:"zoho/reload-account-wise-report",
    getRoleTypeByRoleName:"role-type/get-by-role-name",
    //MSAF
    loanInvoice: "entities/loan-invoices/search",
    invoiceFinanceDetails: "loan-invoice/invoice-finance-details",
    loanRequest: "loan-request/request",
    multiLoanRequest: "loan-request/request/multi",
    generate_drawDown_pdf: "loan-invoice/generate-drawdown-pdf",
    invoiceMultiFinanceDetails: "loan-invoice/invoice-finance-details/multi",
    lenderUTRMapMSAF: 'loan-payment/mark-paid/upload/v2',
    markDisbursedMulti: "loan-payment/mark-disbursed/multi",
    markDisbursed: "loan-payment/mark-disbursed",
    loanInvoiceUpload: "loan-invoice/upload/v2",
    getLenderDetails: "buyer-lender-config/get-buyer-config",
    updateLenderDetails: "buyer-lender-config/update",
    getLenderAccount : "get-lender-accounts",
    uploadLenderConfig: "buyer-lender-config/upload",
    loanPaymentMarkPaid: "loan-payment/mark-paid/upload/v2",
    getBasicDetails: "accounts/details",
    markDisbursedUpload:"loan-payment/mark-disbursed/upload/v2",
    getAccountSummary: "loan-request/get-account-statement",
    buyerUpload: "buyer/upload/v2",
    getBuyerGlobalSearchData: 'entities/buyer/search',
    autoCompleteVedantaSearchResult: "entities/autosuggest/search/buyers",

    //SID buyer/suppllier URLs
    buyerSearch: 'entities/accounts/buyers',
    supplierSearch: 'entities/accounts/suppliers',
    getCompanyInfo: 'company-info/info',
    createBuyer: 'accounts/sid/buyer',
    createSupplier: 'accounts/sid/supplier',
    uploadBulkSupplier: 'borrower/upload/V2',
    uploadBulkBuyer: 'buyer/upload/v2',
    sbMappingGetBuyers: 'sb-mapping/get-buyers',
    mappingSupplierBuyer : 'sb-mapping',
    getSupplierLenders: 'sl-mapping/get-mapping-by-supplier',
    getHeadersWithRecord: 'get-headers-with-records',
    getLenderListing: 'get-lender-accounts',
    slMapping: 'sl-mapping',
    sblMapping: 'sbl-mapping',
    mappingSupplierLenderBuyer: 'sbl-mapping/get-supplier-mapping',
    sbMapGetSupplier: 'sb-mapping/get-suppliers/v2',
    uploadInvoiceDocs: 'documents/upload-invoice',
    createInvoice: 'sid-invoice/create',
    getInvoiceListing: 'sid-invoice',
    generateDrawdown: 'sid-invoice/generate-drawdown-pdf',
    sendInvoiceApproval: 'sid-invoice/send-for-approval',
    createBulkInvoice: 'sid-invoice/create-invoice-bulk',
    buyerAutoSuggest: 'entities/autosuggest',
    getInvoiceDetails: 'sid-invoice',
    adminApproval: 'sid-invoice/admin-approval',
    buyerApproval: 'sid-invoice/buyer-approval',
    rejectInvoiceBySidUser: 'sid-invoice/reject',
    getInvoiceDocuments: 'documents/invoice-documents',
    deleteInvoiceDoc: 'documents',
    downloadInvoiceDoc: 'documents/download',
    getMappedBuyer: 'sbl-mapping/get-mapped-buyers',
    rejectInvoiceFromBuyer: 'sid-invoice/buyer-reject',
    sendBuyerApproval: 'sid-invoice/buyer-request',
    updateStatus: 'accounts/update-status',
    paymentsUpload: 'sid-invoice/payments/upload',
    sendBuyerApprovalEmail: 'sid-invoice/send-buyer-approval-email',
    bulkApprovalAdmin: 'sid-invoice/bulk-approval/admin',
    bulkApprovalBuyer: 'sid-invoice/bulk-approval/buyer',
    multiBuyerRequest: 'sid-invoice/buyer-request/multi',
    supplierReportDownload: 'entities/accounts/suppliers/export',
    buyerReportDownload: 'entities/accounts/buyers/export',
    getColorCode: 'accounts/update-custom-config',
    downloadInvoicesReport: 'entities/invoices/export',
    getBuyerEmail: 'accounts/details',
    getBuyerDocs : 'documents/invoice-documents-by-type',
    getBuyerBulkDocs: 'documents/invoice-documents-by-type/bulk',
    sidInvMarkDisbursed: 'sid-invoice/payments/mark-disbursed',
    sidInvMarkPaid: 'sid-invoice/payments/mark-paid',
    downloadReport:"sid-report/create",
    deleteInvoices: "sid-invoice/delete-invoices",
    downloadDisbursementFile:"disbursement-report",

    //tasva
    tasvaMap: 'invoice/mark-tasva-suppliers',
    tasvaLedger: 'invoice/invoice-booking-tasva',

    //OKR
    createOKR: 'okr/create-goal',
    getAllOKR: "okr/search",
    OkRGoals: 'okr/goals',
    updateOKRGoal: 'okr',
    deleteOKRMember: 'okr/delete-members'
}
