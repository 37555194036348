import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { ErrorToastComponent } from 'src/app/shared-files/error-toast/error-toast.component';
import { SuccessToastComponent } from 'src/app/shared-files/success-toast/success-toast.component';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-form-array-example',
  templateUrl: './form-array-example.component.html',
  styleUrls: ['./form-array-example.component.scss'],
  providers: [MatSnackBar]
})
export class FormArrayExampleComponent implements OnInit {
  myform: FormGroup;
  //   @Input() form: any={
  //     "field": "owner",
  //     "type": "form",
  //     "value": "",
  //     "optional": true,
  //     "display": true,
  //     "displayWhileListing": false,
  //     "showTotal": false,
  //     "displayName": "Owner",
  //     "dataList": [],
  //     "index": 15,
  //     "icon": "",
  //     "properties": [
  //         {
  //             "field": "name",
  //             "type": "string",
  //             "value": "",
  //             "optional": true,
  //             "display": true,
  //             "displayWhileListing": true,
  //             "showTotal": false,
  //             "displayName": "Name",
  //             "dataList": [],
  //             "index": 0,
  //             "icon": "ri-user-line",
  //             "description": "",
  //             "maxValue": "",
  //             "remarks": "",
  //             "fieldEditable": true,
  //             "nameField": false
  //         },
  //         {
  //             "field": "email-id",
  //             "type": "string",
  //             "value": "",
  //             "optional": true,
  //             "display": true,
  //             "displayWhileListing": true,
  //             "showTotal": false,
  //             "displayName": "Email Id",
  //             "dataList": [],
  //             "index": 1,
  //             "icon": "ri-mail-add-line",
  //             "description": "",
  //             "maxValue": "",
  //             "remarks": "",
  //             "fieldEditable": true,
  //             "nameField": false
  //         },
  //         {
  //             "field": "mobile-no",
  //             "type": "string",
  //             "value": "",
  //             "optional": true,
  //             "display": true,
  //             "displayWhileListing": true,
  //             "showTotal": false,
  //             "displayName": "Mobile No",
  //             "dataList": [],
  //             "index": 2,
  //             "icon": "ri-contacts-book-line",
  //             "description": "",
  //             "maxValue": "",
  //             "remarks": "",
  //             "fieldEditable": true,
  //             "nameField": false
  //         },
  //         {
  //             "field": "designation",
  //             "type": "string",
  //             "value": "",
  //             "optional": true,
  //             "display": true,
  //             "displayWhileListing": true,
  //             "showTotal": false,
  //             "displayName": "Designation",
  //             "dataList": [],
  //             "index": 3,
  //             "icon": "ri-user-2-line",
  //             "description": "",
  //             "maxValue": "",
  //             "remarks": "",
  //             "fieldEditable": true,
  //             "nameField": false
  //         },
  //         {
  //             "field": "age",
  //             "type": "number",
  //             "value": "",
  //             "optional": true,
  //             "display": true,
  //             "displayWhileListing": true,
  //             "showTotal": false,
  //             "displayName": "Age",
  //             "dataList": [],
  //             "index": 4,
  //             "icon": "ri-calendar-line",
  //             "description": "",
  //             "maxValue": "",
  //             "remarks": "",
  //             "fieldEditable": true,
  //             "nameField": false
  //         }
  //     ],
  //     "fieldEditable": true,
  //     "nameField": false
  // };
  @Input() form: any;
  displayName: any = "";
  properties: any = [];
  errors: string = '';
  constructor(private fb: FormBuilder, private _snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    console.log('form', this.form)
    if (this.form) {
      this.displayName = this.form.displayName;
      this.properties = this.form.properties;
    }
    this.myform = this.fb.group({
      items: this.fb.array([])
    });
    if (this.form && this.form.rawData && this.form.rawData.items && this.form.rawData.items.length > 0) {
      this.addDataFormGroup(this.form.rawData.items);
    } else {
      this.addBlankFormGroupPrimary();
    }
  }
  get getFormArray(): FormArray {
    return this.myform.get('items') as FormArray;
  }
  addBlankFormGroup() {
    const newForm = this.buildForm();
    this.getFormArray.push(newForm);
  }
  addBlankFormGroupPrimary() {
    const newForm = this.buildFormPrimary();
    this.getFormArray.push(newForm);
  }
  addDataFormGroup(items: any) {
    items.forEach(item => {
      let dataForm = this.buildFormWithData(item);
      this.getFormArray.push(dataForm);
    });
  }
  buildForm(): FormGroup {
    const group = {};
    this.properties.forEach(controlName => {
      group[controlName.field] = ['', this.getValidator(controlName)];
    });
    group['primary'] = [''];
    return this.fb.group(group);
  }
  buildFormPrimary(): FormGroup {
    const group = {};
    this.properties.forEach(controlName => {
      group[controlName.field] = ['', this.getValidator(controlName)];
    });
    group['primary'] = ['true'];
    return this.fb.group(group);
  }
  buildFormWithData(row: any): FormGroup {
    const group = {};
    this.properties.forEach(controlName => {
      group[controlName.field] = [this.getFieldValue(row, controlName.field), this.getValidator(controlName)];
    });
    group['primary'] = [this.getFieldValue(row, 'primary')];
    return this.fb.group(group);
  }
  getFieldValue(row: any, field: any) {
    if (row && row[field]) {
      return row[field];
    } else {
      return '';
    }
  }
  getValidator(Configfield: any) {
    if (!Configfield) return Validators.nullValidator;
    const formValidators = [];
    if (Configfield.optional) {
      formValidators.push(Validators.required);
    }
    if (Configfield.type == 'email') {
      formValidators.push(Validators.email);
    }
    if (Configfield.type == 'phone') {
      formValidators.push(Validators.maxLength(10));
      formValidators.push(Validators.minLength(10));
    }
    return formValidators;
  }
  removeUserFormGroup(index: number) {
    if (this.getFormArray.length > 0) {
      const controlToRemove = this.getFormArray.at(index) as FormControl;
      if (controlToRemove && controlToRemove.value && controlToRemove.value['primary']) {
        this._snackBar.openFromComponent(ErrorToastComponent, {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['atom-toast', 'error'],
          duration: 6000,
          data: "Primary " + this.displayName + " can not be deleted"
        });
        return;
      }
    }
    if (this.getFormArray.length == 1) {
      const err = "At least One " + this.displayName + " required";
      this._snackBar.openFromComponent(ErrorToastComponent, {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: ['atom-toast', 'error'],
        duration: 6000,
        data: err
      });
      this.errors = err;
    }
    else {
      this.getFormArray.removeAt(index);
    }
  }
  onSubmit() {
    this.errors = '';
    if (this.myform.valid) {
      if (!this.anyPrimaryPresent()) {
        const err = "At least One " + this.displayName + " should be primary";
        setTimeout(() => {
          this._snackBar.openFromComponent(ErrorToastComponent, {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: ['atom-toast', 'error'],
            duration: 3500,
            data: err
          });
        }, 0);
        this.errors = err;
        return this.errors;
      } else {
        this.form['rawData'] = this.myform.value;
        return this.errors;
      }
    } else {
      this.validateAllFormFields(this.myform);
      return this.errors;
    }
  }
  validateAllFormFields(formGroup: FormGroup | FormArray) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
        if (!control.valid) {
          const errorMessage = Object.keys(control.errors)[0];
          let formattedMessage = '';
          switch (errorMessage) {
            case 'required':
              formattedMessage = `Missing Information: Supplier Owner`;
              break;
            case 'minlength':
              formattedMessage = `${field} must be at least ${control.errors.minlength.requiredLength} characters`;
              break;
            case 'maxlength':
              formattedMessage = `${field} must be no more than ${control.errors.maxlength.requiredLength} characters`;
              break;
            default:
              formattedMessage = `Invalid value for ${field}`;
          }
          this.errors = formattedMessage.trim();
          return this.errors
        }
      } else if (control instanceof FormGroup || control instanceof FormArray) {
        this.validateAllFormFields(control);
      }
    });
    return this.errors;
  }
  onRadioChange(formIndex: number, selectedLanguage: string) {
    this.getFormArray.controls.forEach((formGroup, index) => {
      console.log("onRadioChange", this.getFormArray, index, formIndex)
      if (index !== formIndex) {
        formGroup.get('primary').setValue('');
      }
    });
  }
  anyPrimaryPresent(): boolean {
    let isPresent: boolean = false;
    this.getFormArray.controls.forEach((formGroup) => {
      if (formGroup.get('primary').value == 'true') {
        isPresent = true;
      }
    });
    return isPresent;
  }

  onBlur(event: FocusEvent, index: any) {
    let inputElement = event.target as HTMLInputElement;
    let inputValue = parseFloat(inputElement.value);

    if (inputValue > 100) {
      inputElement.value = '100';
    } else if (inputValue < 18) {
      inputElement.value = '18';
    }
    
    if (this.getFormArray.length > 0) {
      const limitAge = this.getFormArray.at(index) as FormControl;
      console.log("controlToRemove", limitAge);
      if (limitAge && limitAge.value && limitAge.value['age']) {
        limitAge.value['age'] = inputElement.value;
      }
    }
     
  }


}

