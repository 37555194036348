<div class="leadBoard ">
  <h5>
    Create Lead
  </h5>
  <div class="form-height">
    <div class="dialogbody">
      <div class="form-row">
        <div class="roleadd">
          <mat-label class="required"><label *ngIf="selectedBoardName =='exim'">Lead/Supplier/Exporter Name</label>
             <label *ngIf="selectedBoardName =='domestic'">Supplier Name</label> </mat-label>
          <mat-form-field floatLabel="never">
            <input type="text" matInput [(ngModel)]="leadName">
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div class="btn-footer">
    <button mat-raised-button type="button" class="btnCncl" (click)="cancelDialog()">Cancel</button>
    <button mat-raised-button type="button" class="btnSave" (click)="createNewLead()">Create</button>

  </div>
</div>