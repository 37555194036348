import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MAT_DIALOG_DATA } from '@angular/material';
import { KanbanService } from '../../../kanban.service';
import { M } from '@angular/cdk/keycodes';
import { Constants, generateQuery } from 'src/app/Constants';


@Component({
  selector: 'app-deal-funnel-modal',
  templateUrl: './deal-funnel-modal.component.html',
  styleUrls: ['./deal-funnel-modal.component.scss']
})
export class DealFunnelModalComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource: Array<any> = [];
  boardId: any;
  laneName: any;
  S0: string = Constants.S0;

  displayColumns: Array<string> = ['dealName', 'dealOwner', 'closingDate', 'accountName', 'businessVertical', 'dealType', 'nextActionItem'];
  label: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private kanbanService: KanbanService) {
    console.log("data", data);
    this.laneName = data['lane'];
    this.count = data['count'];
    this.sum = data['sum'];
    this.label = data['label'];
    this.boardId = this.data.boardId;
    this.loadDisplantColumns();
    if (this.boardId !== Constants.boardIdForSobOpportunity) {
      this.loadStageMap();
    }
    else {
      this.loadStageMapOpportunity();
    }
    this.onFilterApply(data);
    this.loadData();
  }
  teamQuery: any;
  bsQuery: any;
  dealtypeQuery: any;
  userQuery: any;
  primaryUserQuery: any;
  secondaryUserQuery: any;
  resetFilter: any;
  teamFilter: any;
  closingDateQuery: any;
  nextActionDateQuery: any;
  createdDateQuery: any;
  lastUpdatedDateQuery: any;
  dormantQuery: any;
  timeDurationDateQuery: any;
  stagemap = new Map<string, string>();
  displayedColumns: any[] = [];
  displayedColumns1: any[] = [];
  isReadMore: boolean = true;
  count: any;
  sum: any;
  pageNumber: number = 0;
  pageSize: number = 10;
  totalLaneHistory: number = 0;
  totalRecords: number = 0;
  activeSorts = [];
  isLoading: boolean = false;
  sortNumberFieldRegex = /date|potential|actionItem|probability|dealsize/igm;
  ValidLane: string[] = ["C0", "C1", "C2", "C3", "C4", "PO Received/ ADP", "Cold Deal", "Lost Deal", "Dormant", "KAMS Handover"];
  ValidLaneOpportunity: string[] = ["S0", "S1", "S2", "S3", "S4", "Deal Won", "Cold Deal", "Lost Deal", "Dormant"];
  ngOnInit() {
  }
  getLanIdByLaneName(laneName) {
    var laneId = this.stagemap.get(laneName);
    return laneId;
  }
  onFilterApply($event) {
    if ($event && $event['resetFilter']) {
      this.teamFilter = null;
      this.bsQuery = null;
      this.userQuery = null;
      this.primaryUserQuery = null;
      this.secondaryUserQuery = null;
      this.teamQuery = null;
      this.closingDateQuery = null;
      this.nextActionDateQuery = null;
      this.createdDateQuery = null;
      this.lastUpdatedDateQuery = null;
      this.dormantQuery = null;
    } else {
      if ($event && $event['teamFilter'] && $event['teamFilter'].length) {
        let tFiler = $event['teamFilter'];
        this.teamFilter = tFiler;
        this.teamQuery = $event['teamQuery']['query']['nestedWhere']["team_query"];
      }
      if ($event && $event['bsQuery']) {
        this.bsQuery = $event['bsQuery']['query']['nestedWhere']["bs_query"];
      }
      if ($event && $event['dealtypeQuery']) {
        this.bsQuery = $event['dealtypeQuery']['query']['nestedWhere']["dealtype_query"];
      }
      if ($event && $event['userQuery']) {
        this.userQuery = $event['userQuery']['query']['where']["user_query"];
      }
      if ($event && $event['primaryUserQuery']) {
        this.primaryUserQuery = $event['primaryUserQuery']['query']['where']["primary_query"];
      }
      if ($event && $event['secondaryUserQuery']) {
        this.secondaryUserQuery = $event['secondaryUserQuery']['query']['nestedWhere']["secondary_query"];
      }
      if ($event && $event['closingDateQuery']) {
        this.closingDateQuery = $event['closingDateQuery'];
      }
      if ($event && $event['nextActionDateQuery']) {
        this.nextActionDateQuery = $event['nextActionDateQuery'];
      }
      if ($event && $event['createdDateQuery']) {
        this.createdDateQuery = $event['createdDateQuery'];
      }
      if ($event && $event['lastUpdatedDateQuery']) {
        this.lastUpdatedDateQuery = $event['lastUpdatedDateQuery'];
      }
      if ($event && $event['dormantQuery']) {
        this.dormantQuery = $event['dormantQuery'];
      }

    }
  }
  isValidLane() {
    if (this.boardId === Constants.boardIdForSobOpportunity) {
      return !this.ValidLaneOpportunity.includes(this.laneName);
    }
    return this.ValidLane.includes(this.laneName);
  }
  loadData() {
    let from = 0;
    if (!this.paginator) {
      from = this.pageNumber;
    } else {
      from = this.paginator.pageIndex * this.pageSize;
    }
    var size = this.pageSize;
    let validLane = this.ValidLane.includes(this.laneName);
    if (this.boardId === Constants.boardIdForSobOpportunity) {
      validLane = this.ValidLaneOpportunity.includes(this.laneName)
    }
    const query = generateQuery(this.boardId, this.getLanIdByLaneName(this.laneName), from, size);
    query.query.where.laneIdQuery = {
      "type": "search",
      "operator": "or",
      "value": [
        {
          "field": "laneId.raw",
          "value": this.getLanIdByLaneName(this.laneName)
        }
      ]
    };

    if (validLane) {
      query['query']['where']['laneId.raw'] = {
        "type": "search",
        "field": "laneId.raw",
        "operator": "or",
        "value": [
          {
            "field": "laneId.raw",
            "value": this.getLanIdByLaneName(this.laneName)
          }
        ]
      }
      query['query']['where']['laneIdQuery'] = {
        "type": "search",
        "operator": "or",
        "value": [
          {
            "field": "laneId.raw",
            "value": this.getLanIdByLaneName(this.laneName)
          }
        ]
      }
    } else if (this.laneName === 'totaldeal') {
      if (this.boardId !== Constants.boardIdForSobOpportunity) {
        query['query']['where']['laneId.raw']['value'] = this.getAllDealQuery();
        query['query']['where']['laneIdQuery']['value'] = this.getAllDealQuery();
      }
      else if (this.boardId === Constants.boardIdForSobOpportunity) {
        query['query']['where']['laneId.raw']['value'] = this.getAllDealQueryOpportunity();
        query['query']['where']['laneIdQuery']['value'] = this.getAllDealQueryOpportunity();
      }
    } else if (this.laneName === 'activedeal' || this.laneName === 'closingDeal' || this.laneName === 'nextActionDateDeal') {
      if (this.boardId === Constants.boardIdForSobOpportunity) {
        query['query']['where']['laneId.raw']['value'] = this.getAllActiveDealQueryOpportunity();
        query['query']['where']['laneIdQuery']['value'] = this.getAllActiveDealQueryOpportunity();
      }
      else {
        query['query']['where']['laneId.raw']['value'] = this.getAllActiveDealQuery();
        query['query']['where']['laneIdQuery']['value'] = this.getAllActiveDealQuery();
      }
    } else if (this.laneName === 'wondeal') {
      if (this.boardId === Constants.boardIdForSobOpportunity) {
        query['query']['where']['laneId.raw']['value'] = this.getAllWonDealQueryOpportunity();
        query['query']['where']['laneIdQuery']['value'] = this.getAllWonDealQueryOpportunity();
      }
      else {
        query['query']['where']['laneId.raw']['value'] = this.getAllWonDealQuery();
        query['query']['where']['laneIdQuery']['value'] = this.getAllWonDealQuery();
      }
    }
    else if (this.laneName === 'kams_handover') {
      query['query']['where']['laneId.raw']['value'] = this.getKAMsHandoverDealQuery();
      query['query']['where']['laneIdQuery']['value'] = this.getKAMsHandoverDealQuery();
    }

    else {
      delete query['query']['where']['laneId.raw']
      delete query['query']['where']['laneIdQuery']
    }
    if (this.activeSorts && this.activeSorts.length > 0) {
      query['query']['nested_sort'] = [{
        ...this.activeSorts[0]
      }]
      delete query['query']['sort'];
    } else {
      query['query']['nested_sort'] = [{ "field": "data.realisticdealsizecr", "order": "desc" }];
    }
    if (this.laneName === 'Dormant') {
      if (this.boardId === Constants.boardIdForSobOpportunity) {
        query['query']['where']['laneId.raw']['value'] = this.getAllActiveDealQueryOpportunity();
        query['query']['where']['laneIdQuery']['value'] = this.getAllActiveDealQueryOpportunity();
      } else {
        query['query']['where']['laneId.raw']['value'] = this.getAllActiveDealQuery();
        query['query']['where']['laneIdQuery']['value'] = this.getAllActiveDealQuery();
      }
    }
    if (this.teamQuery) {
      if (!query['query']['nestedWhere']) {
        query['query']['nestedWhere'] = {};
      }
      query['query']['nestedWhere']["team_query"] = this.teamQuery;
    }
    if (this.bsQuery) {
      if (!query['query']['nestedWhere']) {
        query['query']['nestedWhere'] = {};
      }
      query['query']['nestedWhere']["bs_query"] = this.bsQuery;
    }
    if (this.userQuery) {
      query['query']['where']["user_query"] = this.userQuery;
    }
    if (this.primaryUserQuery) {
      query['query']['where']["primary_query"] = this.primaryUserQuery;
    }
    if (this.secondaryUserQuery) {
      query['query']['nestedWhere']["secondary_query"] = this.secondaryUserQuery;
    }
    if (this.createdDateQuery) {
      query['query']['where']["createdAt"] = this.createdDateQuery['createdAt'];
    }
    if (this.closingDateQuery) {
      if (!query['query']['nestedWhere']) {
        query['query']['nestedWhere'] = {};
      }
      query['query']['nestedWhere']['data.closingdate'] = this.closingDateQuery['data.closingdate'];
    }
    if (this.nextActionDateQuery) {
      if (!query['query']['nestedWhere']) {
        query['query']['nestedWhere'] = {};
      }
      query['query']['nestedWhere']['data.nextactiondate'] = this.nextActionDateQuery['data.nextactiondate'];
    }
    if (this.lastUpdatedDateQuery) {
      query['query']['where']["updatedAt"] = this.lastUpdatedDateQuery['updatedAt'];
    }
    if (this.dormantQuery) {
      query['query']['where']["updatedAt"] = this.dormantQuery['updatedAt'];
    }
    this.isLoading = true;
    this.kanbanService.getLaneTask(query['query']).subscribe((response) => {
      this.isLoading = false;
      if (response && response['result']) {
        this.dataSource = response['result'];
        this.count = response['total'];
      }
    });


  }

  loadDisplantColumns() {
    this.displayedColumns = [
      {
        "key": null,
        "field": "deal-name",
        "rawValue": null,
        "type": "string",
        "value": "",
        "optional": true,
        "display": true,
        "displayWhileListing": true,
        "showTotal": false,
        "displayName": "Deal Name",
        "dataList": [],
        "values": null,
        "additionalData": null,
        "operationsData": null,
        "operations": null,
        "index": 1,
        "icon": "",
        "relationKey": null,
        "nameField": true
      },
      {
        "key": "user",
        "field": "deal-owner",
        "rawValue": null,
        "type": "belongsTo",
        "value": "",
        "optional": true,
        "display": true,
        "displayWhileListing": true,
        "showTotal": false,
        "displayName": "Deal Owner",
        "dataList": [],
        "values": null,
        "additionalData": null,
        "operationsData": null,
        "operations": null,
        "index": 2,
        "icon": "",
        "relationKey": null,
        "nameField": false
      },
      {
        "key": null,
        "field": "closing-date",
        "rawValue": null,
        "type": "date",
        "value": "",
        "optional": true,
        "display": true,
        "displayWhileListing": true,
        "showTotal": false,
        "displayName": "Closing Date",
        "dataList": [],
        "values": null,
        "additionalData": null,
        "operationsData": null,
        "operations": null,
        "index": 3,
        "icon": "",
        "relationKey": null,
        "nameField": false
      },
      {
        "key": null,
        "field": "realistic-deal-size-cr",
        "rawValue": null,
        "type": "currency",
        "value": "",
        "optional": true,
        "display": true,
        "displayWhileListing": true,
        "showTotal": false,
        "displayName": "Realistic Deal Size (In Cr)",
        "dataList": [],
        "values": null,
        "additionalData": null,
        "operationsData": null,
        "operations": null,
        "index": 4,
        "icon": "",
        "relationKey": null,
        "nameField": false
      },
      {
        "key": null,
        "field": "business-potential-in-cr",
        "rawValue": null,
        "type": "currency",
        "value": "",
        "optional": true,
        "display": true,
        "displayWhileListing": true,
        "showTotal": false,
        "displayName": "Business Potential (In Cr)",
        "dataList": [],
        "values": null,
        "additionalData": null,
        "operationsData": null,
        "operations": null,
        "index": 5,
        "icon": "",
        "relationKey": null,
        "nameField": false
      },
      {
        "field": "probability-of-conversion",
        "type": "number",
        "value": "",
        "optional": true,
        "display": true,
        "displayWhileListing": true,
        "showTotal": false,
        "displayName": "Probability of Conversion (%)",
        "dataList": [],
        "index": 6,
        "icon": "",
        "isNameField": false
      },
      {
        "key": "laneTask",
        "field": "account-name",
        "rawValue": null,
        "type": "belongsTo",
        "value": "",
        "optional": true,
        "display": true,
        "displayWhileListing": true,
        "showTotal": false,
        "displayName": "Account Name",
        "dataList": [],
        "values": null,
        "additionalData": null,
        "operationsData": null,
        "operations": null,
        "index": 6,
        "icon": "",
        "relationKey": "63bfa8c3ade7bc466d1c780d",
        "nameField": false
      },
      {
        "key": "string",
        "field": "business-vertical",
        "rawValue": null,
        "type": "select",
        "value": "",
        "optional": true,
        "display": true,
        "displayWhileListing": true,
        "showTotal": false,
        "displayName": "Business Vertical",
        "dataList": [
          "MRO",
          "Packaging",
          "Credlix",
          "Custom Manufacturing",
          "Infra",
          "MaaS Fashion",
          "Mid Market",
          "Oil & Gas"
        ],
        "values": null,
        "additionalData": null,
        "operationsData": null,
        "operations": null,
        "index": 6,
        "icon": "",
        "relationKey": null,
        "nameField": false
      },
      {
        "key": "userTeam",
        "field": "region-team",
        "rawValue": null,
        "type": "belongsTo",
        "value": "",
        "optional": true,
        "display": true,
        "displayWhileListing": true,
        "showTotal": false,
        "displayName": "Region/Team",
        "dataList": [],
        "values": null,
        "additionalData": null,
        "operationsData": null,
        "operations": null,
        "index": 8,
        "icon": "",
        "relationKey": null,
        "nameField": false
      },
      {
        "key": "string",
        "field": "deal-type",
        "rawValue": null,
        "type": "select",
        "value": "",
        "optional": true,
        "display": true,
        "displayWhileListing": true,
        "showTotal": false,
        "displayName": "Deal Type",
        "dataList": [
          "ARC",
          "RFQ"
        ],
        "values": null,
        "additionalData": null,
        "operationsData": null,
        "operations": null,
        "index": 10,
        "icon": "",
        "relationKey": null,
        "nameField": false
      }
    ];
    this.displayedColumns1 = [
      "deal-name",
      "deal-owner",
      "closing-date",
      "realistic-deal-size-cr",
      "business-potential-in-cr",
      "probability-of-conversion",
      "account-name",
      "business-vertical",
      "deal-type",
      "actionItem"
    ]
  }
  loadStageMap() {
    this.stagemap.set('C0', Constants.C0);
    this.stagemap.set('C1', Constants.C1);
    this.stagemap.set('C2', Constants.C2);
    this.stagemap.set('C3', Constants.C3);
    this.stagemap.set('C4', Constants.C4);
    this.stagemap.set('PO Received/ ADP', Constants.poRecievedLaneId);
    this.stagemap.set('Cold Deal', Constants.coldDealLaneId);
    this.stagemap.set('Lost Deal', Constants.lostDealLaneId);
    this.stagemap.set('KAMS Handover', Constants.kamHandoverLaneId);
  }
  loadStageMapOpportunity() {
    this.stagemap.set('S0', this.S0);
    this.stagemap.set('S1', Constants.S1);
    this.stagemap.set('S2', Constants.S2);
    this.stagemap.set('S3', Constants.S3);
    this.stagemap.set('S4', Constants.S4);
    this.stagemap.set('Deal Won', Constants.dealWon);
    this.stagemap.set('Cold Deal', Constants.coldDeal);
    this.stagemap.set('Lost Deal', Constants.lostDeal);
  }
  getAllActiveDealQuery() {
    let newValue = [
      {
        "field": "laneId.raw",
        "value": Constants.C0
      },
      {
        "field": "laneId.raw",
        "value": Constants.C1
      },
      {
        "field": "laneId.raw",
        "value": Constants.C2
      },
      {
        "field": "laneId.raw",
        "value": Constants.C3
      },
      {
        "field": "laneId.raw",
        "value": Constants.C4
      }];
    return newValue;
  }
  getAllActiveDealQueryOpportunity() {
    const opportunityConstants = [
      Constants.S0,
      Constants.S1,
      Constants.S2,
      Constants.S3,
      Constants.S4
    ];

    return opportunityConstants.map(value => ({
      "field": "laneId.raw",
      "value": value
    }));
  }
  getAllWonDealQuery() {
    let newValue = [
      {
        "field": "laneId.raw",
        "value": Constants.poRecievedLaneId
      }];
    return newValue;
  }
  getKAMsHandoverDealQuery() {
    let newValue = [
      {
        "field": "laneId.raw",
        "value": Constants.kamHandoverLaneId
      }];
    return newValue;
  }
  getAllWonDealQueryOpportunity() {
    let newValue = [
      {
        "field": "laneId.raw",
        "value": Constants.dealWon
      }];
    return newValue;
  }
  getAllDealQuery() {
    let newValue = [
      {
        "field": "laneId.raw",
        "value": "63ce39b2a5c09f3f171460a3"
      },
      {
        "field": "laneId.raw",
        "value": "63ce39b7a5c09f3f171460a4"
      },
      {
        "field": "laneId.raw",
        "value": "63ce39b9a5c09f3f171460a5"
      },
      {
        "field": "laneId.raw",
        "value": "63ce39bca5c09f3f171460a6"
      },
      {
        "field": "laneId.raw",
        "value": "63ce39bfa5c09f3f171460a7"
      },
      {
        "field": "laneId.raw",
        "value": "63ce39cba5c09f3f171460a8"
      },
      {
        "field": "laneId.raw",
        "value": "63e50f29dfb86b637fee8abd"
      },
      {
        "field": "laneId.raw",
        "value": "64560e0a24107751707950ed"
      },
      {
        "field": "laneId.raw",
        "value": Constants.kamHandoverLaneId
      }
    ];
    return newValue;
  }
  getAllDealQueryOpportunity() {
    const opportunityConstants = [
      Constants.S0,
      Constants.S1,
      Constants.S2,
      Constants.S3,
      Constants.S4,
      Constants.dealWon,
      Constants.coldDeal,
      Constants.lostDeal
    ];

    return opportunityConstants.map(value => ({
      "field": "laneId.raw",
      "value": value
    }));
  }

  getFieldValue(ele, field) {
    let val = "";

    if (field != null && (field == "createdAt")) {
      if (ele && ele[field]) {
        val = this.formatDate(ele[field]);
      }
    }

    if (field != null && field == "updatedAt") {
      const firstDate = new Date().getTime();
      const secondDate = new Date(ele[field]);
      if (field && ele && ele[field] && firstDate && secondDate) {
        val = this.calculateDifferenceBetweenDates(firstDate, secondDate);
      }
    }

    let laneData = ele['data'];
    if (laneData != null) {

      let laneField = laneData.find((ele) => {
        if (ele['field'] == field) {
          return ele;
        }
      });

      if (laneField && (laneField.type == "string" || laneField.type == "number" || laneField.type == "email" || laneField.type == "phone" || laneField.type == "textarea" ||
        laneField.type == "currency")) {
        val = laneField.value;
      } else if (laneField && (laneField.type == "date") && laneField.value != null && laneField.value.trim() != "") {
        val = this.formatDate(laneField.value);
      } else if (laneField && (laneField.type == "select")) {
        if ((laneField.key == "string" || laneField.key == "number")) {
          val = laneField.value;
        } else {
          //let searchType = laneField.key == "user" ? "user" : "laneTask";
          val = laneField.additionalData != null ? laneField.additionalData.name : "";
        }
      } else if (laneField && (laneField.type == "belongsTo")) {
        val = laneField.additionalData != null ? laneField.additionalData.name : "";
      }
    }

    if (!val || (val && val.trim() == "")) {
      val = "-";
    }
    return val;
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [day, month, year].join('-');
  }
  calculateDifferenceBetweenDates(firstDate, secondDate) {
    let val = "";
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    let diffDays = 0;
    diffDays = Math.round((firstDate - secondDate.getTime()) / oneDay);
    if (diffDays === 0) {
      val = " today";
    } else if (diffDays === 1) {
      val = " yesterday"
    } else {
      val = diffDays + " days ago";
    }
    return val;
  }
  checkPastClosingDate(ele, field) {
    if (field === 'closing-date' && (ele.lane.id === Constants.C0 || ele.lane.id === '63ce39b7a5c09f3f171460a4' || ele.lane.id === '63ce39b9a5c09f3f171460a5' || ele.lane.id === '63ce39bca5c09f3f171460a6' || ele.lane.id === '63ce39bfa5c09f3f171460a7')) {
      const firstDate = new Date().setHours(0, 0, 0, 0);
      let laneData = ele['data'];
      if (laneData != null) {
        let laneField = laneData.find((ele) => {
          if (ele['field'] == field) {
            return ele;
          }
        });
        var secondDate1 = new Date(laneField.value).setHours(0, 0, 0, 0);
        var secondDate = new Date(laneField.value),
          month = '' + (secondDate.getMonth() + 1),
          day = '' + secondDate.getDate(),
          year = secondDate.getFullYear();
        if (firstDate > secondDate1) {
          return true;
        }
      }
    }
    return false;
  }
  getFormattedDate(date) {
    if (date) {
      var secondDate = new Date(date),
        month = '' + (secondDate.getMonth() + 1),
        day = '' + secondDate.getDate(),
        year = secondDate.getFullYear();
      return day + "-" + month + "-" + year;
    }
  }
  openCardDetails(task) {
    window.open('/#/cardDetails/' + task.id, "_blank");
  }
  sortLaneTasks($event) {
    this.activeSorts = [];
    if ($event && $event['active'] && $event['direction']) {
      let index = this.activeSorts.findIndex((tmp) => {
        return tmp.field == $event['active']
      });
      if ($event['direction'].trim() == "") {
        // Do Nothing
      } else {
        let f = $event['active'].replace(/\W/g, '');
        if (!f.match(this.sortNumberFieldRegex)) {
          f = f + ".raw";
        } else if (f === 'actionItem') {
          f = "nextactiondate";
        }
        this.activeSorts.push({
          "field": "data." + f,
          "order": $event['direction']
        })
      }
    } else {
      // Do Nothing
    }
    this.paginator.firstPage();
    this.loadData();
  }
}
