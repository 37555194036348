<div>
  <div class="d-flex-rw">
    <section class="pb-15 dialog-heading light-grey-bg" aria-label="heading">
      <div fxLayout="row mb-30 " fxLayoutAlign="space-between flex-center">
        <div class="mb-15">
          <h1 class="modal-heading-2 text-capitalize">{{data.user.name}}</h1>
          <!-- <h2 class="modal-heading-4">dssfd</h2> -->
        </div>

        <button
          mat-mini-fab
          class="modal-close-btn"
          mat-dialog-close
        >
          <i class="ri-close-circle-fill"></i>
        </button>
      </div>
      <div class="history-filter" fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="row" fxLayoutAlign="start center">

      
        <div class="formfield mr-10">
          <label>Search deal</label>
          <app-field-autocomplete [fieldData]="{
            'config' : accountConfig,
            'isFilter' : true
          }" [modelRef]="accountConfig.value" (filterApplied)="onRelationFilter(accountConfig);">
            </app-field-autocomplete>
        </div>
        <!-- <div class="formfield history-date-picker" style="width: 204px">
          <label>Date</label>
          <crm-date-picker
            [selectDefault]="true"
            [resetDates]="resetDates"
            (dateFilterApplied)="onApplyDates($event)"
          ></crm-date-picker>
        </div> -->
      </div>
      <!--<button mat-flat-button class="mog3_primary_flat_btn add-deal-btn" (click)="addNewLead()">
        <i class="ri-add-line"></i>   
        <span>
        Download Report </span>
      </button>-->
      </div>
    </section>
    <!-- <div fxLayout="row" fxLayoutAlign="flex-end center" style="width: 98%;" >
      <mat-icon class="material-icons close-icon" (click)="dialogRef.close();">close</mat-icon>
    </div> -->
    <div class="mog3_msnsearchtable_sticky history-tab-table bg-dark-grey">
      <table mat-table class="w-100 " [dataSource]="dealHistories"> 

        
        <ng-container matColumnDef="CreationDate"> 
        
        <th mat-header-cell *matHeaderCellDef>
          <span class="no-wrap">Creation Date </span></th> 
        
        <td mat-cell *matCellDef="let element"> 
           <span class="no-wrap"> {{element.creationDate | date}} </span>
          </td> 
        
        </ng-container> 
        
         
         
        
        
        <ng-container matColumnDef="DealName"> 
        
        <th mat-header-cell *matHeaderCellDef> Deal Name </th> 
        
        <td mat-cell *matCellDef="let element">
          <span class="blue-anchor"> {{element.dealName}} </span>
         <br>
         <span class="label-3">{{element.accountName}}</span>
         </td> 
        
        </ng-container> 
        
         
         
        
        
        <ng-container matColumnDef="ClosingDate"> 
        
        <th mat-header-cell *matHeaderCellDef> Closing Date</th> 
        
        <td mat-cell *matCellDef="let element"> 
        
          <span class="no-wrap"> {{element.closingDate | date}} </span>
        <!--- class for red text --->
        <!-- <span class="red-text">  {{element.ClosingDate}} </span> -->
        
        </td> 
        
        </ng-container> 
        
         
         
      
        
        <ng-container matColumnDef="Activity "> 
        
        <th mat-header-cell *matHeaderCellDef> Activity </th> 

        <td mat-cell *matCellDef="let element"> 
          {{element.displayString }} 

        </td> 
        
        </ng-container> 
        
         
         
  
        
        <ng-container matColumnDef="NextActionItem "> 
        
        <th mat-header-cell *matHeaderCellDef> Next Action Item </th> 
        
        <td mat-cell *matCellDef="let element"> 
        
      
        
          <span [innerHtml]="element.actionItemDTO.message" ></span>
        <br>
        <div *ngIf = "element.actionItemDTO.nextActionDate">
         <span class="label-3">Action date: {{element.actionItemDTO.nextActionDate | date}} </span>
        </div>
        </td> 
        
        </ng-container> 
        
         
      
        
         
        <tr mat-header-row *matHeaderRowDef="displayColumns"></tr> 
        
        <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr> 
        
        </table> 
      <div fxLayout="row" fxLayoutAlign="end center" style="width: 100%">
        <div class="d-flex-rw" style="width: 100%">
          <div class="pagination-wrapper" style="width: 100%">
            <!-- <mat-paginator
                  #paginator
                  [hidden]="!dataSource.data.length"
                  [length]="totalRecords"
                  [pageIndex]="pageNumber"
                  class="custom-pagintor"
                  [pageSize]="pageSize"
                  showFirstLastButtons
                  (page)="getLaneTaskHistory()"
                 
                >
             
                </mat-paginator> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
