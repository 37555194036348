export const environment = {
  //for QA URL
  // BASEURL: "https://apiqa.credlixlab.com/",
  //  credlixURL: "https://uiqa.credlixlab.com",
  // for UAT URL
  BASEURL: "https://api-crm.moglilabs.com/",
  // BASEURL: "http://localhost:8081/",
  //MOGLIXKANBAN: "http://localhost:8081/",
  credlixURL: "https://uat.credlixlab.com",
  // credlixSID:"https://credlix-sid.credlixlab.com/",
  ESCROWURL: "https://payuat.credlixlab.com/",
  MOGLIXKANBAN: "https://api-crm.moglilabs.com/",
  UploadMappingURL: "https://credlix-data.integration.credlixlab.com/",
  CREDLIXKANBAN: "https://uat-credlix-kanban.credlixlab.com/",
  production: false
};

