import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EmailerViewComponent } from '../emailer-view/emailer-view.component';
import { SettingService } from 'src/app/settings/setting.service';
import { MatPaginator } from '@angular/material';
import { ViewChild } from '@angular/core';
import { U } from '@angular/cdk/keycodes';
import { Router } from '@angular/router';
import { CustomMultipleSelectComponent } from '../k-board/task-dashboard/chart-filters/custom-multiple-select/custom-multiple-select.component';
import { Constants } from 'src/app/Constants';
import { AbmContactsComponent } from '../abm-contacts/abm-contacts.component';
@Component({
  selector: 'app-abm-management',
  templateUrl: './abm-management.component.html',
  styleUrls: ['./abm-management.component.scss']
})
export class AbmManagementComponent implements OnInit {

  dataSource: Array<any> = [];

  displayColumns: Array<string> = ['CampaignName', 'BusinessUnit','Contacts', 'Account', 'StartDate', 'EndDate', 'Delivered', 'OpenRate', 'CTR', 'Bouncerates', 'View'];


  searchByNameConfig = {
    "key": "laneTask",
    "field": "account-name",
    "rawValue": null,
    "type": "belongsTo",
    "value": "",
    "optional": true,
    "display": true,
    "displayWhileListing": true,
    "showTotal": false,
    "displayName": "Account Name",
    "dataList": [],
    "values": null,
    "additionalData": null,
    "operationsData": null,
    "operations": null,
    "index": 6,
    "icon": "",
    "relationKey": "63bfa8ccade7bc466d1c780e",
    "nameField": false,
    "isClear": false
  };
  searchByNameQuery: any;
  CampaignName;
  pageIndex = -1;
  totalRecords;
  pageSize = 20;
  pageFrom = 0;
  switchBusiness:any;
  businessUnitLabal="Business Unit";
  selectedBU=[];
  defaultBU = ["Marketing",
  "Packaging",
  "MRO",
  "Infra",
  "MaaS",
  "Fashion",
  "Zoglix",
  "Moglix UAE",
  "Internal",
  "Supplier",
  "Events",
  "CS Team",
  "PR & Awards",
  "Fighter Categories",
  "Credlix"];
  defaultBUSearch = this.defaultBU;
  searchControlBU='';
  accountConfigOpportunity = {
    "key": "laneTask",
    "field": "account-name",
    "rawValue": null,
    "type": "belongsTo",
    "value": "",
    "optional": true,
    "display": true,
    "displayWhileListing": true,
    "showTotal": false,
    "displayName": "Account Name",
    "dataList": [],
    "values": null,
    "additionalData": null,
    "operationsData": null,
    "operations": null,
    "index": 6,
    "icon": "",
    "relationKey": Constants.boardIdForSobAccount,
    "nameField": false
  };
  accountIds=[];
  localCache: any = localStorage;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(CustomMultipleSelectComponent) custom: CustomMultipleSelectComponent;
  @ViewChild('customSelectRef') customSelectRef: any;
  constructor(public dialog: MatDialog, public settingService: SettingService, private router: Router) {
  }

  ngOnInit() {
    // this.switchBusiness=this.settingService._ManagementData.subscribe(res => {
    //   console.log('in Oninit abm listing switching business',res)
    //   if(res==="SOB Management" || res==="CRM Management" ){
    //     this.router.navigate(['/crm-board/']);
    //   }      
    // });
    let cachedFilter = this.localCache.getItem('appliedAbmFilters');
    if(cachedFilter){
      cachedFilter = JSON.parse(cachedFilter);
      if (cachedFilter['CampaignName'] != null) {
        this.CampaignName = cachedFilter['CampaignName'];
      }
      if (cachedFilter['selectedBU'] != null) {
        this.selectedBU = cachedFilter['selectedBU'];
      }
      if (cachedFilter['accountIds'] != null) {
        this.accountIds = cachedFilter['accountIds'];
      }
      if (cachedFilter['pageFrom'] != null) {
        this.pageFrom = cachedFilter['pageFrom'];
        this.paginator.pageIndex = this.pageFrom;
      }else{
        this.paginator.pageIndex = 0;
      }
    }
    this.apply();
  }
  ngOnDestroy(){
    console.log('ngOnDestroy')
    //this.switchBusiness.unsubscribe();
  }
  onRelationFilterByName(config) {
    console.log('onRelationFilterByName in chart filter config', config)
    if (!config || !config.value || config.value.trim() == "") {
      this.searchByNameQuery = null;
    } else {
      this.searchByNameConfig.isClear = false;
      let query = {
        "query": {
          "where": {}
        }
      };
      query['query']["where"]["name_query"] = {};
      query['query']["where"]["name_query"]["type"] = "search";
      query['query']["where"]["name_query"]["operator"] = "or";
      let tQuery = [];
      tQuery.push({
        "field": "id",
        "value": config.value,
      })

      query['query']["where"]["name_query"]["value"] = tQuery;
      this.searchByNameQuery = query;
    }
    console.log('onRelationFilterByName in chart filter acc query', this.searchByNameQuery)
    // this.onApply();
  }
  // apply() {
  //   this.pageFrom = this.paginator.pageIndex
  //   this.settingService.fetchCampaignList(this.CampaignName, this.pageFrom, this.pageSize).subscribe(response => {
  //     if (response && response['result']) {
  //       this.totalRecords = response['result']['total_record_count'];
  //       if (response['result']['recent_campaigns']) {
  //         this.dataSource = response['result']['recent_campaigns']
  //       }
  //     }
  //   });
  // }
  apply() {
    let obj:any={};
    obj.campaignName=this.CampaignName;
    obj.businessUnits=this.selectedBU;
    obj.accountIds=this.accountIds;
    obj.fromIndex=this.pageFrom;
    obj.range=this.pageSize;
    this.settingService.fetchCampaignListV1(obj).subscribe(response => {
      if (response && response['result']) {
        this.totalRecords = response['result']['total_record_count'];
        if (response['result']['recent_campaigns']) {
          this.dataSource = response['result']['recent_campaigns']
        }
      }
    });
    let filterCachedObj: any = {};
    if (this.localCache.getItem('appliedAbmFilters')) {
      filterCachedObj = this.localCache.getItem('appliedAbmFilters');
      filterCachedObj = JSON.parse(filterCachedObj);
    } else {
      filterCachedObj = {};
    }
    if (this.CampaignName) {
      filterCachedObj["CampaignName"] = this.CampaignName;
    }else{
      filterCachedObj["CampaignName"] = this.CampaignName;
    }
    if (this.selectedBU) {
      filterCachedObj["selectedBU"] = this.selectedBU;
    }
    if (this.accountIds) {
      filterCachedObj["accountIds"] = this.accountIds;
    }
    filterCachedObj["pageFrom"] = this.pageFrom;
    this.localCache.setItem('appliedAbmFilters', JSON.stringify(filterCachedObj));
  }
  // reload() {
  //   this.pageFrom = this.paginator.pageIndex
  //   this.settingService.reloadCampaignList(this.CampaignName, this.pageFrom, this.pageSize).subscribe(response => {
  //     if (response && response['result']) {
  //       this.totalRecords = response['result']['total_record_count'];
  //       if (response['result']['recent_campaigns']) {
  //         this.dataSource = response['result']['recent_campaigns']
  //       }
  //     }
  //   });
  // }
  reload() {
    this.pageFrom = this.paginator.pageIndex
    let obj:any={};
    obj.campaignName=this.CampaignName;
    obj.businessUnits=this.selectedBU;
    obj.accountIds=this.accountIds;
    obj.fromIndex=this.pageFrom;
    obj.range=this.pageSize;
    this.settingService.reloadCampaignListV1(obj).subscribe(response => {
      if (response && response['result']) {
        this.totalRecords = response['result']['total_record_count'];
        if (response['result']['recent_campaigns']) {
          this.dataSource = response['result']['recent_campaigns']
        }
      }
    });
  }
  clear() {
    this.CampaignName = "";
    this.accountIds=[];
    if (this.customSelectRef) {
      this.customSelectRef.clearSelections();
    }
    this.selectedBU=[];
    this.pageFrom = 0;
    this.paginator.pageIndex = this.pageFrom;
    this.apply();
  }
  openDialog(element) {
    let data = element.campaign_preview;
    const dialogRef = this.dialog.open(EmailerViewComponent, {

      width: '914px',

      height: '90vh',

      backdropClass: 'custom-dialog-backdrop-class',

      panelClass: 'custom-dialog-panel-class',
      data: data

    });

  }
  openPopup(url: string): void {
    if (url) {
      url = "https://" + url
    }
    console.log('url ', url)
    // Define the dimensions and properties of the popup window
    const popupWidth = 800;
    const popupHeight = 600;
    const left = (window.innerWidth - popupWidth) / 2;
    const top = (window.innerHeight - popupHeight) / 2;

    // Open the popup window
    const popupWindow = window.open(
      url,
      'Popup Window',
      `width=${popupWidth}, height=${popupHeight}, left=${left}, top=${top}`
    );

    // Focus the popup window (optional)
    if (popupWindow) {
      popupWindow.focus();
    }
  }
  openAccountWiseReport(campaignKey) {
    window.open('/#/abm-accounts/' + campaignKey, "_self");
  }
  onBUSelectionChange(selection: string[]) {
    console.log('onBUSelectionChange',selection)
    this.selectedBU=selection;
    console.log('onBUSelectionChange  values ',this.selectedBU)
    this.apply();
  }
  filterOptionsBUForDealsListing(event) {
    console.log("filterOptionsBUForDealsListing")
    this.defaultBUSearch = this.defaultBU;
    const filterValue = event.toLowerCase();
    this.defaultBUSearch = this.defaultBU.filter(option => (option).toLowerCase().includes(filterValue));
  }
  handleApplySelections(selectedValues: any) {
    console.log('handleApplySelections Selected values:', selectedValues);
    this.accountIds = selectedValues.map(item => item.id);
    console.log('handleApplySelections account Ids',this.accountIds)
    this.apply();
  }
  onPageChange(){
    console.log('onPageChange')
    this.pageFrom=this.paginator.pageIndex;
    this.apply();
  }
  openContactDialog(element: any) {
    const dialogRef = this.dialog.open(AbmContactsComponent, {
      panelClass: 'right-side-dialog',
      data: { element }, // Pass data to the dialog
    });

    dialogRef.afterClosed().subscribe(result => {
      // Handle any actions after the dialog is closed
    });
  }
}
