import { Component, OnInit, Input , Inject, ElementRef, ViewChild} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { KanbanService } from '../../../kanban.service';

import { Value } from 'sass';

@Component({
  selector: 'app-view-lane',
  templateUrl: './view-lane.component.html',
  styleUrls: ['./view-lane.component.scss']
})
export class ViewLaneComponent implements OnInit {
  @Input() laneTaskDetailsArgs;
  laneTaskIDDetail : any;
  laneArrayKeys = [];
  laneWiseData = {};
  m1:any;
  m2:any;
  m3:any;
  m4:any;
  m5:any;
  m6:any;
  projectionData = [];
  projectionTimeline:any;
  projectionDataAvailable: boolean = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,  public _kService: KanbanService, public dialogRef: MatDialogRef<ViewLaneComponent>) { }

  ngOnInit() {
    this.laneTaskIDDetail = this.data.laneTask;
    if (this.laneTaskIDDetail) {
      this.getLaneDetailsById();
      this.loadProjectionData();
    }
  }
  loadLaneWiseData(laneData) {
    if (laneData != null) {
      this.laneWiseData = laneData['laneWiseData']
      if (this.laneWiseData  != null) {
        let tmpKeys = Object.keys(this.laneWiseData);
        let tmpKeys2 =[];
        let tmp = [];
        for(let i=0; i < tmpKeys.length;i++) {
          let tmpvar = this.laneWiseData[tmpKeys[i]];
          if (tmpvar == null || tmpvar.length < 1) {
           // continue;
          }
          tmpKeys2.push(tmpKeys[i]);
        }
        this.laneArrayKeys = tmpKeys2;
      }
   }
  }
  getLaneDetailsById() {
    if (this.laneTaskIDDetail != null && this.laneTaskIDDetail['laneWiseData'] != null){
      let laneData = this.laneTaskIDDetail;
      this.loadLaneWiseData(laneData);
    } else {
      this._kService.detailsByLaneId(this.laneTaskIDDetail.id).subscribe(res => {
        if (res != null && res['result']) {
          let laneData = res['result']
          this.loadLaneWiseData(laneData);
        }
      });
    }
  }
  getLaneName (name) {
    return name.replaceAll("-", " ").toLowerCase();
  }
  validFieldTypes = [ "string"];
  getFieldsToDisplay  = (data) => {
    if (!data || data.length < 1) {
      return [];
    }
    let result = [];
    for(let i=0; i < data.length; i++) {
      if (data[i]['display'] == true && data[i]['type'] == "string" || data[i]['type'] == "select" ) {
        result.push(data[i])
      } else if ((data[i]['type'] == "email" || data[i]['type'] == "phone" || 
      data[i]['type'] == "currency" || data[i]['type'] == "boolean"  || data[i]['type'] == "number" ) ) {
        result.push(data[i])
      } else if ( data[i]['type'] == "dropdown") {
        let tmp = data[i];
        let val = "N/A";
        if (tmp['dataList'] != null && tmp['dataList'].length >0)  {
          val = tmp['dataList'].join(" and ");
        }
        tmp['value'] = val; 
        result.push(tmp);
      }  else if ((data[i]['type'] == "file" || data[i]['type'] == "file")) {
        let tmp = data[i];
        let val = "N/A";
        if (tmp['value'] != null && tmp['value'].trim() != '')  {
          val = "Available";
        }
        tmp['value'] = val; 
        result.push(tmp);
      }
    }
   // console.log("returning result " + result)
    return result;
  } 
  getLeadContactDetails = (lead) => {
    var contact = {
      "email" : null,
      "phone" : null
    };
    if (lead['data'] != null && lead['data'].length > 0) {
      lead['data'].find(element => {
        if (element['field'] == "contact_details") {
          if (element['operationsData'] != null && element['operationsData']['CONTACT_DETAILS'] != null) {
            let contactDetails = element['operationsData']['CONTACT_DETAILS'];
            if (contactDetails != null && contactDetails.length > 0) {
              for(let k=0;k < contactDetails.length ; k++) {
                let ele = contactDetails[k];
                if (contact['email'] != null && contact['phone'] != null) {
                  break;
                }
                if (ele['phone'] != null && contact['phone'] == null) {
                  contact['phone'] = ele['phone'];
                }
                if (ele['email'] != null && contact['email'] == null) {
                  contact['email'] = ele['email'];
                }
              }
            }
          }
        } 
      });
    }
    return contact;
  }
  loadProjectionData(){
    let projectionMap:any=this.laneTaskIDDetail.projection;
    console.log("Projection",projectionMap);
    if(projectionMap){
      this.projectionDataAvailable = true;
      projectionMap.sort(function(a, b) {
        let x = new Date(a[0]);
        let y = new Date(b[0]);
        // Compare the 2 dates
        if (x < y) return -1;
        if (x > y) return 1;
        return 0;
    });
    this.m1=projectionMap[0].value;
    this.m2=projectionMap[1].value;
    this.m3=projectionMap[2].value;
    this.m4=projectionMap[3].value;
    this.m5=projectionMap[4].value;
    this.m6=projectionMap[5].value;
    this.projectionTimeline=projectionMap[0].date+" to "+projectionMap[5].date;
    }
  }
}
