import { Component, Inject, OnInit ,ViewChild } from '@angular/core';
import { MatPaginator,MAT_DIALOG_DATA, MatDialog, MatTableDataSource } from '@angular/material';
import { SettingService } from 'src/app/settings/setting.service';

@Component({
  selector: 'app-abm-contacts',
  templateUrl: './abm-contacts.component.html',
  styleUrls: ['./abm-contacts.component.scss']
})
export class AbmContactsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  count: any;
  pageNumber: number = 0;
  pageSize: number = 10;
  campaignKey: any;
  dataSource:any = new MatTableDataSource<any>();
  displayColumns: Array<string> = ['Name', 'Designation', 'Email', 'MobileNumber'];
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public settingService: SettingService) { }

  ngOnInit() {
    this.campaignKey = this.data.element.campaign_key
    console.log("ON INIT", this.campaignKey); // Access the passed data
    this.load()
  }

  load(){
    let from = 0;
    if (!this.paginator) {
      from = this.pageNumber;
    } else {
      from = this.paginator.pageIndex;
    }
    this.settingService.fetchAccountWiseCampaignContacts(this.campaignKey,from,this.pageSize).subscribe(response => {
      console.log('response', response)
      if (response && response['result']) {
        let result=response['result'];
        let searchDataList=[];
        if(result){
          for(let i=0;i<result.length;i++){
            searchDataList.push(result[i]['searchData']);
          }
        }
        this.dataSource = searchDataList;
        this.count = response['total'];
        console.log("datasource", this.dataSource)
      }
    });
  }

}
