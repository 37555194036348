import { Component, OnInit, Inject } from '@angular/core';
import { KanbanService } from '../../../kanban.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { saveAs } from 'file-saver';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {FormControl} from '@angular/forms';


@Component({
  selector: 'app-lane-move',
  templateUrl: './lane-move.component.html',
  styleUrls: ['./lane-move.component.scss']
})
export class LaneMoveComponent implements OnInit {
  laneConfig = [];
  currentLane: string;
  previousLane: string;
  laneConfigPresentData = [];
  taskRemark: string;
  countryList = [];
  configFile: any;
  laneTaskIDDetail: any;
  laneTaskIdByUrl: string;
  boardId: string;
  laneTaskConfig = [];
  coldLeadMoveReason: string = 'COLD_LEAD';
  uploadedDocumentList = [];
  selectedAssigneId: any;
  isReasonBtnVisible: boolean = false;
  myControl = new FormControl('');
  getboardName:string;
  getSelectedReason:any;
  filteredOptions: Observable<string[]>;
  boardLane = [
    { name: 'North', },
    { name: 'East' },
    { name: 'West' },
  ];
  CredlixRejectedReason = ["Unapproved Geography", "Unapproved Buyer / Small Buyer", "No trade history with the buyer",
    "Merchant Exporter/Trader", "Multinational Company (MNC)", "Exporter to many countries but wants to apply for unapproved geography only"
    , "High Risk product/ Product rejected by risk team"];
  ClientRejectedReason = ["Working & Satisfied with competitor", "Pricing not matched", "Having sufficient & under utilized banking limits"
    , "Cash rich companies / Self sufficient funds", "Working on advance basis only", "Afraid to share the buyer data" , "Invoice discounting with bank at lower rate."
    , "No recent shipment", "No more exporting" , "Client did not specify the reasons"]
  constructor(public dialogRef: MatDialogRef<LaneMoveComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public _kService: KanbanService, public toasterService: ToastrService) {
    console.log(data);
    if (data['obj']) {
      this.previousLane = data['obj'].previousLaneName
      this.currentLane = data['obj'].currentLaneName;
      if (this.currentLane == 'cold-leads') {
        this.isReasonBtnVisible = true;
      }
      if (data['response']) {
        data['response']['presentFields'].map(data => {
          data['readOnly'] = true;
        })
        this.laneConfigPresentData = data['response']['presentFields'].concat(data['response']['missingFields'])
      }
      if(data['obj']['assignees'] != null &&  data['obj']['assignees'].length>0){
        this.selectedAssigneId= data['obj']['assignees'][0]['user'].name
        this.myControl.setValue({name: this.selectedAssigneId});
      }
      if(data['obj']['boardName']){
        this.getboardName = data['obj']['boardName']
      }
    }
  }

  ngOnInit() {
    this.getCountryList();
    this.getAssigneeList();
    if(this.getboardName == 'domestic'){
      this.getSelectedReason = 'COLD_LEAD'

    }
  }

  validateTaskMovement(field) {
    let obj = {
      id: this.data['obj']['id']
    }
    this._kService.checkLaneMovementValidation(obj).subscribe(res => {
      if (res['status'] == 200) {
        this.laneConfig = res['result']['missingFields'];
        let presentArr = res['result']['presentFields'];
        presentArr.map(data => {
          data['readOnly'] = true;
        })
        let finalData = presentArr.concat(this.laneConfig);
        for (let i = 0; i < this.laneConfigPresentData.length; i++) {
          for (let j = 0; j < finalData.length; j++) {
            if ((this.laneConfigPresentData[i]['type'] == 'file' || this.laneConfigPresentData[i]['type'] == 'multi_upload') && this.laneConfigPresentData[i]['field'] == field
              && (finalData[j]['type'] == 'file' || finalData[j]['type'] == 'multi_upload') && finalData[j]['field'] == field) {
              this.laneConfigPresentData[i] = finalData[j];
            }
          }
        }
        //console.log(this.laneConfigPresentData);
      }
    })
  }
  closeDialog() {
    this.dialogRef.close(false);
  }

  selectColdLeadReason(event) {
    this.getSelectedReason = event;
  }

  SubmitLaneMove() {
    let reqObj = {
      id: this.data['obj'].id,
      laneId: this.data['obj'].laneId,
      data: this.laneConfigPresentData,
      remarks: this.taskRemark
    }
    this._kService.updateLaneTask(reqObj).subscribe(res => {
      if (res['status'] == 201) {
        if(this.data['obj']['assignees'] != null && this.data['obj']['assignees'].length >0){
        if(this.data['obj']['assignees'][0]['user'].name !=  this.selectedAssigneId){
         this.assignMake();
        }
      }
      else {
        this.assignMake();
      }
      if(this.getboardName== 'domestic'){
        this.dialogRef.close([this.getSelectedReason]);

      }
      else {
        this.dialogRef.close(this.getSelectedReason);
      }
      }
    })
  }

  getSelectedState(eventVal, laneVal) {
    console.log({ eventVal, laneVal })
  }

  getCountryList() {
    this._kService.getConfigCountryList().subscribe(res => {
      if (res['result'] != null && res['result'].length > 0) {
        this.countryList = res['result'];
      }
    })
  }

  getSelectedCountry(event, value) {
    console.log(event, 'valuee----', value);
  }

  onConfigFileChanged(event, fieldName, datatype, field) {
    this.configFile = event.target.files[0];
    const uploadData = new FormData();
    uploadData.append('file', this.configFile, this.configFile.name);
    uploadData.append('dataType', datatype);
    uploadData.append('remarks', fieldName);
    uploadData.append('laneTaskId', this.data['obj']['id']);
    this._kService.uploadCofigDocuments(uploadData).subscribe(file => {
      if (file['status'] == 200) {
        this.configFile = file['result'];
        this.validateTaskMovement(field);
      }
    });
  }

  changeConfigDate(event, value) {
    console.log(event, 'value---', value)

  }

  onConfigCheckboxChange(isChekboxCheckd) {
    console.log(isChekboxCheckd);

  }
  resultDownload;
  downloadSingleDocument(id) {
    this._kService.downloadSingleUploadDocument(id).subscribe(res => {
      this.downloadSheetCommonMethod(res);

    })
  }
  downloadDocumentsInZip() {
    this._kService.downloadZipDocumentsByLaneId(this.data['obj']['id']).subscribe(res => {
      this.downloadSheetCommonMethod(res);
    })
  }

  downloadSheetCommonMethod(res) {
    var contentDispositionHeader = res.headers.get('content-disposition');
    this.resultDownload = contentDispositionHeader.split(';')[1].trim().split('=')[1];
    saveAs(res.body, this.resultDownload);
    return this.resultDownload.replace(/"/g, '');
  }

  deleteTaskDocuments(id, field, dataType, index) {
    if (id == null) {
      this.configFile = null;
    } else {
      this._kService.deleteUploadedDocuments(id, field, this.data['obj']['id'], dataType).subscribe(res => {
        if (field != '') {
          this.configFile = null;
          this.validateTaskMovement(index);
        }
      })
    }
  }

  fetchUploadedTaskDocuments() {
    let obj = {
      "where": {
        "laneTask.$id": {
          "type": "search",
          "value": this.data['obj']['id']
        },
        "source": {
          "type": "search",
          "value": "LANETASK"
        },
        "isDeleted": {
          "type": "search",
          "value": false
        }
      }
    }
    this._kService.searchUploadedTaskDocuments(obj).subscribe(res => {
      this.uploadedDocumentList = res['result'];

    })
  }
  onSelectReason(event) {
    this.getSelectedReason = event
  }

  assigneeList = [];
  filterAssigneList=[]
  getAssigneeList() {
    this._kService.getKanbanUserList().subscribe(list => {
      this.assigneeList = list['result'];
      this.assigneeList.forEach(ele => {
        this.filterAssigneList.push(ele.userName)        
      })
      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value || '')),
      );
    })
  }
  onAssigneeChange(event) {
    this.selectedAssigneId = event

  }

  assignMake() {
    let req = []
    if(this.selectedAssigneId != 'UnAssigned'){
    for (let i = 0; i < this.assigneeList.length; i++) {
        if (this.selectedAssigneId == this.assigneeList[i].userName) {          
          req.push({
            "remarks": "test",
            "userId": this.assigneeList[i].userId
          })
        }
      }
    }
    this._kService.addAssigneeToLaneTask(req, this.data['obj'].id).subscribe(res => {
      if (res['status'] == 201) {
      }
    })
  }
 
   _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.filterAssigneList.filter(option => option.toLowerCase().includes(filterValue));
  }
}
