<!-- <table mat-table class="mat-table-theme horizontal-overflow-width kyc-table mat-table" [dataSource]="tableData">

  <ng-container matColumnDef="{{ column.field }}" *ngFor="let column of tableColumns">
    <th mat-header-cell *matHeaderCellDef> {{ column.displayName }} </th>
    <td mat-cell *matCellDef="let element"> {{ element[column.displayName] }} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>  -->

<table class="mat-table-theme  kyc-table mat-table  mat-table-bordered">
  <thead>
    <tr>
      <ng-container *ngFor='let key of tableColumns'>
        <ng-container *ngIf="selectedSubLaneId===customerProfileSubLaneId">
          <th class="text-center" *ngIf='key.subHeaders' [id]='key.field' [attr.colspan]=key.subHeaders.length>
            {{ key.displayName }}
          </th>
          <th *ngIf='!key.subHeaders' [id]='key.field' [attr.rowspan]=1>
            {{ key.displayName }}
          </th>
        </ng-container>
        <ng-container *ngIf="selectedSubLaneId!==customerProfileSubLaneId">
          <th class="text-center" *ngIf='key.subHeaders' [id]='key.field' [attr.colspan]=key.subHeaders.length>
            {{ key.displayName }}
          </th>
          <th *ngIf='!key.subHeaders' [id]='key.field' [attr.rowspan]=2>
            {{ key.displayName }}
          </th>
        </ng-container>

      </ng-container>
    </tr>
    <tr *ngIf="selectedSubLaneId!==customerProfileSubLaneId">
      <ng-container *ngFor='let key of tableColumns'>
        <th *ngFor="let sub of key.subHeaders">
          {{sub.displayName}}
        </th>
      </ng-container>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let row of tableData; let i = index">
      <!-- Show rows based on pagination -->
      <tr *ngIf="i >= paginator.pageIndex * paginator.pageSize && i < (paginator.pageIndex + 1) * paginator.pageSize">
        <!-- Iterate over table columns to display cells -->
        <ng-container *ngFor="let column of tableColumns">
          <!-- If it's a regular column -->
          <td *ngIf="!column.subHeaders">{{ row[column.field] }}</td>
          <!-- If it's a column with subheaders -->
          <ng-container *ngIf="column.subHeaders">
            <!-- Iterate over subheaders to display cells -->
            <ng-container *ngFor="let sub of column.subHeaders">
              <td>{{row[column.field][sub.field] }}</td>
            </ng-container>
          </ng-container>
        </ng-container>
      </tr>
    </ng-container>
  </tbody>
</table>

<!-- Add Paginator -->
<mat-paginator [pageSizeOptions]="[5, 10, 20]" [length]="tableData?.length || 5" #paginator></mat-paginator>