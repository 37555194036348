import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-emailer-view',
  templateUrl: './emailer-view.component.html',
  styleUrls: ['./emailer-view.component.scss']
})
export class EmailerViewComponent implements OnInit {
  campaignContent="";
  mywindow;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<EmailerViewComponent>) { 
    this.campaignContent=data;
    console.log('this.campaignContent  ',this.campaignContent)
    //window.open("https://mogl-zgph.maillist-manage.net/campaigns/SharedCampaignPreview.zc?c=html&cDgs=3z938bfbd6aea1b72aa66203e05ade7ed75e512117779e11eab21ca223c096ed1d","_blank");
    let url="https://mogl-zgph.maillist-manage.net/campaigns/SharedCampaignPreview.zc?c=html&cDgs=3z938bfbd6aea1b72aa66203e05ade7ed75e512117779e11eab21ca223c096ed1d";
    const popupWidth = 800;
    const popupHeight = 600;
    const left = (window.innerWidth - popupWidth) / 2;
    const top = (window.innerHeight - popupHeight) / 2;

    // Open the popup window
    const popupWindow = window.open(
      url,
      'Popup Window',
      `width=${popupWidth}, height=${popupHeight}, left=${left}, top=${top}`
    );

    // Focus the popup window (optional)
    if (popupWindow) {
      popupWindow.focus();
    }
  }

  ngOnInit() {
    //window.open("https://mogl-zgph.maillist-manage.net/campaigns/SharedCampaignPreview.zc?c=html&cDgs=3z938bfbd6aea1b72aa66203e05ade7ed75e512117779e11eab21ca223c096ed1d","_self");
  }

}
