import { Component, OnInit, Inject, HostBinding, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material';
import { KanbanService } from '../../kanban.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource, MatPaginator, MatSelect, MatOption } from '@angular/material';

@Component({
  selector: 'app-k-board-filters',
  templateUrl: './k-board-filters.component.html',
  styleUrls: ['./k-board-filters.component.scss']
})
export class KBoardFiltersComponent implements OnInit {
  assigneeList = [];
  boardLane = [];
  allTags = [];
  filterObj = {}
  show: boolean;
  pageSize:number=500;
  pageNumber: number = 0;
  showtag: boolean;
  filteredAssignList = [];
  selectedAssigneeList = [];
  filteredLaneList = [];
  selectedLaneList = [];
  selectedtagList = [];
  filteredTagList = [];
  seachAssigneeText: string;
  seachTagsText: string;
  @HostBinding('class') class = 'k-board-filter';
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(public _kService: KanbanService, public dialogRef: MatDialogRef<KBoardFiltersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log(data);
  }

  ngOnInit() {
    this.getAssigneeList();
    this.getBoardLaneList();
    this.getTags();
  }

  getTags() {
    this._kService.getAllTags(this.data.boardId).subscribe((res: any) => {
      this.allTags = res['result']
      this.allTags.forEach(ele => {
        ele['checked'] = false
      });
      if (this.data.reqObj['tagList']) {
        for (let i = 0; i < this.data.reqObj.tagList.length; i++) {
          this.allTags.map(val => {
            if (val.id == this.data.reqObj.tagList[i]) {
              val['checked'] = true
            }
          })
        }
      }
    });
  }

  getAssigneeList() {
    let obj={}
    obj['from'] = 0;
    obj['size'] = this.pageSize
    this._kService.getUserList(obj).subscribe(list => {
      this.assigneeList = list['result'];
      this.assigneeList.forEach(ele => {
        ele['checked'] = false
      });
      if (this.data.reqObj['assignList']) {
        for (let i = 0; i < this.data.reqObj.assignList.length; i++) {
          this.selectedAssigneeList.push(this.data.reqObj['assignList']);
          this.assigneeList.map(val => {
            if (val.id == this.data.reqObj.assignList[i]) {
              val['checked'] = true
            }
          })
        }
      }
    });
  }
  getBoardLaneList() {
    this._kService.getLaneWithUserAccess(this.data.boardId, '').subscribe(res => {
      this.boardLane = res['result']['levelAccess'];
      this.boardLane = this.boardLane.sort((a, b) => a.laneDTO.position - b.laneDTO.position).map((exemple, index, array) => exemple)
      this.boardLane.forEach(ele => {
        ele.laneDTO['checked'] = false
      })
      if (this.data.reqObj['laneList']) {
        this.selectedLaneList.push(this.data.reqObj['laneList']);
        for (let i = 0; i < this.data.reqObj.laneList.length; i++) {
          this.boardLane.map(val => {
            if (val.laneDTO.id == this.data.reqObj.laneList[i]) {
              val.laneDTO['checked'] = true
            }
          })
        }
      }
    });
  }


  getSelectedAssigneeList(list) {
    this.selectedAssigneeList.push(list)
  }

  closeDialog() {
    this.filterObj={
      close: true
    }
    this.dialogRef.close(this.filterObj)
  }

  applyFilters(){
    let sendSelectedAssignee = []
    let sendselectedLaneList = [];
    let sendSelectedTagList = [];
    this.filteredAssignList = this.selectedAssigneeList.filter(option => option.checked == true);
    this.filteredAssignList = this.assigneeList.filter(option => option.checked == true);
    this.filteredAssignList.forEach(ele => {
      sendSelectedAssignee.push(ele.id);
    })

    this.filteredLaneList = this.selectedLaneList.filter(option => option.checked == true);
    this.filteredLaneList = this.boardLane.filter(option => option.laneDTO.checked == true);

    this.filteredLaneList.forEach(ele => {
      sendselectedLaneList.push(ele.laneDTO.id);
    });

    this.filteredTagList = this.selectedtagList.filter(option => option.checked == true);
    this.filteredTagList = this.allTags.filter(option => option.checked == true);
    this.filteredTagList.forEach(ele => {
      sendSelectedTagList.push(ele.id);
    });

    this.filterObj = {
      'assignList': sendSelectedAssignee,
      'laneList': sendselectedLaneList,
      'tagList': sendSelectedTagList
    }
    this.dialogRef.close(this.filterObj)
  }

  getSelectedLaneList(list) {
    this.selectedLaneList.push(list);
  }

  getSelectedTags(tag) {
    this.selectedtagList.push(tag);
  }
  resetFilters() {
    this.boardLane.forEach(ele => {
      ele.laneDTO['checked'] = false
    })
    this.assigneeList.forEach(ele => {
      ele['checked'] = false
    });
    this.allTags.forEach(ele => {
      ele['checked'] = false
    });
    this.filterObj={}
    this.dialogRef.close(this.filterObj)

  }
}
