<div fxLayout="column" fxLayoutAlign="start center" select="[rightPane]">

  <div fxLayout="row" fxLayoutAlign="space-between center" style="width:95%;">
    <h1 class="modal-heading-2 m-0">{{title}}</h1>
    <mat-icon class="material-icons close-icon" (click)="dialogRef.close();">close</mat-icon>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center" style="width: 100%;padding:0px 20px;">
    <quill-editor class="w-100 comments-quill-editor" [(ngModel)]="content" [modules]="modules">
    </quill-editor>
  </div>
  <div fxLayout="row" class="mt-15" fxLayoutAlign="end center" style="width: 94%;">

    <div fxLayout="row" class="mt-15" fxLayoutAlign="space-between" style="width: 100%;">
      <div fxLayout="row" fxLayoutAlign="start space-around" style="margin-right: 1rem;align-items: center;">
        <div fxLayout="row" *ngIf="type == 'action-item'" fxLayoutAlign="start center" class="label-width"
          style="width: 40%;">
          <label class="label-2 required">Next Action Date</label>

        </div>
        <div fxLayout="row" *ngIf="type == 'action-item'" class="field-width" style="width: 42%;">
          <mat-form-field class="mog3_matinput lane-card-input" appearance="outline">
            <input matInput (focus)="effectivePicker.open()" [matDatepicker]="effectivePicker" placeholder="Select Date" readonly [(ngModel)]="nextActionDate" [min]="minDateForNextAction">
            <mat-datepicker-toggle matSuffix [for]="effectivePicker"></mat-datepicker-toggle>
            <mat-datepicker #effectivePicker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>


      <div class="d-flex">
        <section aria-label="documents" class="cb_fileinput">
          <ng-container *ngIf="file == null">
            <div draggable="true" class="dragArea config" ngFileDragDrop (fileDropped)="fileUpload($event)">
              <span class="lbl">
                <input style="display: none" type="file" (change)="fileUpload($event)" #fileInput>
                <span (click)="fileInput.value='';fileInput.click()">
                  <mat-icon class="material-icons">attachment</mat-icon>
                </span>
              </span>
            </div>
          </ng-container>
          <ng-container *ngIf="file != null">
            <div class="file-upload-section">
              <div class="d-flex flex-column w-100">
                <div class="file-uploading-status d-flex justify-content-between">
                  <span class="file-name">
                    {{file.name}}
                    <a>
                      <i class="ri-close-line" (click)="rmeoveSelectedFile()"></i>
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </ng-container>
        </section>
        <button class=" mog3_primary_flat_btn post-button"
          (click)="requestfromNewDeal ? addComment() : postComment()">Post</button>
      </div>
    </div>
  </div>
</div>
<div fxLayout="row" fxLayoutAlign="start center" style="width: 100%;">
  <div fxLayout="row" style="width: 100%;">
    <div class="comment-table-height mog3_msnsearchtable_sticky" style="width: 100%;" infinite-scroll
      [infiniteScrollDistance]="5" [infiniteScrollThrottle]="150" [scrollWindow]="false" [fromRoot]="true"
      (scrolled)="onScrollDown()">
      <table mat-table [dataSource]="dataSource" class="mat-table">
        
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef style="width: 70%;"> {{title}} </th>
          <td mat-cell *matCellDef="let element; let i = index;">
            <div class="chat-thread">
              <div class="d-flex justify-content-between ">
                <div class="d-flex">
                  <p class="user-name">
                    {{element?.byUser.name}}
                  </p>
                  <p class="user-email">
                    {{element?.byUser.email}}
                  </p>
                </div>
                <p class="created-date">
                  Date :{{element.createdAt | date}}
                </p>
              </div>
              <span [innerHtml]="element.message" class="main-message"></span>
              <div *ngIf="type == 'action-item'" class="d-flex">
                <p class="created-date">
                  Next Action Date :{{element.nextActionDate | date}}
                </p>
              </div>
              <ng-container *ngIf="element.attachments && element.attachments.length">
                  <div class="file-upload-section">
             
                      <div class="file-uploading-status d-flex justify-content-between">
                        <a  (click)="downloadSingleDocument(element.attachments[0].id)">
                        <span class="file-name"  >
                          {{element.attachments[0].name}}
                          <mat-icon class="material-icons">download</mat-icon>
                        </span>
                      </a>
                      </div>
                  
                  </div>
                </ng-container>
              <span *ngIf=element.comments.length class="comments-label"> Added Comment</span>
              <ul *ngIf="!showMoretext" class="chat-tread-list">
                <li *ngFor="let comment of element.comments | slice:0:2; let i=index ">
                  <div class="d-flex justify-content-between ">
                    <div class="d-flex">
                      <p class="user-name">
                        {{comment?.byUser.name}}
                      </p>
                      <p class="user-email">
                        {{comment?.byUser.email}}
                      </p>
                    </div>
                    <p class="created-date">
                      Date :{{comment.createdAt | date}}
                    </p>
                  </div>
                  <div class="replied-message-section">
                    <!-- <span class="included-user"> @Nupur Singh</span> -->
                    <p class="replied-message" [innerHtml]="comment.message"> </p>
                  </div>
                </li>
              </ul>
              <ul *ngIf="showMoretext" class="chat-tread-list">
                <li *ngFor="let comment of element.comments">
                  <div class="d-flex justify-content-between ">
                    <div class="d-flex">
                      <p class="user-name">
                        {{comment?.byUser.name}}
                      </p>
                      <p class="user-email">
                        {{comment?.byUser.email}}
                      </p>
                    </div>
                    <p class="created-date">
                      Date :{{comment.createdAt | date}}
                    </p>
                  </div>
                  <div class="replied-message-section">
                    <!-- <span class="included-user"> @Nupur Singh</span> -->
                    <p class="replied-message" [innerHtml]="comment.message"> </p>
                  </div>
                </li>
              </ul>
              <span *ngIf="element.comments.length>2" (click)="toggleText()" class="comments-label-blue"> {{ showMoretext ? "View Less" : "View All " + element.comments.length +  " Comments"}}</span>

              <a class="red-anchor" (click)="toggleCommentArea(i)" *ngIf="selectedIndex!=i">Add Comment</a>
              <div class="mt-10" style="width: 100%;padding:0px 10px;" *ngIf="selectedIndex==i">
                <div fxLayout="row" fxLayoutAlign="start center">
                  <quill-editor #elem [id]="i" class="w-100 comments-quill-editor" [modules]="modules">
                  </quill-editor>

                </div>
                <div fxLayout="row" fxLayoutAlign="end center" class="mt-10">
                  <button mat-button class="cancel-comment mr-10" (click)="closeCommentArea(i)">Cancel</button>
                  <button class=" mog3_primary_flat_btn post-button" (click)="addCommentsOnActionItemOrNote(element,i)">Add</button>
                </div>
              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">

        </tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index"
          [ngClass]="{ 'active-row': i === selectedTrIndex }">

        </tr>

      </table>
      <div class="empty-div" *ngIf="(!totalComments || totalComments.length == 0) && type == 'action-item'">
        <img src="../../../../../../../assets/images/blank_watermart1.svg" class="no-data-watermark" />
        <span>You have no Action items <br> to work on it</span>
      </div>
      <div class="empty-div" *ngIf="(!totalComments || totalComments.length == 0) && type != 'action-item'">
        <img src="../../../../../../../assets/images/Group 16078.svg" class="no-data-watermark" />
        <span>You have no Notes <br> to work on it</span>
      </div>
    </div>
  </div>
</div>


<!--- Old code backup --->
<!-- <div fxLayout="row" fxLayoutAlign="start center" style="width: 100%;"  >
      <div fxLayout="row"  style="width: 100%;" >
        <div class="comment-table-height mog3_msnsearchtable_sticky" style="width: 100%;" infinite-scroll
        [infiniteScrollDistance]="5" [infiniteScrollThrottle]="150" 
        [scrollWindow]="false" [fromRoot]="true"  (scrolled)="onScrollDown()"  >
        <table mat-table [dataSource]="dataSource" class="mat-table" >
  
  
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef style="width: 70%;"> {{title}} </th>
            <td mat-cell *matCellDef="let element">
              <div class="table-row-content" style="width: 70%;">
                <span [innerHtml]="element.message" class="comment-message"></span>
                <p class="table-sub-details" style="text-transform: capitalize;">
                   {{element.nextActionDate | date}}
                </p>
                <ng-container *ngIf="element.attachments && element.attachments.length">
                  <div class="file-upload-section">
             
                      <div class="file-uploading-status d-flex justify-content-between">
                        <a  (click)="downloadSingleDocument(element.attachments[0].id)">
                        <span class="file-name"  >
                          {{element.attachments[0].name}}
                          <mat-icon class="material-icons">download</mat-icon>
                        </span>
                      </a>
                      </div>
                  
                  </div>
                </ng-container>
              </div>

            </td>
          </ng-container>
          <ng-container matColumnDef="userName">
            <th mat-header-cell *matHeaderCellDef> 
              <div fxLayout="row"  class="comments-icon-div">
                <span>{{userTitle}} By</span>
               
              </div> 
              </th>
            <td mat-cell *matCellDef="let element">
              <p class="table-sub-details" style="text-transform: capitalize;">
                {{element?.byUser.name}}
              </p>
              <p class="label-3" style="text-transform: capitalize;">
                {{element?.byUser.email}}
             </p>
             <p class="label-3" style="text-transform: capitalize;">
              {{element.createdAt | date}}
           </p>
            </td>
          </ng-container>
          

         
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
           
          </tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index"
            [ngClass]="{ 'active-row': i === selectedTrIndex }">
          
          </tr>
  
     
        </table>
        <div class="empty-div" *ngIf="(!totalComments || totalComments.length == 0) && type == 'action-item'">
          <img src="../../../../../../../assets/images/blank_watermart1.svg" class="no-data-watermark"/>
         <span>You have no Action items <br> to work on it</span>
      </div>
      <div class="empty-div" *ngIf="(!totalComments || totalComments.length == 0) && type != 'action-item'">
        <img src="../../../../../../../assets/images/Group 16078.svg" class="no-data-watermark"/>
       <span>You have no Notes <br> to work on it</span>
    </div>
        </div>
    </div>
    </div> -->