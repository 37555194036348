import { Component, OnInit, HostBinding } from '@angular/core';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { KanbanService } from '../../kanban.service';
import { ToastrService } from 'ngx-toastr';
import { Constants } from 'src/app/Constants';
import { ErrorToastComponent } from 'src/app/shared-files/error-toast/error-toast.component';
import { SuccessToastComponent } from 'src/app/shared-files/success-toast/success-toast.component';
@Component({
  selector: 'app-bulk-upload',
  templateUrl: './bulk-upload.component.html',
  styleUrls: ['./bulk-upload.component.scss']
})
export class BulkUploadComponent implements OnInit {

  @HostBinding('class') class = 'bulk-upload-dialog';

  constructor(public dialogRef: MatDialogRef<BulkUploadComponent>, private kanbanService: KanbanService, public toasterService: ToastrService,private _snackBar: MatSnackBar) { }
  file: any;
  isProgressVisible = false;
  ngOnInit() {
  }
  fileUpload(event) {
    this.isProgressVisible = true;
    if (event[0]) {
      this.file = event[0];
    }
    else {
      this.file = event.target.files[0];
    }

  }
  rmeoveSelectedFile() {
    this.file = null;
  }
  downloadTemplate() {
    console.log('downloadTemplate()')
    this.kanbanService.downloadContactUploadTemplate(Constants.contactTemplateUrl);
  }
  bulkUpload() {
    if (this.file) {
      this.isProgressVisible=true;
      const uploadData = new FormData();
      uploadData.append('file', this.file, this.file.name);
      this.kanbanService.bulkUploadLaneTask(uploadData, Constants.boardIdForSobContacts).subscribe(file => {
        if (file['status'] == 200) {
          let successcount=0;
          if(file['result'] && file['result']['successCount']){
            successcount=file['result']['successCount'];
          }
          this.isProgressVisible = false;
          this._snackBar.openFromComponent(SuccessToastComponent, {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: ['atom-toast', 'success'],
            duration: 6000,
            data: successcount + " Contacts uploaded Successfully"
          });

          // this.toasterService.success(successcount+" Contacts uploaded Successfully");
          
          this.dialogRef.close();
        }
      });
    } else {
      this._snackBar.openFromComponent(ErrorToastComponent, {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: ['atom-toast', 'error'],
        duration: 6000,
        data: "Pleas select a file"
      });
      // this.toasterService.error("Pleas select a file");
    }
  }
}
