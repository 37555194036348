import { Component, OnInit } from '@angular/core';
import { Output } from "@angular/core";
import {EventEmitter
} from '@angular/core';
@Component({
  selector: 'app-sb-kam-activity-dialog',
  templateUrl: './sb-kam-activity-dialog.component.html',
  styleUrls: ['./sb-kam-activity-dialog.component.scss']
})
export class SbKamActivityDialogComponent implements OnInit {
  dataSource: Array<any> = []; 
  @Output() dateFilterApplied = new EventEmitter<string>();
  displayColumns: Array<string> = ['ActivityOn', 'PlantField','FieldName','Activity']; 
  inputData: any = {};
  
  accountConfig = {
    "key": "laneTask",
    "field": "account-name",
    "rawValue": null,
    "type": "belongsTo",
    "value": "",
    "optional": true,
    "display": true,
    "displayWhileListing": true,
    "showTotal": false,
    "displayName": "Account Name",
    "dataList": [],
    "values": null,
    "additionalData": null,
    "operationsData": null,
    "operations": null,
    "index": 6,
    "icon": "",
    "relationKey": "63bfa8c3ade7bc466d1c780d",
    "nameField": false
  };
  resetDates: boolean = false;
  closingDateQuery: { "data.closingdate": { type: string; end_value: any; start_value: any; }; };
  createdDateQuery: { createdAt: { field: string; type: string; start_value: any; end_value: any; }; };
  lastUpdatedDateQuery: { updatedAt: { field: string; type: string; start_value: any; end_value: any; }; };
  constructor() {
    this.getPicksList(); 
   }

  ngOnInit() {
  }
  getPicksList() { 

    this.dataSource = [ 
    
    { ActivityOn: '24-3-2023', ActivityTime:'13:43', PlantField : 'Adani Wilmar Ltd_Mundra', accountName:'Adani', FieldName:'Meeting', Activity: 'MOM added for “meeting name”' }, 
    { ActivityOn: '24-3-2023', ActivityTime:'13:43', PlantField : 'Adani Wilmar Ltd_Mundra', accountName:'Adani', FieldName:'Contact', Activity: 'MOM added for “meeting name”' }, 
    { ActivityOn: '24-3-2023', ActivityTime:'13:43', PlantField : 'Adani Wilmar Ltd_Mundra', accountName:'Adani', FieldName:'Account', Activity: 'MOM added for “meeting name”' }, 
  
    ]
  }
 
  onApplyDates($event, selectedDate) {
    this.resetDates = false;
    if (selectedDate === 'closingDate') {
      let query = {
        "data.closingdate": {
          "type": "range",
          "end_value": $event.endDate.toISOString(),
          "start_value": $event.startDate.toISOString()
        }

      };
      this.closingDateQuery = query;
    }
    if (selectedDate === 'createdDate') {
      let query = {
        "createdAt": {
          "field": "createdAt",
          "type": "range",
          "start_value": $event.startDate.toISOString(),
          "end_value": $event.endDate.toISOString()
        }
      };
      this.createdDateQuery = query;
    }
    if (selectedDate === 'lastUpdatedDate') {
      let query = {
        "updatedAt": {
          "field": "updatedAt",
          "type": "range",
          "start_value": $event.startDate.toISOString(),
          "end_value": $event.endDate.toISOString()
        }
      };
      this.lastUpdatedDateQuery = query;
    }
   

  }
}
