import { Component, OnInit, Inject } from '@angular/core';
import { KanbanService } from '../../../kanban.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { UploadMappingComponent } from 'src/app/settings/upload-mapping/upload-mapping/upload-mapping.component';
import { PagesService } from 'src/app/pages/pages.service';
import { AuthService } from 'src/app/auth/auth.service';
import { SuccessToastComponent } from 'src/app/shared-files/success-toast/success-toast.component';
import { MatSnackBar } from '@angular/material';
@Component({
  selector: 'app-upload-panjiva-dialog',
  templateUrl: './upload-panjiva-dialog.component.html',
  styleUrls: ['./upload-panjiva-dialog.component.scss']
})
export class UploadPanjivaDialogComponent implements OnInit {
  file: any;
  isBulkUpload: boolean = false;

  constructor(private _snackBar: MatSnackBar,public dialogRef: MatDialogRef<UploadPanjivaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public toasterService: ToastrService, public _kService: KanbanService,
    public dialog: MatDialog, private pageService: PagesService, private authService: AuthService) { 
      if(data){
        this.isBulkUpload = data;
      }
    }

  ngOnInit() {
  }
  onFileChanged(event) {
    if (event[0]) {
      this.file = event[0];
    }
    else {
      this.file = event.target.files[0];
    }
  }


  fileUpload(file?) {
    if (file) {
      this.file = file;
      this.file.name = file.name;
    }
    const uploadData = new FormData();
    uploadData.append('file', this.file, this.file.name);
    if(!this.isBulkUpload){
      this._kService.uploadPanjivaFile(uploadData).subscribe(file => {
        if (file['statusCode'] == 200) {
          this._snackBar.openFromComponent(SuccessToastComponent, {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: ['atom-toast', 'success'],
            duration: 6000,
            data: "File Uploaded Successfully"
          });
          
          // this.toasterService.success("File Uploaded Successfully");
          this.dialogRef.close(false);
        }
      });
    }
    else{
      this.bulkUploadFile(uploadData);
    }
  }
  clearStack(event) {
    event.target.value = null;
  }
  close() {
    this.file = null;
  }
  closedialog() {
    this.dialogRef.close(false);
  }

  bulkUploadFile(uploadData){
    let getLocalStorage = JSON.parse(localStorage.getItem("USERINFO"));
    let masterAccountID;
    if(getLocalStorage && getLocalStorage['role'] && getLocalStorage['role']['masterAccount']){
      masterAccountID = getLocalStorage['role']['masterAccount']['id']
    }
    this.pageService.getHeaderList(masterAccountID).subscribe(list => {
      this.authService.storageListHeaders(list['result']);
      this.pageService.getUploadMappingHeaders(uploadData).subscribe(res=>{
        if(res['status'] == 200){
          const dialogRef = this.dialog.open(UploadMappingComponent, {
            disableClose: true,
            maxWidth: "100vw",
            width: "100vw",
            height: "100vh",
            data: {'fileName': this.file.name, 'pageName': "kanban" ,data: res, uploadData: uploadData},
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result == true) {
              this.dialogRef.close(true);
            }
          });
        }
      })
    })
  }

}