import { Component, OnInit, ChangeDetectorRef, SimpleChanges, OnChanges, Input, ViewChild, ElementRef } from '@angular/core';
import { ChangeDetectionStrategy } from '@angular/core';
import { KanbanService } from '../../../kanban.service';
import { ChartFiltersComponent } from '../chart-filters/chart-filters.component';
import { MatTableDataSource, MatSort, Sort } from '@angular/material';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Chart } from 'chart.js';
@Component({
  selector: 'app-top-deals',
  templateUrl: './top-deals.component.html',
  styleUrls: ['./top-deals.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class TopDealsComponent implements OnChanges {
  @Input() inputData;
  @Input() dealTypeInput;
  teams: any;
  userList: any;
  documents: Array<any> = [];
  dataSource = new MatTableDataSource(this.documents);
  passOnData: any;
  regionChartLabel: Array<any> = [];
  regionChartData: Array<any> = [];
  dealType: any;
  pageSize = 10;
  teamFilter: Array<any>;
  oldChart: any;
  pageSizes = [10, 25, 50, 100];
  monthsLabel: Array<string>;
  selectedMonth: string;
  monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'];
  @ViewChild('revenueLineChart') chart: ElementRef;
  @ViewChild(MatSort) sort: MatSort;
  //@ViewChild('revenueLineChart1') chart1: ElementRef;
  displayedColumns: any[] = [{ "field": "closing-date", "label": "Closing Date", "index": 1, "icon": "account" }
    , { "field": "deal-owner", "label": "Deal Owner", "index": 1, "icon": "account" }, { "field": "deal-name", "label": "Deal Name", "index": 1, "icon": "account" }, { "field": "realistic-deal-size-cr", "label": "Realistic Deal Size(Cr)", "index": 1, "icon": "account" }, { "field": "region-team", "label": "Region", "index": 1, "icon": "account" }];
  displayedColumns1: any[] = [];
  @ViewChild(ChartFiltersComponent) chartFilters: ChartFiltersComponent;
  constructor(public cRef: ChangeDetectorRef, public kanbanService: KanbanService, private _liveAnnouncer: LiveAnnouncer) {
    let tmpColumn = [];
    for (let k = 0; k < this.displayedColumns.length; k++) {
      tmpColumn.push(this.displayedColumns[k].field);
    }
    this.displayedColumns1 = tmpColumn;
  }

  ngOnInit() {
    console.log(this.displayedColumns);
    this.initMonthsLabel();
  }
  ngAfterViewInit() {
    this.cRef.detectChanges();
    this.createChart()
  }
  changePageSize(size) {
    this.pageSize = size;
  }
  getNext12MonthNamesWithYear() {
    var now = new Date();
    var month = now.getMonth();
    var year = now.getFullYear();
    var res = [];
    for (var i = 0; i < 3; ++i) {
      res.push(this.monthNames[month] + ' ' + year);
      if (++month === 12) {
        month = 0;
        ++year;
      }
    }
    return res;
  }
  initMonthsLabel() {
    let forwardMonths = this.getNext12MonthNamesWithYear();
    console.log("Forward " + forwardMonths);
    this.monthsLabel = forwardMonths;
    this.selectedMonth = this.monthsLabel[0];
  }
  createChart() {

    this.chart

    let ctx = this.chart.nativeElement.getContext('2d');
    if (this.oldChart != null) {
      this.oldChart.destroy();
    }
    ctx.clearRect(0, 0, this.chart.nativeElement.width, this.chart.nativeElement.height);
    //const ctx1 = this.chart1.nativeElement.getContext('2d');

    const labels = this.regionChartLabel;
    const chartData = this.regionChartData;
    const colors = (this.dealType != null && this.dealType != "upcoming") ? [
      "#284b63",
      "#383e7d",
      "#705b97",
      "#a75b9c",
      "#d9598d",
      "#fc636f",
      "#ff7f46",
      "#ffa600",
      "#39757d",
      "#218480",
      "#1e9277",
      "#3f9e65",
      "#67a74a",
      "#95ad2b",
      "#c8ad00",
      "#ffa600",
    ] : [
      "#284b63",
      "#383e7d",
      "#705b97",
      "#a75b9c",
      "#d9598d",
      "#fc636f",
      "#ff7f46",
      "#ffa600",
      "#39757d",
      "#218480",
      "#1e9277",
      "#3f9e65",
      "#67a74a",
      "#95ad2b",
      "#c8ad00",
      "#ffa600",
    ];
    const data = {
      labels: labels,
      datasets: [{
        label: 'Teams',
        data: chartData,
        backgroundColor: colors,
        hoverOffset: 4
      }]
    };
    const config = {
      type: 'doughnut',
      data: data,
    };
    this.oldChart = new Chart(ctx, config);
    // = new Chart(ctx1, config);


  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.inputData != null && changes.inputData.currentValue != null && changes.inputData.currentValue.team_query) {
      this.teamQuery = changes.inputData.currentValue.team_query;
    } else {
      this.teamQuery = null;
    }
    if (changes.inputData != null && changes.inputData.currentValue != null && changes.inputData.currentValue.bs_query) {
      this.bsQuery = changes.inputData.currentValue.bs_query;
    } else {
      this.bsQuery = null;
    }
    if (changes.inputData != null && changes.inputData.currentValue != null && changes.inputData.currentValue.dealtype_query) {
      this.dealtypeQuery = changes.inputData.currentValue.dealtype_query;
    } else {
      this.dealtypeQuery = null;
    }
    if (changes.inputData != null && changes.inputData.currentValue != null && changes.inputData.currentValue.user_query) {
      this.userQuery = changes.inputData.currentValue.user_query;
    } else {
      this.userQuery = null;
    }
    if (changes.inputData != null && changes.inputData.currentValue != null && changes.inputData.currentValue.teams) {
      this.teams = changes.inputData.currentValue.teams;
    }
    if (changes.inputData != null && changes.inputData.currentValue != null && changes.inputData.currentValue.users) {
      this.userList = changes.inputData.currentValue.users;
    }
    if (changes.dealTypeInput != null && changes.dealTypeInput.currentValue != null && changes.dealTypeInput.currentValue) {
      this.dealType = changes.dealTypeInput.currentValue;
    }
    if (this.teams != null && this.userList != null) {
      this.teamFilter = [];
      this.passOnData = {
        "teams": this.teams,
        "users": this.userList,
        "teamFilter": this.teamFilter,
        "onFilterApply": this.onFilterApply
      };
    }
    if (this.dealType) {
      this.initMonthsLabel();
      this.getTopDealData();
    }

  }
  teamQuery: any;
  bsQuery: any;
  dealtypeQuery: any;
  userQuery: any;
  resetFilter: any;
  onFilterApply($event) {
    if ($event && $event['resetFilter']) {
      this.teamFilter = null;
      this.bsQuery = null;
      this.userQuery = null;
      this.teamQuery = null;
    } else {
      if ($event && $event['teamFilter'] && $event['teamFilter'].length) {
        let tFiler = $event['teamFilter'];
        this.teamFilter = tFiler;
        this.teamQuery = $event['teamQuery']['query']['nestedWhere']["team_query"];
      }
      if ($event && $event['bsQuery']) {
        this.bsQuery = $event['bsQuery']['query']['nestedWhere']["bs_query"];
      }
      if ($event && $event['dealtypeQuery']) {
        this.bsQuery = $event['dealtypeQuery']['query']['nestedWhere']["dealtype_query"];
      }
      if ($event && $event['userQuery']) {
        this.userQuery = $event['userQuery']['query']['where']["user_query"];
      }
    }
    this.getTopDealData();
  }
  lastday = function (y, m) {
    let f = m + 1;
    return new Date(y, f, 0).getDate();
  }

  getTopDealData() {
    if (this.monthsLabel) {
      let query = {
        "query": {
          "sort": {
            "field": "data.realisticdealsizecr",
            "order": "desc"
          },
          "where": {
            "boardId.raw": {
              "type": "search",
              "field": "boardId.raw",
              "value": "63bfa8ccade7bc466d1c780e",

            }
          },
          "nestedWhere": {
            "data.closingdate": {
              "type": "range",
              "custom": 365,
              "range_type": this.dealType != null && this.dealType == "upcoming" ? "next" : "previous"
            }
          }
        },
        "size": this.pageSize,
        "type": "TOP_DEALS_BY_CLOSING_DATE"
      };
      if (this.selectedMonth == null || this.selectedMonth.length == 0) {
        this.selectedMonth = this.monthsLabel[0];
      }
      let startMonthString = this.selectedMonth.split(" ")[0];
      let startMonth = this.monthNames.findIndex((tmp) => {
        return startMonthString == tmp;
      });
      let startYear = this.selectedMonth.split(" ")[1];
      let startDate = new Date(parseInt(startYear), startMonth, 1);
      let endDay = this.lastday(parseInt(startYear), startMonth);
      let endDate = new Date(parseInt(startYear), startMonth, endDay);
      if (this.dealType != null && this.dealType == "upcoming") {
        query['query']['nestedWhere']["data.closingdate"]["custom"] = null;
        query['query']['nestedWhere']["data.closingdate"]["start_value"] = startDate;
        query['query']['nestedWhere']["data.closingdate"]["end_value"] = endDate;
      }

      if (this.teamQuery) {
        query['query']['nestedWhere']["team_query"] = this.teamQuery;
      }
      if (this.bsQuery) {
        query['query']['nestedWhere']["bs_query"] = this.bsQuery;
      }
      if (this.dealtypeQuery) {
        query['query']['nestedWhere']["dealtype_query"] = this.dealtypeQuery;
      }
      if (this.userQuery) {
        query['query']['where']["user_query"] = this.userQuery;
      }
      this.kanbanService.getDashboardStats(query).subscribe((response) => {
        if (response && response['result'] && response['result']['documents'] && response['result']['documents'].length) {
          this.documents = response['result']['documents'];
          this.dataSource = response['result']['documents']
          this.dataSource.sort = this.sort;
          let regionBreakUp = response['result']["aggregations"];
          if (regionBreakUp && regionBreakUp.length > 0) {
            let tmpdonutChartLabel = [];
            let tmpdonutChartData = [];
            for (let i = 0; i < regionBreakUp.length; i++) {
              let tmpVar = regionBreakUp[i];
              tmpdonutChartLabel.push(tmpVar['key']);
              tmpdonutChartData.push(tmpVar['count'] || 0);
            }
            this.regionChartData = tmpdonutChartData;
            this.regionChartLabel = tmpdonutChartLabel;
            this.createChart();
          }
        } else {
          this.dataSource = response['result']['documents'];
          if (this.oldChart != null) {
            this.oldChart.destroy();
          }
        }
      });
    }
  }
  sortData(sort: Sort) {
    const data = this.documents.slice();
    if (!sort.active || sort.direction === '') {
      this.documents = data;
      return;
    }
    console.log(this.documents)
    this.documents = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      return this.compare(a, b, isAsc, sort.active);
    });
    this.announceSortChange(sort)
  }
  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  compare(aObj: any | string, bObj: any | string, isAsc: boolean, field: string) {
    let a = 0;
    let aData = aObj['data'].find((tmp) => {
      return tmp['field'] == field;
    });
    if (aData) {
      a = new Date(aData['value']).getTime();
    }
    let b = 0;
    let bData = bObj['data'].find((tmp) => {
      return tmp['field'] == field;
    });
    if (bData) {
      b = new Date(bData['value']).getTime();
    }
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  getFieldValue(ele, field) {
    let val = "N/A";
    if (ele) {
      let laneData = ele['data'];
      if (laneData != null) {
        let laneField = laneData.find((ele) => {
          if (ele['field'] == field) {
            return ele;
          }
        });
        if (laneField && (laneField.type == "string" || laneField.type == "number" || laneField.type == "email" || laneField.type == "phone" || laneField.type == "textarea" ||
          laneField.type == "currency")) {
          val = laneField.value;
        } else if (laneField && (laneField.type == "select")) {
          if ((laneField.key == "string" || laneField.key == "number")) {
            val = laneField.value;
          } else {
            //let searchType = laneField.key == "user" ? "user" : "laneTask";
            val = laneField.additionalData != null ? laneField.additionalData.name : "N/A";
          }
        } else if (laneField && (laneField.type == "belongsTo")) {
          val = laneField.additionalData != null ? laneField.additionalData.name : "N/A";
        } else if (laneField && laneField.type == "date") {
          if (laneField.value && laneField.value.trim() != "") {
            val = new Date(laneField.value).toDateString();
          }
        }
      }
    }
    if (!val || (val && val.trim() == "")) {
      val = "-";
    }
    return val;
  }

}
