<div>
  <div class="d-flex-rw">
    <section class="pb-15 dialog-heading light-grey-bg" aria-label="heading">
      <div fxLayout="row mb-30 " fxLayoutAlign="space-between flex-center">
        <div class="mb-15">
          <h1 class="modal-heading-2 text-capitalize">Plant Info</h1>
          <!-- <h2 class="modal-heading-4">dssfd</h2> -->
        </div>

        <button
          mat-mini-fab
          class="modal-close-btn"
          mat-dialog-close
        >
          <i class="ri-close-circle-fill"></i>
        </button>
      </div>
      <div class="history-filter" fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="row" fxLayoutAlign="start center">
        <div class="formfield mr-10">
          <label>Search Plant</label>
          <app-field-autocomplete [fieldData]="{
            'config' : accountConfig,
            'isFilter' : true
          }" [modelRef]="accountConfig.value" >
            </app-field-autocomplete>
        </div>
      </div>
      </div>
    </section>
    <div class="mog3_msnsearchtable_sticky history-tab-table bg-dark-grey">
      <table mat-table class="w-100 " [dataSource]="dataSource"> 

        <!-- Picklist no. --> 
        
        <ng-container matColumnDef="accountName"> 
        
        <th mat-header-cell *matHeaderCellDef>Account Name</th> 
        
        <td mat-cell *matCellDef="let element"> {{element.accountName}} </td> 
        
      </ng-container> 
        
        <!-- Location Column --> 
        
        <ng-container matColumnDef="location"> 
        
        <th mat-header-cell *matHeaderCellDef> Location </th> 
        
        <td mat-cell *matCellDef="let element"> {{element.location}} </td> 
        
        </ng-container> 
        
        <tr mat-header-row *matHeaderRowDef="displayColumns"></tr> 
        
        <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr> 
        
        </table> 
      <div fxLayout="row" fxLayoutAlign="end center" style="width: 100%">
        <div class="d-flex-rw" style="width: 100%">
          <div class="pagination-wrapper" style="width: 100%">
            <!-- <mat-paginator
                  #paginator
                  [hidden]="!dataSource.data.length"
                  [length]="totalRecords"
                  [pageIndex]="pageNumber"
                  class="custom-pagintor"
                  [pageSize]="pageSize"
                  showFirstLastButtons
                  (page)="getLaneTaskHistory()"
                 
                >
             
                </mat-paginator> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
