<div>
  <div class="d-flex-rw">
    <section class="pb-15 dialog-heading light-grey-bg" aria-label="heading">
      <div fxLayout="row mb-30 " fxLayoutAlign="space-between flex-center">
        <div class="mb-15">
          <h1 class="modal-heading-2 text-capitalize">Contact Info</h1>
          <!-- <h2 class="modal-heading-4">dssfd</h2> -->
        </div>

        <button mat-mini-fab class="modal-close-btn" mat-dialog-close>
          <i class="ri-close-circle-fill"></i>
        </button>
      </div>
      <div class="contact-info-filter" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="formfield mr-10" style="width: 422px;">
          <!-- <app-field-autocomplete
            [fieldData]="{
              config: accountConfig,
              isFilter: true
            }"
            [modelRef]="accountConfig.value"
          >
          </app-field-autocomplete> -->
        </div>
        <!-- <button mat-flat-button class="mog3_primary_border_btn" (click)="addNewLead()">
          <i class="ri-add-line"></i>Add New Contact
        </button> -->
        <button mat-flat-button class="mog3_primary_flat_btn add-deal-btn" *ngIf="boardId===boardIdForCRMContacts" (click)="addNewLead()">
          <i class="ri-add-line"></i>
          <span>Add Contacts</span>
        </button>
        <button mat-flat-button class="mog3_primary_flat_btn add-deal-btn" *ngIf="boardId!==boardIdForCRMContacts" (click)="addNewSobContactLead()">
          <i class="ri-add-line"></i>
          <span>Add Contacts</span>
        </button>
        
        <!-- <div fxLayout="row" fxLayoutAlign="end center" style="width: 100%; position: absolute; top: 20px;" >
          <div fxLayout="row" fxLayoutAlign="space-evenly center" style="width: 15%;" >
            <div fxLayout="row" fxLayoutAlign="space-evenly center"  style="width: 100%;">
            <div fxLayout="row">
                  <button mat-flat-button class="mog3_primary_flat_btn add-deal-btn" (click)="addNewLead()">
                <i class="ri-add-line"></i>
                <span>Add Conatcats</span>
              </button>
            </div>
          </div>
          </div>

        </div>-->
      </div>

    </section>
    <div
      class="mog3_msnsearchtable_sticky contact-info-table bg-dark-grey bg-grey"
    >

      <table mat-table class="w-100" [dataSource]="dataSource">
        <!-- Name no. -->
        <ng-container matColumnDef="Name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox *ngIf="!element.isChecked" (change)="updatePassedValue(true, element)">
              <span class="blue-anchor">
                {{element.displayName}}
              </span>
            </mat-checkbox>
            <mat-checkbox [checked]="element.isChecked" *ngIf="element.isChecked" (change)="updatePassedValue(false, element)">
              <span class="blue-anchor">
                {{element.displayName}}
              </span>
            </mat-checkbox>
          </td>
        </ng-container>

        <!-- designation -->
        <ng-container matColumnDef="designation">
          <th mat-header-cell *matHeaderCellDef>Designation</th>
          <td mat-cell *matCellDef="let element">
            {{ element.searchData.designation}}
          </td>
        </ng-container>

        <!-- Account -->
        <ng-container matColumnDef="Account">
          <th mat-header-cell *matHeaderCellDef>Account</th>
          <td mat-cell *matCellDef="let element">
            {{ element.searchData.account_name }}
            <br />
            <span class="label-3">{{ element.role }}</span>
          </td>
        </ng-container>

        <!-- Email -->
        <ng-container matColumnDef="Email">
          <th mat-header-cell *matHeaderCellDef>Email</th>
          <td mat-cell *matCellDef="let element">
            {{ element.searchData.email }}
          </td>
        </ng-container>

        <!-- Mobile Number -->
        <ng-container matColumnDef="MobileNumber">
          <th mat-header-cell *matHeaderCellDef>Mobile Number</th>
          <td mat-cell *matCellDef="let element">
            {{ element.searchData.mobilenumber }}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>

        <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
      </table>
        <div class="empty-div" *ngIf="dataSource.data.length==0">
        <span>No Contact to display.</span>
      </div>
      <div fxLayout="row" fxLayoutAlign="end center" style="width: 100%">
        <div class="d-flex-rw" style="width: 100%">
          <div class="pagination-wrapper" style="width: 100%">
            <!-- <mat-paginator
                  #paginator
                  [hidden]="!dataSource.data.length"
                  [length]="totalRecords"
                  [pageIndex]="pageNumber"
                  class="custom-pagintor"
                  [pageSize]="pageSize"
                  showFirstLastButtons
                  (page)="getLaneTaskcontact-info()"

                >

                </mat-paginator> -->
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer" fxLayout="row" fxLayoutAlign="flex-end center">
      <div>
        <button mat-flat-button class="mog3_primary_border_btn mr-20" (click)="onCancelClick()">
          Cancel
        </button>
        <button *ngIf="isCreateLaneTaskTabSelected" mat-flat-button class="mog3_primary_flat_btn" (click)="addContact()">
          Add Contact
        </button>
        <button *ngIf="!isCreateLaneTaskTabSelected" mat-flat-button class="mog3_primary_flat_btn" (click)="addAndUpdateContact()">
          Add & Update Contact
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isLoading" class="overlay">
  <mat-spinner class="spinner" [diameter]="60"></mat-spinner>
</div>