import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { LandingComponent } from './components/landing/landing.component';
import { HomeComponent } from './components/home/home.component';
import { DocumentComponent } from './pages/pages-init/document/document.component';

import { AccountAuthorizationComponent } from './auth/account-authorization/account-authorization/account-authorization.component';
const routes: Routes = [
  // { path: '',loadChildren: './auth/auth.module#AuthModule'}, 
  { path: '', component: LandingComponent, canActivate: [AuthGuard] },
  { path: 'auth', loadChildren: './auth/auth.module#AuthModule' },
  { path: 'pages', loadChildren: './pages/pages.module#PagesModule', canActivate: [AuthGuard] },
  { path: 'settings', loadChildren: './settings/settings.module#SettingsModule', canActivate: [AuthGuard] },

  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'pages',
    component: DocumentComponent,
    canActivate: [AuthGuard]
  },

  {
    path: 'select-account',
    component: AccountAuthorizationComponent,
    canActivate: [AuthGuard]
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
