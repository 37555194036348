import { Output } from "@angular/core";

import {
  Component, Input, OnInit, SimpleChanges, ChangeDetectorRef, OnChanges
  , ChangeDetectionStrategy, EventEmitter
} from '@angular/core';

import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import * as dayjs from "dayjs";
import { Dayjs } from 'dayjs/esm';
import * as moment from 'moment';
import { P } from "@angular/cdk/keycodes";
export interface TimePeriod {
  [index: string]: Dayjs;

  startDate: Dayjs;
  endDate: Dayjs;
}
export interface DateRanges {
  [index: string]: [moment.Moment, moment.Moment];
}
export interface DateRange {
  label: string;
  dates: [Dayjs, Dayjs];
}

@Component({
  selector: 'app-date-picker-custom',
  templateUrl: './date-picker-custom.component.html',
  styleUrls: ['./date-picker-custom.component.scss']
})
export class DatePickerCustomComponent implements OnInit {
  @Input() placeholder;
  @Input() selectDefault;
  @Input() inputData;
  @Input() resetDates;
  @Input() dynamicPlaceholder: string = "Time Duration"; // Default placeholder value
  @Output() filterApplied = new EventEmitter<string>();
  selected: any;
  mom = moment();
  alwaysShowCalendars: boolean;
  showRangeLabelOnInput: boolean;
  keepCalendarOpeningWithRange: boolean;
  maxDate: dayjs.Dayjs;
  minDate: dayjs.Dayjs;
  invalidDates: dayjs.Dayjs[] = [];
  tooltips = [
    { date: dayjs(), text: 'Today is just unselectable' },
    { date: dayjs().add(2, 'days'), text: 'Yeeeees!!!' }
  ];

  inlineDateTime: TimePeriod;
  ranges: DateRanges = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 3 Months': [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };
  constructor(public cRef: ChangeDetectorRef) {
    this.maxDate = dayjs().add(2, 'weeks');
    this.minDate = dayjs().subtract(3, 'days');

    this.alwaysShowCalendars = true;
    this.keepCalendarOpeningWithRange = false;
    this.showRangeLabelOnInput = true;
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.selectDefault) {
      this.selectDefault = changes.selectDefault.currentValue;
      if (changes.selectDefault != null && changes.selectDefault.currentValue != null && changes.selectDefault.currentValue) {

      }
    }
    if (changes && changes.resetDates && changes.resetDates.currentValue && changes.resetDates.currentValue == true) {
      this.selected = null;
    }
    if (localStorage.getItem('createdDateFilter') != null && !this.selectDefault) {
      let dateCache = JSON.parse(localStorage.getItem("createdDateFilter"));
      let startDate = new Date(dateCache['startDate']);
      let endDate = new Date(dateCache['endDate']);
      let d = {
        startDate: moment(startDate),
        endDate: moment(endDate),
      };
      this.selected = d;
    }
    if (localStorage.getItem('closingDateFilter') != null && this.selectDefault) {
      let dateCache = JSON.parse(localStorage.getItem("closingDateFilter"));
      let startDate = new Date(dateCache['startDate']);
      let endDate = new Date(dateCache['endDate']);
      let d = {
        startDate: moment(startDate),
        endDate: moment(endDate),
      };
      this.selected = d;
    }
    if (this.resetDates) {
      this.selected = null;
      this.inputData['dateFilterQuery'] = null;
      localStorage.removeItem("closingDateFilter");
      this.inputData['createdDateQuery'] = null;
      localStorage.removeItem("createdDateFilter");
    }
  }

  clearDate() {
    if (this.selectDefault) {
      this.inputData['dateFilterQuery'] = null;
      localStorage.removeItem("closingDateFilter");
    } else {
      this.inputData['createdDateQuery'] = null;
      localStorage.removeItem("createdDateQuery");
    }

    this.filterApplied.emit(this.inputData);
  }

  ngOnInit() {
    if (typeof this.selectDefault !== 'boolean') {
      this.selected = this.selectDefault; 
    }
    this.cRef.detectChanges();
  }
  isInvalidDate = (m: dayjs.Dayjs): boolean => {
    return this.invalidDates.some((d) => d.isSame(m, 'day'));
  };

  isTooltipDate = (m: Dayjs): string | boolean | null => {
    const tooltip = this.tooltips.find((tt) => tt.date.isSame(m, 'day'));
    if (tooltip) {
      return tooltip.text;
    } else {
      return false;
    }
  };

  rangeClicked(range: DateRange): void {
    // eslint-disable-next-line no-console

  }

  datesUpdated(range: TimePeriod): void {
    // eslint-disable-next-line no-console
    if (range.startDate && range.endDate) {
      this.onApply(range);
    } else if (this.inputData) {
      if (this.selectDefault) {
        if (this.inputData['dateFilterQuery']) {
          // this.clearDate();
        }
      } else {
        if (this.inputData['createdDateQuery']) {
          // this.clearDate();
        }
        // localStorage.removeItem("createdDateFilter");
      }
    }
    // if(range.startDate && range.endDate) {
    //   this.selected = {
    //     startDate:range.startDate,
    //     endDate: range.endDate
    //   };
    // }

  }
  onApply = (range: TimePeriod) => {
    console.log('onapply old date ', range)
    let query = {
      "query": {
      }
    };

    if (this.selectDefault === true) {
      if (!range.startDate || !range.endDate) {
        this.inputData['dateFilterQuery'] = null;
        this.inputData['start_date'] = null;
        this.inputData['end_date'] = null;
        this.inputData['resetFilter'] = true;
        this.filterApplied.emit(this.inputData);
        return;
      } else {
        query['query']['nestedWhere'] = {};
        query['query']['nestedWhere']["data.closingdate"] = {
          "type": "range",
          "start_value": range.startDate.toISOString(),
          "end_value": range.endDate.toISOString()
        };
        this.inputData['dateFilterQuery'] = query;
        let dateCache = {
          "startDate": range.startDate.toISOString(),
          "endDate": range.endDate.toISOString()
        };
        localStorage.setItem("closingDateFilter", JSON.stringify(dateCache));
      }

    } else if(this.selectDefault === false){
      if (!range.startDate || !range.endDate) {
        this.inputData['createdDateQuery'] = null;
        this.inputData['start_date'] = null;
        this.inputData['end_date'] = null;
        this.inputData['resetFilter'] = true;
        this.filterApplied.emit(this.inputData);
      } else {
        query['query']['where'] = {};
        query['query']['where']['createdAt'] = {
          field: "createdAt",
          type: "range",
          start_value: range.startDate.toISOString(),
          end_value: range.endDate.toISOString()
        }
        this.inputData['createdDateQuery'] = query;
      }
      let dateCache = {
        startDate: range.startDate.toISOString(),
        endDate: range.endDate.toISOString()
      }
      localStorage.setItem("createdDateFilter", JSON.stringify(dateCache));
    }
    else{
      query.query['filter'] = {
        "start_date": this.selected.startDate.toISOString(),
        "end_date": this.selected.endDate.toISOString()
      }
    }

    this.inputData['start_date'] = range.startDate;
    this.inputData['end_date'] = range.endDate;
    this.inputData['resetFilter'] = false;
    this.filterApplied.emit(this.inputData);
  }


  chosenDateTime(e: TimePeriod): void {
    this.inlineDateTime = e;
  }
}
