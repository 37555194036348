import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KBoardComponent } from './k-board/k-board.component';
import { KUserManagementComponent } from './k-user-management/k-user-management.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { CardDetailsComponent } from './k-board/board-lane/card-details/card-details.component';
import { LaneCardDetailsComponent } from './k-board/board-lane/lane-card-details/lane-card-details.component';
import { CreateLaneTaskComponent } from './k-board/board-lane/create-lane-task/create-lane-task.component';
import {TermsheetlistingComponent} from './termsheetlisting/termsheetlisting.component';
import { TaskDashboardComponent } from './k-board/task-dashboard/task-dashboard.component';
import { HuntingProjectionsComponent } from './k-board/task-dashboard/hunting-projections/hunting-projections.component';
import { CreateLaneTaskRouteComponent } from './k-board/board-lane/create-lane-task/create-lane-task-route/create-lane-task-route.component';
import { SopManagementComponent } from './sop-management/sop-management.component';
import { EmailCommunicationComponent } from './email-communication/email-communication.component';
import { AbmManagementComponent } from './abm-management/abm-management.component';
import { AbmAccountsComponent } from './abm-accounts/abm-accounts.component';
import { AbmContactsComponent } from './abm-contacts/abm-contacts.component';
import { AddFormComponent } from './add-form/add-form.component';
import { FormArrayExampleComponent } from './form-array-example/form-array-example.component';
import { KamListingComponent } from './k-board/task-dashboard/kam-listing/kam-listing.component';
const routes: Routes = [
  {
    path: 'crm-board',
    component: KBoardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'hunting-dashboard',
    component: TaskDashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path : 'hunting-projections',
    component : HuntingProjectionsComponent,
    canActivate : [AuthGuard]
  },
  {
    path: 'KboardUsermanagement/:id',
    component: KUserManagementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'lane-task/add-new/:id/:laneId',
    component: CreateLaneTaskRouteComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'cardDetails/:id',
    component: LaneCardDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'bulkListing/:id',
    component: TermsheetlistingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'sop-management',
    component: SopManagementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'email-communication',
    component: EmailCommunicationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'meeting',
    component: SopManagementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'tracker',
    component: KamListingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'abm-management',
    component: AbmManagementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'abm-accounts/:id',
    component: AbmAccountsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'abm-contacts',
    component: AbmContactsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'add-form',
    component: AddFormComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'add-form-array',
    component: FormArrayExampleComponent,
    canActivate: [AuthGuard]
  },
];
@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]


})
export class CredlixBoardRoutingModule { }
