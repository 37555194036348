<section class="dashboard-section">
  <mat-tab-group class="dashboard-tab" animationDuration="0ms" *ngIf="boardId!==boardIdForSobOpportunity">
    <mat-tab label="Hunting Activity">
      <section class="section">
      <div class="atom-row m-0 ">
    
       <div class="w-p162 mr-10">
          <div class="formfield">
            <app-custom-multiple-select [label]="businessVerticalLabel" 
              [selectedValues]="selectedBusinessVertical"
              [availableOptions]="defaultBusinessVerticalSearch" 
              [searchControl]="searchControlBS"
              (selectionChange)="onBusinessVerticalSelectionChange($event)"
              (searchChange)="onBusinessVerticalSearchChange($event)">
            </app-custom-multiple-select>
          </div>
       </div>
       <div class="w-p148 mr-10">
        <div class="formfield">
          <app-custom-multiple-select [label]="regionTeamLabel" [selectedValues]="selectTeams"
            [availableOptions]="teamsSearch" [searchControl]="searchControlTeams"
            (selectionChange)="onRegionTeamSelectionChange($event)"
            (searchChange)="onRegionTeamSearchChange($event)">
          </app-custom-multiple-select>
        </div>
      </div>

      <div class="w-p109 mr-10">
        <div class="formfield">
          <app-custom-multiple-select [label]="hunterLabel" [selectedValues]="selectedUsers"
            [availableOptions]="allUserListSearch" [searchControl]="searchControlHunter"
            (selectionChange)="onHunterSelectionChange($event)" (searchChange)="onHunterSearchChange($event)">
          </app-custom-multiple-select>
        </div>
      </div>

      <div class="w-p185 mr-10">
        <div class="formfield new_filterDate">
          <crm-date-picker [selectDefault]="true" [resetDates]="resetDates" [placeholder]="'Created Date'"
            (dateFilterApplied)="onApplyDates($event,'createdDate')"></crm-date-picker>

        </div>
      </div>

      <div class="w-p185 mr-10">
        <div class="formfield new_filterDate">
          <!-- <label>Closing Date</label> -->
          <crm-date-picker [selectDefault]="false" [resetDates]="resetDates" [placeholder]="'Closing Date'"
            (dateFilterApplied)="onApplyDates($event,'closingDate')"></crm-date-picker>
        </div>
      </div>


      <div class="w-p185 mr-10">
        <div class="formfield new_filterDate">
          <!-- <label>Time Duration</label> -->
          <crm-date-picker [selectDefault]="true" [resetDates]="resetDates" [placeholder]="'Last Updated Date'"
            (dateFilterApplied)="onApplyDates($event,'lastUpdatedDate')"></crm-date-picker>
        </div>
      </div>
      <div class="w-p190 mr-10 flex items-center justify-center">
        <button (click)="clearAll()" mat-button class="btn-basic btn-text-black btn-md" color="primary">
          Clear All
        </button>
      </div>
      
    </div>
  </section>
        <div fxLayout="row" fxLayoutAlign="center space-around">
          <div fxFlex="60" fxLayout="column" class="mr-20">
            <div class="panel panel-height-1">
              <div class="panel-heading">
                <h1>Deal Funnel</h1>
              </div>
              <div class="panel-body panel-body-height-1">
                <div fxLayout="row" class="h-100" fxLayoutAlign="center center">
                  <div fxFlex="40"  fxLayout="column" class="mr-20 h-100">
                    <ul class="deal-funnel-list">
                      <li>
                        <span class="deal-stage">C0</span>
                        <div class="deal-info" (click)="dealFunnelModal('C0')">
                          <span class="deal-number">{{
                            getCountBykey("C0")
                            }}</span>
                          <span class="deal-amount">{{ getSumBykey("C0") }} cr</span>
                          <i class="ri-arrow-right-s-line"></i>
                        </div>
                      </li>
                      <li>
                        <span class="deal-stage">C1</span>
                        <div class="deal-info" (click)="dealFunnelModal('C1')">
                          <span class="deal-number">{{
                            getCountBykey("C1")
                            }}</span>
                          <span class="deal-amount">{{ getSumBykey("C1") }} cr</span>
                          <i class="ri-arrow-right-s-line"></i>
                        </div>
                      </li>
                      <li>
                        <span class="deal-stage">C2</span>
                        <div class="deal-info" (click)="dealFunnelModal('C2')">
                          <span class="deal-number">{{
                            getCountBykey("C2")
                            }}</span>
                          <span class="deal-amount">{{ getSumBykey("C2") }} cr</span>
                          <i class="ri-arrow-right-s-line"></i>
                        </div>
                      </li>
                      <li>
                        <span class="deal-stage">C3</span>
                        <div class="deal-info" (click)="dealFunnelModal('C3')">
                          <span class="deal-number">{{
                            getCountBykey("C3")
                            }}</span>
                          <span class="deal-amount">{{ getSumBykey("C3") }} cr</span>
                          <i class="ri-arrow-right-s-line"></i>
                        </div>
                      </li>
                      <li>
                        <span class="deal-stage">C4</span>
                        <div class="deal-info" (click)="dealFunnelModal('C4')">
                          <span class="deal-number">{{
                            getCountBykey("C4")
                            }}</span>
                          <span class="deal-amount">{{ getSumBykey("C4") }} cr</span>
                          <i class="ri-arrow-right-s-line"></i>
                        </div>
                      </li>
                      <li>
                        <span class="deal-stage">WON</span>
                        <div class="deal-info" (click)="dealFunnelModal('PO Received/ ADP')">
                          <span class="deal-number">{{
                            getCountBykey("PO Received/ ADP")
                            }}</span>
                          <span class="deal-amount">{{ getSumBykey("PO Received/ ADP") }} cr</span>
                          <i class="ri-arrow-right-s-line"></i>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div fxFlex="60" class="h-100" fxLayout="column" >
                    <highcharts-chart class="hunting-activity-highchart" [Highcharts]="Highcharts" [constructorType]="'chart'" [options]="chartOptions"
                      [(update)]="updateFlag" [eToOne]="oneToOneFlag" style="
                        width: 100%;
      
                        display: block;
                        position: relative;
                      ">
                    </highcharts-chart>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div fxFlex="40" fxLayout="column">
            <div class="panel mb-15">
              <div class="panel-heading d-flex justify-content-between">
                <h1>Cold Deals</h1>
                <i (click)="dealFunnelModal('Cold Deal')" class="ri-external-link-line icon-dark-blue"></i>
              </div>
              <div class="panel-body ">
                <div class="d-flex justify-content-between deals-detail">
                  <span class="deals-count">{{
                    getCountBykey("Cold Deal")
                    }}</span>
                  <span class="deals-worth">{{ getSumBykey("Cold Deal") }} cr</span>
                </div>
              </div>
            </div>
            <div class="panel mb-15">
              <div class="panel-heading d-flex justify-content-between">
                <h1>Lost Deals</h1>
                <i (click)="dealFunnelModal('Lost Deal')" class="ri-external-link-line icon-dark-blue"></i>
              </div>
              <div class="panel-body">
                <div class="d-flex justify-content-between deals-detail">
                  <span class="deals-count">{{
                    getCountBykey("Lost Deal")
                    }}</span>
                  <span class="deals-worth">{{ getSumBykey("Lost Deal") }} cr</span>
                </div>
              </div>
            </div>
            <div class="panel mb-15">
              <div class="panel-heading d-flex justify-content-between">
                <h1>Dormant Deals</h1>
                <i (click)="dealFunnelModal('Dormant')" class="ri-external-link-line icon-dark-blue"></i>
              </div>
              <div class="panel-body">
                <div class="d-flex justify-content-between deals-detail">
                  <span class="deals-count">{{ dormantDealsCount }}</span>
                  <span class="deals-worth">{{ dormatDealSum | number : "1.2-2" }} cr</span>
                </div>
              </div>
            </div>
            <!-- KAMs Handover -->
            <div class="panel">
              <div class="panel-heading d-flex justify-content-between">
                <h1>KAM Handover</h1>
                <i (click)="dealFunnelModal('KAMS Handover')" class="ri-external-link-line icon-dark-blue"></i>
              </div>
              <div class="panel-body">
                <div class="d-flex justify-content-between deals-detail">
                  <span class="deals-count">{{ getCountBykey("KAMS Handover") }}</span>
                  <span class="deals-worth">{{ getSumBykey("KAMS Handover") }} cr</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      <br />
        <mat-tab-group class="separate-tab mb-30" animationDuration="0ms" (selectedTabChange)="tabChanged($event)">
          <mat-tab label="Top 5 Won deals">
            <div class="mog3_msnsearchtable_sticky ">
              <table mat-table class="w-100" [dataSource]="topWonTableDataSource">
                <ng-container *ngFor="let disCol of displayedColumns; let colIndex = index"
                  matColumnDef="{{ disCol.field }}">
                  <th mat-header-cell *matHeaderCellDef class="table-header">
                    {{ disCol.label }}
                  </th>
                  <td mat-cell *matCellDef="let element"  class="table-row">
                    <div>
                      <span> {{ getFieldValue(element, disCol.field) }}</span>
                    </div>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns1; sticky: true"></tr>
                <tr mat-row *matRowDef="
                    let row;
                    columns: displayedColumns1;
                    let i = index
                  " [ngClass]="{ 'active-row': i === selectedTrIndex }"></tr>
              </table>

              <mat-paginator [length]="topwondealcount" (page)="onPageChange($event, 'won')"
                [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons></mat-paginator>
                <div *ngIf="isLoading" class="loading-spinner-container">
                  <mat-spinner strokeWidth="2" [diameter]="60" [color]="red"></mat-spinner>
                </div> 
            </div>
          </mat-tab>
          <mat-tab label="Top 5 Lost deals">
            <div class="mog3_msnsearchtable_sticky">
              <table mat-table class="w-100" [dataSource]="topLostTableDataSource">
                <ng-container *ngFor="let disCol of displayedColumns; let colIndex = index"
                  matColumnDef="{{ disCol.field }}">
                  <th mat-header-cell *matHeaderCellDef class="table-header">
                    {{ disCol.label }}
                  </th>
                  <td mat-cell *matCellDef="let element"class="table-row"
                    >
                    <div>
                      <span> {{ getFieldValue(element, disCol.field) }}</span>
                    </div>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns1; sticky: true"></tr>
                <tr mat-row *matRowDef="
                    let row;
                    columns: displayedColumns1;
                    let i = index
                  " [ngClass]="{ 'active-row': i === selectedTrIndex }"></tr>
              </table>

              <mat-paginator [length]="toplostdealcount" (page)="onPageChange($event, 'lost')"
                [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons></mat-paginator>
                <div *ngIf="isLoading" class="loading-spinner-container">
                  <mat-spinner strokeWidth="2" [diameter]="60" [color]="red"></mat-spinner>
                </div> 
            </div>
          </mat-tab>
          <mat-tab *ngIf="false" label="Handover">
                                
            <div class="mat-table-overflow handover-table-height">
              <table mat-table class="mat-table-theme horizontal-overflow-width" [dataSource]="dataSource">
                
                <ng-container matColumnDef="DealName" >
                  <th mat-header-cell *matHeaderCellDef class="sticky-left"> <span class="pr-20"> Deal Name </span>
                  </th>
                  <td mat-cell *matCellDef="let element" class="sticky-left">
                        <p class="link">
                          {{ element.DealName }}  
                        </p>
                  </td>
                </ng-container>
                
                <ng-container matColumnDef="DealOwner">
                  <th mat-header-cell *matHeaderCellDef> <span class="pr-20">Deal Owner</span></th>
                  <td mat-cell *matCellDef="let element">
                   <p> {{ element.DealOwner}}  </p>
                   
                  </td>
                </ng-container>

                <ng-container matColumnDef="KAM">
                  <th mat-header-cell *matHeaderCellDef> <span class="pr-20">KAM</span></th>
                  <td mat-cell *matCellDef="let element">
                   <p> {{ element.KAM}}  </p>
                   
                  </td>
                </ng-container>

                <ng-container matColumnDef="DealSize" >
                  <th mat-header-cell *matHeaderCellDef> <span class="pr-20">Deal Size</span></th>
                  <td mat-cell *matCellDef="let element">
                   <p> {{ element.DealSize}}  </p>
                   
                  </td>
                </ng-container>
                
                <ng-container matColumnDef="BusinessPotential">
                  <th mat-header-cell *matHeaderCellDef> <span class="pr-20">Business Potential</span></th>
                  <td mat-cell *matCellDef="let element">
                   <p> {{ element.BusinessPotential}}  </p>
                   
                  </td>
                </ng-container>

                <ng-container matColumnDef="StageAging">
                  <th mat-header-cell *matHeaderCellDef> <span class="pr-20">Stage Aging </span></th>
                  <td mat-cell *matCellDef="let element">
                   <p> {{ element.StageAging}}  </p>
                   
                  </td>
                </ng-container>

                <ng-container matColumnDef="POType">
                  <th mat-header-cell *matHeaderCellDef> <span class="pr-20">PO Type </span></th>
                  <td mat-cell *matCellDef="let element">
                   <p> {{ element.POType}}  </p>
                   
                  </td>
                </ng-container>

                <ng-container matColumnDef="Region">
                  <th mat-header-cell *matHeaderCellDef> <span class="pr-20">Region</span></th>
                  <td mat-cell *matCellDef="let element">
                   <p> {{ element.Region}}  </p>
                   
                  </td>
                </ng-container>

                <ng-container matColumnDef="HandoverDate">
                  <th mat-header-cell *matHeaderCellDef> <span class="pr-20">Handover Date</span></th>
                  <td mat-cell *matCellDef="let element">
                   <p> {{ element.HandoverDate}}  </p>
                   
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayColumns;sticky:true;"></tr>
                <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
              </table>
            </div>


            
          </mat-tab>
        </mat-tab-group>

        <div hidden class="mog3_msnsearchtable_sticky">
          <table mat-table class="w-100" [dataSource]="dataSource3">
            <!-- Deal Name -->

            <ng-container matColumnDef="DealName">
              <th mat-header-cell *matHeaderCellDef>Deal Name</th>
              <td mat-cell *matCellDef="let element">{{ element.DealName }}</td>
            </ng-container>

            <!-- Customer Name -->

            <ng-container matColumnDef="CustomerName">
              <th mat-header-cell *matHeaderCellDef>Customer Name</th>

              <td mat-cell *matCellDef="let element">
                {{ element.CustomerName }}
              </td>
            </ng-container>

            <!-- Deal Size -->

            <ng-container matColumnDef="DealSize">
              <th mat-header-cell *matHeaderCellDef>Deal Size</th>

              <td mat-cell *matCellDef="let element">
                {{ element.DealSize }}
              </td>
            </ng-container>

            <!-- Business Potential -->

            <ng-container matColumnDef="BusinessPotential">
              <th mat-header-cell *matHeaderCellDef>Business Potential</th>

              <td mat-cell *matCellDef="let element">
                {{ element.BusinessPotential }}
              </td>
            </ng-container>

            <!-- Stage -->

            <ng-container matColumnDef="Stage">
              <th mat-header-cell *matHeaderCellDef>Stage</th>

              <td mat-cell *matCellDef="let element">
                {{ element.Stage }}
              </td>
            </ng-container>

            <!-- POType -->
            <ng-container matColumnDef="POType">
              <th mat-header-cell *matHeaderCellDef>POType</th>
              <td mat-cell *matCellDef="let element">
                {{ element.POType }}
              </td>
            </ng-container>

            <!-- Region -->
            <ng-container matColumnDef="Region">
              <th mat-header-cell *matHeaderCellDef>Region</th>
              <td mat-cell *matCellDef="let element">
                {{ element.Region }}
              </td>
            </ng-container>

            <!-- Closing date -->
            <ng-container matColumnDef="Closingdate">
              <th mat-header-cell *matHeaderCellDef>Closing date</th>
              <td mat-cell *matCellDef="let element">
                {{ element.Closingdate }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="top5InProgressDealsColumns; sticky: true"></tr>

            <tr mat-row *matRowDef="let row; columns: top5InProgressDealsColumns"></tr>
          </table>
        </div>
 
    </mat-tab>
    <mat-tab label="Hunting Velocity">

           <section class="section mx-0">


            <div class="atom-row m-0 justify-content-start">
              <div class="w-p108 mr-10">
                <div class="input-group">
                  <!-- <mat-label>Starting Week</mat-label> -->
                  <mat-form-field appearance="outline" class="mat-select selected-highlight deep-0" appearance="outline">
                    <mat-select disableOptionCentering  [panelClass]="'select-panel-class'" placeholder="Select Last Week" [(ngModel)]="selectedLastWeek">
                      <div class="select-options">
                        <mat-option (click)="onLastWeekSelectionForVelocity(lw.count)" *ngFor="let lw of lastWeeksDropDown"
                          [value]="lw.count">{{ lw.value }}</mat-option>
                      </div>
                    </mat-select>
                  </mat-form-field>
                </div>

              </div>   
              <div class="w-p112 mr-10">
                <div class="input-group">
                  <!-- <mat-label>Owner</mat-label> -->
                  <mat-form-field class="mat-select mat-select-search selected-highlight deep-0" appearance="outline" id="inputdefault">
                    <mat-select  [panelClass]="'select-panel-class'"  #sl10 placeholder="Hunters" multiple [(ngModel)]="selectedUsersForVelocity">
                      <mat-form-field class="mat-input icon"  appearance="outline">
                        <i class="ri-search-line"></i>
                        <input type="text" matInput placeholder="Search" [(ngModel)]="searchControlHunterForVelocity"
                          (keyup)="filterOptionsForVelocity($event)" />
                    
                      </mat-form-field>
                      <div class="select-options">
                        <mat-option *ngFor="let hunter of allUserListSearchForVelocity" [value]="hunter.id">{{ hunter.name
                          }}</mat-option>
                      </div>
                      <div class="select-footer">
                        <button mat-button
                        class="btn-basic btn-text-black mr-10"
                        color="primary" (click)="
                            selectedUsersForVelocity = [];
                            resetUserFilterInCacheForVelocity();
                            onApplyForVelocity();
                            sl10.close()
                          ">
                          Clear
                        </button>
                        <button class="btn-primary" mat-raised-button color="warn" (click)="onApplyForVelocity(); sl10.close()">
                          Apply
                        </button>
                      </div>
                    </mat-select>
                  </mat-form-field>
                </div>
                </div>
                <div class="w-p108 mr-10">
                  <div class="input-group">
                    <!-- <mat-label>Region</mat-label> -->
                    <mat-form-field appearance="outline" class="mat-select mat-select-search selected-highlight deep-0" id="inputdefault">
                      <mat-select  [panelClass]="'select-panel-class'" #sl11 placeholder="Teams" multiple [(ngModel)]="selectTeamsForVelocity">
                        <mat-form-field class="mat-input icon"  appearance="outline">
                          <i class="ri-search-line"></i>
                          <input type="text" matInput placeholder="Search" [(ngModel)]="searchControlTeamsForVelocity"
                            (keyup)="filterOptionsTeamsForVelocity($event)" />
                     
                        </mat-form-field>
                        <div class="select-options">
                          <mat-option *ngFor="let team of teamsSearchForVelocity" [value]="team.id">{{ team.name
                            }}</mat-option>
                        </div>
                        <div  class="select-footer">
                          <button  mat-button
                          class="btn-basic btn-text-black mr-10"
                          color="primary" (click)="
                              selectTeamsForVelocity = [];
                              resetTeamFilterInCacheForVelocity();
                              onApplyForVelocity();
                              sl11.close()
                            ">
                            Clear
                          </button>
                          <button class="btn-primary" mat-raised-button color="warn" (click)="onApplyForVelocity(); sl11.close()">
                            Apply
                          </button>
                        </div>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="w-p152 mr-10">
                  <div class="input-group">
                    <!-- <mat-label>Business Vertical</mat-label> -->
                    <mat-form-field appearance="outline" class="mat-select mat-select-search selected-highlight deep-0" id="inputdefault">
                      <mat-select [panelClass]="'select-panel-class'"  #sl2 placeholder="Select vertical" #sl12 [(ngModel)]="selectedBusinessVerticalForVelocity"
                        multiple>
                        <mat-form-field class="mat-input icon" appearance="outline">
                          <i class="ri-search-line"></i>
                          <input type="text" matInput placeholder="Search" [(ngModel)]="searchControlBSForVelocity"
                            (keyup)="filterOptionsVerticalForVelocity($event)" />
                      
                        </mat-form-field>
                        <div class="select-options">
                          <mat-option *ngFor="
                              let bs of defaultBusinessVerticalSearchForVelocity
                            " [value]="bs">{{ bs }}</mat-option>
                        </div>
                        <div class="select-footer">
                          <button  mat-button
                          class="btn-basic btn-text-black mr-10"
                          color="primary" (click)="
                              selectedBusinessVerticalForVelocity = [];
                              resetBSInCacheForVelocity();
                              onApplyForVelocity();
                              sl12.close()
                            ">
                            Clear
                          </button>
                          <button class="btn-primary" color="warn" mat-raised-button  (click)="onApplyForVelocity(); sl12.close()">
                            Apply
                          </button>
                        </div>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="w-p144 mr-10">
                  <div class="input-group">
                    <!-- <mat-label>Deal Type</mat-label> -->
                    <mat-form-field class="mat-select selected-highlight deep-0" appearance="outline">
                      <mat-select disableOptionCentering  [panelClass]="'select-panel-class'" placeholder="Select Deal Type" [(ngModel)]="selectedDealType">
                     
                          <mat-option (click)="onDealTypeSelectionForVelocity('Core')" value="Core">Core</mat-option>
                          <mat-option (click)="onDealTypeSelectionForVelocity('Emerging')"
                            value="Emerging">Emerging</mat-option>
                          <mat-option (click)="onDealTypeSelectionForVelocity('PaaS')" value="PaaS">PaaS</mat-option>
                       
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>   
                <div class="w-p77 mr-10 flex items-center">  
                  <button (click)="clearAllForVelocity()" mat-button class="btn-basic btn-text-black btn-md mr-10 " color="primary">
                    Clear All
                  </button>
                </div>
                <div class="w-p127 mr-10">
                  <div class="input-group">
                    <!-- <mat-label>Starting Stage</mat-label> -->
                    <mat-form-field  class="mat-select selected-highlight deep-0" appearance="outline">
                      <mat-select disableOptionCentering  [panelClass]="'select-panel-class'" placeholder="Select stage" [(ngModel)]="selectedStartingStage">
                        <div class="select-options">
                          <mat-option (click)="onStartingStageSelectionForVelocity('C0','0')" value="C0">C0</mat-option>
                          <mat-option (click)="onStartingStageSelectionForVelocity('C1','1')" value="C1">C1</mat-option>
                          <mat-option (click)="onStartingStageSelectionForVelocity('C2','2')" value="C2">C2</mat-option>
                          <mat-option (click)="onStartingStageSelectionForVelocity('C3','3')" value="C3">C3</mat-option>
                          <mat-option (click)="onStartingStageSelectionForVelocity('C4','4')" value="C4">C4</mat-option>
                          <mat-option (click)="onStartingStageSelectionForVelocity('PO Received/ ADP','5')" value="PO Received/ ADP">PO Received/ ADP</mat-option>
                          <mat-option (click)="onStartingStageSelectionForVelocity('KAMS Handover','6')" value="KAMS Handover">KAM Handover</mat-option>

                          <mat-option (click)="onStartingStageSelectionForVelocity('Cold Deal','7')" value="Cold Deal">Cold
                            Deal</mat-option>
                          <mat-option (click)="onStartingStageSelectionForVelocity('Lost Deal','8')" value="Lost Deal">Lost
                            Deal</mat-option>
                        </div>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="w-p127 mr-10 flex items-center">
                  <div class="input-group">
                    <mat-slide-toggle (change)="onFreeze()" [ngModel]="isFreeze"   class="mat-slider-toggle ">Freeze
                      Stage</mat-slide-toggle>
                  </div>
                </div>

                    

           </div>
 
          </section>   
      

        <div class="mog3_msnsearchtable_sticky hunting-velocity-table-height">
          <table mat-table class="mat-table-theme  w-100 mat-table-bordered" [dataSource]="HuntingVelocityTableDataSource">
            <ng-container *ngFor="
                let disCol of displayedColumnsForHuntingVelocity;
                let colIndex = index
              " matColumnDef="{{ disCol.field }}">
             <!-- {{console.log(HuntingVelocityTableDataSource)}} -->
              <ng-container *ngIf="disCol.field == 'Stage'">
                <th mat-header-cell *matHeaderCellDef class="table-header">
                  <span class="col-name"> {{ disCol.value }}</span>
                </th>
                <td mat-cell *matCellDef="let element; let i = index" >
                  {{ element.stage }}
                </td>
              </ng-container>
              <ng-container *ngIf="disCol.field != 'Stage'">
                <th mat-header-cell *matHeaderCellDef class="table-header">
                  <div>
                    <span>
                      {{ disCol.startDate }}
                    </span>
                    <br>
                    <span>
                      {{ disCol.endDate }}
                    </span>
                  </div>
                </th>
                <td mat-cell *matCellDef="let element"  class="table-row"
                [ngClass]="{'highlighted': element.laneTaskVelocityWeeklyDTOS[colIndex - 1].count !=0 && isFreeze}"
                >
                  <div *ngIf="element.laneTaskVelocityWeeklyDTOS[colIndex - 1].count !=0 ">
                    <span >
                      {{
                      element.laneTaskVelocityWeeklyDTOS[colIndex - 1].count
                      }}
                    </span>
                    <br>
                    <span class="amtVar">{{
                      element.laneTaskVelocityWeeklyDTOS[colIndex - 1].sum
                      }}
                      cr</span>

                  </div>
                </td>
              </ng-container>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="
                displayedColumnsForHuntingVelocity1;
                sticky: true
              "></tr>
            <tr mat-row *matRowDef="
                let row;
                columns: displayedColumnsForHuntingVelocity1;
                let i = index
              "
              [ngClass]="{ 'active-row': i === selectedTrIndex, 'highlighted': i == highlightedRows && selectedStartingStage != null   }"></tr>
          </table>
          <!-- 
        <mat-paginator
          [length]="toplostdealcount"
          (page)="onPageChange($event, 'lost')"
          [pageSizeOptions]="[5, 10, 25, 100]"
          showFirstLastButtons
        ></mat-paginator> -->
        </div>
   
    </mat-tab>
  </mat-tab-group>
  <mat-tab-group class="sob_tabs" animationDuration="0ms" *ngIf="boardId===boardIdForSobOpportunity">
    <mat-tab label="SOB Activity">
      <section class="dashboard-section">
        <div class="chart-filter-row " >
          <div class="atom-row m-0">

            <div class="w-p154 mr-10">
              <div class="formfield">
                <app-custom-multiple-select [label]="businessVerticalLabel" 
                  [selectedValues]="selectedBusinessVertical"
                  [availableOptions]="defaultBusinessVerticalSearch" 
                  [searchControl]="searchControlBS"
                  (selectionChange)="onBusinessVerticalSelectionChange($event)"
                  (searchChange)="onBusinessVerticalSearchChange($event)">
                </app-custom-multiple-select>
              </div>
              </div>

              <div class="w-p137 mr-10">
                <div class="formfield">
                  <app-custom-multiple-select [label]="regionTeamLabel" [selectedValues]="selectTeams"
                    [availableOptions]="teamsSearch" [searchControl]="searchControlTeams"
                    (selectionChange)="onRegionTeamSelectionChange($event)"
                    (searchChange)="onRegionTeamSearchChange($event)">
                  </app-custom-multiple-select>
                </div>
              </div>

              <div class="w-p86 mr-10">
                <div class="formfield">
                  <app-custom-multiple-select [label]="kamLabel" [selectedValues]="selectedUsers"
                    [availableOptions]="allUserListSearch" [searchControl]="searchControlHunter"
                    (selectionChange)="onHunterSelectionChange($event)" (searchChange)="onHunterSearchChange($event)">
                  </app-custom-multiple-select>
                </div>
              </div>

              <div class="w-p185 mr-10">
                <div class="formfield new_filterDate">
                  <crm-date-picker [selectDefault]="true" [resetDates]="resetDates" [placeholder]="'Created Date'"
                    (dateFilterApplied)="onApplyDates($event,'createdDate')"></crm-date-picker>
    
                </div>
              </div>

              <div class="w-p185 mr-10">
                <div class="formfield new_filterDate">
                  <!-- <label>Closing Date</label> -->
                  <crm-date-picker [selectDefault]="false" [resetDates]="resetDates" [placeholder]="'Closing Date'"
                    (dateFilterApplied)="onApplyDates($event,'closingDate')"></crm-date-picker>
                </div>
              </div>
        

              <div class="w-p185 mr-10">
                <div class="formfield new_filterDate">
                  <!-- <label>Time Duration</label> -->
                  <crm-date-picker [selectDefault]="true" [resetDates]="resetDates" [placeholder]="'Last Updated Date'"
                    (dateFilterApplied)="onApplyDates($event,'lastUpdatedDate')"></crm-date-picker>
                </div>
              </div>

              <div class="w-p77 mr-10 flex items-center">

                  <button (click)="clearAll();" mat-button class="btn-basic btn-text-black btn-md"  color="primary">Clear All</button>
                
              </div>


            <!-- Business Vertical -->
          </div>
          <!-- <div fxLayout="row" fxFlex="10" fxLayoutAlign="center space-around" class="filter-sec" >
        <div class="formfield">
          <label>Time Duration</label>
          <crm-date-picker
                  [selectDefault]="true"
                  [resetDates]="resetDates"
                  (dateFilterApplied)="onApplyDates($event,'timeDuration')"
                ></crm-date-picker>
        </div>
      </div> -->
        
        </div>
        <div fxLayout="row" fxLayoutAlign="center space-around" class="mb-20">
          <div fxFlex="60" fxLayout="column" class="mr-20">
            <div class="panel panel-height-1">
              <div class="panel-heading mb-20">
                <h1>Deal Stage</h1>
              </div>
              <div class="panel-body panel-body-height-1">
                <div fxLayout="row"  class="h-100" fxLayoutAlign="center center">
                  <div fxFlex="40" class="h-100" fxLayout="column" class="mr-20">
                    <ul class="deal-funnel-list">
                      <li *ngFor="let stage of stages">
                        <span class="deal-stage">{{ stage }}</span>
                        <div class="deal-info" (click)="dealFunnelModal(stage)">
                          <span class="deal-number">{{ getCountBykey(stage) }}</span>
                          <span class="deal-amount">{{ getSumBykey(stage) }} cr</span>
                          <i class="ri-arrow-right-s-line"></i>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div fxFlex="60" class="h-100" fxLayout="column">
                    <highcharts-chart class="sob-highchart" [Highcharts]="Highcharts" [constructorType]="'chart'" [options]="chartOptions"
                      [(update)]="updateFlag"  [eToOne]="oneToOneFlag"
                      style="width:100%;display: block;position: relative;">

                    </highcharts-chart>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div fxFlex="40" fxLayout="column">
            <div class="panel mb-20">
              <div class="panel-heading d-flex justify-content-between">
                <h1>Cold Deals</h1>
                <i (click)="dealFunnelModal('Cold Deal')" class="ri-external-link-line icon-dark-blue"></i>
              </div>
              <div class="panel-body">
                <div class="d-flex justify-content-between deals-detail">
                  <span class="deals-count">{{getCountBykey('Cold Deal')}}</span>
                  <span class="deals-worth">{{getSumBykey('Cold Deal')}} cr</span>
                </div>
              </div>
            </div>
            <div class="panel mb-20">
              <div class="panel-heading d-flex justify-content-between">
                <h1>Lost Deals</h1>
                <i (click)="dealFunnelModal('Lost Deal')" class="ri-external-link-line icon-dark-blue"></i>
              </div>
              <div class="panel-body">
                <div class="d-flex justify-content-between deals-detail">
                  <span class="deals-count">{{getCountBykey('Lost Deal')}}</span>
                  <span class="deals-worth">{{getSumBykey('Lost Deal')}} cr</span>
                </div>
              </div>
            </div>
            <div class="panel">
              <div class="panel-heading d-flex justify-content-between">
                <h1>Dormant Deals</h1>
                <i (click)="dealFunnelModal('Dormant')" class="ri-external-link-line icon-dark-blue"></i>
              </div>
              <div class="panel-body">
                <div class="d-flex justify-content-between deals-detail">
                  <span class="deals-count">{{dormantDealsCount}}</span>
                  <span class="deals-worth">{{dormatDealSum | number: '1.2-2'}} cr</span>
                </div>
              </div>
            </div>
            
          </div>
        </div>
        <mat-tab-group class="separate-tab mb-30" animationDuration="0ms" (selectedTabChange)="tabChanged($event)">
          <mat-tab label="Top 5 Won deals">
            <div class="mog3_msnsearchtable_sticky">
              <table mat-table class="w-100" [dataSource]="topWonTableDataSource">
                <ng-container>
                  <ng-container *ngFor="let disCol of displayedColumnsSOB; let colIndex = index" matColumnDef="{{disCol.field}}">
                    <ng-container>
                      <th mat-header-cell *matHeaderCellDef class="table-header">
                        <span class="col-name"> {{disCol.label}}</span>
                      </th>
                    </ng-container>
                    <td mat-cell *matCellDef="let element; let i = index;"  class="table-row">
                      <div>
                        <span>  {{ getFieldValue(element, disCol.field) }} </span>
                      </div>
                    </td>
                  </ng-container>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsNameList; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsNameList; let i = index"
                  [ngClass]="{ 'active-row': i === selectedTrIndex }">
              </table>
              <mat-paginator [length]="topwondealcount" (page)="onPageChange($event,'won')"
                [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons></mat-paginator>
                <div *ngIf="isLoading" class="loading-spinner-container">
                  <mat-spinner strokeWidth="2" [diameter]="60" [color]="red"></mat-spinner>
                </div>                
            </div>
          </mat-tab>
          <mat-tab label="Top 5 Lost deals">
            <div class="mog3_msnsearchtable_sticky">
              <table mat-table class="w-100" [dataSource]="topLostTableDataSource">
                <ng-container>
                  <ng-container *ngFor="let disCol of displayedColumnsSOB; let colIndex = index" matColumnDef="{{disCol.field}}">
                    <ng-container>
                      <th mat-header-cell *matHeaderCellDef class="table-header">
                        <span class="col-name"> {{disCol.label}}</span>
                      </th>
                    </ng-container>
                    <td mat-cell *matCellDef="let element; let i = index;"  class="table-row">
                      <div>
                        <span>  {{ getFieldValue(element, disCol.field) }} </span>
                      </div>
                    </td>
                  </ng-container>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsNameList; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsNameList; let i = index"
                  [ngClass]="{ 'active-row': i === selectedTrIndex }">
              </table>
              <mat-paginator [length]="toplostdealcount" (page)="onPageChange($event,'lost')"
                [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons></mat-paginator>
                <div *ngIf="isLoading" class="loading-spinner-container">
                  <mat-spinner strokeWidth="2" [diameter]="60" [color]="red"></mat-spinner>
                </div> 
            </div>
          </mat-tab>
          <mat-tab label="Top 5 Ongoing deals">
            <div class="mog3_msnsearchtable_sticky">
              <table mat-table class="w-100" [dataSource]="topOnGoingTableDataSource">
                <ng-container>
                  <ng-container *ngFor="let disCol of displayedColumnsSOB; let colIndex = index" matColumnDef="{{disCol.field}}">
                    <ng-container>
                      <th mat-header-cell *matHeaderCellDef class="table-header">
                        <span class="col-name"> {{disCol.label}}</span>
                      </th>
                    </ng-container>
                    <td mat-cell *matCellDef="let element; let i = index;" class="table-row">
                      <div>
                        <span>  {{ getFieldValue(element, disCol.field) }} </span>
                      </div>
                    </td>
                  </ng-container>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsNameList; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsNameList; let i = index"
                  [ngClass]="{ 'active-row': i === selectedTrIndex }">
              </table>
              <mat-paginator [length]="topOnGoingDealCount" (page)="onPageChange($event,'ongoingSob')"
                [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons></mat-paginator>
                <div *ngIf="isLoading" class="loading-spinner-container">
                  <mat-spinner strokeWidth="2" [diameter]="60" [color]="red"></mat-spinner>
                </div> 
            </div>
          </mat-tab>
          <!-- <mat-tab label="KAM Deals Summary">
            <div class="mog3_msnsearchtable_sticky">
              <table mat-table class="w-100" [dataSource]="topLostTableDataSource">
                <ng-container *ngFor="let disCol of displayedColumns; let colIndex = index"
                  matColumnDef="{{disCol.field}}">
                  <th mat-header-cell *matHeaderCellDef class="table-header"> {{disCol.label}}
                  </th>
                  <td mat-cell *matCellDef="let element" class="table-row"
                    >
                    <div>
                      <span> {{ getFieldValue(element, disCol.field) }}</span>
                    </div>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns1; sticky: true">

                </tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns1; let i = index"
                  [ngClass]="{ 'active-row': i === selectedTrIndex }">
              </table>
              <mat-paginator [length]="toplostdealcount" (page)="onPageChange($event,'lost')"
                [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons></mat-paginator>
            </div>
          </mat-tab> -->
          <mat-tab label="Meeting Overview">
            <div class="mog3_msnsearchtable_sticky">
              <table mat-table [dataSource]="meetingsOverviewDataSource">
                <ng-container matColumnDef="plant">
                  <th mat-header-cell *matHeaderCellDef><span class="no-wrap">Plant</span></th>
                  <td mat-cell *matCellDef="let element">{{element.searchData.name}}</td>
                </ng-container>
          
                <ng-container matColumnDef="customerName">
                  <th mat-header-cell *matHeaderCellDef>Customer</th>
                  <td mat-cell *matCellDef="let element">{{element.searchData.account_name}}</td>
                </ng-container>
          
                <ng-container matColumnDef="kam">
                  <th mat-header-cell *matHeaderCellDef>KAM</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.searchData.plantowner_name ? element.searchData.plantowner_name : '-' }}
                  </td>
                </ng-container>
                
          
                <ng-container matColumnDef="meetingDone">
                  <th mat-header-cell *matHeaderCellDef>Meeting Done</th>
                  <td mat-cell *matCellDef="let element">{{element.totalMeetings}}</td>
                </ng-container>
          
                <tr mat-header-row *matHeaderRowDef="displayedColumnsForMeetingOverview; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsForMeetingOverview"></tr>
              </table>
              <mat-paginator #paginator1 [pageIndex]="selectedPage1 - 1" [pageSize]="5" [length]="topMeetingOverviewCount" 
              (page)="onPageChange($event,'meetingOverView')"
                [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons>
              </mat-paginator>
                
                <div *ngIf="isLoading" class="loading-spinner-container">
                  <mat-spinner strokeWidth="2" [diameter]="60" [color]="red"></mat-spinner>
                </div> 
            </div>
          </mat-tab>
        </mat-tab-group>

      </section>
    </mat-tab>
  </mat-tab-group>
</section>

<!-- <ng-container>
  <div fxLayout="column" fxLayoutAlign="flex-start space-between" class="board-dashboard">
    <div fxLayout="row" class="dashboard-section">
      <app-chart-filters  #child [inputData]="passOnData" (filterApplied)="onFilterApply($event)" style="width: 100%;"></app-chart-filters>
    </div>
    <div fxLayout="row" class="dashboard-section">
      <app-hunting-overview class="chart-section" style="width: 100%;" #child  [inputData]="passOnData" [huntingData]="huntingOverview"
      (onDrillDown)="drillDown($event)"> </app-hunting-overview>
    </div>
    <div fxLayout="row" class="dashboard-section">
      <app-top-deals class="chart-section"  style="width: 100%;" [inputData]="passOnData"  [dealTypeInput]="'upcoming'"  #child > </app-top-deals>
    </div>
    <div fxLayout="row" class="dashboard-section">
       <app-top-deals class="chart-section"  style="width: 100%;" [inputData]="passOnData" [dealTypeInput]="'past'" #child > </app-top-deals>
    </div>
    <div fxLayout="row" class="dashboard-section">
     <app-hunting-projections class="chart-section"  style="width: 100%;"></app-hunting-projections>
   </div> -->
<!-- <div fxLayout="row" class="dashboard-section">
      <app-stage-time class="chart-section" style="width: 100%;"  [inputData]="passOnData"  #child ></app-stage-time>
    </div> -->
<!-- </div>
</ng-container> -->