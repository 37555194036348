<div fxLayout="column" fxLayoutAlign="start center" select="[rightPane]">
  <div fxLayout="row " class="w-100 p-20 pb-0" fxLayoutAlign="space-between flex-center">
    <h1 class="modal-heading-2 text-capitalize">New Meeting</h1>
    <button mat-mini-fab class="modal-close-btn" (click)="closeDialog()">
      <i class="ri-close-circle-fill"></i>
    </button>
  </div>
  <section aria-label="lead-info" class="dialog-body w-100 pt-0" fxLayout="column" fxLayoutAlign="start">
    <!-- Select Platform -->
    <!-- <div class="atom-row mb-5">
      <div class="atom-col-sm-6">
      <div class="field-container" *ngIf="isPlatformDropdownVisible">
        <label class="modal-input-label-2 required">Select Platform</label>
        <mat-form-field appearance="outline" class="mat-select deep-0">
          <mat-select placeholder="Select Platform" [(ngModel)]="selectedPlatform" (selectionChange)="onSelectedValueChange()">
            <mat-option *ngFor="let platform of platformOptions" [value]="platform.value">
              {{ platform.display }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
   </div> -->
  
   <!-- SOB -->
    <div class="atom-row mb-5">
  
      <!-- Select Account -->
      <div class="atom-col-sm-6">
        <div class="field-container">
          <label class="modal-input-label-2 required">Select Account</label>
          <mat-form-field appearance="outline"  class="mat-select mat-select-search deep-0">
            <mat-select [panelClass]="'select-panel-class'" #sa placeholder="Select Account" multiple [(ngModel)]="selectedAccounts" (selectionChange)="onApplyAccounts()">
              <mat-form-field  class="mat-input icon"  appearance="outline">
                <input type="text" matInput placeholder="Search" [(ngModel)]="searchControlAccounts" (keyup)="filterOptionsAccounts($event)">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16">
                  <path d="M18.031 16.6168L22.3137 20.8995L20.8995 22.3137L16.6168 18.031C15.0769 19.263 13.124 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20 13.124 19.263 15.0769 18.031 16.6168ZM16.0247 15.8748C17.2475 14.6146 18 12.8956 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18C12.8956 18 14.6146 17.2475 15.8748 16.0247L16.0247 15.8748Z" fill="rgba(111,111,112,1)"></path>
                </svg>
              </mat-form-field>
              <div class="select-options">
                <mat-option *ngFor="let account of accountOptionsSelected" [value]="account">{{account.name}}</mat-option>
              </div>
              <div  class="select-footer">
                <button mat-button
                class="btn-basic btn-text-black mr-10" (click)="clearAccounts();selectedAccounts=[];sa.close();">Clear</button>
              </div>
            </mat-select>
            <div *ngIf="isLoading" fxLayout="row" class="sp-cont">
              <mat-spinner strokeWidth="2" [diameter]="20" [color]="red"></mat-spinner>
            </div>
          </mat-form-field>
        </div>
      </div>
      
      <!-- Select Plant -->
      <div class="atom-col-sm-6">
      <div class="field-container">
        <label class="modal-input-label-2 required">Select Plant</label>
        <mat-form-field  appearance="outline"  class="mat-select mat-select-search deep-0">
          <mat-select [panelClass]="'select-panel-class'" #sl placeholder="Select Plants" multiple [(ngModel)]="selectedPlantsForMeeting" (selectionChange)="onApplyPlants()">
            <mat-form-field class="mat-input icon" appearance="outline">
              <input type="text" matInput placeholder="Search" [(ngModel)]="searchControlPlants" (keyup)="filterOptionsPlants($event)">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16">
                <path
                  d="M18.031 16.6168L22.3137 20.8995L20.8995 22.3137L16.6168 18.031C15.0769 19.263 13.124 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20 13.124 19.263 15.0769 18.031 16.6168ZM16.0247 15.8748C17.2475 14.6146 18 12.8956 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18C12.8956 18 14.6146 17.2475 15.8748 16.0247L16.0247 15.8748Z"
                  fill="rgba(111,111,112,1)"></path>
              </svg>
            </mat-form-field>
            <div class="select-options">
              <mat-option *ngFor="let plant of plantOptionsSelected" [value]="plant">{{plant.name}}</mat-option>
            </div>
            <div  class="select-footer">
              <button class="filter-btn clear" (click)="clearPlants();selectedPlantsForMeeting=[];">Clear</button>
            </div>
          </mat-select>
          <div *ngIf ="isLoading" fxLayout="row" class="sp-cont">
            <mat-spinner strokeWidth="2" [diameter]="20" [color]="red"></mat-spinner>
          </div>
        </mat-form-field>
      </div>
      </div>


      <!-- Select Opportunity -->
      <!-- <div class="atom-col-sm-6">
        <div class="field-container">
          <label class="modal-input-label-2">Select Opportunity</label>
          <mat-form-field appearance="outline" class="mat-select mat-select-search deep-0">
            <mat-select #s2 placeholder="Select Opportunity" multiple [(ngModel)]="selectedOpportunity"
              [disabled]="!opportunityList || opportunityList.length === 0" (selectionChange)="onApplyOpportunity()">
              <div class="custom-panel search-with-options">
                <mat-option *ngFor="let opportunity of opportunityList" [value]="opportunity">{{opportunity.displayName}}</mat-option>
              </div>
              <div fxLayout="row" fxLayoutAlign="flex-end space-between" class="filter-btn-section">
                <button class="filter-btn clear" (click)="clearOpportunity();selectedOpportunity=[];">Clear</button>
              </div>
            </mat-select>
            <div *ngIf ="isLoading" fxLayout="row" class="sp-cont">
              <mat-spinner strokeWidth="2" [diameter]="20" [color]="red"></mat-spinner>
            </div>
          </mat-form-field>
        </div>
      </div> -->
    </div>

    <!-- Hunting -->
    <div class="atom-row mb-5">
      <!-- Select Account -->
      <!-- <div class="atom-col-sm-6">
        <div class="field-container">
          <label class="modal-input-label-2 required">Select Account</label>
          <mat-form-field appearance="outline"  class="mat-select mat-select-search deep-0">
            <mat-select [panelClass]="'select-panel-class'" #sa placeholder="Select Account" multiple [(ngModel)]="selectedAccounts" (selectionChange)="onApplyAccounts()">
              <mat-form-field  class="mat-input icon"  appearance="outline">
                <input type="text" matInput placeholder="Search" [(ngModel)]="searchControlAccounts" (keyup)="filterOptionsAccounts($event)">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16">
                  <path d="M18.031 16.6168L22.3137 20.8995L20.8995 22.3137L16.6168 18.031C15.0769 19.263 13.124 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20 13.124 19.263 15.0769 18.031 16.6168ZM16.0247 15.8748C17.2475 14.6146 18 12.8956 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18C12.8956 18 14.6146 17.2475 15.8748 16.0247L16.0247 15.8748Z" fill="rgba(111,111,112,1)"></path>
                </svg>
              </mat-form-field>
              <div class="select-options">
                <mat-option *ngFor="let account of accountOptionsSelected" [value]="account">{{account.name}}</mat-option>
              </div>
              <div  class="select-footer">
                <button mat-button
                class="btn-basic btn-text-black mr-10" (click)="clearAccounts();selectedAccounts=[];sa.close();">Clear</button>
              </div>
            </mat-select>
            <div *ngIf="isLoading" fxLayout="row" class="sp-cont">
              <mat-spinner strokeWidth="2" [diameter]="20" [color]="red"></mat-spinner>
            </div>
          </mat-form-field>
        </div>
      </div> -->
      
      <!-- Select Deals -->
      <!-- <div class="atom-col-sm-6">
          <div class="field-container">
            <label class="modal-input-label-2">Select Deals</label>
            <mat-form-field appearance="outline" class="mat-select mat-select-search deep-0" >
              <mat-select [panelClass]="'select-panel-class'" #s6 placeholder="Select Deals" multiple [(ngModel)]="selectedDeals"
                [disabled]="!dealsList || dealsList.length === 0" (selectionChange)="onApplyDeals()">
                <div class="select-options">
                  <mat-option *ngFor="let deal of dealsList" [value]="deal">{{deal.searchData.dealname}}</mat-option>
                </div>
                <div class="select-footer">
                  <button class="filter-btn clear" (click)="clearDeals();selectedDeals=[];s6.close();">Clear</button>
                </div>
              </mat-select>
              <div *ngIf="isLoading" fxLayout="row" class="sp-cont">
                <mat-spinner strokeWidth="2" [diameter]="20" [color]="red"></mat-spinner>
              </div>
            </mat-form-field>
            
          </div>
      </div> -->
    </div>

    <!-- Supplier -->
    <div class="atom-row mb-5" *ngIf="selectedPlatform === 'SRM'">
      <!-- Select Supplier -->
      <div class="atom-col-sm-6">
        <div class="field-container">
          <label class="modal-input-label-2">Select Supplier</label>
                <app-custom-multiple-select [label]="'Supplier'" 
                            [selectedValues]="selectedSupplier"
                            [selectedHighlight]="false"
                            [valueCountView]="true"
                            [availableOptions]="supplierAllDetailsFilter" 
                            [searchControl]="searchControlSupplier"
                            (selectionChange)="onSupplierSelectionChange($event)"
                            (searchChange)="filterOptionsSupplier($event)">
                </app-custom-multiple-select>
        </div>
      </div>
    </div>

    <!-- Select Contacts -->
    <div class="atom-row mb-5">
      <div class="atom-col-sm-6" *ngIf="selectedPlatform !== 'SRM'" fxLayout="row" fxLayoutAlign="start">
        <div class="field-container">
          <label class="modal-input-label-2 ">Contact</label>
          <mat-form-field appearance="outline" class="mog3-matselect create-lane-input">
            <mat-select #s3 placeholder="Select Contact" multiple [(ngModel)]="selectedContact" [disabled]="!contactList || contactList.length === 0" (selectionChange)="onApplyContacts()">
              <div class="custom-panel search-with-options">
                <mat-option *ngFor="let contact of contactList" [value]="contact">{{contact.displayName}}</mat-option>
              </div>
              <div fxLayout="row" fxLayoutAlign="flex-end space-between" class="filter-btn-section">
                <button class="filter-btn clear" (click)="clearContacts();">Clear</button>
              </div>
            </mat-select>
            <div *ngIf ="isLoading" fxLayout="row" class="sp-cont">
              <mat-spinner strokeWidth="2" [diameter]="20" [color]="red"></mat-spinner>
            </div>
          </mat-form-field>
        </div>
      </div>

       <!-- Enter or Select Email Address -->
      <div class="atom-col-sm-6" *ngIf="selectedPlatform === 'SRM'" fxLayout="row" fxLayoutAlign="start">
        <div class="field-container mb-5">
          <label class="modal-input-label-2 required">Enter or Select Email Address</label>
          <mat-form-field class="mat-chips deep-0" appearance="outline">
            <mat-chip-list #chipList>
              <mat-chip *ngFor="let contact of contacts" [selectable]="selectable" [removable]="removable" (removed)="remove(contact)">
                {{contact}}<mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip>
              <input #contactInput [formControl]="contactCtrl" [matAutocomplete]="auto" [matChipInputFor]="chipList" 
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)">
            </mat-chip-list>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
              <mat-option *ngFor="let contact of filteredcontacts | async" [value]="contact">{{contact}}</mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>

      <!-- Add Attendees -->
      <div class="atom-col-sm-6">
        <div class="field-container">
          <label class="modal-input-label-2">Add Vedanta Attendees</label>
          <app-custom-multiple-select [label]="attendeeLabel" 
              [selectedValues]="selectedAttendees" 
              [availableOptions]="allUserListSearch" 
              [searchControl]="searchControlAttendees" 
              [valueCountView]="true"
              [selectedHighlight]="false"
              (selectionChange)="onAttendeeSelectionChange($event)" 
              (searchChange)="filterOptionsAttendees($event)">
          </app-custom-multiple-select>
        </div>
      </div>
  
    </div>
   
    <!-- Meeting Title -->
    <div class="mb-5" fxLayout="row" fxLayoutAlign="start">
      <div class="field-container">
        <label class="modal-input-label-2 required">Meeting Title</label>
        <mat-form-field class="mog3_matinput create-lane-input" appearance="outline">
          <input matInput type="text" placeholder="Type Here" class="input-field" [(ngModel)]="meetingTitle" />
        </mat-form-field>
      </div>
    </div>
    <!-- Meeting Type -->
    <div class="mb-5" fxLayout="row" fxLayoutAlign="start">
      <div fxLayout="row" fxFlex="50" fxLayoutGap="10" class="mr-30" fxLayoutAlign="start">
        <div class="field-container">
          <label class="modal-input-label-2 required">Meeting Type</label>
          <mat-form-field appearance="outline" class="mog3-matselect create-lane-input">
            <mat-select #s4 placeholder="Select Meeting Type" [(ngModel)]="selectedMeetingType">
              <div class="custom-panel search-with-options meeting-type">
                <mat-option *ngFor="let meetingType of meetingTypeOptions"
                  [value]="meetingType">{{meetingType}}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" fxFlex="50" fxLayoutGap="10" class="ml-15" fxLayoutAlign="start">
        <div class="field-container">
          <label class="modal-input-label-2 required">Value Delivery</label>
          <mat-form-field appearance="outline" class="mog3-matselect create-lane-input">
            <mat-select #s5 placeholder="Select Option" [(ngModel)]="selectedValueDelivery">
              <div class="custom-panel search-with-options value-delivery">
                <mat-option *ngFor="let valueDelivery of valueDeliveryOptions"
                  [value]="valueDelivery">{{valueDelivery}}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <!-- Start Date -->
    <div class="mb-5" fxLayout="row" fxLayoutAlign="start">
      <div fxLayout="row" fxFlex="50" fxLayoutGap="10" class="mr-30" fxLayoutAlign="start">
        <div fxLayout="row" fxLayoutAlign="start space-around" style="margin-right: 1rem; align-items: center"
          class="w-100">
          <div fxLayout="row" fxLayoutAlign="start center" class="label-width" style="width: 40%">
            <label class="label-1 required">Start Date</label>
          </div>
          <div fxLayout="row" class="field-width" style="width: 60%">
            <mat-form-field class="mog3_matinput lane-card-input" appearance="outline">
              <input matInput (focus)="startDate.open()" [matDatepicker]="startDate" placeholder="Select Date"
                [(ngModel)]="startDateValue" (ngModelChange)="selectDate($event)" [min]="minDate"/>
              <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
              <mat-datepicker #startDate></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxFlex="50" fxLayoutGap="10" fxLayoutAlign="start">
        <div fxLayout="row" fxLayoutAlign="start space-around" style="margin-right: 1rem; align-items: center"
          class="w-100">
          <div fxLayout="row" fxLayoutAlign="start center" class="label-width" style="width: 30%">
            <label class="label-1 required">Start time</label>
          </div>
          <div fxLayout="row" class="field-width" style="width: 70%">
            <mat-form-field appearance="outline" class="mog3-matselect">
              <mat-select class="time-select" placeholder="Select start time" [ngModel]="startTimeValue"
                (ngModelChange)="selectTime($event)" [disabled]="startDateValue == null || startDateValue == undefined">
                <mat-option class="time-select-option" value="select time" selected disabled>Select time</mat-option>
                <mat-option class="time-select-option" *ngFor="let time of times" [value]="time">{{ time
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <!-- End Date -->
    <div class="mb-5" fxLayout="row" fxLayoutAlign="start">
      <div fxLayout="row" fxFlex="50" fxLayoutGap="10" fxLayoutAlign="start" class="mr-30">
        <div fxLayout="row" fxLayoutAlign="start space-around" style="margin-right: 1rem; align-items: center"
          class="w-100">
          <div fxLayout="row" fxLayoutAlign="start center" class="label-width" style="width: 40%">
            <label class="label-1 required">End Date</label>
          </div>
          <div fxLayout="row" class="field-width" style="width: 60%">
            <mat-form-field class="mog3_matinput lane-card-input" appearance="outline">
              <input matInput (focus)="endDate.open()" [matDatepicker]="endDate"
                [disabled]="startTimeValue == null || startTimeValue == undefined" placeholder="Select Date"
                [(ngModel)]="endDateValue" (ngModelChange)="selectEndDate($event)" [min]="minEndDate" />
              <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
              <mat-datepicker #endDate></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxFlex="50" fxLayoutGap="10" fxLayoutAlign="start">
        <div fxLayout="row" fxLayoutAlign="start space-around" style="margin-right: 1rem; align-items: center"
          class="w-100">
          <div fxLayout="row" fxLayoutAlign="start center" class="label-width" style="width: 30%">
            <label class="label-1 required">End time</label>
          </div>
          <div fxLayout="row" class="field-width" style="width: 70%">
            <mat-form-field appearance="outline" class="mog3-matselect create-lane-input">
              <mat-select placeholder="Select end time" [ngModel]="endTimeValue" (ngModelChange)="selectEndTime($event)"
                [disabled]="endDateValue == null || endDateValue == undefined">
                <mat-option *ngFor="let time of timesEnd" [value]="time">{{ time }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>          
        </div>
      </div>
    </div>

  </section>


  <div fxLayout="row" fxLayoutAlign="start center" style="width: 100%; padding: 0px 20px">
    <quill-editor class="w-100" [(ngModel)]="content" [modules]="modules">
    </quill-editor>
  </div><br />
  <div fxLayout="row " class="w-100 metting-mom" fxLayoutAlign="space-between flex-center" *ngIf="isCreatingPreviousMeeting">
    <h1 class="modal-heading-2 text-capitalize required">Add MOM</h1>
  </div>
  <div fxLayout="unset " class="w-100 metting-mom-Attendees" fxLayout="row-reverse"fxLayoutAlign="space-between center" *ngIf="isCreatingPreviousMeeting">
    <div class="formfield"  style="display: flex; align-items: center;">
      <mat-slide-toggle   style="margin-top: 12px;" class ="mat-slider-toggle pl-15 "  (change)="sendMail($event.checked)">Share MOM with all attendees</mat-slide-toggle>
    </div>
     <!-- Add Attendees -->
     <div class="atom-col-sm-6">
      <div class="field-container">
        <label class="modal-input-label-2">Add Vedanta Attendees</label>
        <app-custom-multiple-select [label]="attendeeLabel" 
            [selectedValues]="selectedMomAttendees" 
            [availableOptions]="allUserListSearch" 
            [searchControl]="searchControlAttendees" 
            [valueCountView]="true"
            [selectedHighlight]="false"
            (selectionChange)="onMomAttendeeSelectionChange($event)" 
            (searchChange)="filterOptionsAttendees($event)">
        </app-custom-multiple-select>
      </div>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" style="width: 100%; padding: 0px 20px" *ngIf="isCreatingPreviousMeeting">    
    <quill-editor class="w-100" [(ngModel)]="addMomContent" [modules]="modules">
    </quill-editor>
  </div>
  <!-- <div fxLayout="row" fxLayoutAlign="start center" style="width: 100%; padding: 0px 20px" *ngIf="isCreatingPreviousMeeting">
    <div class="container">
      <select [(ngModel)]="selectedLanguage" class="select-language">
        <option *ngFor="let lang of languages" [value]="lang.code">{{ lang.name }}</option>
      </select>
    
      <div class="buttons-container">
        <button #startButton *ngIf="!isRecognizing" (click)="startRecognition()" class="button" [ngClass]="{'active': isRecognizing}">
          <mat-icon class="material-icons">mic</mat-icon>
        </button>
        <button #startButton *ngIf="isRecognizing" (click)="stopRecognition()" class="button" [ngClass]="{'active': isRecognizing}">
          <mat-icon class="material-icons">mic</mat-icon>
        </button>
      </div>
      
      <div #timer class="timer">00:00</div>
    </div>
  </div> -->

  <div fxLayout="row" class="mt-15 mb-30" fxLayoutAlign="end center" style="width: 94%">
    <div fxLayout="row" class="mt-15" fxLayoutAlign="space-between" style="width: 100%">
      <div fxLayout="row" fxLayoutAlign="start space-around" style="margin-right: 1rem; align-items: center"
        *ngIf="isCreatingPreviousMeeting">
        <div fxLayout="row" fxLayoutAlign="start center" class="label-width" style="width: 40%">
          <label class="label-1 required">Next Action Date</label>
        </div>
        <div fxLayout="row" class="field-width" style="width: 42%">
          <mat-form-field class="mog3_matinput lane-card-input" appearance="outline">
            <input matInput (focus)="nextactiondate.open()" [matDatepicker]="nextactiondate" placeholder="Select Date"
              [(ngModel)]="nextActionDate" [min]="minDateForNextAction" readonly />
            <!-- Make input field readonly to prevent manual date entry -->
            <mat-datepicker-toggle matSuffix [for]="nextactiondate"></mat-datepicker-toggle>
            <mat-datepicker #nextactiondate [startView]="'month'"></mat-datepicker>
          </mat-form-field>

        </div>
      </div>

      <div class="d-flex">
        <section aria-label="documents" class="cb_fileinput" *ngIf="isCreatingPreviousMeeting">
          <ng-container *ngIf="file == null">
            <div draggable="true" class="dragArea config" ngFileDragDrop (fileDropped)="fileUpload($event)">
              <span class="lbl">
                <input style="display: none" type="file" (change)="fileUpload($event)" #fileInput />
                <span (click)="fileInput.value = ''; fileInput.click()">
                  <mat-icon class="material-icons">attachment</mat-icon>
                </span>
              </span>
            </div>
          </ng-container>
          <ng-container *ngIf="file != null">
            <div class="file-upload-section">
              <div class="d-flex flex-column w-100">
                <div class="file-uploading-status d-flex justify-content-between">
                  <span class="file-name">
                    <span title="{{ file.name }}" class="file-name-truncate">
                      {{ file.name }}</span>

                    <i class="ri-close-line" (click)="rmeoveSelectedFile()"></i>
                  </span>
                </div>
              </div>
            </div>
          </ng-container>
        </section>
        <button class="mog3_primary_flat_btn post-button" (click)="postSubmit()">
          Submit Meeting
        </button>
      </div>
    </div>
  </div>
</div>