<form class="w-full " [formGroup]="myform" (ngSubmit)="onSubmit()">
  <div class="w-full">
    <div class="flex justify-end add-form-group items-center mt-10 mb-10 ml-5">
      <button mat-button class="btn-basic" color="Red" (click)="addBlankFormGroup()">
        <i class="ri-add-fill icon-left"></i>Add {{displayName}}
      </button>
      <!-- <button mat-button class="btn-basic" color="Red" type="submit">Save {{displayName}}</button> -->
    </div>
  </div>
  <div formArrayName="items">
    <div class="add-form-list mb-10" *ngFor="let userGroup of getFormArray.controls; let i = index">
      <div class="add-form-card pb-15">
        <div [formGroupName]="i">
          <div class="w-full mb-5">
            <div class="flex justify-between add-form-group items-center">
              <div class="flex items-center">


                <span class="mr-20"> {{displayName}} : {{i+1}} Details</span>
                <mat-radio-group [formControlName]="'primary'" (change)="onRadioChange(i, $event.value)"
                  class="mat-radio-row" aria-label="Set as primary owner">
                  <mat-radio-button value="true">Set as primary {{displayName}}</mat-radio-button>

                </mat-radio-group>
              </div>
              <button mat-button class="btn-basic" color="Red" (click)="removeUserFormGroup(i)">
                <i class="ri-delete-bin-6-line icon-left"></i>Delete
              </button>
            </div>
          </div>

          <div class="add-input-group atom-row mx-0 mt-20">
            <div class="w-20 px-10" *ngFor="let item of properties">

              <div [ngSwitch]="item.type">

                <div *ngSwitchCase="'string'">
                  <div class="input-group">
                    <mat-label>Enter {{item.displayName}}</mat-label>
                    <mat-form-field class="mat-input icon relative mb-5 deep-0" appearance="outline">
                      <i [class]="item.icon"></i>
                      <input matInput type="text" [formControlName]="item.field" [placeholder]="item.displayName">
                      <mat-error class="error-absolute-position">{{item.displayName}} is required</mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div *ngSwitchCase="'email'">
                  <div class="input-group">
                    <mat-label>Enter {{item.displayName}}</mat-label>
                    <mat-form-field class="mat-input icon relative mb-5 deep-0" appearance="outline">
                      <i [class]="item.icon"></i>
                      <input matInput type="text" [formControlName]="item.field" [placeholder]="item.displayName">
                      <mat-error class="error-absolute-position">{{item.displayName}} is not valid</mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div *ngSwitchCase="'number'">
                  <div class="input-group">
                    <mat-label>Enter {{item.displayName}}</mat-label>
                    <mat-form-field class="mat-input icon relative mb-5 deep-0" appearance="outline">
                      <i [class]="item.icon"></i>
                      <input *ngIf="item.field!=='age'" matInput type="number" [formControlName]="item.field"
                             [placeholder]="item.displayName">
                
                      <input *ngIf="item.field==='age'" matInput type="text" [formControlName]="item.field"
                             [placeholder]="item.displayName" class="input-field" min="18" pattern="^\d*\.?\d*$"
                             oninput="validity.valid||(value=''); this.value = this.value.replace(/^0+(\d)/, '$1')"
                             (blur)="onBlur($event, i)" />
                
                      <mat-error class="error-absolute-position">{{item.displayName}} is required</mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div *ngSwitchCase="'phone'">
                  <div class="input-group">
                    <mat-label>Enter {{item.displayName}}</mat-label>
                    <mat-form-field class="mat-input icon mb-5 relative deep-0" appearance="outline">
                      <i [class]="item.icon"></i>
                      <input matInput type="text" pattern="\d*" [maxlength]="10" [minLength]="10"
                        [formControlName]="item.field" [placeholder]="item.displayName">
                      <mat-error class="error-absolute-position">{{item.displayName}} is not valid</mat-error>
                    </mat-form-field>
                  </div>
                </div>

              </div>
            </div>


          </div>

        </div>
      </div>
    </div>
  </div>
</form>