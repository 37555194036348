import { Component, OnInit, ChangeDetectorRef, SimpleChanges , ViewChild, Input} from '@angular/core';
import { ChartFiltersComponent } from '../chart-filters/chart-filters.component';
@Component({
  selector: 'app-stage-time',
  templateUrl: './stage-time.component.html',
  styleUrls: ['./stage-time.component.scss']
})
export class StageTimeComponent implements OnInit {
  @Input() inputData;
  teams: any;
  userList: any;
  passOnData : any;
  @ViewChild(ChartFiltersComponent) chartFilters : ChartFiltersComponent;
  constructor(public cRef: ChangeDetectorRef) { }

  ngOnInit() {
    
  }
  ngAfterViewInit() {
    this.cRef.detectChanges();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.inputData != null && changes.inputData.currentValue != null && changes.inputData.currentValue.teams) {
      this.teams = changes.inputData.currentValue.teams;
    }
    if (changes.inputData != null && changes.inputData.currentValue != null  && changes.inputData.currentValue.users) {
      this.userList = changes.inputData.currentValue.users;
    }
    if (this.teams != null && this.userList != null) {
      this.passOnData = {
        "teams" : this.teams,
        "users" : this.userList
      };
    }
  }

}
