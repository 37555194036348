<div class="pb-15 dialog-heading light-grey-bg" aria-label="heading">
  <div fxLayout="row mb-30 " fxLayoutAlign="space-between center">
    <h1 class="modal-heading-2 text-capitalize mb-0">Bulk upload</h1>

    <h2 class="modal-heading-4"></h2>

    <button mat-mini-fab class="modal-close-btn" (click)="dialogRef.close()">
      <i class="ri-close-circle-fill"></i>
    </button>
  </div>
</div>
<div class="upload-section">

  <div  fxLayout="row" fxLayoutAlign="center" class="download-template-section mt-30 mr-10">
    <section aria-label="documents" class="cb_fileinput" >
      <ng-container *ngIf="file == null">
      <div draggable="true"  class="dragArea config"
        ngFileDragDrop (fileDropped)="fileUpload($event)">
        <span class="input-btn">
          <input style="display: none" type="file"
            (change)="fileUpload($event)" #fileInput>
          <span (click)="fileInput.value='';fileInput.click()">
            <i class="ri-file-excel-line mr-10"></i>
            Select Files
          </span>
        </span>
      </div>
    </ng-container>
    <ng-container *ngIf="file != null">
      <div class="file-upload-section">
        <div class="d-flex flex-column w-100">
          <div class="file-uploading-status d-flex justify-content-between">
            <span class="file-name" >
              {{file.name}}
             
                <i class="ri-close-line"
                  (click)="rmeoveSelectedFile()"></i>
           
            </span>
          </div>
        </div>
      </div>
    </ng-container>
    </section> 
  

  </div>
</div>
<div mat-dialog-actions>
  <div fxLayout="row" class="w-100" fxLayoutAlign="space-between center">
    <a class="blue-anchor-2 d-flex" (click)="downloadTemplate()"><i class="ri-file-download-line mr-10"></i> Download Template</a>
  <button mat-flat-button cdkFocusInitial class="mog3_primary_flat_btn add-deal-btn" (click)="bulkUpload()">
    <i class="ri-upload-2-line"></i>
    <span> Bulk Upload</span>
  </button>
  </div>


</div>