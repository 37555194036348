import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { SettingService } from 'src/app/settings/setting.service';
import { TimePeriod } from '../chart-filters/date-picker-custom/date-picker-custom.component';
import { MatPaginator } from '@angular/material';
import * as moment from 'moment';

@Component({
  selector: 'app-last-login-history',
  templateUrl: './last-login-history.component.html',
  styleUrls: ['./last-login-history.component.scss']
})
export class LastLoginHistoryComponent implements OnInit {
  dataSource: Array<any> = [];
  displayColumns: Array<string> = ['CreationDate'];
  formattedDate: any;
  listRes: any[] = [];
  sortedDates: string[];
  userIdElement: any;
  startDate: any;
  endDate: any;
  totalRecords: any = 0;
  pageSize = 10;
  pageFrom = 0;
  resetDates: boolean = false;
  selected: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private datePipe: DatePipe, public settingService: SettingService, private changeDetectorRef: ChangeDetectorRef) {
  }


  ngOnInit() {
    this.setDefaultDateRange();
    this.getPicksList();
  }
  getPicksList() {
    this.userIdElement = this.settingService.getUserIdElementForLoginDetails();
    console.log("user", this.userIdElement['user']);
    const obj = {
      from: this.pageFrom + this.paginator.pageIndex,
      size: this.pageSize,
      userEmail: this.userIdElement['user'].email,
      startDate: this.startDate,
      endDate: this.endDate
    };
    this.settingService.getLoginHistoryDetailsByUserId(obj).subscribe(
      (response: any[]) => {
        this.listRes = response['result'].dates;
        this.totalRecords = response['size']
        this.formatDates();
        this.changeDetectorRef.detectChanges();
      },
      (error) => {
        console.error('Error fetching login details history:', error);
      }
    );
  }
  setDefaultDateRange() {
    const startDate = moment().subtract(6, 'days').startOf('day');
    const endDate = moment().endOf('day');

    this.selected = {
      startDate: startDate.toDate(),
      endDate: endDate.toDate()
    };
  }

  formatDates() {
    this.dataSource = this.listRes.map((originalDate) => {
      const formattedDate = this.datePipe.transform(originalDate, 'dd-M-yyyy');
      const formattedTime = this.datePipe.transform(originalDate, 'hh:mm a');
      return {
        CreationDate: formattedDate,
        CreationTime: formattedTime
      };
    });
  }
  clearAll() {
    this.resetDates = true;
    this.startDate = null;
    this.endDate = null;
    this.getPicksList();
  }

  onApplyDates(event: TimePeriod) {
    this.resetDates = false
    this.startDate = event.startDate.format('YYYY-MM-DD HH:mm:ss');
    this.endDate = event.endDate.format('YYYY-MM-DD HH:mm:ss');

    const startDate = moment(event.startDate.format('YYYY-MM-DD HH:mm:ss')).startOf('day');
    const endDate = moment(event.endDate.format('YYYY-MM-DD HH:mm:ss')).endOf('day');

    this.selected = {
      startDate: startDate.toDate(),
      endDate: endDate.toDate()
    };
    this.getPicksList();
  }

  onScrollDown(value: boolean) {
    this.getPicksList();
  }
}
