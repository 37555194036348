<div class="costcoDialog bg-white">
  <div class="lft">
    <div class="inner">
      <section aria-label="heading">
        <div fxLayout="row" fxLayoutAlign="space-between flex-start" class="card-header ">
          <div class="w-100" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="headLbl d-flex flex-direction-row mb-10 ml-10">
              <a class="back-btn mr-10" (click)="navigation.goBack()"><i class="ri-arrow-left-s-line"></i></a>
              <span *ngIf="boardName !== ''">Create New {{boardName}}</span>
            </div>
            <div class="mb-10 mr-20">
              <button *ngIf="enableActionItem" mat-flat-button class="mog3_primary_flat_btn mr-10" (click)="showActionItems()" aria-label="Save"
                matTooltip="Save" matTooltipClass="new-tooltip"><i class="ri-save-2-fill"></i> Add Action Item </button>
              <button mat-flat-button class="mog3_primary_flat_btn" (click)="saveLaneTask()" aria-label="Save"
                matTooltip="Save" matTooltipClass="new-tooltip"><i class="ri-save-2-fill"></i> Save Changes </button>
            </div>
            
          </div>
      
        </div>
      </section>
      <section aria-label="lead-info" class="lead-info" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column" style=" width: 100%; overflow: auto; ">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div class="d-flex">
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div style="margin-right: 10px;">
              </div>
              <div>
              </div>
            </div>
          </div>
          <ul class="customer-profile-list">
            <ng-container *ngFor="let formItem of laneTaskConfig; let i = index">
              <ng-container *ngIf="formItem.display">
                <li class="lane-grid" *ngIf="formItem.optional && (!formItem.accessibleLanes ||formItem.accessibleLanes.includes(laneTaskIdByUrl))" [ngClass]="{'w-full': formItem.type === 'table' || formItem.type === 'form','w-50': formItem.type !== 'table' && formItem.type !== 'form'}">
                  <div class="atom-row" style=" width: 100%" [ngClass]="{'flex-col': formItem.type === 'table' || formItem.type === 'form'}">
                    <div fxLayout="row" class="label-width items-start  atom-col-sm-5">
                      <label class="label-1"
                        *ngIf="formItem.field !== 'repeat-rf-qs-frequency' && formItem.field !== 'new-categories' && formItem.field !== 'new-category-list'"
                        [ngClass]="{'required': formItem.optional && checkValidity(formItem)}">{{formItem.displayName}}</label>
                      <label class="label-1" *ngIf="formItem.field === 'new-categories' && isDealTypeNewCategories"
                        [ngClass]="{'required': formItem.optional && checkValidity(formItem)}">{{formItem.displayName}}</label>
                      <label class="label-1" *ngIf="formItem.field === 'repeat-rf-qs-frequency' && isRepeatedRFQFrequency"
                        [ngClass]="{'required': formItem.optional && checkValidity(formItem)}">{{formItem.displayName}}</label>
                      <label *ngIf=" formItem.field === 'new-category-list' &&  isNewCategories " class="label-1"
                        [ngClass]="{ required:  formItem.optional && checkValidity(formItem)}">{{ formItem.displayName
                        }}</label>
                    </div>
                    <div [ngSwitch]="formItem.type" [ngClass]="{'atom-col-sm-12': formItem.type === 'table','atom-col-sm-7': formItem.type !== 'table','atom-col-sm-12': formItem.type == 'form'}">
                      <div *ngSwitchCase="'string'" fxLayout="column" class="field-container">
                        <!-- <label *ngIf="formItem.error" fxLayout="row"  class="error-label" > Mandtory Field </label> -->
                        <div fxLayout="row">
                          <mat-form-field class="mog3_matinput lane-card-input" appearance="outline"
                            [ngClass]="{'error-border': formItem.error}">
                            <input matInput [id]="formItem.field" type="text" [(ngModel)]="formItem.value">
                          </mat-form-field>
                          <!-- <span *ngIf="formItem.optional" class="mandatory-icon">*</span> -->
                        </div>
                      </div>
                      <div *ngSwitchCase="'email'" fxLayout="row" class="field-container">
                        <mat-form-field appearance="fill" class="mog3_matinput lane-card-input" appearance="outline"
                          [ngClass]="{'error-border': formItem.error}">
                          <input matInput [id]="formItem.field" type="email" name="email" [email]="true" class="input-field"
                            [(ngModel)]="formItem.value" (input)="validateEmail(formItem);">
                        </mat-form-field>
                        <!-- <span *ngIf="formItem.optional" class="mandatory-icon">*</span> -->
                      </div>
                      <div *ngSwitchCase="'number'" fxLayout="row" class="field-container">

                        <mat-form-field *ngIf="formItem.field != 'probability-of-conversion'" class="mog3_matinput lane-card-input" appearance="outline" [ngClass]="{'error-border': formItem.error}">
                          <input matInput [id]="formItem.field" type="number" class="input-field" min="0"
                            oninput="this.value = Math.abs(this.value)" [(ngModel)]="formItem.value">
                        </mat-form-field>

                        <mat-form-field *ngIf="formItem.field == 'probability-of-conversion'" class="mog3_matinput lane-card-input" appearance="outline" [ngClass]="{'error-border': formItem.error}">
                          <input matInput [id]="formItem.field" type="number" class="input-field" min="0" max="100"
                            oninput="this.value = this.value === '' ? '' : Math.min(Math.max(parseInt(this.value) || 0, 0), 100)"
                            [(ngModel)]="formItem.value">
                        </mat-form-field>


                        <!-- <span *ngIf="formItem.optional" class="mandatory-icon">*</span> -->
                      </div>
                      <div *ngSwitchCase="'phone'" fxLayout="row" class="field-container">
                        <mat-form-field class="mog3_matinput lane-card-input" appearance="outline"
                          [ngClass]="{'error-border': formItem.error}">
                          <input matInput [id]="formItem.field" type="text" pattern="\d*" class="input-field"
                            [(ngModel)]="formItem.value" (input)="validateLength(formItem, 10);" [maxlength]="10"
                            [max]="10">
                        </mat-form-field>
                        <!-- <span *ngIf="formItem.optional" class="mandatory-icon">*</span> -->
                      </div>
                      <div *ngSwitchCase="'currency'" fxLayout="row" class="field-container">
                        <!-- <mat-form-field class="mog3_matinput lane-card-input" appearance="outline"
                          [ngClass]="{'error-border': formItem.error}">
                          <input matInput [id]="formItem.field" type="number" class="input-field" min="0"
                            oninput="this.value = Math.abs(this.value)" [(ngModel)]="formItem.value">
                        </mat-form-field> -->
                        
                        <mat-form-field class="mog3_matinput lane-card-input" appearance="outline" [ngClass]="{'error-border': formItem.error}">
                          <input matInput [id]="formItem.field" type="number" class="input-field" 
                                step="any" min="0" [(ngModel)]="formItem.value" />
                        </mat-form-field>
                        
                        <!-- <span *ngIf="formItem.optional" class="mandatory-icon">*</span> -->
                      </div>
                      <div *ngSwitchCase="'dropdown'" fxLayout="row" class="field-container">
                        <mat-form-field class="mat-select lane-card-input deep-0 mb-025em pb-5"  appearance="outline"
                          [ngClass]="{'error-border': formItem.error}">
                          <mat-select [id]="formItem.field" [(ngModel)]="formItem.value">
                            <mat-option *ngFor="let opt of formItem.dataList" [value]="opt">{{opt}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <!-- <span *ngIf="formItem.optional" class="mandatory-icon">*</span> -->
                      </div>
                      <div *ngSwitchCase="'date'" fxLayout="row" class="field-container">
                        <mat-form-field class="mog3_matinput lane-card-input" appearance="outline"
                          [ngClass]="{'error-border': formItem.error}">
                          <input matInput (focus)="effectivePicker.open()" [matDatepicker]="effectivePicker"
                            placeholder="Select Date" [(ngModel)]="formItem.value" [min]="minDateForNextAction">
                          <mat-datepicker-toggle matSuffix [for]="effectivePicker"></mat-datepicker-toggle>
                          <mat-datepicker #effectivePicker></mat-datepicker>
                        </mat-form-field>
                        <!-- <span *ngIf="formItem.optional" class="mandatory-icon">*</span> -->
                      </div>
                      <div *ngSwitchCase="'multiSelect'" fxLayout="row" class="field-container">

                        <!-- <mat-form-field class="mat-select lane-card-input deep-0 mb-025em pb-5" appearance="outline"
                        *ngIf="(formItem.key == 'string' || formItem.key == 'number' )  && formItem.field !=='new-category-list'"
                        [ngClass]="{'error-border': formItem.error}">
                        <mat-select multiple [id]="formItem.field" [(ngModel)]="formItem.values">
                          <mat-option *ngFor="let opt of formItem.dataList" [value]="opt">{{opt}}</mat-option>
                        </mat-select>
                      </mat-form-field> -->

                        <ng-container *ngIf="(formItem.key == 'string' || formItem.key == 'number') &&  formItem.field != 'new-category-list' &&  
                        formItem.field != 'sob-l2-category-list'">
                        <div class="mat-input lane-card-input">
                          <div class="input-group-1">
                            <app-custom-multiple-select [label]="formItem.displayName" [ngClass]="{'error-border': formItem.error}"
                                [selectedValues]="formItem.values"
                                [selectedHighlight]="false"
                                [valueCountView]="true"
                                [availableOptions]="getFilteredOptions(formItem)" 
                                [searchControl]="searchControl"
                                (selectionChange)="getSelectionChange($event, formItem)"
                                (searchChange)="searchChange($event, formItem)">
                              </app-custom-multiple-select>
                          </div>
                        </div>
      
                        </ng-container>
                        <mat-form-field appearance="outline" class="mat-select"
                        *ngIf="formItem.field === 'sob-l2-category-list'" [ngClass]="{'error-border': formItem.error}">
                        <mat-select multiple [id]="formItem.field" [(ngModel)]="formItem.values">
                          <mat-select-trigger>
                            <ng-container *ngIf="
                                formItem.values &&
                                formItem.values.length > 0
                              ">
                              <span class="test">{{
                                formItem.values[0]
                                }}</span>
                              <span class="test2" *ngIf="formItem.values.length > 1"
                                class="example-additional-selection">
                                (+ {{ formItem.values.length - 1 }}
                                {{
                                formItem.values.length === 2
                                ? "other"
                                : "others"
                                }})
                              </span>
                            </ng-container>
                          </mat-select-trigger>

                          <mat-option *ngFor="let opt of subCategoriesOfNewCategories" [value]="opt">{{ opt
                            }}</mat-option>
                        </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="mat-select lane-card-select deep-0"
                          *ngIf="formItem.field === 'new-category-list' && shouldDisableField(formItem.field)">
                          <mat-select multiple [id]="formItem.field" [(ngModel)]="formItem.values">
                            <mat-select-trigger>
                              <ng-container *ngIf="formItem.values && formItem.values.length > 0">
                                <span class="test">{{ formItem.values[0] }}</span>
                                <span class="test2" *ngIf="formItem.values.length > 1" class="example-additional-selection">
                                  (+ {{ formItem.values.length - 1 }} {{ formItem.values.length === 2 ? "other" : "others"
                                  }})
                                </span>
                              </ng-container>
                            </mat-select-trigger>
                            <mat-form-field class="mog3_matinput select-search" appearance="outline">
                              <input type="text" matInput placeholder="Search" [(ngModel)]="searchControlL2Category"
                                (keyup)="filterOptionsL2Categories()" />
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="14" height="16">
                                <path
                                  d="M18.031 16.6168L22.3137 20.8995L20.8995 22.3137L16.6168 18.031C15.0769 19.263 13.124 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20 13.124 19.263 15.0769 18.031 16.6168ZM16.0247 15.8748C17.2475 14.6146 18 12.8956 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18C12.8956 18 14.6146 17.2475 15.8748 16.0247L16.0247 15.8748Z"
                                  fill="rgba(111,111,112,1)"></path>
                              </svg>
                            </mat-form-field>
                            <mat-option *ngFor="let opt of l2CategoryListSearch" [value]="opt">{{ opt
                              }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <!-- <span *ngIf="formItem.optional" class="mandatory-icon">*</span> -->
                      </div>
                      <div *ngSwitchCase="'select'" fxLayout="row" class="field-container">
                        <ng-container
                          *ngIf="formItem.field !== 'business-vertical' && formItem.field !=='deal-type' && formItem.field !=='lead-source' && formItem.field !=='new-categories'">
                          <mat-form-field appearance="outline" class="mat-select lane-card-select deep-0 mb-025em"
                            *ngIf="!shouldDisableField(formItem.field)" [ngClass]="{'error-border': formItem.error}">
                            <mat-select [id]="formItem.field" (selectionChange)="onSelectOption(laneTaskConfig, formItem)"
                              [(ngModel)]="formItem.value" (openedChange)="onDropdownClosed($event)">

                              <div class="custom-panel search-with-options">
                                <mat-option *ngFor="let opt of getOptionList(formItem.dataList, formItem.field)"
                                  [value]="opt">{{opt}}</mat-option>
                              </div>

                            </mat-select>
                          </mat-form-field>
                        </ng-container>

                        <ng-container *ngIf="formItem.field === 'business-vertical'">
                          <app-custom-single-select class="full-width mb-025em pb-5"  [formItem]="formItem"
                            [searchControl]="searchControlBusinessVertical" [optionList]="businessVerticalListSearch"
                            (selectionChange)="onSelectOption(laneTaskConfig, $event)"
                            (searchChange)="filterOptionsBusinessVertical($event)">
                          </app-custom-single-select>
                        </ng-container>

                        <ng-container *ngIf="formItem.field === 'deal-type'">
                          <app-custom-single-select class="full-width mb-025em pb-5" [formItem]="formItem"
                            [searchControl]="searchControlDealType" [optionList]="dealTypeListSearch"
                            (selectionChange)="onSelectOption(laneTaskConfig, $event)"
                            (searchChange)="filterOptionsDealType($event)">
                          </app-custom-single-select>
                        </ng-container>

                        <ng-container *ngIf="formItem.field === 'lead-source'">
                          <app-custom-single-select class="full-width mb-025em pb-5" [formItem]="formItem"
                            [searchControl]="searchControlLeadSource" [optionList]="leadSourceListSearch"
                            (selectionChange)="onSelectOption(laneTaskConfig, $event)"
                            (searchChange)="filterOptionsLeadSource($event)">
                          </app-custom-single-select>
                        </ng-container>

                        <ng-container *ngIf="formItem.field === 'new-categories'">
                          <app-custom-single-select class="full-width mb-025em pb-5" *ngIf="!shouldDisableField(formItem.field)"
                            [formItem]="formItem" [searchControl]="searchControlL1Category"
                            [optionList]="newCategoryListSearch" (selectionChange)="onSelectOption(laneTaskConfig, $event)"
                            (searchChange)="filterOptionsL1CategoryList($event)">
                          </app-custom-single-select>
                        </ng-container>
                      </div>
                      <div *ngSwitchCase="'boolean'" fxLayout="row" class="field-container">
                        <mat-form-field appearance="outline" class="mat-select lane-card-select deep-0 mb-025em pb-5"
                          *ngIf="formItem.key == 'string' || formItem.key == 'number'"
                          [ngClass]="{'error-border': formItem.error}">
                          <mat-select [id]="formItem.field" [(ngModel)]="formItem.value">
                            <mat-option *ngFor="let opt of ['','Yes', 'No']" [value]="opt">{{opt}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <!-- <span *ngIf="formItem.optional" class="mandatory-icon">*</span> -->
                      </div>
                      <div *ngSwitchCase="'belongsTo'" fxLayout="row" class="field-container">
                        <ng-container
                          *ngIf="formItem.field != 'plant-name' && formItem.field != 'region-team' && formItem.field !== 'contact'">
                          <app-field-autocomplete class="mb-025em pb-5" style="width: 100% !important;" [fieldData]="{
                              'config' : formItem, isFilter: true,'selectedHighlight':true  }" [modelRef]="formItem.value"
                            (filterApplied)="getPlantListBasedOnAccount(formItem)">
                          </app-field-autocomplete>
                        </ng-container>

                        <ng-container *ngIf="formItem.field == 'region-team'">
                          <mat-form-field appearance="outline" class="mat-select deep-0 mb-025em pb-5" [ngClass]="{'error-border': formItem.error}">
                            <mat-select #sl2 placeholder="Region/Teams" [(ngModel)]="formItem.value"
                              (openedChange)="onDropdownClosed($event)">
                              <mat-form-field class="mog3_matinput select-search" appearance="outline">
                                <input type="text" matInput placeholder="Search" [(ngModel)]="searchControlRegionTeam"
                                  (keyup)="filterOptionsRegionTeam()" />
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="14" height="16">
                                  <path
                                    d="M18.031 16.6168L22.3137 20.8995L20.8995 22.3137L16.6168 18.031C15.0769 19.263 13.124 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20 13.124 19.263 15.0769 18.031 16.6168ZM16.0247 15.8748C17.2475 14.6146 18 12.8956 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18C12.8956 18 14.6146 17.2475 15.8748 16.0247L16.0247 15.8748Z"
                                    fill="rgba(111,111,112,1)"></path>
                                </svg>
                              </mat-form-field>
                              <div class="custom-panel search-with-options">
                                <mat-option *ngFor="let region of regionTeamListSearch" [value]="region.id"> {{ region.name
                                  }} </mat-option>
                              </div>
                            </mat-select>
                          </mat-form-field>
                        </ng-container>

                        <ng-container *ngIf="formItem.field == 'plant-name' && boardId == boardIdForSobOpportunity">
                          <mat-form-field appearance="outline" class="mat-select deep-0" [ngClass]="{'error-border': formItem.error}">
                            <mat-select #sl2 placeholder="Plants" multiple [(ngModel)]="selectedPlantIds" (selectionChange)="applyPlantsIds()">

                              <mat-form-field class="mog3_matinput select-search" appearance="outline">
                                <input type="text" matInput placeholder="Search" [(ngModel)]="searchControlForPlants"
                                  (keyup)="filterOptionsPlants($event)" />
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="14" height="16">
                                  <path
                                    d="M18.031 16.6168L22.3137 20.8995L20.8995 22.3137L16.6168 18.031C15.0769 19.263 13.124 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20 13.124 19.263 15.0769 18.031 16.6168ZM16.0247 15.8748C17.2475 14.6146 18 12.8956 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18C12.8956 18 14.6146 17.2475 15.8748 16.0247L16.0247 15.8748Z"
                                    fill="rgba(111,111,112,1)"></path>
                                </svg>
                              </mat-form-field>

                              <div class="custom-panel search-with-options">
                                <mat-option *ngFor="let plant of accountPlantListSearch" [value]="plant.id"> {{ plant.name +
                                  ' (' + (plant.searchData.plantid ? plant.searchData.plantid : 'N/A') + ')' }}
                                </mat-option>
                              </div>
                              <div fxLayout="row" fxLayoutAlign="flex-end space-between" class="filter-btn-section">
                                <button class="filter-btn clear" (click)="clearSelectedPlantIds()">Clear</button>
                                <!-- <button class="filter-btn apply" (click)="applyPlantsIds(); sl2.close()">Apply</button> -->
                              </div>
                            </mat-select>
                            <div *ngIf ="isLoading" fxLayout="row" class="sp-cont">
                              <mat-spinner strokeWidth="2" [diameter]="20" [color]="red"></mat-spinner>
                            </div>
                          </mat-form-field>
                        </ng-container>

                        <ng-container *ngIf="formItem.field == 'contact' && boardId === boardIdForCRMDeals">
                          <div fxLayout="row" fxLayoutAlign="start space-around" *ngIf="boardId === boardIdForCRMDeals"
                            style=" width: 100%">
                            <div fxLayout="column" class="field-width" style="width: 100%">
                              <mat-form-field class="mog3_matinput lane-card-input" appearance="outline" [ngClass]="{'error-border': formItem.error}">
                                <input class="input-field" matInput placeholder="Select from contact book"
                                  (click)="contactInfo()" />
                                <svg (click)="contactInfo()" class="contact-icon" xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24" width="16" height="16">
                                  <path
                                    d="M7 2V22H3V2H7ZM9 2H19.0049C20.1068 2 21 2.89821 21 3.9908V20.0092C21 21.1087 20.1074 22 19.0049 22H9V2ZM22 6H24V10H22V6ZM22 12H24V16H22V12ZM15 12C16.1046 12 17 11.1046 17 10C17 8.89543 16.1046 8 15 8C13.8954 8 13 8.89543 13 10C13 11.1046 13.8954 12 15 12ZM12 16H18C18 14.3431 16.6569 13 15 13C13.3431 13 12 14.3431 12 16Z"
                                    fill="rgba(57,127,244,1)"></path>
                                </svg>
                              </mat-form-field>
                              <a class="red-anchor-2" (click)="addNewLead()"><span>Add Contact</span></a>
                              <div class="contact-detail-section">
                                <ul class="contact-detail-list">
                                  <ng-container *ngFor="let contact of displayContactList; let i = index">
                                    <li *ngIf="contact && i < 2">
                                      <ul>
                                        <li class="info-list">
                                          <div class="field-name">Name:</div>
                                          <div class="field-value">
                                            <span>{{ contact.displayName }}</span>
                                          </div>
                                        </li>
                                        <li class="info-list">
                                          <div class="field-name">Designation:</div>
                                          <div class="field-value">{{ contact.designation }}</div>
                                        </li>
                                        <br />

                                      </ul>
                                    </li>
                                  </ng-container>
                                </ul>
                                <div *ngIf="displayContactList.length === 0">
                                  <span>No Contact to display.</span>
                                </div>
                                <a (click)="contactInfo()" class="blue-anchor-2 mt-20">{{ displayContactList.length > 2 ?
                                  "View
                                  all " + displayContactList.length : "" }}</a>
                                <br />
                              </div>
                            </div>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="formItem.field == 'contact' && boardId === boardIdForSobOpportunity">
                          <div fxLayout="row" fxLayoutAlign="start space-around" style="width: 100%">
                            <div fxLayout="column" class="field-width" style="width: 100%">
                              <mat-form-field class="mog3_matinput lane-card-input" appearance="outline" >
                                <input class="input-field" matInput placeholder="Select from contact book"
                                  (click)="contactInfo()" />
                                <svg (click)="contactInfo()" class="contact-icon" xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24" width="16" height="16">
                                  <path
                                    d="M7 2V22H3V2H7ZM9 2H19.0049C20.1068 2 21 2.89821 21 3.9908V20.0092C21 21.1087 20.1074 22 19.0049 22H9V2ZM22 6H24V10H22V6ZM22 12H24V16H22V12ZM15 12C16.1046 12 17 11.1046 17 10C17 8.89543 16.1046 8 15 8C13.8954 8 13 8.89543 13 10C13 11.1046 13.8954 12 15 12ZM12 16H18C18 14.3431 16.6569 13 15 13C13.3431 13 12 14.3431 12 16Z"
                                    fill="rgba(57,127,244,1)"></path>
                                </svg>
                              </mat-form-field>
                              <a class="red-anchor-2" (click)="addNewLeadOppContact()"><span>Add Contact</span></a>
                              <div class="contact-detail-section">
                                <ul class="contact-detail-list">
                                  <ng-container *ngFor="let contact of displayContactList; let i = index">
                                    <li *ngIf="contact && i < 2">
                                      <!-- Display only the first two contacts -->
                                      <ul>
                                        <li class="info-list">
                                          <div class="field-name">Name:</div>
                                          <div class="field-value">
                                            <span>{{ contact.displayName }}</span>
                                            <!-- <a class="remove-anchor" (click)="removeContact(contact)">Remove</a> -->
                                          </div>
                                        </li>
                                        <li class="info-list">
                                          <div class="field-name">Designation:</div>
                                          <div class="field-value">{{ contact.designation }}</div>
                                        </li>
                                        <br />
                                        <!-- <li class="info-list">
                                          <div class="field-name">Email:</div>
                                          <div class="field-value">{{ contact.data[5].value }}</div>
                                        </li> -->
                                      </ul>
                                    </li>
                                  </ng-container>
                                </ul>
                                <div *ngIf="displayContactList.length === 0">
                                  <span>No Contact to display.</span>
                                </div>
                                <a (click)="contactInfo()" class="blue-anchor-2 mt-20">{{ displayContactList.length > 2 ?
                                  "View
                                  all " + displayContactList.length : "" }}</a>
                                <br />
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                      <div *ngSwitchCase="'textarea'" fxLayout="row" class="field-container">
                        <mat-form-field class="mog3_matinput lane-card-input" appearance="outline"
                          [ngClass]="{'error-border': formItem.error}">
                          <textarea matInput [(ngModel)]="formItem.value" rows="3">     </textarea>
                        </mat-form-field>
                        <!-- <span *ngIf="formItem.optional" class="mandatory-icon">*</span> -->
                      </div>
                      <div *ngSwitchCase="'form'" fxLayout="row" class="field-container">
                          <app-form-array-example class="w-full" [form]="formItem"></app-form-array-example>
                      </div>
                    </div>
                  </div>
                </li>
              </ng-container>
            </ng-container>
          </ul>
        </div>
      </section>
    </div>
  </div>
</div>