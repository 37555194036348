import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-account-reject',
  templateUrl: './account-reject.component.html',
  styleUrls: ['./account-reject.component.scss']
})
export class AccountRejectComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AccountRejectComponent>) { }

  ngOnInit() {
    
  }
  closeDialog() {
    this.dialogRef.close(false);
  }
}
