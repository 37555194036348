
<div class="input-group">
<mat-form-field appearance="outline" class="mat-select mat-select-search deep-0 app-custom-select selected-highlight">
  <mat-select  [panelClass]="'select-panel-class'" #s1 placeholder="{{label}}" multiple [(ngModel)]="selectedAccountIds">
    <mat-form-field class="mat-input icon" appearance="outline">
      <i class="ri-search-line"></i>
      <input type="text" placeholder="Search" matInput [(ngModel)]="userInput" (input)="onSearch()" (keydown)="onKeyDown($event)">
    </mat-form-field>
    <div class="select-options" (scroll)="onScroll($event)">


      <span class="select-label"  *ngIf="selectedAccountItems && selectedAccountItems.length > 0">
        <span class="select-label-text"> Selected </span>
      </span>

      <ng-container *ngIf="selectedAccountItems && selectedAccountItems.length > 0">
        <mat-option *ngFor="let selectedAccount of selectedAccountItems" (click)="unSelect(selectedAccount)" [value]="selectedAccount">
          {{ selectedAccount.displayName }}
        </mat-option>
      </ng-container>

      <span class="select-label"  *ngIf="selectedAccountItems && selectedAccountItems.length > 0">
        <span class="select-label-text"> Others </span>
      </span>

      <mat-option *ngFor="let account of accountIdsListSearch" (click)="selected(account)" [value]="account">{{ account.displayName }}</mat-option>
      <div *ngIf="loading" fxLayout="row" style="position: absolute; top: 75%; left:45%" fxLayoutAlign="center center">
        <mat-spinner strokeWidth="2" [diameter]="20" [color]="red"></mat-spinner>
      </div>
    </div>
    <mat-select-trigger>
      {{ label }} ({{ selectedAccountIds.length }})
    </mat-select-trigger>
    <div  class="select-footer">
      <button mat-button class="btn-basic btn-text-black mr-10" color="primary" (click)="clearSelections(); s1.close()">Clear</button>
      <button class="btn-primary" mat-raised-button color="warn" (click)="applySelections(); s1.close()">Apply</button>
    </div>
  </mat-select>
</mat-form-field>
</div>