import { Component, OnInit, Inject } from '@angular/core';
import { KanbanService } from '../../kanban.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import * as lodash from 'lodash';
import { ErrorToastComponent } from 'src/app/shared-files/error-toast/error-toast.component';
import { SuccessToastComponent } from 'src/app/shared-files/success-toast/success-toast.component';
import { MatSnackBar } from '@angular/material';
@Component({
  selector: 'app-add-new-lead',
  templateUrl: './add-new-lead.component.html',
  styleUrls: ['./add-new-lead.component.scss']
})
export class AddNewLeadComponent implements OnInit {
  public keyValueForm: FormGroup;
  boardLane = [];
  userList = [];
  laneTaskConfig = [];
  selectedLane: string;
  fetchLaneConfigById: string;
  selectedUserList = [];
  leadName: string;
  countryList=[];
  selectedBoardName:string
  _: any = lodash;
  customCountry: string;

  constructor(private _snackBar: MatSnackBar,public _kService: KanbanService, public toasterService: ToastrService, private formBuilder: FormBuilder, public dialogRef: MatDialogRef<AddNewLeadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data)
    const items = [];
    this.keyValueForm = this.formBuilder.group({
      details: this.formBuilder.array(items)
    });
    this.selectedBoardName= data.boardName
  }

  ngOnInit() {
    //this.fetchBoardLane();
  }

  fetchBoardLane() {
    this._kService.getLaneWithUserAccess(this.data.boardId,'').subscribe(res => {
      for (let i=0;i<res['result']['levelAccess'].length;i++){
        if(res['result']['levelAccess'][i].laneDTO.position==1){
          this.boardLane.push(res['result']['levelAccess'][i].laneDTO);
          this.selectedLane=res['result']['levelAccess'][i].laneDTO.name;
          this.fetchLaneConfigById = res['result']['levelAccess'][i].laneDTO.id;
        }
      }
      this.getLaneCofigFields();
    })
  }


  getLaneCofigFields() {
    this._kService.getLaneConfigById(this.fetchLaneConfigById).subscribe(res => {
      if (res['status'] == 200) {
        this.laneTaskConfig = res['result']['config'];
      }
    });
  }

  getselectedUserId(ids) {
    this.selectedUserList = ids;
  }

  customInfo
  addCustomFields() {
    this.customInfo = this.keyValueForm.get('details') as FormArray;
    this.customInfo.push(this.createItem());
  }
  createItem(): FormGroup {
    return this.formBuilder.group({
      key: [],
      value: []
    });
  }

  deleteCustomRow(index) {
    this.customInfo.removeAt(index)
  }
  shipmentAmount;
  shipmentCurrency;
  createNewLead() {
    if (this.leadName == null || this.leadName == '') {
      this._snackBar.openFromComponent(ErrorToastComponent, {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: ['atom-toast', 'error'],
        duration: 6000,
        data: "Please Enter Lead name"
      });

      // this.toasterService.error("Please Enter Lead name");
      return;
    }

    let obj =
    {
       "laneId": this.data.boardId,
      "name": this.leadName,
      "pinned": true,
      "leadSource":"SYSTEM"
    }
 
    this._kService.createLaneTask(obj).subscribe(res => {
      this._snackBar.openFromComponent(SuccessToastComponent, {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: ['atom-toast', 'success'],
        duration: 6000,
        data: "Details Submitted Successfully"
      });
    
      // this.toasterService.success("Details Submitted Successfully");
      this.dialogRef.close(true);
    });
  }
  cancelDialog() {
    this.dialogRef.close();
  }

  getmultipleBuyerRegion(event){

  }
  matchCurrentUser(){
    let currentUserID;
    let getSessionStorage = JSON.parse(localStorage.getItem("USERINFO"));
    if(getSessionStorage && getSessionStorage['role']){
      currentUserID = getSessionStorage['role']['userId'];
    }
    this.userList.map(data=>{
      if(data['userId'] == currentUserID){
        this.selectedUserList = [];
        this.selectedUserList.push(currentUserID);
      }
    })
  }
  getOwnerShipList = [];
  getCurrencyList = [];
  getIndustries = [];
  getDropdownData() {
    this._kService.getDropdowndata().subscribe(res => {
      this.getCurrencyList = res['currencies'];
      this.getIndustries = res['industries'];
      this.getOwnerShipList = res['type_of_ownership'];
      this.getOwnerShipList.push('Others');
      console.log(this.getOwnerShipList)
    })
  }
}
