

  <mat-form-field class="mat-select mat-select-search search-box deep-0 " [ngClass]="{'error-border': error}"  [ngClass]="{'selected-highlight': fieldData.selectedHighlight != false}" appearance="outline">

    <mat-select [panelClass]="'select-panel-class'" #sl22 [formControl]="textServerSideCtrl" [placeholder]="addData" [disabled]="fieldData.config.fieldEditable!=undefined && !fieldData.config.fieldEditable">
      <!-- place ngx-mat-select-search inside mat-option to allow opening without initial selection -->
      <div class="select-options">
        <ng-container *ngIf="isCreate">
          <mat-option [value]="addData">
            {{addData}}
          </mat-option>
        </ng-container>
    <ng-container *ngIf="!isCreate">
      <mat-option>
        <ngx-mat-select-search [formControl]="textServerSideFilteringCtrl"  [placeholderLabel]="addData" [searching]="searching" noEntriesFoundLabel="'No results found'">
          <mat-icon ngxMatSelectSearchClear (click)="clearFilter();sl22.close();" >close</mat-icon>
        </ngx-mat-select-search>
      </mat-option>
      <mat-option *ngFor="let bank of filteredServerSideTexts | async" [value]="bank"  (click)="selectAction(bank);sl22.close()">
        {{bank.name || bank.userName}}
      </mat-option>
    </ng-container>
      </div>
      <!-- <div *ngIf="!isCreate" fxLayout="row" fxLayoutAlign="flex-end space-between" class="filter-btn-section">
        <button class="filter-btn clear" (click)="clearFilter();sl22.close();">Clear</button>
        <button class="filter-btn clear" (click)="clearFilter();sl22.close();">
          <mat-icon class="material-icons" style="font-size: 14px; margin: 0;">close</mat-icon>
        </button>
      </div> -->
    </mat-select>
    <!-- <div *ngIf="searchText != '' && !isCreate" fxLayout="row" fxLayoutAlign="flex-end space-between" class="filter-btn-section">
      <button class="filter-btn apply" (click)="clearFilter();sl22.close();"><mat-icon class="material-icons" style="font-size: 14px; margin: 0;">close</mat-icon></button>
    </div> -->
  </mat-form-field>
  <div *ngIf="fieldData.config.relationKey == '63bfa8c3ade7bc466d1c780d' && (!fieldData || !fieldData.isFilter) && fieldData.selectedBoard != null && fieldData.selectedBoard != '63bfa8ccade7bc466d1c780e'"  fxLayout="row" fxLayoutAlign="flex-end">
    <div fxLayout="row"  >
    <span class="add-new-account" fxLayoutAlign="flex-end" >
      Don’t have an account ? &nbsp;
      <span class="add-new-account-btn" (click)="createNewAccount();sl22.close()"> Add New Account </span>
    </span>
  </div>
  </div>

