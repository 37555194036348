import { Component, OnInit, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { SettingService } from 'src/app/settings/setting.service';
import { FormBuilder } from '@angular/forms';
import { TopDealsComponent } from './top-deals/top-deals.component';
import { ViewChild } from '@angular/core';
import { StageTimeComponent } from './stage-time/stage-time.component';
import { HuntingOverviewComponent } from './hunting-overview/hunting-overview.component';
import { KanbanService } from '../../kanban.service';
import { FilteredDealsComponent } from './filtered-deals/filtered-deals.component';
import { MatDialog, ThemePalette } from '@angular/material';
import * as Highcharts from "highcharts";
import more from 'highcharts/highcharts-more';
import * as moment from 'moment';
import { DealFunnelModalComponent } from './deal-funnel-modal/deal-funnel-modal.component';
import { MatPaginator, MatSort, MatTableDataSource, PageEvent } from '@angular/material';
import { Router } from '@angular/router';
import { Constants, generateQuery } from 'src/app/Constants';
import { DropdownConstants } from 'src/utils/DropdownConstants';


more(Highcharts);

export interface Task {
  name: string;
  completed: boolean;
  color: ThemePalette;
  subtasks?: Task[];
}
 
@Component({
  selector: 'app-task-dashboard',
  templateUrl: './task-dashboard.component.html',
  styleUrls: ['./task-dashboard.component.scss']
})
export class TaskDashboardComponent implements OnInit {
  
  @ViewChild(TopDealsComponent) topDeals: TopDealsComponent;
  @ViewChild(StageTimeComponent) stageTime: StageTimeComponent;
  @ViewChild(HuntingOverviewComponent) huntungOverview: HuntingOverviewComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  teams: any;
  teamsSearch: any;
  allUserList: any;
  allUserListSearch: any;
  passOnData: any;
  huntingOverview: any;
  dormantDealsCount = 0;
  dormatDealSum = 0;
  dataSource1: Array<any> = [];
  dataSource2: Array<any> = [];
  dataSource3: Array<any> = [];
  dataSourceOnGoingActiveDeals: Array<any> = [];
  dataSourceOnMeetingOverview: Array<any> = [];
  displayedColumnsForHuntingVelocity: any[] = [];
  displayedColumns1: any[] = [];
  displayedColumnsNameList: any[] = [];
  displayedColumns: any[] = [
    { "field": "deal-name", "label": "Deal Name", "index": 1, "icon": "account" },
    { "field": "account-name", "label": "Account Name", "index": 1, "icon": "account" },
    { "field": "realistic-deal-size-cr", "label": "Realistic Deal Size(Cr)", "index": 1, "icon": "account" },
    { "field": "business-potential-in-cr", "label": "Business Potential", "index": 1, "icon": "account" },
    { "field": "stage", "label": "Stage", "index": 1, "icon": "account" },
    { "field": "deal-type", "label": "PO Type", "index": 1, "icon": "account" },
    { "field": "region-team", "label": "Region", "index": 1, "icon": "account" },
    // { "field": "closing-date", "label": "Closing Date", "index": 1, "icon": "account" }
  ];
  displayedColumnsSOB: any[] = [
    { "field": "deal-name", "label": "Opportunity", "index": 1, "icon": "account" },
    { "field": "account-name", "label": "Customer Name", "index": 1, "icon": "account" },
    { "field": "plant-name", "label": "Plant Name", "index": 1, "icon": "plant" },
    { "field": "po-type", "label": "PO Type(RFQ/ARC)", "index": 1, "icon": "account" },
    // { "field": "business-potential-in-cr", "label": "Business Potential", "index": 1, "icon": "account" },
    { "field": "deal-type", "label": "Deal Type", "index": 1, "icon": "account" },
    { "field": "stage", "label": "Stage", "index": 1, "icon": "account" },
    { "field": "new-categories", "label": "L1 Category", "index": 1, "icon": "account" },
    { "field": "opportunity-owner", "label": "KAM Name", "index": 1, "icon": "account" },
    { "field": "realistic-deal-size-cr", "label": "Incremental Deal Size(Cr)", "index": 1, "icon": "account" },
    // { "field": "region-team", "label": "Region", "index": 1, "icon": "account" },
    { "field": "closing-date", "label": "Closing Date", "index": 1, "icon": "account" }
  ]
  displayedColumnsForMeetingOverview: any[] = ["kam", "customerName", "plant", "meetingDone"]
  top5wonDealsColumns: Array<string> = ['DealName', 'CustomerName', 'DealSize', 'BusinessPotential', 'POType', 'Region', 'Closingdate'];
  top5LostDealsColumns: Array<string> = ['DealName', 'CustomerName', 'DealSize', 'BusinessPotential', 'Stage', 'POType', 'Region', 'Closingdate'];
  top5InProgressDealsColumns: Array<string> = ['DealName', 'CustomerName', 'DealSize', 'BusinessPotential', 'Stage', 'POType', 'Region', 'Closingdate'];
  resetDates: boolean = false;
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {
    chart : {}
  };
  updateFlag = false;
  oneToOneFlag = true;
  data = [];
  chartMap = new Map<number, number>();
  mappedValue: Map<String, Map<String, String>> = new Map();
  defaultBusinessVertical = [
    "MRO",
    "Packaging",
    "Credlix",
    "Custom Manufacturing",
    "Infra",
    "MaaS Fashion",
    "Mid Market",
    "Oil & Gas",
    "UAE",
    "Zoglix",
    "MaaS"
  ];

  defaultBusinessVerticalSearch = this.defaultBusinessVertical;
  selectedBusinessVertical = [];
  selectTeams = [];
  selectTeamsIds = [];
  selectedUsers = [];
  selectedUsersIds = [];
  inputData: any = {};
  localCache: any;
  iscreatedDate: any;
  stagemapforchart = new Map<number, string>();
  topWonTableDataSource: MatTableDataSource<Array<any>>;
  topLostTableDataSource: MatTableDataSource<Array<any>>;
  topOnGoingTableDataSource: MatTableDataSource<Array<any>>;
  meetingsOverviewDataSource: MatTableDataSource<Array<any>>;
  HuntingVelocityTableDataSource: MatTableDataSource<Array<any>>;
  from: any = 0;
  pagesize: any = 5;
  defaultPageSize: any = 5;
  topwondealcount: any;
  toplostdealcount: any;
  topOnGoingDealCount: any;
  topMeetingOverviewCount: any;
  crmBusinessId = Constants.businessIdForCRM;
  sobBusinessId = Constants.businessIdForSOB;
  switchBusiness: any;
  selectedBusinessId: any;
  searchControlBS = '';
  searchControlTeams = '';
  searchControlHunter = '';
  selectedUsersForVelocity = [];
  searchControlHunterForVelocity = '';
  allUserListSearchForVelocity: any;
  selectTeamsForVelocity = [];
  searchControlTeamsForVelocity = '';
  teamsSearchForVelocity: any;
  selectedBusinessVerticalForVelocity = [];
  searchControlBSForVelocity = '';
  defaultBusinessVerticalSearchForVelocity = DropdownConstants.BusinessFilter;
  selectedDealType;
  selectedStartingStage;
  isFreeze = false;
  selectedLastWeek;
  lastWeeksDropDown = [{ "value": "last 1 Week", "count": 1 },
  { "value": "last 2 Week", "count": 2 },
  { "value": "last 3 Week", "count": 3 },
  { "value": "last 4 Week", "count": 4 },
  { "value": "last 5 Week", "count": 5 },
  { "value": "last 6 Week", "count": 6 },
  { "value": "last 7 Week", "count": 7 },
  { "value": "last 8 Week", "count": 8 },
  { "value": "last 9 Week", "count": 9 },
  { "value": "last 10 Week", "count": 10 },
  { "value": "last 11 Week", "count": 11 },
  { "value": "last 12 Week", "count": 12 },
  { "value": "last 13 Week", "count": 13 },
  { "value": "last 14 Week", "count": 14 },
  { "value": "last 15 Week", "count": 15 }];
  boardIdForSobOpportunity = Constants.boardIdForSobOpportunity;
  displayedColumnsForHuntingVelocity1: any[] = [];
  boardId: any;
  stages: any;
  stagesForHunters = ['C0', 'C1', 'C2', 'C3', 'C4', 'PO Received/ ADP'];
  StagesForOpportunity = ["S0", "S1", "S2", "S3", "S4", "Deal Won"];
  businessVerticalLabel: string = 'Business Vertical';
  regionTeamLabel: string = 'Region/Team';
  hunterLabel: string = 'Hunters';
  kamLabel: string = 'KAM';
  teamQuery: any;
  bsQuery: any;
  dealtypeQuery: any;
  userQuery: any;
  resetFilter: any;
  teamFilter: any;
  closingDateQuery: any;
  createdDateQuery: any;
  lastUpdatedDateQuery: any;
  timeDurationDateQuery: any;
  isLoading: boolean = false;

  dataSource: Array<any> = [];  
  displayColumns: Array<string> = ['DealName', 'DealOwner','KAM','DealSize','BusinessPotential','StageAging','POType','Region','HandoverDate'];
  highlightedRows:any = '';

  constructor(public settingService: SettingService, public router: Router, private cd: ChangeDetectorRef, private fb: FormBuilder, private kanbanService: KanbanService, public dialog: MatDialog) {
    let tmpColumn = [];
    const columnsToIterate = this.boardId === Constants.boardIdForSobOpportunity ? this.displayedColumnsSOB : this.displayedColumns;

    for (let k = 0; k < columnsToIterate.length; k++) {
      tmpColumn.push(columnsToIterate[k].field);
    }
    this.displayedColumns1 = tmpColumn;
    this.stages = this.stagesForHunters;
  }

  ngOnInit() {
    this.switchBusiness = this.settingService._ManagementData.subscribe(res => {
      console.log("ngOnInit res", res);
      // Remove board id from local storage on switching business
      if (res && res.length > 0) {
        if (localStorage.getItem('selected_business') !== res) {
          localStorage.removeItem('lane_board');
          localStorage.removeItem('selected_lane');
        }
        localStorage.setItem('selected_business', res);
      } else {
        res = localStorage.getItem('selected_business');
        localStorage.removeItem('lane_board');
        localStorage.removeItem('selected_lane');
        localStorage.removeItem('selected_business');
      }
      if (res === 'SOB Management') {
        this.selectedBusinessId = this.sobBusinessId;
        localStorage.setItem('selectedBusinessId', this.selectedBusinessId);
        localStorage.setItem('selected_business', "SOB Management");
        this.boardId = Constants.boardIdForSobOpportunity;
        this.stages = this.StagesForOpportunity;
        this.getMeetingsOverViewUsingPlants();
        this.top5OnGoingDeal();
      } else if (res === 'CRM Management') {
        this.selectedBusinessId = this.crmBusinessId;
        localStorage.setItem('selectedBusinessId', this.selectedBusinessId);
        localStorage.setItem('selected_business', "CRM Management");
        this.boardId = Constants.boardIdForCRMDeals;
        this.stages = this.stagesForHunters;
      }

      // Load teams and dashboard data
      this.loadTeams();
      this.getDashBoardData();
      this.top5wonDeal();
      this.top5LosDeal();
      this.dormantDeals();

      // Set passOnData object
      this.passOnData = {
        "teams": this.teams,
        "users": this.allUserList,
        "isDeals": true,
      };

      this.selectedLastWeek = 5;

      // Set stagemapforchart based on boardId
      if (this.boardId === this.boardIdForSobOpportunity) {
        this.setStagemapForChart("S", "Deal Won", this.displayedColumnsSOB);
      } else {
        this.setStagemapForChart("C", "PO Received/ ADP", this.displayedColumnsNameList);
        this.onApplyForVelocity();
      }
    });
    this.intializeChart();
    this.getPicksList(); 

  }

  setStagemapForChart(prefix: string, lastStage: string, columnsToIterate: any[]) {
    for (let i = 0; i < columnsToIterate.length; i++) {
      this.stagemapforchart.set(i, `${prefix}${i}`);
    }
    this.stagemapforchart.set(columnsToIterate.length, lastStage);
    this.displayedColumnsNameList = columnsToIterate.map(column => column.field);
  }


  ngOnDestroy() {
    this.switchBusiness.unsubscribe();
  }
  loadTeams() {
    this.settingService.fetchTeams().subscribe(response => {
      this.teams = response['result'];
      this.teamsSearch = this.teams;
      this.teamsSearchForVelocity = this.teams;
      this.loadUsers();
    });
  }
  loadUsers() {
    let obj = {};
    obj['from'] = 0;
    obj['size'] = 100;
    obj['businessId'] = this.selectedBusinessId;
    this.settingService.getUserDetailsForOwnerDropDown(obj).subscribe(list => {
      let userList = [];
      for (let i = 0; i < list['result'].length; i++) {
        let obj = list['result'][i];
        let tmp = list['result'][i].userTeams;
        let teamList = [];
        if (tmp) {
          for (let k = 0; k < tmp.length; k++) {
            teamList.push(tmp[k].id);
          }
          obj.userTeams = teamList;
          userList.push(obj)
        } else {
          obj.userTeams = [];
          userList.push(obj)
        }
      }
      this.allUserList = userList;
      this.allUserListSearch = this.allUserList;
      this.allUserListSearchForVelocity = this.allUserList;
      if (this.allUserList != null && this.teams != null) {
        this.passOnData = {
          "teams": this.teams,
          "users": this.allUserList
        }
      }
    });
  }
  onFilterApply($event) {
    if ($event && $event['resetFilter']) {
      this.teamFilter = null;
      this.bsQuery = null;
      this.userQuery = null;
      this.teamQuery = null;
      this.closingDateQuery = null;
      this.createdDateQuery = null;
      this.lastUpdatedDateQuery = null;
      this.passOnData = {
        "teams": this.teams,
        "users": this.allUserList,
        "team_query": this.teamQuery,
        "user_query": this.userQuery,
        "bs_query": this.bsQuery,
        "dealtype_query": this.dealtypeQuery,
        "createdDateQuery": this.createdDateQuery,
        "closingDateQuery": this.closingDateQuery
      }
    } else {
      if ($event && $event['teamFilter'] && $event['teamFilter'].length) {
        let tFiler = $event['teamFilter'];
        this.teamFilter = tFiler;
        this.teamQuery = $event['teamQuery']['query']['nestedWhere']["team_query"];
      }
      if ($event && $event['bsQuery']) {
        this.bsQuery = $event['bsQuery']['query']['nestedWhere']["bs_query"];
      }
      if ($event && $event['dealtypeQuery']) {
        this.bsQuery = $event['dealtypeQuery']['query']['nestedWhere']["dealtype_query"];
      }
      if ($event && $event['userQuery']) {
        this.userQuery = $event['userQuery']['query']['where']["user_query"];
      }
      if ($event && $event['closingDateQuery']) {
        this.closingDateQuery = $event['closingDateQuery'];
      }
      if ($event && $event['createdDateQuery']) {
        this.createdDateQuery = $event['createdDateQuery'];
      }
      if ($event && $event['lastUpdatedDateQuery']) {
        this.lastUpdatedDateQuery = $event['lastUpdatedDateQuery'];
      }
      this.passOnData = {
        "teams": this.teams,
        "users": this.allUserList,
        "team_query": this.teamQuery,
        "user_query": this.userQuery,
        "bs_query": this.bsQuery,
        "dealtype_query": this.dealtypeQuery,
        "closingDateQuery": this.closingDateQuery,
        "createdDateQuery": this.createdDateQuery
      }
    }
    this.getDashBoardData();
    this.top5wonDeal();
    this.top5LosDeal();
    this.dormantDeals();
  }
  filterOptionsBVerticalHunting(event) {
    console.log("bv ", event, this.defaultBusinessVertical)
    this.defaultBusinessVerticalSearch = this.defaultBusinessVertical;
    const filterValue = event.target.value.toLowerCase();
    this.defaultBusinessVerticalSearch = this.defaultBusinessVertical.filter(option => (option).toLowerCase().includes(filterValue));
  }

  filterOptionsVertical(event) {
    console.log("bv ", event, this.defaultBusinessVertical)
    this.defaultBusinessVerticalSearch = this.defaultBusinessVertical;
    const filterValue = event.toLowerCase();
    this.defaultBusinessVerticalSearch = this.defaultBusinessVertical.filter(option => (option).toLowerCase().includes(filterValue));
  }
  filterOptionsVerticalForVelocity(event) {
    this.defaultBusinessVerticalSearchForVelocity = DropdownConstants.BusinessFilter;
    const filterValue = event.target.value.toLowerCase();
    this.defaultBusinessVerticalSearchForVelocity = (DropdownConstants.BusinessFilter).filter(option => (option).toLowerCase().includes(filterValue));
  }

  filterOptionsTeamsHunting(event) {
    this.teamsSearch = this.teams;
    const filterValue = event.target.value.toLowerCase();
    this.teamsSearch = this.teams.filter(option => (option.name).toLowerCase().includes(filterValue));
  }

  filterOptionsTeams(event) {
    this.teamsSearch = this.teams;
    const filterValue = event.toLowerCase();
    this.teamsSearch = this.teams.filter(option => (option.name).toLowerCase().includes(filterValue));
  }
  filterOptionsTeamsForVelocity(event) {
    this.teamsSearchForVelocity = this.teams;
    const filterValue = event.target.value.toLowerCase();
    this.teamsSearchForVelocity = this.teams.filter(option => (option.name).toLowerCase().includes(filterValue));
  }
  filterOptionsHunting(event) {
    this.allUserListSearch = this.allUserList;
    const filterValue = event.target.value.toLowerCase();
    this.allUserListSearch = this.allUserList.filter(option => (option.name).toLowerCase().includes(filterValue));
  }
  
  filterOptions(event) {
    this.allUserListSearch = this.allUserList;
    const filterValue = event.toLowerCase();
    this.allUserListSearch = this.allUserList.filter(option => (option.name).toLowerCase().includes(filterValue));
  }
  filterOptionsForVelocity(event) {
    this.allUserListSearchForVelocity = this.allUserList;
    const filterValue = event.target.value.toLowerCase();
    this.allUserListSearchForVelocity = this.allUserList.filter(option => (option.name).toLowerCase().includes(filterValue));
  }

  getDashBoardData() {
    let query = {
      "query": {
        "where": {
          "boardId": {
            "type": "search",
            "field": "boardId.raw",
            "value": this.boardId,
          }
        }
      },
      "size": 10,
      "type": "TOP_DEALS_BY_CLOSING_DATE"
    };
    if (this.teamQuery) {
      if (!query['query']['nestedWhere']) {
        query['query']['nestedWhere'] = {};
      }
      query['query']['nestedWhere']["team_query"] = this.teamQuery;
    }
    if (this.bsQuery) {
      if (!query['query']['nestedWhere']) {
        query['query']['nestedWhere'] = {};
      }
      query['query']['nestedWhere']["bs_query"] = this.bsQuery;
    }
    if (this.userQuery) {
      query['query']['where']["user_query"] = this.userQuery;
    }
    if (this.createdDateQuery) {
      query['query']['where']["createdAt"] = this.createdDateQuery['createdAt'];
    }
    if (this.lastUpdatedDateQuery) {
      query['query']['where']["updatedAt"] = this.lastUpdatedDateQuery['updatedAt'];
    }
    if (this.closingDateQuery) {
      if (!query['query']['nestedWhere']) {
        query['query']['nestedWhere'] = {};
      }
      query['query']['nestedWhere']['data.closingdate'] = this.closingDateQuery['data.closingdate'];
    }
    this.kanbanService.getDashboardStats(query).subscribe((response) => {
      if (response && response['result']) {
        this.huntingOverview = response['result']['termsAgg'];
      }
      this.data = [];
      if (this.huntingOverview.length > 0) {
        this.mappedValue = new Map();
        for (let i = 0; i < this.huntingOverview.length; i++) {
          let count = this.huntingOverview[i].count
          let countInt = parseInt(this.huntingOverview[i].count)
          let key = this.huntingOverview[i].key
          let sum = this.huntingOverview[i].sum
          let map = new Map<String, String>();
          map.set('sum', sum);
          map.set('count', count);
          this.mappedValue.set(key, map);
        }
      } else {
        this.mappedValue = new Map();
      }
      if (this.boardId === this.boardIdForSobOpportunity) {
        this.data = [this.getCountBykeyAsInt('S0'), this.getCountBykeyAsInt('S1'), this.getCountBykeyAsInt('S2'), this.getCountBykeyAsInt('S3'),
        this.getCountBykeyAsInt('S4'), this.getCountBykeyAsInt('Deal Won')];
      }
      else {
        this.data = [this.getCountBykeyAsInt('C0'), this.getCountBykeyAsInt('C1'), this.getCountBykeyAsInt('C2'), this.getCountBykeyAsInt('C3'),
        this.getCountBykeyAsInt('C4'), this.getCountBykeyAsInt('PO Received/ ADP')];
      }
      this.intializeChart();
    });
  }
  getSumBykey(key) {
    let sum = "0";
    if (this.mappedValue.get(key) && this.mappedValue.get(key).get('sum'))
      sum = this.mappedValue.get(key).get('sum').valueOf();
    return sum;
  }
  getCountBykey(key) {
    let count = "0";
    if (this.mappedValue.get(key) && this.mappedValue.get(key).get('count'))
      count = this.mappedValue.get(key).get('count').valueOf();
    return count;
  }
  getCountBykeyAsInt(key) {
    let count = "0";
    if (this.mappedValue.get(key) && this.mappedValue.get(key).get('count'))
      count = this.mappedValue.get(key).get('count').valueOf();
    return parseInt(count);
  }

  drillDown($event) {
    if ($event && $event['search']) {
      const dialogRef = this.dialog.open(FilteredDealsComponent, {
        disableClose: false,
        width: '70%',
        height: '80%',
        data: {
          "search": $event['search'],
          "userQuery": this.userQuery,
          "bsQuery": this.bsQuery,
          "teamQuery": this.teamQuery,
          "createdDateQuery": this.createdDateQuery,
          "closingDateQuery": this.closingDateQuery,
          "tabFilterQuery": $event['tabFilterQuery']
        }
      });
    }
  }
  top5wonDeal() {
    let wonDealId = null;
    if (this.boardId === this.boardIdForSobOpportunity) {
      wonDealId = Constants.dealWon
    }
    else {
      wonDealId = Constants.poRecievedLaneId;
    }
    let query = {
      "query": {
        "where": {
          "boardId": {
            "type": "search",
            "operator": "or",
            "value": [
              {
                "field": "boardId.raw",
                "value": this.boardId
              }
            ]
          },
          "laneId.raw": {
            "type": "search",
            "field": "laneId.raw",
            "operator": "or",
            "value": [
              {
                "field": "laneId.raw",
                "value": wonDealId
              }
            ]
          },
          "laneIdQuery": {
            "type": "search",
            "operator": "or",
            "value": [
              {
                "field": "laneId.raw",
                "value": wonDealId
              }
            ]
          }
        },
        "nestedWhere": {},
        "from": this.from,
        "size": this.pagesize,
        "nested_sort": [
          {
            "field": "data.realisticdealsizecr",
            "order": "desc"
          }
        ]
      }
    };
    if (this.teamQuery) {
      if (!query['query']['nestedWhere']) {
        query['query']['nestedWhere'] = {};
      }
      query['query']['nestedWhere']["team_query"] = this.teamQuery;
    }
    if (this.bsQuery) {
      if (!query['query']['nestedWhere']) {
        query['query']['nestedWhere'] = {};
      }
      query['query']['nestedWhere']["bs_query"] = this.bsQuery;
    }
    if (this.userQuery) {
      query['query']['where']["user_query"] = this.userQuery;
    }
    if (this.createdDateQuery) {
      query['query']['where']["createdAt"] = this.createdDateQuery['createdAt'];
    }
    if (this.closingDateQuery) {
      if (!query['query']['nestedWhere']) {
        query['query']['nestedWhere'] = {};
      }
      query['query']['nestedWhere']['data.closingdate'] = this.closingDateQuery['data.closingdate'];
    }
    if (this.lastUpdatedDateQuery) {
      query['query']['where']["updatedAt"] = this.lastUpdatedDateQuery['updatedAt'];
    }
    this.isLoading = true;
    this.kanbanService.getLaneTask(query['query']).subscribe((response) => {
      this.isLoading = false;
      if (response && response['result']) {
        this.dataSource1 = response['result'];
        this.topwondealcount = response['total'];
        this.topWonTableDataSource = new MatTableDataSource(this.dataSource1);
      }
    });
  }
  top5OnGoingDeal() {
    let onGoingDealId = null;
    if (this.boardId === this.boardIdForSobOpportunity) {
      onGoingDealId = this.getAllActiveDealQueryOpportunity();
    }
    else {
      onGoingDealId = this.getAllActiveDealQueryHunter();
    }
    const query = generateQuery(this.boardId, onGoingDealId, this.from, this.pagesize);
    query.query.where = {
      boardId: {
        type: "search",
        operator: "or",
        value: [
          {
            field: "boardId.raw",
            value: this.boardId
          }
        ]
      },
      "laneId.raw": {
        type: "search",
        operator: "or",
        value: onGoingDealId,
      }
    }
    query.query.where.laneIdQuery = {
      type: "search",
      operator: "or",
      value: onGoingDealId,
    };


    if (this.teamQuery) {
      if (!query['query']['nestedWhere']) {
        query['query']['nestedWhere'] = {};
      }
      query['query']['nestedWhere']["team_query"] = this.teamQuery;
    }
    if (this.bsQuery) {
      if (!query['query']['nestedWhere']) {
        query['query']['nestedWhere'] = {};
      }
      query['query']['nestedWhere']["bs_query"] = this.bsQuery;
    }
    if (this.userQuery) {
      query['query']['where']["user_query"] = this.userQuery;
    }
    if (this.createdDateQuery) {
      query['query']['where']["createdAt"] = this.createdDateQuery['createdAt'];
    }
    if (this.closingDateQuery) {
      if (!query['query']['nestedWhere']) {
        query['query']['nestedWhere'] = {};
      }
      query['query']['nestedWhere']['data.closingdate'] = this.closingDateQuery['data.closingdate'];
    }
    if (this.lastUpdatedDateQuery) {
      query['query']['where']["updatedAt"] = this.lastUpdatedDateQuery['updatedAt'];
    }
    this.isLoading = true;
    this.kanbanService.getLaneTask(query['query']).subscribe((response) => {
      this.isLoading = false;
      if (response && response['result']) {
        this.dataSourceOnGoingActiveDeals = response['result'];
        this.topOnGoingDealCount = response['total'];
        this.topOnGoingTableDataSource = new MatTableDataSource(this.dataSourceOnGoingActiveDeals);
      }
    });
  }
  top5LosDeal() {
    let lostDealId = null;
    if (this.boardId === this.boardIdForSobOpportunity) {
      lostDealId = Constants.lostDeal
    }
    else {
      lostDealId = Constants.lostDealLaneId;
    }
    let query = {
      "query": {
        "where": {
          "boardId": {
            "type": "search",
            "operator": "or",
            "value": [
              {
                "field": "boardId.raw",
                "value": this.boardId
              }
            ]
          },
          "laneId.raw": {
            "type": "search",
            "field": "laneId.raw",
            "operator": "or",
            "value": [
              {
                "field": "laneId.raw",
                "value": lostDealId
              }
            ]
          },
          "laneIdQuery": {
            "type": "search",
            "operator": "or",
            "value": [
              {
                "field": "laneId.raw",
                "value": lostDealId
              }
            ]
          }
        },
        "nestedWhere": {},
        "from": this.from,
        "size": this.pagesize,
        "nested_sort": [
          {
            "field": "data.realisticdealsizecr",
            "order": "desc"
          }
        ]
      }
    };
    if (this.teamQuery) {
      if (!query['query']['nestedWhere']) {
        query['query']['nestedWhere'] = {};
      }
      query['query']['nestedWhere']["team_query"] = this.teamQuery;
    }
    if (this.bsQuery) {
      if (!query['query']['nestedWhere']) {
        query['query']['nestedWhere'] = {};
      }
      query['query']['nestedWhere']["bs_query"] = this.bsQuery;
    }
    if (this.userQuery) {
      query['query']['where']["user_query"] = this.userQuery;
    }
    if (this.createdDateQuery) {
      query['query']['where']["createdAt"] = this.createdDateQuery['createdAt'];
    }
    if (this.closingDateQuery) {
      if (!query['query']['nestedWhere']) {
        query['query']['nestedWhere'] = {};
      }
      query['query']['nestedWhere']['data.closingdate'] = this.closingDateQuery['data.closingdate'];
    }
    if (this.lastUpdatedDateQuery) {
      query['query']['where']["updatedAt"] = this.lastUpdatedDateQuery['updatedAt'];
    }
    this.isLoading = true;
    this.kanbanService.getLaneTask(query['query']).subscribe((response) => {
      this.isLoading = false;
      if (response && response['result']) {
        this.dataSource2 = response['result'];
        this.toplostdealcount = response['total'];
        this.topLostTableDataSource = new MatTableDataSource(this.dataSource2);
      }
    });

  }

  getMeetingsOverViewUsingPlants() {
    const query = generateQuery(Constants.boardIdForSobPlant, Constants.laneIdForSOBPlant, this.from, this.pagesize);
    delete query.query['nested_sort']
    query.query.sort = {
      field: "updatedAt",
      order: "desc"
    }
    this.kanbanService.getLaneTask(query['query']).subscribe((response) => {
      if (response && response['result']) {
        this.dataSourceOnMeetingOverview = response['result'];
        this.topMeetingOverviewCount = response['total'];
        this.meetingsOverviewDataSource = new MatTableDataSource(this.dataSourceOnMeetingOverview);
      }
    });
  }
  onPageChange(event: PageEvent, tab) {
    // Perform any action you want when the pagination changes
    console.log('Page event:', event, tab);
    // You can access properties like event.pageIndex, event.pageSize, etc. and update your data accordingly
    this.from = event.pageIndex * this.pagesize;
    this.pagesize = event.pageSize;
    if (tab === 'won') {
      this.top5wonDeal();
    } else if (tab === 'lost') {
      this.top5LosDeal();
    } else if (tab === 'ongoingSob') {
      this.top5OnGoingDeal();
    } else if (tab === 'meetingOverView') {
      this.getMeetingsOverViewUsingPlants();
    }
  }
  tabChanged(event) {
    console.log("pageSize");
    this.from = 0;
    this.pagesize = this.defaultPageSize;
    this.paginator.pageSize = 5;
    this.paginator.pageIndex = 0;
    if (this.boardIdForSobOpportunity === this.boardId) {
      switch (event.index) {
        case 0:
          this.top5wonDeal();
          break;
        case 1:
          this.top5LosDeal();
          break;
        case 2:
          this.top5OnGoingDeal();
          break;
        case 3:
          this.getMeetingsOverViewUsingPlants();
          break;
      }
    }
    else {
      switch (event.index) {
        case 0:
          this.top5wonDeal();
          break;
        case 1:
          this.top5LosDeal();
          break;
      }
    }

  }

  top5InProgressDeal() {
    this.dataSource3 = [
      { DealName: 'TrMT', CustomerName: 'Tata Metaliks Limited', DealSize: '76 Cr', BusinessPotential: '200 Cr', Stage: 'WON', POType: 'RFQ', Region: 'N1', Reason: '15 April 2023', Closingdate: '15 April 2023' },
      { DealName: 'TMT', CustomerName: 'Tata Metaliks Limited', DealSize: '76 Cr', BusinessPotential: '200 Cr', Stage: 'WON', POType: 'RFQ', Region: 'N1', Reason: '15 April 2023', Closingdate: '15 April 2023' },
      { DealName: 'TMT', CustomerName: 'Tata Metaliks Limited', DealSize: '76 Cr', BusinessPotential: '200 Cr', Stage: 'WON', POType: 'RFQ', Region: 'N1', Reason: '15 April 2023', Closingdate: '15 April 2023' },
      { DealName: 'TMT', CustomerName: 'Tata Metaliks Limited', DealSize: '76 Cr', BusinessPotential: '200 Cr', Stage: 'WON', POType: 'RFQ', Region: 'N1', Reason: '15 April 2023', Closingdate: '15 April 2023' },
      { DealName: 'TMT', CustomerName: 'Tata Metaliks Limited', DealSize: '76 Cr', BusinessPotential: '200 Cr', Stage: 'WON', POType: 'RFQ', Region: 'N1', Reason: '15 April 2023', Closingdate: '15 April 2023' },
      { DealName: 'TMT', CustomerName: 'Tata Metaliks Limited', DealSize: '76 Cr', BusinessPotential: '200 Cr', Stage: 'WON', POType: 'RFQ', Region: 'N1', Reason: '15 April 2023', Closingdate: '15 April 2023' },
      { DealName: 'TMT', CustomerName: 'Tata Metaliks Limited', DealSize: '76 Cr', BusinessPotential: '200 Cr', Stage: 'WON', POType: 'RFQ', Region: 'N1', Reason: '15 April 2023', Closingdate: '15 April 2023' },
      { DealName: 'TMT', CustomerName: 'Tata Metaliks Limited', DealSize: '76 Cr', BusinessPotential: '200 Cr', Stage: 'WON', POType: 'RFQ', Region: 'N1', Reason: '15 April 2023', Closingdate: '15 April 2023' },
      { DealName: 'TMT', CustomerName: 'Tata Metaliks Limited', DealSize: '76 Cr', BusinessPotential: '200 Cr', Stage: 'WON', POType: 'RFQ', Region: 'N1', Reason: '15 April 2023', Closingdate: '15 April 2023' },

    ];
  }

  dormantDeals() {
    let laneIdList = null;
    if (this.boardId === this.boardIdForSobOpportunity) {
      laneIdList = this.getAllActiveDealQueryOpportunity();
    }
    else {
      laneIdList = this.getAllActiveDealQueryHunter();
    }

    let query = {
      "query": {
        "where": {
          "boardId": {
            "type": "search",
            "operator": "or",
            "value": [
              {
                "field": "boardId.raw",
                "value": this.boardId
              }
            ]
          },
          "laneId.raw": {
            "type": "search",
            "field": "laneId.raw",
            "operator": "or",
            "value": laneIdList
          },
          "laneIdQuery": {
            "type": "search",
            "operator": "or",
            "value": laneIdList
          },
          "updatedAt": {
            "field": "updatedAt",
            "type": "range",
            "start_value": "2020-04-22T18:30:00.000Z",
            "end_value": moment().subtract(30, 'days').toISOString()
          }
        },
        "nestedWhere": {},
        "nested_sort": [
          {
            "field": "data.realisticdealsizecr",
            "order": "desc"
          }
        ]
      },
      "from": 0,
      "size": 5,
      "type": "TOP_DEALS_BY_CLOSING_DATE"
    };
    if (this.teamQuery) {
      if (!query['query']['nestedWhere']) {
        query['query']['nestedWhere'] = {};
      }
      query['query']['nestedWhere']["team_query"] = this.teamQuery;
    }
    if (this.bsQuery) {
      if (!query['query']['nestedWhere']) {
        query['query']['nestedWhere'] = {};
      }
      query['query']['nestedWhere']["bs_query"] = this.bsQuery;
    }
    if (this.userQuery) {
      query['query']['where']["user_query"] = this.userQuery;
    }
    if (this.createdDateQuery) {
      query['query']['where']["createdAt"] = this.createdDateQuery['createdAt'];
    }
    if (this.closingDateQuery) {
      if (!query['query']['nestedWhere']) {
        query['query']['nestedWhere'] = {};
      }
      query['query']['nestedWhere']['data.closingdate'] = this.closingDateQuery['data.closingdate'];
    }
    // if (this.lastUpdatedDateQuery) {
    //   query['query']['where']["updatedAt"] = this.lastUpdatedDateQuery['updatedAt'];
    // }
    this.kanbanService.getDashboardStats(query).subscribe((response) => {
      if (response && response['result']) {
        if (response['result']['total']) {
          this.dormantDealsCount = response['result']['total'];
        } else {
          this.dormantDealsCount = 0;
        }
        if (response['result']['termsAgg']) {
          this.dormatDealSum = 0;
          let array = response['result']['termsAgg'];
          for (let i = 0; i < array.length; i++) {
            this.dormatDealSum = this.dormatDealSum + array[i].sum;
          }
        } else {
          this.dormatDealSum = 0;
        }
      }

    });


  }
  getAllActiveDealQueryHunter() {
    const opportunityConstants = [
      Constants.C0,
      Constants.C1,
      Constants.C2,
      Constants.C3,
      Constants.C4
    ];

    return opportunityConstants.map(value => ({
      "field": "laneId.raw",
      "value": value
    }));
  }
  getAllActiveDealQueryOpportunity() {
    const opportunityConstants = [
      Constants.S0,
      Constants.S1,
      Constants.S2,
      Constants.S3,
      Constants.S4
    ];

    return opportunityConstants.map(value => ({
      "field": "laneId.raw",
      "value": value
    }));
  }
  parseData(data) {

    const parsedData = []
    let n = 0;
    data.forEach(value => {
      parsedData.push([n, -(value / 2), value / 2])
      n++;
    })

    return parsedData

  }
  intializeChart() {
    let _self = this;
    this.chartOptions = {
      colors: ['#FFC000', '#ED7D31', '#70AD47', '#5B9BD5', '#5B64D5', '#D3D3D3','#2ACB99'],
      chart: {
        type: 'columnrange',
        inverted: true,
        // height: 350
      },
      title: {
        text: null
      },
      credits: {
        enabled: false
      },
      xAxis: {
       
        visible: false
      },
      yAxis: {
        visible: false,
        gridLineWidth: 0,
        labels: {
          enabled: false
        },
        title: null,
      },
      plotOptions: {
        columnrange: {
          colorByPoint: true,
          dataLabels: {
            enabled: true,
            align: 'center',
            inside: true,
            formatter: function () {
              return Highcharts.numberFormat(this.y * 2, 0, '.', ',');
            }
          }
        },

  
      },
      legend: {
        enabled: false
      },
      series: [{
        name: '',
        data: this.parseData(this.data),
        type: 'columnrange',
        tooltip: {
          headerFormat: '<span style="font-size: 10px"></span><br/>',
          pointFormatter: function () {
            let value: any = this.options;
            let count = _self.getCountBykey(_self.stagemapforchart.get(value.x))
            return `<table class="tooltipTable">
              <tr>
                <td style="padding:0">
                  <b>
                    <span style="color:#363636;font-weight: 500;padding:0">Total ${count} <span>
                  </b>
                </td>
              </tr>
              <br>`
          },
          footerFormat: '</table>',
        },
        pointPadding: 0,
        groupPadding: 0.1
      }],
    }
  }

  getFieldValue(ele, field) {
    let val = "N/A";
    if (ele) {
      if (field === 'stage') {
        if (ele.lane.displayName === 'PO Received/ ADP') {
          return "WON";
        }
        else if (ele.lane.displayName === 'Lost Deals') {
          return "LOST";
        } else {
          return ele.lane.displayName;
        }
      }
      let laneData = ele['data'];
      if (laneData != null) {
        let laneField = laneData.find((ele) => {
          if (ele['field'] == field) {
            return ele;
          }
        });
        if (laneField && (laneField.type == "string" || laneField.type == "number" || laneField.type == "email" || laneField.type == "phone" || laneField.type == "textarea" ||
          laneField.type == "currency")) {
          val = laneField.value;
        } else if (laneField && (laneField.type == "select")) {
          if ((laneField.key == "string" || laneField.key == "number")) {
            val = laneField.value;
          } else {
            //let searchType = laneField.key == "user" ? "user" : "laneTask";
            val = laneField.additionalData != null ? laneField.additionalData.name : "N/A";
          }
        } else if (laneField && (laneField.type == "belongsTo")) {
          val = laneField.additionalData != null ? laneField.additionalData.name : "N/A";
        } else if (laneField && laneField.type == "date") {
          if (laneField.value && laneField.value.trim() !== "") {
            const date = new Date(laneField.value);
          
            const options: Intl.DateTimeFormatOptions = {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
            };
          
            val = date.toLocaleDateString('en-IN', options);
          }
               
        }
      }
    }
    if (!val) {
      val = "-";
    }
    return val;
  }
  resetBSInCache() {
    this.bsQuery = null;
    this.searchControlBS = '';
    this.defaultBusinessVerticalSearch = this.defaultBusinessVertical;
  }
  resetBSInCacheForVelocity() {
    this.searchControlBSForVelocity = '';
    this.defaultBusinessVerticalSearchForVelocity = DropdownConstants.BusinessFilter;
  }
  resetUserFilterInCache() {
    this.userQuery = null;
    this.searchControlHunter = '';
    this.allUserListSearch = this.allUserList;
  }
  resetUserFilterInCacheForVelocity() {
    this.searchControlHunterForVelocity = '';
    this.allUserListSearchForVelocity = this.allUserList;
  }
  clearAll() {
    this.inputData = [];
    this.inputData['resetFilter'] = true;
    this.resetDates = true;
    this.selectedUsersIds = [];
    this.selectedUsers = [];
    this.resetUserFilterInCache();
    this.selectTeams = [];
    this.selectTeamsIds = [];
    this.resetTeamFilterInCache();
    this.selectedBusinessVertical = [];
    this.resetBSInCache();
    this.closingDateQuery = null;
    this.createdDateQuery = null;
    this.lastUpdatedDateQuery = null;
    this.onFilterApply(this.inputData);
  }
  clearAllForVelocity() {
    this.selectedStartingStage = null;
    this.isFreeze = false;
    this.displayedColumnsForHuntingVelocity = [];
    this.displayedColumnsForHuntingVelocity1 = [];
    this.selectedUsersForVelocity = [];
    this.selectTeamsForVelocity = [];
    this.selectedBusinessVerticalForVelocity = [];
    this.selectedDealType = null;
    this.selectedLastWeek = 5;
    this.resetUserFilterInCacheForVelocity();
    this.resetBSInCacheForVelocity();
    this.resetTeamFilterInCacheForVelocity();
    this.onApplyForVelocity();
  }
  onApply = () => {
    if (this.closingDateQuery) {
      let query = {
        "query": {
          "nestedWhere": {}
        }
      };
      query['query']['nestedWhere'] = this.closingDateQuery;
      this.inputData['closingDateQuery'] = this.closingDateQuery;
    } else {
      this.inputData['closingDateQuery'] = null;
    }
    if (this.createdDateQuery) {
      let query = {
        "query": {
          "where": {}
        }
      };
      query['query']['where'] = this.createdDateQuery;
      this.inputData['createdDateQuery'] = this.createdDateQuery;
    } else {
      this.inputData['createdDateQuery'] = null;
    }
    if (this.lastUpdatedDateQuery) {
      let query = {
        "query": {
          "where": {}
        }
      };
      query['query']['where'] = this.lastUpdatedDateQuery;
      this.inputData['lastUpdatedDateQuery'] = this.lastUpdatedDateQuery;
    } else {
      this.inputData['lastUpdatedDateQuery'] = null;
    }
    if (this.selectTeamsIds && this.selectTeamsIds.length) {
      let query = {
        "query": {
          "nestedWhere": {}
        }
      };
      query['query']['nestedWhere']["team_query"] = {};
      query['query']['nestedWhere']["team_query"]["type"] = "search";
      query['query']['nestedWhere']["team_query"]["operator"] = "or";
      let tQuery = [];
      for (let k = 0; k < this.selectTeamsIds.length; k++) {
        tQuery.push({
          "field": "data.regionteam.raw",
          "value": this.selectTeamsIds[k]
        })
      }
      query['query']['nestedWhere']["team_query"]["value"] = tQuery;
      this.inputData['teamFilter'] = this.selectTeamsIds;
      this.inputData['teamQuery'] = query;
    } else {
      this.inputData['teamFilter'] = [];
      this.inputData['teamQuery'] = null;
    }

    if (this.selectedBusinessVertical && this.selectedBusinessVertical.length) {
      let query = {
        "query": {
          "nestedWhere": {}
        }
      };
      query['query']['nestedWhere']["bs_query"] = {};
      query['query']['nestedWhere']["bs_query"]["type"] = "search";
      query['query']['nestedWhere']["bs_query"]["operator"] = "or";
      let tQuery = [];

      for (let k = 0; k < this.selectedBusinessVertical.length; k++) {
        tQuery.push({
          "field": "data.businessvertical.raw",
          "value": this.selectedBusinessVertical[k]
        })
      }

      query['query']['nestedWhere']["bs_query"]["value"] = tQuery;
      this.inputData['bsQuery'] = query;
    } else {
      this.inputData['bsQuery'] = null;
    }

    if (this.selectedUsersIds && this.selectedUsersIds.length) {
      let query = {
        "query": {
          "where": {}
        }
      };
      query['query']['where']["user_query"] = {};
      query['query']['where']["user_query"]["type"] = "search";
      query['query']['where']["user_query"]["operator"] = "or";
      let tQuery = [];
      for (let k = 0; k < this.selectedUsersIds.length; k++) {
        tQuery.push({
          "field": "assigneeId.raw",
          "value": this.selectedUsersIds[k]
        })
      }
      query['query']['where']["user_query"]["value"] = tQuery;
      this.inputData['userFilter'] = this.selectedUsersIds;
      this.inputData['userQuery'] = query;
    } else {
      this.inputData['userFilter'] = [];
      this.inputData['userQuery'] = null;
    }
    this.inputData['resetFilter'] = false;
    this.onFilterApply(this.inputData);
    let filterCachedObj: any = {};

    // if (this.localCache.getItem('appliedFilters')) {
    //   filterCachedObj = this.localCache.getItem('appliedFilters');
    //   filterCachedObj = JSON.parse(filterCachedObj);
    // } else {
    //   filterCachedObj = {};
    // }
    // if (this.selectTeams && this.selectTeams.length > 0) {
    //   filterCachedObj["selectTeams"] = this.selectTeams;
    // }
    // if (this.selectedUsers && this.selectedUsers.length > 0) {
    //   filterCachedObj["selectedUsers"] = this.selectedUsers;
    // }
    // if (this.selectedBusinessVertical && this.selectedBusinessVertical.length > 0) {
    //   filterCachedObj["selectedBS"] =  this.selectedBusinessVertical
    // }
    // if (this.createdDateQuery) {
    //   filterCachedObj["selectedCreatedDate"] = this.createdDateQuery;
    // }
    // if (this.closingDateQuery) {
    //   filterCachedObj["selectedClosingDate"] = this.closingDateQuery;
    // }
    // this.localCache.setItem('appliedFilters', JSON.stringify(filterCachedObj));
  }
  resetTeamFilterInCache() {
    this.teamQuery = null;
    this.searchControlTeams = '';
    this.teamsSearch = this.teams;
  }
  resetTeamFilterInCacheForVelocity() {
    this.searchControlTeamsForVelocity = '';
    this.teamsSearchForVelocity = this.teams;
  }

  onApplyDates($event, selectedDate) {
    this.resetDates = false;
    if (selectedDate === 'closingDate') {
      let query = {
        "data.closingdate": {
          "type": "range",
          "end_value": $event.endDate.toISOString(),
          "start_value": $event.startDate.toISOString()
        }

      };
      this.closingDateQuery = query;
    }
    if (selectedDate === 'createdDate') {
      let query = {
        "createdAt": {
          "field": "createdAt",
          "type": "range",
          "start_value": $event.startDate.toISOString(),
          "end_value": $event.endDate.toISOString()
        }
      };
      this.createdDateQuery = query;
    }
    if (selectedDate === 'lastUpdatedDate') {
      let query = {
        "updatedAt": {
          "field": "updatedAt",
          "type": "range",
          "start_value": $event.startDate.toISOString(),
          "end_value": $event.endDate.toISOString()
        }
      };
      this.lastUpdatedDateQuery = query;
    }
    this.onApply();

  }
  dealFunnelModal(element) {
    if (this.boardId === this.boardIdForSobOpportunity && element === "PO Received") {
      element = "Deal Won"
    }
    let count: any = this.getCountBykey(element)
    let sum: any = this.getSumBykey(element)
    if (element === 'Dormant') {
      this.inputData['dormantQuery'] =
      {
        "updatedAt": {
          "field": "updatedAt",
          "type": "range",
          "start_value": "2020-04-22T18:30:00.000Z",
          "end_value": moment().subtract(30, 'days').toISOString()
        }
      };
      count = this.dormantDealsCount;
      sum = this.dormatDealSum;
    } else {
      this.inputData['dormantQuery'] = null;
    }
    this.inputData['lane'] = element
    this.inputData['count'] = count
    this.inputData['sum'] = sum
    this.inputData['boardId'] = this.boardId;
    const dialog = this.dialog.open(DealFunnelModalComponent, {
      width: '650px',
      height: '530px',
      panelClass: 'right-side-dialog',
      data: this.inputData
    });

  }
  onApplyForVelocity() {
    let obj = {};
    if (this.selectedUsersForVelocity) {
      obj["userIds"] = this.selectedUsersForVelocity;
    }
    if (this.selectTeamsForVelocity) {
      obj["regionIds"] = this.selectTeamsForVelocity;
    }
    if (this.selectedBusinessVerticalForVelocity) {
      obj["businessVerticals"] = this.selectedBusinessVerticalForVelocity;
    }
    if (this.selectedDealType) {
      obj["dealType"] = this.selectedDealType;
    }
    if (this.selectedLastWeek) {
      obj["lastWeeks"] = this.selectedLastWeek;
    } else {
      obj["lastWeeks"] = 5;
    }
    if (this.selectedStartingStage) {
      obj["startingStage"] = this.selectedStartingStage;
    }
    obj["freeze"] = this.isFreeze;
    this.loadHuntingVelocityData(obj);
  }
  onDealTypeSelectionForVelocity(deal: any) {
    this.selectedDealType = deal;
    this.onApplyForVelocity();
  }
  onStartingStageSelectionForVelocity(stage: any,selectedRow:any) {
    this.selectedStartingStage = stage;
    this.onApplyForVelocity();
    this.highlightedRows = selectedRow
  }
  onFreeze() {
    this.isFreeze = !this.isFreeze;
    this.onApplyForVelocity();
  }
  onLastWeekSelectionForVelocity(lastWeek: any) {
    this.selectedLastWeek = lastWeek;
    this.onApplyForVelocity();
  }
  loadHuntingVelocityData(obj: any) {
    this.displayedColumnsForHuntingVelocity = [];
    this.displayedColumnsForHuntingVelocity1 = [];
    this.displayedColumnsForHuntingVelocity.push({ "field": "Stage", value: "Stage" });
    this.displayedColumnsForHuntingVelocity1.push("Stage");
    this.kanbanService.loadHuntingVelocityData(obj).subscribe((response) => {
      if (response && response['result']) {
        let result = response['result'];
        let headerslist = result['velocityHeaders'];
        this.HuntingVelocityTableDataSource = result['laneTaskVelocityStageWiseDTOS'];
        this.loadHuntingVelocityHeaders(headerslist);
      }
    });
  }
  loadHuntingVelocityHeaders(headers: any) {
    for (let i = 0; i < headers.length; i++) {
      let startDate = "(" + this.formatDate(headers[i]["startDate"]) + ")";
      let endDate = "(" + this.formatDate(headers[i]["endDate"]) + ")";
      // this.displayedColumnsForHuntingVelocity.push(startDate+"-"+endDate);
      let value = startDate + "-" + endDate;
      this.displayedColumnsForHuntingVelocity.push({ "field": value, "startDate": this.formatDate(headers[i]["startDate"]), "endDate": this.formatDate(headers[i]["endDate"]) });
      this.displayedColumnsForHuntingVelocity1.push(value);
    }
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [day, month, year].join('-');
  }

  onBusinessVerticalSelectionChange(selection: string[]) {
    this.selectedBusinessVertical = selection;
    if (this.selectedBusinessVertical.length === 0) {
      this.resetBSInCache();
    }
    this.onApply();
  }

  onBusinessVerticalSearchChange(searchTerm: string) {
    this.searchControlBS = searchTerm;
    this.filterOptionsVertical(searchTerm);
  }

  onRegionTeamSelectionChange(selection: any[]) {
    const idList = selection.map(item => item.id);
    this.selectTeamsIds = idList;
    if (this.selectTeamsIds.length === 0) {
      this.selectTeams = [];
      this.resetTeamFilterInCache();
    }
    this.onApply();
  }

  onRegionTeamSearchChange(searchTerm: string) {
    this.searchControlTeams = searchTerm;
    this.filterOptionsTeams(searchTerm);
  }

  onHunterSelectionChange(selection: any[]) {
    const idList = selection.map(item => item.id);
    this.selectedUsersIds = idList;
    if (this.selectedUsersIds.length === 0) {
      this.selectedUsers = [];
      this.resetUserFilterInCache();
    }
    this.onApply();
  }

  onHunterSearchChange(searchTerm: string) {
    this.searchControlHunter = searchTerm;
    this.filterOptions(searchTerm);
  }
  // Extra function code
               
                       
  task: Task = {
    name: 'Select All ',
    completed: false,
    color: 'primary',
    subtasks: [
      {name: 'Select 1', completed: false, color: 'primary'},
      {name: 'Select 2', completed: false, color: 'primary'},
      {name: 'Select 3', completed: false, color: 'primary'},
    ],
  };
  
  allComplete: boolean = false;
  
  updateAllComplete() {
    this.allComplete = this.task.subtasks != null && this.task.subtasks.every(t => t.completed);
  }
  
  someComplete(): boolean {
    if (this.task.subtasks == null) {
      return false;
    }
    return this.task.subtasks.filter(t => t.completed).length > 0 && !this.allComplete;
  }
  
  setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.task.subtasks == null) {
      return;
    }
    this.task.subtasks.forEach(t => (t.completed = completed));
  } 
  

  getPicksList() {  
    this.dataSource = [  
        {'DealName':'VC - Adani Wilmar', 'DealOwner':'Mirzanoor Rahman','KAM' : 'Ashish Singh','DealSize' : '76 Cr','BusinessPotential' : '200 Cr','StageAging' : '10 days ago','POType' : 'RFQ','Region' : 'N1','HandoverDate' : '31-11-2023'},
        {'DealName':'VC - Adani Wilmar', 'DealOwner':'Mirzanoor Rahman','KAM' : 'Ashish Singh','DealSize' : '76 Cr','BusinessPotential' : '200 Cr','StageAging' : '10 days ago','POType' : 'RFQ','Region' : 'N1','HandoverDate' : '31-11-2023'},
        {'DealName':'VC - Adani Wilmar', 'DealOwner':'Mirzanoor Rahman','KAM' : 'Ashish Singh','DealSize' : '76 Cr','BusinessPotential' : '200 Cr','StageAging' : '10 days ago','POType' : 'RFQ','Region' : 'N1','HandoverDate' : '31-11-2023'},
        {'DealName':'VC - Adani Wilmar', 'DealOwner':'Mirzanoor Rahman','KAM' : 'Ashish Singh','DealSize' : '76 Cr','BusinessPotential' : '200 Cr','StageAging' : '10 days ago','POType' : 'RFQ','Region' : 'N1','HandoverDate' : '31-11-2023'},
        {'DealName':'VC - Adani Wilmar', 'DealOwner':'Mirzanoor Rahman','KAM' : 'Ashish Singh','DealSize' : '76 Cr','BusinessPotential' : '200 Cr','StageAging' : '10 days ago','POType' : 'RFQ','Region' : 'N1','HandoverDate' : '31-11-2023'},
        {'DealName':'VC - Adani Wilmar', 'DealOwner':'Mirzanoor Rahman','KAM' : 'Ashish Singh','DealSize' : '76 Cr','BusinessPotential' : '200 Cr','StageAging' : '10 days ago','POType' : 'RFQ','Region' : 'N1','HandoverDate' : '31-11-2023'},
        {'DealName':'VC - Adani Wilmar', 'DealOwner':'Mirzanoor Rahman','KAM' : 'Ashish Singh','DealSize' : '76 Cr','BusinessPotential' : '200 Cr','StageAging' : '10 days ago','POType' : 'RFQ','Region' : 'N1','HandoverDate' : '31-11-2023'},
        {'DealName':'VC - Adani Wilmar', 'DealOwner':'Mirzanoor Rahman','KAM' : 'Ashish Singh','DealSize' : '76 Cr','BusinessPotential' : '200 Cr','StageAging' : '10 days ago','POType' : 'RFQ','Region' : 'N1','HandoverDate' : '31-11-2023'},
        {'DealName':'VC - Adani Wilmar', 'DealOwner':'Mirzanoor Rahman','KAM' : 'Ashish Singh','DealSize' : '76 Cr','BusinessPotential' : '200 Cr','StageAging' : '10 days ago','POType' : 'RFQ','Region' : 'N1','HandoverDate' : '31-11-2023'},
       
      ];  }  
      
}

