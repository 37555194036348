import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-rfq-won-confirmation',
  templateUrl: './rfq-won-confirmation.component.html',
  styleUrls: ['./rfq-won-confirmation.component.scss']
})
export class RfqWonConfirmationComponent implements OnInit {
  plantId: string;
  customerId: string;
  stage:string;
  currentdate:any;
  constructor(public dialogRef: MatDialogRef<RfqWonConfirmationComponent>,public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.plantId = this.data.plantId;
    this.customerId = this.data.customerId;
    this.stage=this.data.stage;
    this.currentdate=new Date().getTime();
    console.log("OnInit", this.data);
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  cancelDialog() {
    this.dialogRef.close(false);
  }
  submit(){
    this.dialogRef.close(true);
  }
}
