import { Component, OnInit, Inject, ElementRef, ViewChild, inject } from '@angular/core';
import { KanbanService } from '../../../kanban.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl,FormGroup,FormBuilder,Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatChipInputEvent, MatAutocomplete,MatSelect, MatSnackBar } from '@angular/material';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CreateLaneTaskComponent } from '../create-lane-task/create-lane-task.component';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Constants } from 'src/app/Constants';
import { SettingService } from 'src/app/settings/setting.service';
import { ErrorToastComponent } from 'src/app/shared-files/error-toast/error-toast.component';
import { SuccessToastComponent } from 'src/app/shared-files/success-toast/success-toast.component';

@Component({
  selector: 'app-lane-movement',
  templateUrl: './lane-movement.component.html',
  styleUrls: ['./lane-movement.component.scss']
})
export class LaneMovementComponent implements OnInit {

  boardName: string = "Test Board";
  toLane: any;
  fromLane: any;
  lanetask: any;
  selectedReason = "";
  stageConversion: any;
  realisticDealSize: any;
  businessPotential: any;
  finalContacts: any[] = [];
  contactBoardId: any;
  contactBoardLane: any;

  nextActionDate: any = null;
  moveReasons = [];
  huntingMoveReasons = [
    "Reasons for moving a deal to cold/lost stage:Less interest",
    "No agreement on working model",
    "Previous bad experience with Moglix",
    "Wants to Deal with Authorized channel partners only",
    "Not able to match price",
    "Less savings visible",
    "Few Items addressable",
    "Change in Decision maker",
    "Wrong POC",
    "Not willing to share data",
    "Lost to competitor",
    "Customer made direct contact with OEM",
    "Requirement on hold",
    "Requirement revoked"
  ];
  sobMoveReasons = [
    "Unable to match price",
    "No feedback from Customer",
    "Lost to competitor",
    "Lead time issue",
    "Requirement on Hold",
    "Requirement Revoked",
    "Supplier/Brand Alignment issue"
  ];
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  contactCtrl = new FormControl();
  filteredcontacts: Observable<string[]>;
  pageIndex = -1;
  boardIdForCRMDeals = Constants.boardIdForCRMDeals;
  boardIdForSobOpportunity = Constants.boardIdForSobOpportunity;
  boardIdForSrmSupplier = Constants.boardIdForSrmSupplier;

  pageSize = 200;
  pageFrom = 0;
  isLoading: boolean = true;
  accountId: any;
  laneTaskIdByUrl: any;
  contactDetails: any[] = [];
  contactIds: any[] = [];
  boardId: any;

  contacts: string[] = [];
  allcontacts: string[] = [];
  m1: any;
  m2: any;
  m3: any;
  m4: any;
  m5: any;
  m6: any;
  projectionMap = new Map<any, any>();
  projectionData = [];
  closingDate: Date;
  projectionTimeline: any;
  opportunityname:any;
  isRepeatedRFQFrequency: boolean = false;
  isDealTypeNewCategories: boolean = false;
  isNewCategories: boolean = false;
  subCategoriesOfNewCategories: any;
  formDetails: FormGroup;
  searchControlSOBBusinessVertical = "";
  sobBusinessVerticalConfig:any;
  sobBusinessVerticalList: any = [];
  sobBusinessVerticalListSearch: any = [];
  searchControlSOBOpportunityType = "";
  sobOpportunityTypeConfig:any;
  sobOpportunityTypeList: any = [];
  sobOpportunityTypeListSearch: any = [];
  sobSearchControlL1Category = "";
  sobL1CategoryConfig:any;
  sobL1CategoryList: any = [];
  sobL1CategoryListSearch: any = [];
  sobSearchControlL2Category = "";
  sobL2CategoryConfig:any;
  sobL2CategoryList: any = [];
  sobL2CategoryListSearch: any = [];
  sobSearchControlPoType = "";
  sobPoTypeConfig:any;
  sobPoTypeList: any = [];
  sobPoTypeListSearch: any = [];
  dealType=''
  errorField: any = [];
  sobAccountConfigOpportunity = {
    "key": "laneTask",
    "field": "sob-account-name",
    "rawValue": null,
    "type": "belongsTo",
    "value": "",
    "optional": true,
    "display": true,
    "displayWhileListing": true,
    "showTotal": false,
    "displayName": "Account Name",
    "dataList": [],
    "values": null,
    "additionalData": null,
    "operationsData": null,
    "operations": null,
    "index": 6,
    "icon": "",
    "relationKey": Constants.boardIdForSobAccount,
    "nameField": false
  };
  sobUserConfig={
      "key": "user",
      "field": "sob-kam",
      "rawValue": null,
      "type": "belongsTo",
      "value": "",
      "optional": false,
      "display": true,
      "displayWhileListing": false,
      "showTotal": false,
      "displayName": "SOB KAM",
      "dataList": [],
      "values": null,
      "additionalData": null,
      "operationsData": null,
      "operations": null,
      "index": 1,
      "icon": "",
      "relationKey": null,
      "businessId": "6492cd17a056ce1c2860f874",
      "nameField": false,
      "fieldEditable": true
  }
  selectedPlantIds: string[] = [];
  searchControl = '';
  sobPlantList: any = [];
  sobPlantListSearch: any = [];
  sobAccountId:any;
  sobregion:any;
  regionTeamListSearch:any=[];
  regionTeamList: any = [];
  searchControlRegionTeam = "";
  sobUser:any;
  l2category:any;
  kamHandoverLaneConfig:any;
  rfqID:any;
  poID:any;
  file:any;
  isFileAdded:boolean=false;
  @ViewChild('sl2') sl2: MatSelect;
  @ViewChild('autocompleteComponent') autocompleteComponent: any;
  @ViewChild('userAutoCompleteComponent') userAutoCompleteComponent: any;
  @ViewChild('contactInput') contactInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  constructor(private _snackBar: MatSnackBar, public dialogRef: MatDialogRef<LaneMovementComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog, public _kService: KanbanService, public toasterService: ToastrService,
  private formBuilder: FormBuilder,public settingServ: SettingService) {

    this.filteredcontacts = this.contactCtrl.valueChanges.pipe(
      startWith(null),
      map((contact: string | null) => (contact ? this._filter(contact) : this.allcontacts.slice())),
    );
  }

  ngOnInit() {
    if (this.data && this.data['toLane'] && this.data['laneTask']) {
      this.toLane = this.data['toLane'];
      this.fromLane = this.data['laneTask'].lane;
      this.lanetask = this.data['laneTask'];
      this.boardId = this.lanetask['boardId'];
      if (this.boardId === this.boardIdForCRMDeals) {
        this.moveReasons = this.huntingMoveReasons;
      }
      else if (this.boardId === this.boardIdForSobOpportunity) {
        this.moveReasons = this.sobMoveReasons;
      }
      let pofcIndex = this.lanetask.data.findIndex((element) => {
        return element.field == "probability-of-conversion";
      });
      let realsticeSizeIndex = this.lanetask.data.findIndex((element) => {
        return element.field == "realistic-deal-size-cr";
      });
      let accountId = this.lanetask.data.findIndex((element) => {
        return element.field == "account-name";
      });
      let busniessPotIndex = this.lanetask.data.findIndex((element) => {
        return element.field == "business-potential-in-cr";
      });
      let closingDateIndex = this.lanetask.data.findIndex((element) => {
        return element.field == "closing-date";
      });
      let rfqIdIndex = this.lanetask.data.findIndex((element) => {
        return element.field == "rfq-id";
      });
      let poIdIndex = this.lanetask.data.findIndex((element) => {
        return element.field == "po-id";
      });
      if(pofcIndex!=-1){
        this.stageConversion = parseFloat(this.lanetask.data[pofcIndex].value);
      }     
      if(realsticeSizeIndex!=-1){
        this.realisticDealSize = parseFloat(this.lanetask.data[realsticeSizeIndex].value);
      }
      if(busniessPotIndex!=-1){
        this.businessPotential = parseFloat(this.lanetask.data[busniessPotIndex].value);
      }
      if(accountId!=-1){
        this.accountId = this.lanetask.data[accountId].value;
      }
      if(closingDateIndex!=-1){
        this.closingDate = new Date(this.lanetask.data[closingDateIndex].value);
      }
      if(rfqIdIndex!=-1){
        this.rfqID=this.lanetask.data[rfqIdIndex].value;
      }
      if(poIdIndex!=-1){
        this.poID=this.lanetask.data[poIdIndex].value;
      }
      this.laneTaskIdByUrl = this.lanetask.id;
      if(this.boardId==this.boardIdForCRMDeals || this.boardId==this.boardIdForSobOpportunity){
        this.getPicksList();
      }
      if(this.closingDate){
        this.projectionTimeline = this.formatDate(this.addMonths(this.closingDate, 1)) + " to " + this.formatDate(this.addMonths(this.closingDate, 6))
      }
      if (this.lanetask.projection && this.lanetask.projection.length > 0) {
        this.m1 = this.lanetask.projection[0].value;
        this.m2 = this.lanetask.projection[1].value;
        this.m3 = this.lanetask.projection[2].value;
        this.m4 = this.lanetask.projection[3].value;
        this.m5 = this.lanetask.projection[4].value;
        this.m6 = this.lanetask.projection[5].value;
      }
      if(this.boardId === this.boardIdForCRMDeals && this.toLane.id==Constants.kamHandoverLaneId){
       this.loadKamHandoVerMovement();
      }
    } else {
      this.closeDialog();
    }
    if(this.boardId==this.boardIdForCRMDeals || this.boardId==this.boardIdForSobOpportunity){
      this.fetchBoardlane();
    }
    this.errorField=[];
    this.sobAccountId=null;
    this.selectedPlantIds=[];
    this.sobUser=null;
    this.isFileAdded=false;
  }
  getMonthYearFormatedDate(date: any) {
    return date.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/ /g, '-');
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [day, month, year].join('-');
  }
  addMonths(d, months) {
    const newDate = new Date(d)
    newDate.setMonth(d.getMonth() + months, 1)
    return newDate
  }

  getPicksList() {
    this.isLoading = true;
    this.allcontacts = [];
    this.contacts = [];
    let boardIdContacts;
    if (this.boardId === this.boardIdForCRMDeals) {
      boardIdContacts = Constants.boardIdForCRMContacts;
    }
    else if (this.boardId === this.boardIdForSobOpportunity) {
      boardIdContacts = Constants.boardIdForSobContacts;
    }
    let obj = {
      "where": {
        "boardId": {
          "type": "search",
          "operator": "or",
          "value": [
            {
              "field": "boardId.raw",
              "value": boardIdContacts
            }
          ]
        }
      },
      "sort": { "field": "updatedAt", "order": "desc" },

      "from": this.pageFrom,
      "size": this.pageSize
    }
    let tQuery = [];
    tQuery.push({
      "field": "data.account.raw",
      "value": this.accountId
    })
    let query = {
      "query": {
        "nestedWhere": {}
      }
    };
    query['query']['nestedWhere']["account_query"] = {};
    query['query']['nestedWhere']["account_query"]["type"] = "search";
    query['query']['nestedWhere']["account_query"]["operator"] = "or";
    query['query']['nestedWhere']["account_query"]["value"] = tQuery;

    obj['nestedWhere'] = query['query']['nestedWhere'];
    this._kService.getLaneTaskContactDetails(obj, this.laneTaskIdByUrl).subscribe(result => {
      this.isLoading = false;
      if (result == null) {
        return;
      }
      this.pageIndex++;
      const contactList = result['result'];;
      for (let index = 0; index < contactList.length; index++) {

        const element = contactList[index];
        if (element.isChecked) {
          this.contacts.push(contactList[index].displayName)
        }
        this.allcontacts.push(contactList[index].displayName)
        this.contactDetails = contactList;
      }
      this.filteredcontacts = this.contactCtrl.valueChanges.pipe(
        startWith(null),
        map((contact: string | null) => (contact ? this._filter(contact) : this.allcontacts.slice())),
      );
    });
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  async moveLaneTask() {
    //validating Lane Task 
   
    for (let index = 0; index < this.contacts.length; index++) {
      const contactName = this.contacts[index];
      for (let index = 0; index < this.contactDetails.length; index++) {
        const contactRow = this.contactDetails[index];
        if (contactRow.displayName === contactName) {
          this.finalContacts.push(contactRow.id);
        }
      }
    }
    if ((this.boardId == Constants.boardIdForCRMDeals && !Constants.nonActiveDealIds.includes(this.toLane.id)) ||
      (this.boardId == Constants.boardIdForSobOpportunity && !Constants.nonActiveOppIds.includes(this.toLane.id))) {
      if ((this.lanetask.nextActionDate == null || this.lanetask.nextActionDate == undefined) &&
        (this.lanetask.nextActionItem == null || this.lanetask.nextActionItem == undefined)) {
        // this.toasterService.error('Update the "Next Action Item" first to proceed further');
        this._snackBar.openFromComponent(ErrorToastComponent, {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['atom-toast', 'error'],
          duration: 6000,
          data: 'Update the "Action Item" first to proceed further'
        });
        return;
      }
      else if (this.lanetask.nextActionDate != null) {
        const inputDateObj = new Date(this.lanetask.nextActionDate);
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - 1); // Subtract one day
        if (inputDateObj < currentDate) {
          // this.toasterService.error('Next "Action Date" is less than the current date');
          this._snackBar.openFromComponent(ErrorToastComponent, {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: ['atom-toast', 'error'],
            duration: 6000,
            data: 'Next "Action Date" is less than the current date'
          });
          return;
        }
      }
    }
    if ((this.boardId == Constants.boardIdForCRMDeals) && (this.toLane.position > 7) && (!this.selectedReason || this.selectedReason.trim() == "")) {
      // this.toasterService.error("Reason Required");
      this._snackBar.openFromComponent(ErrorToastComponent, {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: ['atom-toast', 'error'],
        duration: 6000,
        data: "Reason Required"
      });
      return;
    }
    if (((this.boardId == this.boardIdForCRMDeals || this.boardId==this.boardIdForSobOpportunity) && this.toLane.position == 3 ) && !this.businessPotential ) {
      this._snackBar.openFromComponent(ErrorToastComponent, {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: ['atom-toast', 'error'],
        duration: 6000,
        data: "Business Potential Required"
      });
      return;
    }
    if ((this.boardId == this.boardIdForCRMDeals || this.boardId==this.boardIdForSobOpportunity) && (this.toLane.position == 3 ) && !this.realisticDealSize) {
      this._snackBar.openFromComponent(ErrorToastComponent, {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: ['atom-toast', 'error'],
        duration: 6000,
        data: "Deal Size Required"
      });
      return;
    }
    if ((this.boardId == this.boardIdForCRMDeals || this.boardId==this.boardIdForSobOpportunity) && this.toLane.position == 4) {
      if (this.closingDate) {
        if (this.m1 != null && this.m2 != null && this.m3 != null && this.m4 != null && this.m5 != null && this.m6 != null) {
          this.projectionData.push({ "date": this.formatDate(this.addMonths(this.closingDate, 1)), "value": this.m1 });
          this.projectionData.push({ "date": this.formatDate(this.addMonths(this.closingDate, 2)), "value": this.m2 });
          this.projectionData.push({ "date": this.formatDate(this.addMonths(this.closingDate, 3)), "value": this.m3 });
          this.projectionData.push({ "date": this.formatDate(this.addMonths(this.closingDate, 4)), "value": this.m4 });
          this.projectionData.push({ "date": this.formatDate(this.addMonths(this.closingDate, 5)), "value": this.m5 });
          this.projectionData.push({ "date": this.formatDate(this.addMonths(this.closingDate, 6)), "value": this.m6 });
        } else {
          this._snackBar.openFromComponent(ErrorToastComponent, {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: ['atom-toast', 'error'],
            duration: 6000,
            data: "Please provide all month projection"
          });
          // this.toasterService.error("Please provide all month projection");
          return;
        }
      } else {
        this._snackBar.openFromComponent(ErrorToastComponent, {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['atom-toast', 'error'],
          duration: 6000,
          data: "Please set closing date fisrt"
        });
        // this.toasterService.error("Please set closing date fisrt");
        return;
      }
    }
    if (this.boardId == Constants.boardIdForCRMDeals && this.toLane.position == 7) {
      //validate on movement to KAM-Handover
      this.errorField=[];
      if(!this.opportunityname){
        this.errorField.push('opportunityname');
      }
      if(!this.sobAccountId){
        this.errorField.push('sobAccountId');
      }
      if(!this.selectedPlantIds){
        this.errorField.push('sobSelectedPlantIds');
      }
      if(!this.sobregion){
        this.errorField.push('sobregion');
      }
      if(!this.sobUser){
        this.errorField.push('subUser');
      }
      if(!this.sobBusinessVerticalConfig.value){
        this.errorField.push('sobbusinessvertical');
      }
      if(!this.sobOpportunityTypeConfig.value){
        this.errorField.push('sobopportunity');
      }
      if(!this.sobPoTypeConfig.value){
        this.errorField.push('sobpotype');
      }
      if(this.isDealTypeNewCategories && !this.sobL1CategoryConfig.value){
        this.errorField.push('sobL1Category');
      }
      if(this.isNewCategories && (!this.sobL2CategoryConfig.values || this.sobL2CategoryConfig.values.length==0)){
        this.errorField.push('sobL2Category');
      }
      if(this.errorField.length>0){
      // this.toasterService.error('Please check required fields!');
      return;  
      }

    }
    if(this.boardId==this.boardIdForSrmSupplier){
      if (this.toLane.position == 2) {
        let isUploaded = await this.uploadAttachment('nda');
        console.log('is uploaded', isUploaded);
        if (!isUploaded) {
            console.log('nested is uploaded', isUploaded);
            this._snackBar.openFromComponent(ErrorToastComponent, {
                horizontalPosition: 'center',
                verticalPosition: 'top',
                panelClass: ['atom-toast', 'error'],
                duration: 6000,
                data: 'NDA Document Required!'
            });
            return;
        }
    }
      else if(this.toLane.position==3 && !this.rfqID){
        this._snackBar.openFromComponent(ErrorToastComponent, {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['atom-toast', 'error'],
          duration: 6000,
          data: 'RFQ Id Required!'
        });
        return;
      }
      else if(this.toLane.position==4 && !this.poID){
        this._snackBar.openFromComponent(ErrorToastComponent, {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['atom-toast', 'error'],
          duration: 6000,
          data: 'PO Id Required!'
        });
        return;
      }
      if (this.toLane.position == 5) {
        let isUploaded = await this.uploadAttachment('tod-document');
        console.log('is uploaded', isUploaded);
        if (!isUploaded) {
            console.log('nested is uploaded', isUploaded);
            this._snackBar.openFromComponent(ErrorToastComponent, {
                horizontalPosition: 'center',
                verticalPosition: 'top',
                panelClass: ['atom-toast', 'error'],
                duration: 6000,
                data: 'TOD Document Required!'
            });
            return;
        }
    }
    }  
    let obj = {
      "id": this.lanetask.id,
      "laneId": this.fromLane.id,
      "toLaneID": this.toLane.id,
      "remarks": this.selectedReason,
      "nextActionDate": this.nextActionDate,
      "realisticDealSize": this.realisticDealSize != null ? parseFloat(this.realisticDealSize) : 0,
      "businessPotential": this.businessPotential != null ? parseFloat(this.businessPotential) : 0,
      "stageConversion": this.stageConversion != null ? parseFloat(this.stageConversion) : 0,
      "projection": this.projectionData,
      "sobopportunityname":this.opportunityname,
      "sobaccountid":this.sobAccountId,
      "sobplantids":this.selectedPlantIds,
      "sobregion":this.sobregion,
      "sobuser":this.sobUser,
      "sobbusinessvertical":this.sobBusinessVerticalConfig!=null?this.sobBusinessVerticalConfig.value:"",
      "sobopportunitytype":this.sobOpportunityTypeConfig!=null?this.sobOpportunityTypeConfig.value:"",
      "sobl1Category":this.sobL1CategoryConfig!=null?this.sobL1CategoryConfig.value:"",
      "sobl2Categories":this.sobL2CategoryConfig!=null?this.sobL2CategoryConfig.values:[],
      "sobpotype":this.sobPoTypeConfig!=null?this.sobPoTypeConfig.value:"",
      "rfqId":this.rfqID,
      "poId":this.poID
    }
    this.addAndUpdateContact();
    obj["contactIds"] = this.finalContacts;

    if ((this.boardId == this.boardIdForCRMDeals || this.boardId==this.boardIdForSobOpportunity) && this.toLane.position === 2 && this.fromLane.position === 1) {
      if (!this.finalContacts || this.finalContacts.length <= 0) {
        this._snackBar.openFromComponent(ErrorToastComponent, {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['atom-toast', 'error'],
          duration: 6000,
          data: "Provide at least one contact."
        });
        // this.toasterService.error("Provide at least one contact.");
      } else {
        this.moveLane(obj);
      }
    } else {
      this.moveLane(obj);
    }
  }

  moveLane(obj: any): void {
    this._kService.assignLaneTaskMovement(obj).subscribe(
      (res) => {
        if (res['status'] === 200) {
          this._snackBar.openFromComponent(SuccessToastComponent, {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: ['atom-toast', 'success'],
            duration: 6000,
            data: "Lane Successfully Moved"
          });
          
          // this.toasterService.success("Lane Successfully Moved");
          this.dialogRef.close(res['result']);
        }
        // window.location.reload();
      },
      (error) => {
        this._snackBar.openFromComponent(SuccessToastComponent, {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['atom-toast', 'error'],
          duration: 6000,
          data: error.error.message
        });
        // this.toasterService.error("Something went wrong!");
      }
    );
  }

  add(event: MatChipInputEvent): void {
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;
      if ((value || '').trim()) {
        this.contacts.push(value.trim());
      }
      if (input) {
        input.value = '';
      }

      this.contactCtrl.setValue(null);
    }
  }
  remove(contact: string): void {
    for (let index = 0; index < this.contactDetails.length; index++) {
      if (this.contactDetails[index].displayName === contact) {
        this.contactDetails[index].isChecked = false;
      }
    }
    const index = this.contacts.indexOf(contact);
    if (index >= 0) {
      this.contacts.splice(index, 1);
    }
  }
  selected(event: MatAutocompleteSelectedEvent): void {
    // if (this.contactDetails.displayName === event.option.value) {
    //   this.contactIds.push(this.contactDetails.id)
    // }
    this.contacts.push(event.option.viewValue);
    this.contactInput.nativeElement.value = '';
    this.contactCtrl.setValue(null);
  }

  addAndUpdateContact() {
    if (this.contactDetails.length <= 0 || this.contactDetails !== undefined) {
      for (let index = 0; index < this.contactDetails.length; index++) {
        const oldData = this.contactDetails[index];
        if (oldData.isChecked) {
          const id = oldData['id'];
          this.contactIds.push(id);
        }
      }
    }
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allcontacts.filter(contact => contact.toLowerCase().includes(filterValue));
  }

  fetchBoardlane() {
    console.log("fetchBoardlane")
    let contactBoardIdForFetchingLaneWithAccess;
    if (this.boardId === this.boardIdForCRMDeals) {
      contactBoardIdForFetchingLaneWithAccess = Constants.boardIdForCRMContacts
    }
    else if (this.boardId === this.boardIdForSobOpportunity) {
      contactBoardIdForFetchingLaneWithAccess = Constants.boardIdForSobContacts
    }
    this._kService.getLaneWithUserAccess(contactBoardIdForFetchingLaneWithAccess, '').subscribe(res => {
      let tmp = [];
      for (let i = 0; i < res['result']['levelAccess'].length; i++) {
        let t = res['result']['levelAccess'][i].laneDTO;
        tmp.push(t);
      }
      this.contactBoardLane = tmp;
    });
  }

  addNewLead() {
    let contactBoardIdForFetchingLaneWithAccess;
    if (this.boardId === this.boardIdForCRMDeals) {
      contactBoardIdForFetchingLaneWithAccess = Constants.boardIdForCRMContacts
    }
    else if (this.boardId === this.boardIdForSobOpportunity) {
      contactBoardIdForFetchingLaneWithAccess = Constants.boardIdForSobContacts
    }
    const createLaneTaskDialog = this.dialog.open(CreateLaneTaskComponent, {
      disableClose: false,
      width: '40%',
      height: '100vh',
      position: { bottom: '0px', right: '0px' },
      data: {
        "boardId": contactBoardIdForFetchingLaneWithAccess,
        "boardName": "contact",
        "selectedLane": this.contactBoardLane[0]
      },
      panelClass: 'addnewleadoverlay'
    });
    createLaneTaskDialog.afterClosed().subscribe(result => {
      this.getPicksList();
    });
  }
  shouldDisableFieldConfig(fieldConfig: any): boolean {
    return fieldConfig.fieldEditable!=undefined ?!fieldConfig.fieldEditable:false;
   }
  shouldDisableField(fieldName: string): boolean {
    switch (fieldName) {
      case 'repeat-rf-qs-frequency':
        return !this.isRepeatedRFQFrequency;
      case 'new-categories':
        return !this.isDealTypeNewCategories;
      case 'new-category-list':
        return this.isNewCategories;
      default:
        return false; // For other fields, assume they are not disabled
    }
  }
  getOptionList(list) {
    if (!list) {
      return [];
    }
    list.sort(function (a, b) {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });
    return list;
  }
  onAccountFilter(accountConfig:any){
    this.sobAccountId=accountConfig.value;
    if(!this.sobAccountId){
      // return this.toasterService.error("Please Select Account First!");
      this._snackBar.openFromComponent(ErrorToastComponent, {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: ['atom-toast', 'error'],
        duration: 6000,
        data: "Please Select Account First!"
      });
    }
    let obj = {
      "where": {
        "boardId": {
          "type": "search",
          "operator": "or",
          "value": [
            {
              "field": "boardId.raw",
              "value": Constants.boardIdForSobPlant
            }
          ]
        }
      },
      "sort": { "field": "updatedAt", "order": "desc" },

      "from": this.pageFrom,
      "size": this.pageSize
    }
    let tQuery = [];
    tQuery.push({
      "field": "data.account.raw",
      "value": this.sobAccountId
    })
    let query = {
      "query": {
        "nestedWhere": {}
      }
    };
    query['query']['nestedWhere']["account_query"] = {};
    query['query']['nestedWhere']["account_query"]["type"] = "search";
    query['query']['nestedWhere']["account_query"]["operator"] = "or";
    query['query']['nestedWhere']["account_query"]["value"] = tQuery;

    obj['nestedWhere'] = query['query']['nestedWhere'];
    this.getPlantsList(obj);
  }
  filterOptions(event) {
    this.sobPlantListSearch = this.sobPlantList;
    const filterValue = event.target.value.toLowerCase();
    this.sobPlantListSearch = this.sobPlantListSearch.filter(option => (option.name).toLowerCase().includes(filterValue));
  }
  getPlantsList(obj) {
    this.isLoading = true;
    this._kService.getLaneTaskWithoutAccess(obj).subscribe(res => {
      if (res == null) {
        return;
      }
      else {
        this.sobPlantList = res['result'];
        this.isLoading = false;
        this.sobPlantListSearch = this.sobPlantList;
      }
      this.pageIndex++;
    },
      (error) => {
      }
    );
    if (this.sl2) {
      this.sl2.open();
    }
  }
  clearSelections() {
    this.selectedPlantIds = [];
    this.searchControl='';
  }
  filterOptionsRegionTeam() {
    this.regionTeamListSearch = this.regionTeamList.filter(option => (option.name).toLowerCase().includes(this.searchControlRegionTeam));
  }
  fetchTeams() {
    this.settingServ.fetchTeams().subscribe(teams => {
      if (teams) {
        this.regionTeamListSearch = teams['result'];
        this.regionTeamList = this.regionTeamListSearch;
      } else {
        this._snackBar.openFromComponent(ErrorToastComponent, {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['atom-toast', 'error'],
          duration: 6000,
          data: "Error while fetching Region/Teams"
        });
  
        // this.toasterService.error("Error while fetching Region/Teams")
        
      }
    });
  }
  setSobUser(sobUserConfig){
    this.sobUser=sobUserConfig.value;
  }
  filterOptionsBusinessVertical(event: any) {
    this.sobBusinessVerticalListSearch = this.sobBusinessVerticalList.filter(option => (option).toLowerCase().includes(event));
  }
  filterOptionsOpportunityType(event: any) {
    this.sobOpportunityTypeListSearch = this.sobOpportunityTypeListSearch.filter(option => (option).toLowerCase().includes(event));
  }
  filterOptionsL1CategoryList(event: any) {
    this.sobL1CategoryListSearch = this.sobL1CategoryListSearch.filter(option => (option).toLowerCase().includes(event));
  }
  filterOptionsPoTypeList(event:any){
    this.sobPoTypeListSearch=this.sobPoTypeListSearch.filter(option =>(option).toLowerCase().includes(event));
  }
  onSelectOptionSelectionChange(formItem: any) {
    let formItemWithSubKeys: any = {
      config: formItem,
      subKeys: formItem.field
    };
    this.onSelectOption(formItemWithSubKeys, formItemWithSubKeys.config);
  }
  onSelectOption(formItem: any, config: any) {
    const fieldName = config.field;
      switch (fieldName) {
        case 'sob-po-type':
          this.isRepeatedRFQFrequency = config.value === 'Repeat RFQ';
          if (this.isRepeatedRFQFrequency) {
            const repeatRfqFrequencyElement = this.lanetask.data.find((element) => element.subKeys === 'repeat-rf-qs-frequency');
            if (repeatRfqFrequencyElement) {
              repeatRfqFrequencyElement.config.value = null;
              repeatRfqFrequencyElement.config.optional = true;
            }
          }
          else {
            const repeatRfqFrequencyElement = this.lanetask.data.find((element) => element.subKeys === 'repeat-rf-qs-frequency');
            if (repeatRfqFrequencyElement) {
              repeatRfqFrequencyElement.config.value = null;
              repeatRfqFrequencyElement.config.optional = false;
            }
          }
          break;
        case 'sob-opportunity-type':
          this.isDealTypeNewCategories = config.value === 'New category';

          if (this.isDealTypeNewCategories) {
            if (this.sobL1CategoryConfig) {
              this.sobL1CategoryConfig.value = null;
              this.sobL1CategoryConfig.optional = true;
            }
            if (this.sobL2CategoryConfig) {
              this.isNewCategories = false;
              this.sobL2CategoryConfig.values = [];
              this.sobL2CategoryConfig.optional = false;
            }
          }
          else {
            if (this.sobL1CategoryConfig) {
              this.sobL1CategoryConfig.value = null;
              this.sobL1CategoryConfig.optional = true;
            }
            if (this.sobL2CategoryConfig) {
              this.isNewCategories = false;
              this.sobL2CategoryConfig.values = [];
              this.sobL2CategoryConfig.optional = false;
            }
          }
          break;
        case 'sob-l1-categories':
          let newCategoryValue = config.value;
          this.isNewCategories = true;
          if (newCategoryValue) {
            this.sobL2CategoryConfig.values = [];
            this.sobL2CategoryConfig.optional = true;
            this._kService.getL2Categories(newCategoryValue).subscribe(category => {
              this.subCategoriesOfNewCategories = category;
            });
          }
        default:
          break;
      }
    
  }
  disablePlantOnKamHandover(){
    if(this.sobregion && this.sobAccountId){
      return false
    }
    return true;
  }
  loadKamHandoVerMovement(){
     //  for KAM-Hanover
        //get laneconfig fields from lane Id(KAM-Handover)
        this._kService.getLaneConfigById(this.toLane.id).subscribe(res => {
          if (res['status'] == 200) {
             this.kamHandoverLaneConfig = res['result']['config'];
            
          let existingBusinessVertical = this.lanetask.data.find((element) => {
            return element.field == "business-vertical";
          });
          let existingDealRegion = this.lanetask.data.find((element) => {
            return element.field == "region-team";
          });
          if(existingDealRegion){
              this.sobregion=existingDealRegion.value;
          }
          let SOBBusinessVertical = this.kamHandoverLaneConfig.find((element) => {
            return element.field == "sob-business-vertical";
          });
          let SOBOpportunity = this.kamHandoverLaneConfig.find((element) => {
            return element.field == "sob-opportunity-type";
          });
          let SOBL1Category = this.kamHandoverLaneConfig.find((element) => {
            return element.field == "sob-l1-categories";
          });
          let SOBL2Category = this.kamHandoverLaneConfig.find((element) => {
            return element.field == "sob-l2-category-list";
          });
          let SOBPoType = this.kamHandoverLaneConfig.find((element) => {
            return element.field == "sob-po-type";
          });
          if(this.lanetask.displayName){
            this.opportunityname=this.lanetask.displayName;
          }
          this.sobBusinessVerticalConfig=SOBBusinessVertical;
          this.sobBusinessVerticalList=SOBBusinessVertical.dataList;
          this.sobBusinessVerticalListSearch=this.sobBusinessVerticalList;
          if(existingBusinessVertical){
            this.sobBusinessVerticalConfig.value=existingBusinessVertical.value;
          }
          this.sobOpportunityTypeConfig=SOBOpportunity;
          this.sobOpportunityTypeList=SOBOpportunity.dataList;
          this.sobOpportunityTypeListSearch=this.sobOpportunityTypeList;
          
          this.sobL1CategoryConfig=SOBL1Category;
          this.sobL1CategoryList=SOBL1Category.dataList;
          this.sobL1CategoryListSearch=this.sobL1CategoryList;
  
          this.sobL2CategoryConfig=SOBL2Category;
          this.sobL2CategoryList=SOBL2Category.dataList;
          this.sobL2CategoryListSearch=this.sobL2CategoryList;
  
          this.sobPoTypeConfig=SOBPoType;
          this.sobPoTypeList=SOBPoType.dataList;
          this.sobPoTypeListSearch=this.sobPoTypeList;
          this.fetchTeams();
        }
        });
  }
  removeSelectedFile() {
    this.file = null;
  }

  fileUpload(event) {
    console.log(" upload file event: ", event)
    this.isLoading = true;
    if (event[0]) {
      this.file = event[0];
    }
    else {
      this.file = event.target.files[0];
    }
  }
  async uploadAttachment(field: any): Promise<boolean> {
    console.log('uploadAttachment field and file', field, this.file);
    if (this.file) {
        this.isLoading = true;
        const uploadData = new FormData();
        uploadData.append('file', this.file, this.file.name);
        return new Promise<boolean>((resolve, reject) => {
            this._kService.uploadSubDocumentsForConfig(uploadData, null, null, this.laneTaskIdByUrl, field, "config", null).subscribe(file => {
                console.log('is uploadAttachment');
                if (file['status'] == 200) {
                    this.isFileAdded = true;
                    this.isLoading = false;
                    resolve(true);
                } else {
                    resolve(false);
                }
            });
        });
    } else {
        return false;
    }
}

}
