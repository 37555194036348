<div class="eximFilterDialog">
  <div class="headItem">
    <span class="text">
      <span class="material-icons"> filter_list </span>
      Filters
    </span>
    <span class="close" (click)="closeDialog()">
      <i class="ri-close-line"></i>
    </span>
  </div>
  <mat-dialog-content class="scrlContent">
    <h5>Assigned To</h5>
    <div class="searchBox">
      <input type="text" class="srchBox" fxFlex placeholder="Search Assignee" [(ngModel)]="seachAssigneeText"
     (keydown.space)="$event.preventDefault();">
    </div>
    <ul class="checkbox-overflow-1 overflow-scroll-1" [class.show]="show">
      <li *ngFor="let list of assigneeList | filter:seachAssigneeText" >
        <mat-checkbox (change)="getSelectedAssigneeList(list)" [(ngModel)]="list.checked" [disableRipple]="true">{{list.name}}</mat-checkbox>
        
      </li>
    </ul>
    <button (click)="show = !show">{{ show ? 'Show less': 'Show More' }}</button>

    <h5>Tags</h5>
    <div class="searchBox">
      <input type="text" class="srchBox" fxFlex placeholder="Search Tags" [(ngModel)]="seachTagsText"
     (keydown.space)="$event.preventDefault();">
    </div>
    <ul class="bottomGap checkbox-overflow-2 overflow-scroll-1"  [class.show]="showtag">
      <li *ngFor="let tag of allTags | filter:seachTagsText">
        <mat-checkbox (change)="getSelectedTags(tag)" [(ngModel)]="tag.checked"></mat-checkbox>
        <span class="yellow" [style.background]='tag.tagColor'>{{tag.tagName}}</span>
      </li>
    </ul>
    <button (click)="showtag = !showtag">{{ showtag ? 'Show less': 'Show More' }}</button>
    <h5>Status</h5>
    <ul class="bottomGap checkbox-overflow-1 overflow-scroll-1">
      <li *ngFor="let lane of boardLane">
        <mat-checkbox (change)="getSelectedLaneList(lane.laneDTO)" [(ngModel)]="lane.laneDTO.checked">{{lane.laneDTO.name}}</mat-checkbox>
      </li>
    </ul>
  </mat-dialog-content>

  <mat-dialog-actions class="justify-content-end">
    <button mat-raised-button class="cnclbtn" (click)="resetFilters()">Reset</button>
    <button mat-raised-button class="savebtn" (click)="applyFilters()">Apply Filter</button> 
  </mat-dialog-actions>
</div>