<ng-container>

  <div fxLayout="row" fxLayoutAlign="flex-start space-between" class="board-dashboard">
    <div fxLayout="row" class="filter-section chart-section width-100">
      <div fxLayout="row" style="position: relative;" class="width-100">
        <div fxLayout="column" fxLayoutAlign="center center" style="width: 100%;">
         
          <div *ngIf="isLoading" fxLayout="row" fxLayoutAlign="center center">
            <mat-spinner strokeWidth="2" [diameter]="60" [color]="red"></mat-spinner>
          </div>
          <div *ngIf="(!displayedColumns || displayedColumns.length < 1) && !isLoading" fxLayout="row"
            style="position: absolute; bottom: 50%; " fxLayoutAlign="center center">
            No Document to Show.
          </div>
          <div fxLayout="column"  class="section-width width-100 ">
            <div fxLayout="row" fxLayoutAlign="flex-start" class="heading ection-width width-100">
              <span class="dash-button">Monthly Calculation</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="end center" class="section-width width-100 ">
              <div fxLayout="row" class="width-100">
                <div fxLayout="row" class="pagination-wrapper width-100" style="margin-top: 2%;">
                  <mat-paginator #paginator [length]="totalRecords" class="custom-pagintor" [pageSize]="pageSize"
                    (page)="loadDeals()" showFirstLastButtons>
                    <!--  (page)="onScrollDown($event, lane?.laneDTO.id, lane['taskList'].length, lane?.totalRecords, lane['taskList'])" -->
                  </mat-paginator>
                </div>
              </div>
            </div>
            <div   fxLayout="row"  class="sb_table section-width  width-100">
              <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 width-100"
                *ngIf="displayedColumns && displayedColumns.length > 0">
    
                <!-- tag Column -->
                <ng-container *ngFor="let disCol of displayedColumns; let colIndex = index" matColumnDef="{{disCol.field}}">
                  <th mat-header-cell *matHeaderCellDef class="table-header">
                    {{disCol.label}}
                  </th>
                  <td mat-cell *matCellDef="let element" style="text-align: center; cursor: pointer;"
                    (click)="openCardDetails(element)">
                    <div>
                      <span style="text-transform: capitalize;"> {{ getFieldValue(element, disCol.field) }}</span>
                    </div>
                  </td>
                </ng-container>
                
                <tr mat-header-row *matHeaderRowDef="displayedColumns1; sticky: true">
    
                </tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns1; let i = index"
                  [ngClass]="{ 'active-row': i === selectedTrIndex }">
    
                </tr>
              </table>
            </div>
          </div>
          <div fxLayout="row" class="section-width  divider">
            <mat-divider></mat-divider>
          </div>
           <div fxLayout="column"  class="section-width width-100 ">
            <div fxLayout="row" fxLayoutAlign="flex-start" class="heading ection-width width-100">
              <span class="dash-button">Monthly Progress</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="end center" class="section-width width-100 ">
              <div fxLayout="row" class="width-100">
                <div fxLayout="row" class="pagination-wrapper width-100" style="margin-top: 2%;">
                  <mat-paginator #matPaginator [length]="monthlyProgressTotalRecords" class="custom-pagintor" [pageSize]="monthlyProgressPageSize"
                    (page)="loadMonthlyProgressDeals()" showFirstLastButtons>
                    <!--  (page)="onScrollDown($event, lane?.laneDTO.id, lane['taskList'].length, lane?.totalRecords, lane['taskList'])" -->
                  </mat-paginator>
                </div>
              </div>
            </div>
            <div   fxLayout="row"  class="sb_table section-width  width-100">
              <table mat-table [dataSource]="monthlyProgressdataSource" class="mat-elevation-z8 width-100"
                *ngIf="monthlyProgressDisplayedColumns && monthlyProgressDisplayedColumns.length > 0">
              
                <!-- tag Column -->
                <ng-container *ngFor="let disCol of monthlyProgressDisplayedColumns; let colIndex = index" matColumnDef="{{disCol.field}}">
                  <th mat-header-cell *matHeaderCellDef class="table-header">
                    {{disCol.label}}
                  </th>
                  <td mat-cell *matCellDef="let element" style="text-align: center; cursor: pointer;"
                    (click)="openCardDetails(element)">
                    <div>
                      <span style="text-transform: capitalize;"> {{ getFieldValue(element, disCol.field) }}</span>
                    </div>
                  </td>
                </ng-container>
                
                <tr mat-header-row *matHeaderRowDef="monthlyProgressDisplayedColumns1; sticky: true">
    
                </tr>
                <tr mat-row *matRowDef="let row; columns: monthlyProgressDisplayedColumns1; let i = index"
                  [ngClass]="{ 'active-row': i === selectedTrIndex }">
    
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>