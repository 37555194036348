<div>
  <div class="d-flex-rw">
    <section class="pb-15 dialog-heading light-grey-bg" aria-label="heading">
      <div fxLayout="row mb-30 " fxLayoutAlign="space-between flex-center">
        <div class="mb-15">
          <h1 class="modal-heading-2 text-capitalize">Contact Info</h1>
          <!-- <h2 class="modal-heading-4">dssfd</h2> -->
        </div>

        <button mat-mini-fab class="modal-close-btn" mat-dialog-close>
          <i class="ri-close-circle-fill"></i>
        </button>
      </div>
      <div
        class="history-filter"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <div fxLayout="row" fxLayoutAlign="start center">
          <div class="formfield mr-10">
            <label>Search Plant</label>
            <app-field-autocomplete [fieldData]="{
              'config' : accountConfig,
              'isFilter' : true
            }" [modelRef]="accountConfig.value" >
              </app-field-autocomplete>
          </div>
        </div>
      </div>
    </section>
    <div class="mog3_msnsearchtable_sticky history-tab-table bg-dark-grey">
      <table mat-table class="w-100" [dataSource]="dataSource">
        <!-- contactName no. -->

        <ng-container matColumnDef="contactName">
          <th mat-header-cell *matHeaderCellDef>Contact Name</th>
          <td mat-cell *matCellDef="let element">{{ element.contactName }}
            <br>
            <span  class="label-3">{{element.role}}</span>
          </td>
        </ng-container>

        <!-- designation Column -->

        <ng-container matColumnDef="designation">
          <th mat-header-cell *matHeaderCellDef>Designation</th>

          <td mat-cell *matCellDef="let element">{{ element.designation }}</td>
        </ng-container>

        <!-- phoneNumber Column -->

        <ng-container matColumnDef="phoneNumber">
          <th mat-header-cell *matHeaderCellDef>Phone Number</th>

          <td mat-cell *matCellDef="let element">{{ element.phoneNumber }}
            <br>
            <span  class="label-3">{{element.email}}</span>
          </td>
        </ng-container>

        <!-- plant Column -->

        <ng-container matColumnDef="plant">
          <th mat-header-cell *matHeaderCellDef>plant</th>

          <td mat-cell *matCellDef="let element">{{ element.plant }}
            <br>
            <span  class="label-3">{{element.plantLocation}}</span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>

        <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
      </table>
      <div fxLayout="row" fxLayoutAlign="end center" style="width: 100%">
        <div class="d-flex-rw" style="width: 100%">
          <div class="pagination-wrapper" style="width: 100%">
            <!-- <mat-paginator
                  #paginator
                  [hidden]="!dataSource.data.length"
                  [length]="totalRecords"
                  [pageIndex]="pageNumber"
                  class="custom-pagintor"
                  [pageSize]="pageSize"
                  showFirstLastButtons
                  (page)="getLaneTaskHistory()"
                 
                >
             
                </mat-paginator> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
