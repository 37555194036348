import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatOption, MatSelect, MatSelectChange } from '@angular/material';
import { Constants } from 'src/app/Constants';
import { KanbanService } from '../../../kanban.service';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { LabelConstants } from 'src/app/LabelConstants';

@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss']
})
export class CustomSelectComponent implements OnInit {
  loading = false; // Indicates if a request is in progress
  boardId: any;
  pageIndex = -1;
  pageSize = 20;
  pageFrom = 0;
  isEmptyRes: boolean = false;
  pageFromForInputQuery = 0;
  accountIdsList: any = [];
  accountIdsListSearch: any = [];
  totalIdsFoundForSearch = 0;
  @ViewChild('sl') s1: MatSelect;
  localCache: any = localStorage;
  isSOBOpportunity: any = false;
  searchControl = new FormControl();
  userInput: string = null;
  selectedAccountIds: string[] = []; // Assuming selectedAccountIds is an array of strings.
  @Input() fieldData: { config: { key: string; type: string; relationKey: any; isClear: any; values: string; icon: string; additionalData: {}; }; };
  @Output() selectedAccountIdsChange = new EventEmitter<string[]>();
  selectedAccountItems: any[] = [];
  putBackSelectedAccountItems: any[] = [];
  label: string = LabelConstants.accountLabel;
  constructor(public _kService: KanbanService, public cRef: ChangeDetectorRef) { }

  ngOnInit() {
    if (this.fieldData.config.key != null && this.fieldData.config.key.trim() != "") {
      if (this.fieldData.config.type == "belongsTo") {
        this.boardId = this.fieldData.config.relationKey;
        this.loadIdsList();
      }
    }
  }
  ngAfterViewInit() {
    this.localCache = localStorage;
    this.cRef.detectChanges();
    this.searchControl.valueChanges.pipe(debounceTime(300)).subscribe((query: string) => {
        this.performSearch(query);
      });
    
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.fieldData.config.isClear) {
      this.fieldData.config.values = "";
      this.fieldData.config.icon = "";
      this.fieldData.config.additionalData = {};
      this.selectedAccountIdsChange.emit([]);
    }
  }
  onSearch() {
    const query = this.userInput;
    this.performSearch(query);
    if(this.userInput === null || this.userInput.trim() === ''){
      this.pageFrom = 0;
      this.loadIdsList();
    }
  }
  performSearch(query: string) {
    const nameSearchQuery = {
      searchTerm: query,
      size: this.pageSize,
      from: this.pageFromForInputQuery * this.pageSize,
      sort: { field: "updatedAt", order: "desc" },
      suggest: true,
      where: {
        boardId: { 
          type: "search", 
          field: "boardId.raw", 
          value: this.boardId
        },
        name: {
          operator: "or",
          type: "search",
          value: [{ 
            field: "name", 
            sub_type: "suggest", 
            value: query 
          }],
        }
      },
    };
    this._kService.getLaneTask(nameSearchQuery).subscribe(res => {
      if(this.pageFromForInputQuery === 0){
       // this.accountIdsList =  res['result'];
        this.accountIdsListSearch=res['result'];
        //this.accountIdsListSearch.sort((a, b) => { return a.displayName.localeCompare(b.displayName);});
      }
      else{
        this.accountIdsList = this.accountIdsList.concat(res['result']);
      //  this.accountIdsList.sort((a, b) => { return a.displayName.localeCompare(b.displayName);});
        this.accountIdsListSearch = this.accountIdsList;
        
      }
      if(res['result'].length === 0 ){
        this.isEmptyRes = true;
      }
    })
  }
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Backspace') {
      this.pageFromForInputQuery = 0;
    }
    if (event.key === ' ') {
      this.userInput += ' ';
    // Prevent the default space character input behavior
      event.preventDefault();
    }
  }
  unSelect(event: any){
    if (!this.accountIdsListSearch.includes(event)) {
      this.accountIdsListSearch.push(event);
      const index = this.selectedAccountItems.indexOf(event);
      if (index !== -1) {
        this.selectedAccountItems.splice(index, 1);
      }
    }
    this.accountIdsListSearch.sort((a, b) => { return a.displayName.localeCompare(b.displayName);});
    this.selectedAccountItems.sort((a, b) => { return a.displayName.localeCompare(b.displayName);});

  }
  selected(event: any){
      if (!this.selectedAccountItems.includes(event)) {
        this.selectedAccountItems.push(event);
        const index = this.accountIdsListSearch.indexOf(event);
        if (index !== -1) {
          this.accountIdsListSearch.splice(index, 1);
        }
      }
      this.accountIdsListSearch.sort((a, b) => { return a.displayName.localeCompare(b.displayName);});
      this.selectedAccountItems.sort((a, b) => { return a.displayName.localeCompare(b.displayName);});  
  }
  
  
  loadIdsList() {
    this.loading = true;
    if(this.isEmptyRes){
      this.pageFrom = 0;
    }
    let obj = {
      "where": {
        "boardId": {
          "type": "search",
          "operator": "or",
          "value": [
            {
              "field": "boardId.raw",
              "value": this.boardId
            }
          ]
        }
      },
      "sort": { "field": "updatedAt", "order": "desc" },
      "from": this.pageFrom * this.pageSize,
      "size": this.pageSize
    }
    this.getLaneTaskAccountList(obj);
  }
  getLaneTaskAccountList(obj) {
    this._kService.getLaneTask(obj).subscribe(res => {
      if (res == null) {
        return;
      }
      else {
        this.accountIdsList = this.accountIdsList.concat(res['result']);
        this.accountIdsListSearch = this.accountIdsList;
        this.loading = false;
      }
      this.pageIndex++;
      this.pageFrom++;
    },
      (error) => {
        console.log('Error while getting accounts',error);
      }
    );
    if(this.s1){
      this.s1.open();
    }
  }
  onScroll(event: any) {
    console.log('onScroll')
    const element = event.target;
    const roundedScrollTop = Math.round(element.scrollTop);
    const isScrollNearBottom = element.scrollHeight - roundedScrollTop <= element.clientHeight;
    if (isScrollNearBottom && !this.loading && this.userInput===null) {
      this.loadIdsList();
    }
    if (isScrollNearBottom && !this.loading && this.userInput!==null && !this.isEmptyRes) {
      this.pageFromForInputQuery++;
      this.onSearch();
    }
  }
  applySelections() {
    this.selectedAccountIdsChange.emit(this.selectedAccountIds);
  }
  clearSelections() {
    this.userInput = null;
    this.selectedAccountIds = [];
    this.selectedAccountItems = [];
    this.pageFrom=0;
    this.pageFromForInputQuery;
    this.selectedAccountIdsChange.emit([]);
  }
}
