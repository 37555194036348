<div class="costcoDialog bg-white" [ngClass]="{ 'bg-grey': boardId == boardIdForSobAccount }" fxLayout="row">
  <div class="lft" fxLayout="row" fxLayoutAlign="center">
    <div class="inner" fxLayout="column" [ngClass]="{ 'p-0': boardId == boardIdForSobAccount }"
      style="position: relative; overflow: auto; padding-top: 0px">
      <section aria-label="heading">
        <div fxLayout="row" fxLayoutAlign="space-between center" class="card-header">
          <div style="width: 40%; padding: 0.7%; font-size: 14px" fxLayout="row" fxLayoutAlign="start">
            <div class="headLbl" *ngIf="laneTaskIDDetail != null">
              <span class="breadcrumb-text">
                <span class="text-red d-inline-flex" style="cursor: pointer" (click)="navigation.goBack()" *ngIf="
                    laneTaskIDDetail != null &&
                    laneTaskIDDetail.boardId != '63bfa8ccade7bc466d1c780e' &&
                    laneTaskIDDetail.boardId != boardIdForSobOpportunity
                  ">
                  {{ laneTaskIDDetail?.lane.board.name }}
                  <i class="ri-arrow-right-s-line"></i>
                </span>
                <span class="text-red d-inline-flex" style="cursor: pointer" (click)="navigation.goBack()" *ngIf="
                    laneTaskIDDetail != null &&
                    laneTaskIDDetail.boardId == '63bfa8ccade7bc466d1c780e'
                  ">
                  Deals <i class="ri-arrow-left-s-line"></i></span>
                <span class="text-red d-inline-flex" style="cursor: pointer" (click)="navigation.goBack()" *ngIf="
                    laneTaskIDDetail != null &&
                    laneTaskIDDetail.boardId == boardIdForSobOpportunity
                  ">
                  Opportunity <i class="ri-arrow-left-s-line"></i></span>
                <ng-container *ngIf="laneTaskIDDetail.boardId != boardIdForSobOpportunity">{{ laneTaskIDDetail?.name
                  }}</ng-container>
                <ng-container *ngIf="laneTaskIDDetail.boardId == boardIdForSobOpportunity">{{
                  laneTaskIDDetail.searchData.dealname }}</ng-container>
              </span>
            </div>
          </div>
          <div style="width: 60%" fxLayout="row" fxLayoutAlign="flex-end center">
            <div fxLayout="row" fxLayoutAlign="start center" (click)="showHistory('history')"
              class="action-tab-margin header-button-height">
              <div fxLayout="row" fxLayoutAlign="center center" class="comments-icon-div">
                <img src="../../../../../../assets/images/history.svg" alt="" />
              </div>
              <div fxLayout="column" fxLayoutAlign="center start" *ngIf="laneTaskIDDetail != null">
                <span class="comments-label" *ngIf="
                    laneTaskIDDetail.assignee == null ||
                    laneTaskIDDetail.assignee.length == 0
                  ">Assigned To
                </span>
                <ng-container *ngIf="
                    laneTaskIDDetail.assignee != null &&
                    laneTaskIDDetail.assignee.length > 0
                  ">
                  <p class="comments-label">History</p>
                </ng-container>
              </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" (click)="showActionItems()" class="action-tab-margin"
              *ngIf="
                boardId === boardIdForCRMDeals ||
                boardId === boardIdForSobOpportunity
              " [class.disabledDivWithChild]="
                currentSelectedLaneId === kamHandoverLaneId
              ">
              <div fxLayout="row" fxLayoutAlign="center center" class="comments-icon-div">
                <img src="../../../../../../assets/images/action_item.svg" alt="" />
              </div>
              <div fxLayout="column" fxLayoutAlign="center start">
                <span class="comments-label">Action Items</span>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" (click)="showComments()"
              *ngIf="boardId !== boardIdForSobOpportunity" class="action-tab-margin" [class.disabledDivWithChild]="
                currentSelectedLaneId === kamHandoverLaneId
              ">
              <div fxLayout="row" fxLayoutAlign="center center" class="comments-icon-div">
                <img src="../../../../../../assets/images/note.svg" alt="" />
              </div>
              <div fxLayout="column" fxLayoutAlign="center start">
                <span class="comments-label">Notes</span>
              </div>
            </div>
            <button mat-flat-button class="mog3_primary_flat_btn mr-10" (click)="saveLaneTask()" aria-label="Save"
              [class.disabledDivWithChild]="
                currentSelectedLaneId === kamHandoverLaneId || currentSubLaneId === serviceAbilitySubLaneId
              " matTooltip="Save" matTooltipClass="new-tooltip" *ngIf="laneTaskIDDetail?.canEdit">
              <i class="ri-save-2-fill"></i> Save Changes
            </button>
          </div>
        </div>
      </section>
      <section aria-label="lead-info" class="lead-info" fxLayout="row" fxLayoutAlign="start center"
        *ngIf="boardId !== boardIdForSobAccount">
        <div fxLayout="column" style=" width: 100%; overflow: auto" *ngIf="
            laneTaskIDDetail != null && laneTaskIDDetail.laneType == 'form'">
          <div fxLayout="row" fxLayoutAlign="space-between start ">
            <div class="status-tabs mb-10" *ngIf="boardLane && boardLane.length > 1">
              <div class="d-flex">
                <h4>Move Stage</h4>
                <span class="stage-history">
                  <ng-container *ngIf="laneTaskIDDetail.lastStageMovementDate">| &nbsp; Stage updated on:
                    {{
                    formatDisplayDate(laneTaskIDDetail.lastStageMovementDate)
                    }}
                  </ng-container>
                  <ng-container *ngIf="laneTaskIDDetail.nextActionDate">&nbsp; | &nbsp; Next Action Date:
                    {{ formatDisplayDate(laneTaskIDDetail.nextActionDate) }}
                  </ng-container></span>
              </div>
              <!-- Stage Movement -->
              <ul class="d-flex">
                <li *ngFor="let opt of boardLane">
                  <ng-container>
                    <button mat-flat-button class="status-tab" [ngClass]="{
                        active: opt.currentLane,
                        'next-active': opt.movementAllowed
                      }" (click)="opt.movementAllowed && showLaneTaskMove(opt)" [matTooltip]="opt.description"
                      matTooltipClass="new-tooltip">
                      {{ opt.displayName }}
                    </button>
                  </ng-container>
                </li>
              </ul>
              <!-- Lane Task  Modified At-->
              <span class="stage-history ml-0">
                Last Modified:
                {{ formatDisplayDate(laneTaskIDDetail.updatedAt) }}
              </span>
            </div>

            <div *ngIf="boardLane && boardLane.length < 1">
              <!-- blank div added for balancing the space do not remove -->
            </div>
            <!-- <button mat-flat-button class="mog3_primary_flat_btn ml-auto" (click)="saveLaneTask()" aria-label="Save"
              [class.disabledDivWithChild]="
                currentSelectedLaneId === kamHandoverLaneId
              " matTooltip="Save" matTooltipClass="new-tooltip" *ngIf="laneTaskIDDetail?.canEdit">
              <i class="ri-save-2-fill"></i> Save Changes
            </button> -->
          </div>
          <!-- W1 Check Box-->
          <div class="mb-10" *ngIf="boardId === boardIdForCRMDeals">
            <mat-checkbox [(ngModel)]="isW1Checked" [disabled]="disableW1checkBox">
              <label class="label-1">W1 </label>
            </mat-checkbox>
            <span class="won-label"> | {{ rfqCapturedText }}</span>
          </div>
          <!-- W1 data -->
          <div *ngIf="boardId === boardIdForCRMDeals" class="wonWrapper won-section"
            [ngClass]="{ disabledW1: disableW1checkBox }">
            <ul class="card-detail-list" *ngIf="shouldShowUlElement()">
              <li class="lane-grid">
                <div fxLayout="row" fxLayoutAlign="start space-around" style="margin-right: 1rem; width: 100%">
                  <div fxLayout="column" class="label-width" style="width: 40%">
                    <label class="label-1">Customer Id :</label>
                  </div>
                  <div fxLayout="column" class="field-width" style="width: 60%; align-items: center">
                    <mat-form-field class="mat-input lane-card-input" appearance="outline">
                      <input matInput type="text" class="input-field" [(ngModel)]="customerId" />
                    </mat-form-field>
                  </div>
                </div>
              </li>
              <li class="lane-grid">
                <div fxLayout="row" fxLayoutAlign="start space-around" style="margin-right: 1rem; width: 100%">
                  <div fxLayout="row" class="label-width" style="width: 40%">
                    <label class="label-1">Plant Id :</label>
                  </div>
                  <div fxLayout="column" class="field-width" style="width: 60%">
                    <mat-form-field class="mat-input lane-card-input" appearance="outline">
                      <input matInput type="text" class="input-field" [(ngModel)]="plantId" />
                    </mat-form-field>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <ul class="customer-profile-list my-0 mt-10">
            <!-- All Lane Task Fields -->
            <ng-container *ngFor="let formItem of laneArrayKeys; let i = index">
              <ng-container *ngIf="formItem.config.display">
                <li class="lane-grid" *ngIf="shouldDisplayLi(formItem)" [ngStyle]="{'width': getColumnWidth(formItem)}">
                  <div class="atom-row" style=" width: 100%"  [ngClass]="{'flex-col': formItem.config.type === 'table' || formItem.config.type === 'form'}">
                      <!-- label row -->
                      <div fxLayout="row" class="label-width items-start atom-col-sm-12">
                        <label *ngIf="shouldDisplayLabel(formItem)" class="label-1" [ngClass]="{required: isRequired(formItem)}">{{ formItem.config.displayName }}</label>
                      </div>
                      
                      <!-- field row -->
                      <div fxLayout="column" [ngSwitch]="formItem.config.type" class="atom-col-sm-12"  [class.disabledDivWithChild]="currentSelectedLaneId === kamHandoverLaneId ">
                      
                        <ng-container *ngSwitchCase="'string'">
                          <mat-form-field *ngIf="formItem.config.field !== 'event-name'" class="mat-input lane-card-input" appearance="outline" [ngClass]="{'error-border': formItem.config.error}">
                            <input matInput [id]="formItem.config.field" type="text" class="input-field" [disabled]="!formItem.config.fieldEditable" [(ngModel)]="formItem.config.value" />
                          </mat-form-field>

                          <mat-form-field *ngIf="formItem.config.field === 'event-name' && formItem.config.optional === true" class="mat-input lane-card-input" appearance="outline" [ngClass]="{'error-border': formItem.config.error}">
                            <input matInput [id]="formItem.config.field" type="text" class="input-field" [disabled]="!formItem.config.fieldEditable" [(ngModel)]="formItem.config.value" />
                          </mat-form-field>
                        </ng-container>

                        <mat-form-field *ngSwitchCase="'email'" class="mat-input lane-card-input" appearance="outline" [ngClass]="{'error-border': formItem.config.error}">
                          <input matInput [id]="formItem.config.field" type="email" name="email" [email]="true"
                            class="input-field" [(ngModel)]="formItem.config.value"
                            (input)="validateEmail(formItem.config)" />
                        </mat-form-field>

                        <mat-form-field *ngSwitchCase="'currency'" class="mat-input lane-card-input" appearance="outline" [ngClass]="{'error-border': formItem.config.error}">
                          <input matInput [id]="formItem.config.field" type="text" class="input-field" min="0"
                            pattern="^\d*\.?\d*$"
                            oninput="validity.valid||(value=''); this.value = this.value.replace(/^0+(\d)/, '$1')"
                            [(ngModel)]="formItem.config.value" />
                        </mat-form-field>

                        <mat-form-field *ngSwitchCase="'number'" class="mat-input lane-card-input" appearance="outline" [ngClass]="{'error-border': formItem.config.error}">
                          <ng-container *ngIf="formItem.config.field != 'probability-of-conversion'">
                            <input matInput [id]="formItem.config.field" type="text" class="input-field" min="0"
                            pattern="^\d*\.?\d*$"
                            oninput="validity.valid||(value=''); this.value = this.value.replace(/^0+(\d)/, '$1')"
                            [(ngModel)]="formItem.config.value" />
                          </ng-container>
                          <ng-container *ngIf="formItem.config.field == 'probability-of-conversion'">
                            <input matInput [id]="formItem.config.field" class="input-field" min="0" max="100"
                              oninput="this.value = this.value === '' ? '' : Math.min(Math.max(parseInt(this.value) || 0, 0), 100)"
                              [(ngModel)]="formItem.config.value" />
                          </ng-container>
                        </mat-form-field>

                        <mat-form-field *ngSwitchCase="'phone'" class="mat-input lane-card-input" appearance="outline" [ngClass]="{'error-border': formItem.config.error}">
                          <input matInput [id]="formItem.config.field" *ngIf="boardId != boardIdForCRMContacts" type="text"
                            class="input-field" pattern="\d*" placeholder="0000000000" [(ngModel)]="formItem.config.value" />
                          <input matInput [id]="formItem.config.field" *ngIf="
                              !isCountryIndia && boardId == boardIdForCRMContacts
                            " type="text" pattern="\d*" placeholder="0000000000" class="input-field"
                            [(ngModel)]="formItem.config.value" (input)="
                              validateLength(formItem, formItem.config.value.length)
                            " />
                          <input matInput [id]="formItem.config.field" type="text"
                            *ngIf="isCountryIndia && boardId == boardIdForCRMContacts" pattern="\d*" placeholder="0000000000"
                            class="input-field" [(ngModel)]="formItem.config.value" (input)="validateLength(formItem, 10)"
                            [minLength]="isCountryIndia ? 10 : null" [maxlength]="isCountryIndia ? 10 : null" />
                        </mat-form-field>

                        <mat-form-field *ngSwitchCase="'dropdown'" appearance="outline" class="mat-select" [ngClass]="{'error-border': formItem.config.error}">
                          <mat-select [id]="formItem.config.field" [(ngModel)]="formItem.config.value">
                            <mat-option *ngFor="let opt of formItem.config.dataList" [value]="opt">{{ opt }}</mat-option>
                          </mat-select>
                        </mat-form-field>

                        <mat-form-field *ngSwitchCase="'boolean'" appearance="outline" class="mat-select" [ngClass]="{'error-border': formItem.config.error}">
                          <span *ngIf="formItem.config.optional" class="mandatory-icon">*</span>
                          <mat-select [id]="formItem.config.field" [(ngModel)]="formItem.config.value">
                            <mat-option value="">N/A</mat-option>
                            <mat-option value="Yes">Yes</mat-option>
                            <mat-option value="No">No</mat-option>
                          </mat-select>
                        </mat-form-field>

                        <ng-container *ngSwitchCase="'date'">
                          <mat-form-field *ngIf="formItem.config.field !== 'event-date'" class="mat-input lane-card-input" appearance="outline" [ngClass]="{'error-border': formItem.config.error}">
                            <input matInput (focus)="effectivePicker.open()" [matDatepicker]="effectivePicker" [min]="minDateForNextAction" placeholder="Select Date" [(ngModel)]="formItem.config.value" readonly />
                            <mat-datepicker-toggle matSuffix [for]="effectivePicker"></mat-datepicker-toggle>
                            <mat-datepicker #effectivePicker></mat-datepicker>
                          </mat-form-field>
                          <mat-form-field *ngIf="formItem.config.field === 'event-date' && formItem.config.optional === true" class="mat-input lane-card-input" appearance="outline" [ngClass]="{'error-border': formItem.config.error}">
                            <input matInput (focus)="effectivePicker.open()" [matDatepicker]="effectivePicker" placeholder="Select Date" [(ngModel)]="formItem.config.value" readonly />
                            <mat-datepicker-toggle matSuffix [for]="effectivePicker"></mat-datepicker-toggle>
                            <mat-datepicker #effectivePicker></mat-datepicker>
                          </mat-form-field>
                        </ng-container>                  

                        <ng-container *ngSwitchCase="'multiSelect'">
                        <ng-container *ngIf="(formItem.config.key == 'string' || formItem.config.key == 'number') &&  formItem.config.field != 'new-category-list' &&  
                        formItem.config.field != 'sob-l2-category-list'">
                        <div class="mat-input lane-card-input">
                          <div class="input-group-1">
                            <app-custom-multiple-select [label]="formItem.config.displayName" [ngClass]="{'error-border': formItem.config.error}"
                                [selectedValues]="formItem.config.values"
                                [selectedHighlight]="false"
                                [valueCountView]="true"
                                [availableOptions]="getFilteredOptions(formItem)" 
                                [searchControl]="searchControl"
                                (selectionChange)="getSelectionChange($event, formItem)"
                                (searchChange)="searchChange($event, formItem)">
                              </app-custom-multiple-select>
                          </div>
                        </div>

                        </ng-container>
                          <!-- <mat-form-field appearance="outline" class="mat-select" *ngIf="(formItem.config.key == 'string' || formItem.config.key == 'number') &&  formItem.config.field != 'new-category-list' &&  formItem.config.field != 'sob-l2-category-list'" [ngClass]="{'error-border': formItem.config.error}">
                            <mat-select multiple [id]="formItem.config.field" [(ngModel)]="formItem.config.values">
                              <mat-form-field class="mat-input icon " appearance="outline">
                                <i class="ri-search-line"></i>
                                <input type="text" matInput placeholder="Search" [(ngModel)]="searchControl" (keyup)="filterOptionsConfigDataList(formItem)">
                              </mat-form-field>

                              <mat-select-trigger>
                                <span class="test" *ngIf="formItem.config.values && formItem.config.values.length > 0">{{ formItem.config.values[0] }}</span>
                                <span class="test2" *ngIf="formItem.config.values && formItem.config.values > 1" class="example-additional-selection">
                                  (+ {{ formItem.config.values.length - 1 }} {{ formItem.config.values?.length === 2 ? "other"  : "others" }})
                                </span>
                              </mat-select-trigger>
                              
                              <mat-option *ngFor="let opt of getFilteredOptions(formItem)" [value]="opt">{{ opt }}</mat-option>

                            </mat-select>
                          </mat-form-field> -->

                          <mat-form-field appearance="outline" class="mat-select" *ngIf="
                              formItem.config.field === 'new-category-list' &&
                              shouldDisableField(formItem.config.field)" [ngClass]="{'error-border': formItem.config.error}">
                            <mat-select multiple [id]="formItem.config.field" [(ngModel)]="formItem.config.values">
                              <mat-select-trigger>
                                <ng-container *ngIf="
                                    formItem.config.values &&
                                    formItem.config.values.length > 0
                                  ">
                                  <span class="test">{{
                                    formItem.config.values[0]
                                    }}</span>
                                  <span class="test2" *ngIf="formItem.config.values.length > 1"
                                    class="example-additional-selection">
                                    (+ {{ formItem.config.values.length - 1 }}
                                    {{
                                    formItem.config.values.length === 2
                                    ? "other"
                                    : "others"
                                    }})
                                  </span>
                                </ng-container>
                              </mat-select-trigger>

                              <mat-option *ngFor="let opt of subCategoriesOfNewCategories" [value]="opt">{{ opt
                                }}</mat-option>
                            </mat-select>
                          </mat-form-field>
                          <mat-form-field appearance="outline" class="mat-select"
                            *ngIf="formItem.config.field === 'sob-l2-category-list'" [ngClass]="{'error-border': formItem.config.error}">
                            <mat-select multiple [id]="formItem.config.field" [(ngModel)]="formItem.config.values">
                              <mat-select-trigger>
                                <ng-container *ngIf="
                                    formItem.config.values &&
                                    formItem.config.values.length > 0
                                  ">
                                  <span class="test">{{
                                    formItem.config.values[0]
                                    }}</span>
                                  <span class="test2" *ngIf="formItem.config.values.length > 1"
                                    class="example-additional-selection">
                                    (+ {{ formItem.config.values.length - 1 }}
                                    {{
                                    formItem.config.values.length === 2
                                    ? "other"
                                    : "others"
                                    }})
                                  </span>
                                </ng-container>
                              </mat-select-trigger>

                              <mat-option *ngFor="let opt of subCategoriesOfNewCategories" [value]="opt">{{ opt
                                }}</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </ng-container>

                        <ng-container *ngSwitchCase="'select'">
                          <ng-container
                            *ngIf="formItem.subKeys !== 'industry' && formItem.subKeys !== 'business-vertical' && formItem.subKeys !=='deal-type' 
                            && formItem.subKeys !=='lead-source' && formItem.subKeys !=='new-categories' && formItem.subKeys !=='role' && formItem.subKeys !=='role-type'">
                            <mat-form-field appearance="outline" class="mat-select "
                              *ngIf="formItem.config.key == 'string' && !shouldDisableField(formItem.config.field)" [ngClass]="{'error-border': formItem.config.error}">
                              <mat-select [id]="formItem.config.field" [(ngModel)]="formItem.config.value"
                                (selectionChange)="onSelectOption(formItem, formItem.config)">
                                <mat-option *ngFor="let opt of getOptionList(formItem.config.dataList, formItem.config.field)" [value]="opt">{{ opt
                                  }}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </ng-container>

                          <ng-container *ngIf="formItem.subKeys === 'industry'">
                            <app-custom-single-select [formItem]="formItem.config" [searchControl]="searchControlIndustry"
                              [optionList]="industryListSearch" (selectionChange)="onIndustrySelectionChange($event)"
                              (searchChange)="onIndustrySearchChange($event)">
                            </app-custom-single-select>
                          </ng-container>

                          <ng-container *ngIf="formItem.subKeys === 'business-vertical'">
                            <app-custom-single-select class="full-width" [formItem]="formItem.config"
                              *ngIf="!shouldDisableField(formItem.field)" [searchControl]="searchControlBusinessVertical"
                              [optionList]="businessVerticalListSearch" (selectionChange)="
                                onSelectOptionSelectionChange($event)
                              " (searchChange)="filterOptionsBusinessVertical($event)">
                            </app-custom-single-select>
                          </ng-container>

                          <ng-container *ngIf="formItem.subKeys === 'deal-type'">
                            <app-custom-single-select class="full-width" [formItem]="formItem.config"
                              *ngIf="!shouldDisableField(formItem.field)" [searchControl]="searchControlDealType"
                              [optionList]="dealTypeListSearch" (selectionChange)="
                                onSelectOptionSelectionChange($event)
                              " (searchChange)="filterOptionsDealType($event)">
                            </app-custom-single-select>
                          </ng-container>

                          <ng-container *ngIf="formItem.subKeys === 'lead-source'">
                            <app-custom-single-select class="full-width" [formItem]="formItem.config"
                              *ngIf="!shouldDisableField(formItem.field)" [searchControl]="searchControlLeadSource"
                              [optionList]="leadSourceListSearch" (selectionChange)="
                                onSelectOptionSelectionChange($event)
                              " (searchChange)="filterOptionsLeadSource($event)">
                            </app-custom-single-select>
                          </ng-container>

                          <ng-container *ngIf="formItem.subKeys === 'new-categories'">
                            <app-custom-single-select class="full-width" *ngIf="isDealTypeNewCategories"
                              [formItem]="formItem.config" [searchControl]="searchControlL1Category"
                              [optionList]="newCategoryListSearch" (selectionChange)="
                                onSelectOptionSelectionChange($event)
                              " (searchChange)="filterOptionsL1CategoryList($event)">
                            </app-custom-single-select>
                          </ng-container>

                          <ng-container *ngIf="formItem.subKeys === 'role'">
                            <app-custom-single-select class="full-width" [formItem]="formItem.config"
                              [searchControl]="searchControlRoleName" [optionList]="roleNameListSearch"
                              (selectionChange)="onSelectOptionSelectionChange($event)"
                              (searchChange)="filterOptionsRoleNameList($event)">
                            </app-custom-single-select>
                          </ng-container>

                          <ng-container *ngIf="formItem.subKeys === 'role-type'">
                            <app-custom-single-select class="full-width" [formItem]="formItem.config"
                              [searchControl]="searchControlRoleType" [optionList]="roleTypeListSearch"
                              (selectionChange)="onSelectOptionSelectionChange($event)"
                              (searchChange)="filterOptionsRoleType($event)">
                            </app-custom-single-select>
                          </ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="'belongsTo'">
                          <ng-container *ngIf="
                              ((formItem.config.field != 'contact' &&
                                formItem.config.field != 'plant' &&
                                formItem.config.field != 'plant-name') ||
                                (formItem.config.field == 'contact' &&
                                  boardId != boardIdForCRMDeals &&
                                  boardId != boardIdForSobOpportunity) ||
                                (boardId === boardIdForSobContacts &&
                                  formItem.config.field != 'plant') ||
                                (boardId === boardIdForSobOpportunity &&
                                  formItem.config.field != 'plant-name' &&
                                  formItem.config.field != 'contact')) &&
                              formItem.config.field != 'region-team' && formItem.config.field !== 'add-ceo' && formItem.config.field !== 'private-owner'
                            ">
                            <app-field-autocomplete [id]="formItem.config.field" class="w-100 lane-card-input mb-025em"
                              [fieldData]="{ config: formItem.config, isFilter: true, selectedBoard: boardId ,'selectedHighlight':false}"
                              [modelRef]="formItem.config.value" (filterApplied)="setPlantIdAndAccountId(formItem)">
                            </app-field-autocomplete>
                          </ng-container>

                          <ng-container *ngIf="formItem.config.field == 'contact' && boardId === boardIdForCRMDeals">
                            <div fxLayout="row" fxLayoutAlign="start space-around" *ngIf="boardId === boardIdForCRMDeals"
                              style="margin-right: 1rem; width: 100%">
                              <div fxLayout="column" class="field-width" style="width: 100%">
                                <mat-form-field class="mat-input lane-card-input" appearance="outline">
                                  <input matInput placeholder="Select from contact book" (click)="contactInfo()"
                                    class="input-field" />
                                  <svg (click)="contactInfo()" class="contact-icon" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24" width="16" height="16">
                                    <path
                                      d="M7 2V22H3V2H7ZM9 2H19.0049C20.1068 2 21 2.89821 21 3.9908V20.0092C21 21.1087 20.1074 22 19.0049 22H9V2ZM22 6H24V10H22V6ZM22 12H24V16H22V12ZM15 12C16.1046 12 17 11.1046 17 10C17 8.89543 16.1046 8 15 8C13.8954 8 13 8.89543 13 10C13 11.1046 13.8954 12 15 12ZM12 16H18C18 14.3431 16.6569 13 15 13C13.3431 13 12 14.3431 12 16Z"
                                      fill="rgba(57,127,244,1)"></path>
                                  </svg>
                                </mat-form-field>
                                <a class="red-anchor-2" (click)="addNewLead()"><span>Add Contact</span></a>
                                <div class="contact-detail-section" *ngIf="boardId === boardIdForCRMDeals">
                                  <ul class="contact-detail-list">
                                    <ng-container *ngFor="
                                        let contact of contactDetails;
                                        let i = index
                                      ">
                                      <li *ngIf="contact && i < 2">
                                        <!-- Display only first two contacts -->
                                        <ul>
                                          <li class="info-list">
                                            <div class="field-name">Name :</div>
                                            <div class="field-value">
                                              <span>{{ contact.displayName }}</span>
                                              <!-- <a class="remove-anchor" (click)="removeContact(contact)">Remove</a> -->
                                            </div>
                                          </li>
                                          <li class="info-list">
                                            <div class="field-name">
                                              Designation :
                                            </div>
                                            <div class="field-value">
                                              {{ contact.searchData.designation }}
                                            </div>
                                          </li>
                                          <br />
                                          <!-- <li class="info-list">
                                          <div class="field-name">Email :</div>
                                          <div class="field-value">{{ contact.data[5].value }}</div>
                                        </li> -->
                                        </ul>
                                      </li>
                                    </ng-container>
                                  </ul>
                                  <div *ngIf="contactDetails.length == 0">
                                    <span>No Contact to display.</span>
                                  </div>
                                  <a (click)="contactInfo()" class="blue-anchor-2 mt-20">{{
                                    contactDetails.length > 2
                                    ? "View
                                    all " + contactDetails.length
                                    : ""
                                    }}</a>
                                  <br />
                                </div>
                              </div>
                            </div>
                          </ng-container>

                          <ng-container *ngIf="formItem.config.field == 'contact' && boardId === boardIdForSobOpportunity">
                            <div fxLayout="row" fxLayoutAlign="start space-around" style="width: 100%">
                              <div fxLayout="column" class="field-width" style="width: 100%">
                                <mat-form-field class="mat-input lane-card-input" appearance="outline">
                                  <input matInput placeholder="Select from contact book" (click)="contactInfo()"
                                    class="input-field" />
                                  <svg (click)="contactInfo()" class="contact-icon" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24" width="16" height="16">
                                    <path
                                      d="M7 2V22H3V2H7ZM9 2H19.0049C20.1068 2 21 2.89821 21 3.9908V20.0092C21 21.1087 20.1074 22 19.0049 22H9V2ZM22 6H24V10H22V6ZM22 12H24V16H22V12ZM15 12C16.1046 12 17 11.1046 17 10C17 8.89543 16.1046 8 15 8C13.8954 8 13 8.89543 13 10C13 11.1046 13.8954 12 15 12ZM12 16H18C18 14.3431 16.6569 13 15 13C13.3431 13 12 14.3431 12 16Z"
                                      fill="rgba(57,127,244,1)"></path>
                                  </svg>
                                </mat-form-field>
                                <a class="red-anchor-2" (click)="addNewLeadOppContact()"><span>Add Contact</span></a>
                                <div class="contact-detail-section">
                                  <ul class="contact-detail-list">
                                    <ng-container *ngFor="
                                        let contact of contactDetails;
                                        let i = index
                                      ">
                                      <li *ngIf="contact && i < 2">
                                        <!-- Display only the first two contacts -->
                                        <ul>
                                          <li class="info-list">
                                            <div class="field-name">Name:</div>
                                            <div class="field-value">
                                              <span>{{ contact.displayName }}</span>
                                              <!-- <a class="remove-anchor" (click)="removeContact(contact)">Remove</a> -->
                                            </div>
                                          </li>
                                          <li class="info-list">
                                            <div class="field-name">Designation:</div>
                                            <div class="field-value">
                                              {{ contact.searchData.designation }}
                                            </div>
                                          </li>
                                          <br />
                                          <!-- <li class="info-list">
                                            <div class="field-name">Email:</div>
                                            <div class="field-value">{{ contact.data[5].value }}</div>
                                          </li> -->
                                        </ul>
                                      </li>
                                    </ng-container>
                                  </ul>
                                  <div *ngIf="contactDetails.length === 0">
                                    <span>No Contact to display.</span>
                                  </div>
                                  <a (click)="contactInfo()" class="blue-anchor-2 mt-20">{{
                                    contactDetails.length > 2
                                    ? "View
                                    all " + contactDetails.length
                                    : ""
                                    }}</a>
                                  <br />
                                </div>
                              </div>
                            </div>
                          </ng-container>

                          <ng-container *ngIf="formItem.config.field == 'plant' || formItem.config.field == 'plant-name'">
                            <mat-form-field appearance="outline" class="mat-select" [ngClass]="{'error-border': formItem.config.error}">
                              <mat-select #sl2 placeholder="Plants" multiple [(ngModel)]="selectedPlantIds" (selectionChange)="applySelections()">
                                <mat-form-field class="mat-input select-search" appearance="outline">
                                  <input type="text" matInput placeholder="Search" [(ngModel)]="searchControl"
                                    (keyup)="filterOptions($event)" />
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="14" height="16">
                                    <path
                                      d="M18.031 16.6168L22.3137 20.8995L20.8995 22.3137L16.6168 18.031C15.0769 19.263 13.124 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20 13.124 19.263 15.0769 18.031 16.6168ZM16.0247 15.8748C17.2475 14.6146 18 12.8956 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18C12.8956 18 14.6146 17.2475 15.8748 16.0247L16.0247 15.8748Z"
                                      fill="rgba(111,111,112,1)"></path>
                                  </svg>
                                </mat-form-field>

                                <div class="custom-panel search-with-options">
                                  <mat-option *ngFor="let contact of accountContactListSearch" [value]="contact.id">
                                    {{
                                    contact.name +
                                    " (" +
                                    (contact.searchData.plantid
                                    ? contact.searchData.plantid
                                    : "N/A") +
                                    ")"
                                    }}
                                  </mat-option>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="flex-end space-between" class="filter-btn-section">
                                  <button class="filter-btn clear" (click)="clearSelections()">
                                    Clear
                                  </button>
                                  <!-- <button class="filter-btn apply" (click)="applySelections(); sl2.close()">
                                    Apply
                                  </button> -->
                                </div>
                              </mat-select>
                              <div *ngIf ="isLoading" fxLayout="row" class="sp-cont">
                                <mat-spinner strokeWidth="2" [diameter]="20" [color]="red"></mat-spinner>
                              </div>
                            </mat-form-field>
                          </ng-container>

                          <ng-container *ngIf="formItem.config.field == 'region-team'">
                            <mat-form-field appearance="outline" class="mat-select" [ngClass]="{'error-border': formItem.config.error}">
                              <mat-select #sl2 placeholder="Region/Teams" [(ngModel)]="formItem.config.value">
                                <mat-form-field class="mat-input select-search" appearance="outline">
                                  <input type="text" matInput placeholder="Search" [(ngModel)]="searchControlRegionTeam"
                                    (keyup)="filterOptionsRegionTeam()" />
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="14" height="16">
                                    <path
                                      d="M18.031 16.6168L22.3137 20.8995L20.8995 22.3137L16.6168 18.031C15.0769 19.263 13.124 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20 13.124 19.263 15.0769 18.031 16.6168ZM16.0247 15.8748C17.2475 14.6146 18 12.8956 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18C12.8956 18 14.6146 17.2475 15.8748 16.0247L16.0247 15.8748Z"
                                      fill="rgba(111,111,112,1)"></path>
                                  </svg>
                                </mat-form-field>
                                <div class="custom-panel search-with-options">
                                  <mat-option *ngFor="let region of regionTeamListSearch" [value]="region.id">
                                    {{ region.name }}
                                  </mat-option>
                                </div>
                              </mat-select>
                            </mat-form-field>
                          </ng-container>

                          <ng-container *ngIf="formItem.config.field == 'add-ceo'">
                          
                            <app-custom-multiple-select [label]="'Select CEO'" class="mat-select mat-select-search"
                                        [selectedValues]="selectedCEOIds"
                                        [selectedHighlight]="false"
                                        [valueCountView]="true"
                                        [availableOptions]="accountContactCeoListSearch" 
                                        [searchControl]="searchControl"
                                        (selectionChange)="applyCeo($event)"
                                        (searchChange)="filterOptionsCeo($event)">
                            </app-custom-multiple-select>


                          </ng-container>

                          <ng-container *ngIf="formItem.config.field == 'private-owner'">
                          
                            <app-custom-multiple-select [label]="'Private Owner'" class="mat-select mat-select-search"
                                        [selectedValues]="selectedUsers"
                                        [selectedHighlight]="false"
                                        [valueCountView]="true"
                                        [availableOptions]="userListSearch" 
                                        [searchControl]="searchControlOwner"
                                        (selectionChange)="onPrivateOwnerSelectionChange($event)"
                                        (searchChange)="filterOptionsPrivateOwner($event)">
                            </app-custom-multiple-select>

                          </ng-container>

                        </ng-container>

                        <ng-container *ngSwitchCase="'textarea'">
                          <mat-form-field class="mat-input lane-card-input" appearance="outline" [ngClass]="{'error-border': formItem.config.error}">
                            <textarea matInput [(ngModel)]="formItem.config.value" rows="3">
                            </textarea>
                          </mat-form-field>
                        </ng-container>

                        <!-- table -->
                        <ng-container *ngSwitchCase="'table'">
                          <div class="plant-table-overflow plant-table-one">
                            <div
                              [ngStyle]="{'width': formItem.config.type === 'string' ? columnWidth : (formItem.config.type === 'table' ? '100%' : 'auto')}"
                              [ngClass]="{'atom-row': formItem.config.type === 'string','table-column-serviceability': formItem.config.type === 'table'}">
                              <div class="items-start"
                                [ngClass]="{'atom-col-sm-12': formItem.config.type === 'string','mb-5': formItem.config.type === 'table'}">
                                <label *ngIf="formItem.config.type === 'string'" class="label-1 mt-0"
                                  [ngClass]="{required: formItem.config.optional && checkValidity(formItem.config)}">{{
                                  formItem.config.displayName }}</label>
                                <!-- Only for upload and download button -->
                                <div *ngIf="formItem.config.type === 'table'" class="atom-row mx-0">
                                  <div class="atom-col-sm-12 justify-between px-0">
                                    <!-- <label class="label-1 mt-0" [ngClass]="{required: formItem.config.optional && checkValidity(formItem.config)}">{{ formItem.config.displayName }}</label> -->
                                    <!-- Upload and Download Button -->
                                    <div class="flex">
                                      <div
                                        *ngIf="formItem.config.availableButtons && formItem.config.availableButtons.includes('download')">
                                        <a mat-button class="btn-basic mr-10 download-btn" color="primary"
                                          (click)="downloadTemplate(formItem.config.field,'')"><i
                                            class="ri-download-2-line icon-left"></i> Download</a>
                                      </div>

                                      <div
                                        *ngIf="formItem.config.availableButtons && formItem.config.availableButtons.includes('upload')">
                                        <section aria-label="documents" class="cb_fileinput">
                                          <ng-container *ngIf="file == null">
                                            <div draggable="true" class="dragArea upload-sheet-section config" ngFileDragDrop
                                              (fileDropped)="tableUploadForLane($event, formItem.config.field)">
                                              <span class="lbl upload-sheet-btn">
                                                <input style="display: none" type="file"
                                                  (change)="tableUploadForLane($event, formItem.config.field)" #fileInput />
                                                <span (click)="fileInput.value = ''; fileInput.click()"><i
                                                    class="ri-upload-2-line mr-10"></i> Upload Sheet</span>
                                              </span>
                                            </div>
                                          </ng-container>
                                          <ng-container *ngIf="file != null">
                                            <div class="file-upload-section upload-file-name-section">
                                              <div class="d-flex flex-column w-100">
                                                <div class="file-uploading-status d-flex justify-content-between">
                                                  <span class="file-name">{{ file.name }}<a><i class="ri-close-line"
                                                        (click)="removeSelectedFile()"></i></a></span>
                                                </div>
                                              </div>
                                            </div>
                                          </ng-container>
                                        </section>
                                      </div>
                                    </div>
                                    <!-- Upload and Download Button End-->
                                  </div>
                                </div>
                              </div>
                              <div [ngSwitch]="formItem.config.type"
                                [ngClass]="{'atom-col-sm-12': formItem.config.type === 'string'}">
                                <ng-container *ngSwitchCase="'table'">
                                  <div class="financials-table-overflow mb-30 table-one">
                                    <app-dynamic-table [tableData]="getTableDataSource(formItem.config.field)"
                                      [tableColumns]="formItem.config.tableColumns"></app-dynamic-table>
                                  </div>
                                </ng-container>
                              </div>
                            </div>
                          </div>
                        </ng-container>

                        <ng-container *ngSwitchCase="'form'">
                          <app-form-array-example class="w-full" [form]="formItem.config"></app-form-array-example>
                        </ng-container>
                      
                        <div *ngSwitchCase="'file'" fxLayout="row" class="field-container">
                          <!-- <ng-container *ngIf="!fileForFields[formItem.config.field]">
                          <button class="btn-outline-primary atom-input-file-btn" color="Red" type="button" mat-stroked-button >
                              <div class="atom-select-file" (click)="fileInput.value='';fileInput.click()" draggable="true" ngFileDragDrop (fileDropped)="fileUploadForField($event,formItem.config)">
                                  <input style="display: none" type="file" (change)="fileUploadForField($event,formItem.config)" #fileInput>
                                  <span >
                                    <i class="ri-upload-2-line mr-5"></i> Attach
                                  </span>
                              </div>
                          </button>
                          </ng-container>
                          <ng-container *ngIf="fileForFields[formItem.config.field]">
                            <div class="file-name-section">
                              <span class="file-name">
                                  {{fileForFields[formItem.config.field].name}} 
                              </span>
                              <a>
                                <i class="ri-close-line" (click)="removeSelectedFileForField(formItem.config)"></i>
                              </a>
                            </div>
                            <button class="btn-outline-primary atom-input-file-btn" color="Red" type="button" mat-stroked-button (click)="uploadAttachemet(formItem.config)">
                              <span >
                                <i class="ri-upload-2-line mr-5"></i> Upload
                              </span>
                              </button>
                          </ng-container> -->
                          <ng-container *ngIf="formItem.config.value">
                            <div class="file-upload-section">
                                <div class="file-uploading-status d-flex justify-content-between">
                                  <a  (click)="downloadSingleDocument(formItem.config.value)">
                                  <span class="file-name" >
                                    {{ formItem.config.additionalData.fileName.length < 5 ? formItem.config.additionalData.fileName : formItem.config.additionalData.fileName.substring(0, 5) }}
                                    <mat-icon class="material-icons">download</mat-icon>
                                  </span>
                                </a>
                                </div>
                            </div>
                          </ng-container>
                      </div>
                      </div>
                      
                  </div>
                </li>
              </ng-container>
            </ng-container>
            <!--Projection Data  -->
            <div *ngIf="!isHidden">
              <h3 class="bottom-ProjectionHeading">
                6 Month projection ( {{ projectionTimeline }})
              </h3>
              <li class="lane-grid">
                <div fxLayout="row" fxLayoutAlign="start space-around" style="margin-right: 1rem; width: 100%">
                  <div fxLayout="row" class="label-width" style="width: 40%">
                    <label class="label-1">M1 (Cr) :</label>
                  </div>
                  <div fxLayout="column" class="field-width" style="width: 60%">
                    <mat-form-field class="mat-input lane-card-input" appearance="outline">
                      <input matInput type="text" class="input-field" [(ngModel)]="m1" />
                    </mat-form-field>
                  </div>
                </div>
              </li>
              <li class="lane-grid">
                <div fxLayout="row" fxLayoutAlign="start space-around" style="margin-right: 1rem; width: 100%">
                  <div fxLayout="row" class="label-width" style="width: 40%">
                    <label class="label-1">M2 (Cr) :</label>
                  </div>
                  <div fxLayout="column" class="field-width" style="width: 60%">
                    <mat-form-field class="mat-input lane-card-input" appearance="outline">
                      <input matInput type="text" class="input-field" [(ngModel)]="m2" />
                    </mat-form-field>
                  </div>
                </div>
              </li>
              <li class="lane-grid">
                <div fxLayout="row" fxLayoutAlign="start space-around" style="margin-right: 1rem; width: 100%">
                  <div fxLayout="row" class="label-width" style="width: 40%">
                    <label class="label-1">M3 (Cr) :</label>
                  </div>
                  <div fxLayout="column" class="field-width" style="width: 60%">
                    <mat-form-field class="mat-input lane-card-input" appearance="outline">
                      <input matInput type="text" class="input-field" [(ngModel)]="m3" />
                    </mat-form-field>
                  </div>
                </div>
              </li>
              <li class="lane-grid">
                <div fxLayout="row" fxLayoutAlign="start space-around" style="margin-right: 1rem; width: 100%">
                  <div fxLayout="row" class="label-width" style="width: 40%">
                    <label class="label-1">M4 (Cr) :</label>
                  </div>
                  <div fxLayout="column" class="field-width" style="width: 60%">
                    <mat-form-field class="mat-input lane-card-input" appearance="outline">
                      <input matInput type="text" class="input-field" [(ngModel)]="m4" />
                    </mat-form-field>
                  </div>
                </div>
              </li>
              <li class="lane-grid">
                <div fxLayout="row" fxLayoutAlign="start space-around" style="margin-right: 1rem; width: 100%">
                  <div fxLayout="row" class="label-width" style="width: 40%">
                    <label class="label-1">M5 (Cr) :</label>
                  </div>
                  <div fxLayout="column" class="field-width" style="width: 60%">
                    <mat-form-field class="mat-input lane-card-input" appearance="outline">
                      <input matInput type="text" class="input-field" [(ngModel)]="m5" />
                    </mat-form-field>
                  </div>
                </div>
              </li>
              <li class="lane-grid">
                <div fxLayout="row" fxLayoutAlign="start space-around" style="margin-right: 1rem; width: 100%">
                  <div fxLayout="row" class="label-width" style="width: 40%">
                    <label class="label-1">M6 (Cr) :</label>
                  </div>
                  <div fxLayout="column" class="field-width" style="width: 60%">
                    <mat-form-field class="mat-input lane-card-input" appearance="outline">
                      <input matInput type="text" class="input-field" [(ngModel)]="m6" />
                    </mat-form-field>
                  </div>
                </div>
              </li>
            </div>
            <!-- Account Id for SOb Opportunity -->
            <li class="lane-grid w-33" *ngIf="boardId === boardIdForSobOpportunity">
              <div fxLayout="row" fxLayoutAlign="start space-around" style="margin-right: 1rem; width: 100%">
                <div fxLayout="row" class="label-width" style="width: 40%">
                  <label class="label-1">Customer Id</label>
                </div>
                <div fxLayout="column" class="field-width" style="width: 60%">
                  <mat-form-field appearance="outline">
                    <input matInput id="account-name" type="text" class="input-field" disabled
                      [(ngModel)]="accountNameId" />
                  </mat-form-field>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>

      <!--- Account KYC section start --->
      <div *ngIf="boardId === boardIdForSobAccount">
        <mat-tab-group class="tab-level-1">
          <ng-container *ngFor="let lane of boardLane">
            <mat-tab *ngIf="lane.name === 'kyc'" [label]="lane.displayName">
              <ng-container *ngIf="lane.name === 'kyc'">
                <mat-tab-group class="tab-level-2" animationDuration="0ms" mat-align-tabs="start"
                  (selectedTabChange)="onTabChange($event, lane.laneSubdivisions[$event.index])" [selectedIndex]="selectedTabIndex">
                  <mat-tab *ngFor="let tab of lane.laneSubdivisions" [label]="tab.displayName">
                    <div class="selection-criteria-table basic-table-overflow">

                      <ng-container *ngIf="tab.displayName === 'Selection Criteria'">
                        <table class="basic-table">
                          <thead>
                            <tr>
                              <th style="width: 20%;max-width: 20%;min-width: 20%;">Area</th>
                              <th style="width: 10%;max-width: 10%;min-width: 10%;">Weightage</th>
                              <th style="width: 10%;max-width: 10%;min-width: 10%;">MAX</th>
                              <th style="width: 35%;max-width: 35%;min-width: 35%;">Remarks</th>
                              <!-- Apply width here -->
                              <th style="width: 25%;max-width: 25%;min-width: 25%;">
                                <h2>{{ laneTaskIDDetail?.name}}</h2>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <ng-container *ngFor="let formItem of laneArrayKeys; let i = index">
                              <!-- First row -->
                              <ng-container *ngIf="i === 0">
                                <tr>
                                  <td>
                                    <h4>{{ formItem.config.displayName }} :</h4>
                                    <p>{{ formItem.config.description }}</p>
                                  </td>
                                  <td>
                                    <div class="w-p110">
                                      <mat-form-field class="mat-input weightage-input" appearance="outline">
                                        <input matInput [id]="formItem.config.field" type="text" class="input-field"
                                          min="0" pattern="^\d*\.?\d*$"
                                          oninput="validity.valid||(value=''); this.value = this.value.replace(/^0+(\d)/, '$1')"
                                          [(ngModel)]="formItem.config.value" (blur)="onBlur($event, formItem)" />
                                      </mat-form-field>
                                    </div>
                                  </td>
                                  <td><strong>{{ formItem.config.maxValue }}%</strong></td>
                                  <td>{{ formItem.config.remarks }}</td>
                                  <td>
                                    <span class="bold-text">
                                      <strong>
                                        Average Scoring <br />
                                        {{percentage}}%
                                        <span [ngClass]="{
                                          'text-green': strengthLevel === 'Strong',
                                          'text-orange': strengthLevel === 'Intermediate',
                                          'text-red': strengthLevel === 'Weak'
                                        }">{{ strengthLevel }}</span>

                                      </strong>
                                    </span>
                                  </td>
                                </tr>
                              </ng-container>
                              <!-- Second row -->
                              <ng-container *ngIf="i === 1">
                                <tr>
                                  <td>
                                    <h4>{{ formItem.config.displayName }} :</h4>
                                    <p>{{ formItem.config.description }}</p>
                                  </td>
                                  <td>
                                    <div class="w-p110">
                                      <mat-form-field class="mat-input weightage-input" appearance="outline">
                                        <input matInput [id]="formItem.config.field" type="text" class="input-field"
                                          min="0" pattern="^\d*\.?\d*$"
                                          oninput="validity.valid||(value=''); this.value = this.value.replace(/^0+(\d)/, '$1')"
                                          [(ngModel)]="formItem.config.value" (blur)="onBlur($event, formItem)" />
                                      </mat-form-field>
                                    </div>
                                  </td>
                                  <td><strong>{{ formItem.config.maxValue }}%</strong></td>
                                  <td>{{ formItem.config.remarks }}</td>
                                  <td class="vertical-align-top bg-grey w-p250" rowspan="6">
                                    <div class="score-card">
                                      <ul>
                                        <li>
                                          <span class="bold-text">
                                            <strong>
                                              <span class="text-green">
                                                Score > 80%
                                              </span>
                                            </strong>
                                          </span>
                                          <span>Pursue for "full-throttle" SOB growth</span>
                                        </li>
                                        <li>
                                          <span class="bold-text">
                                            <strong>
                                              <span class="text-orange">
                                                60% < Score>80%
                                              </span>
                                            </strong>
                                          </span>
                                          <span>Work to improve of lacking areas (alignment or EBIDTA or perf.)</span>
                                        </li>
                                        <li>
                                          <span class="bold-text">
                                            <strong>
                                              <span class="text-red">
                                                Score < 60% </span>
                                            </strong>
                                          </span>
                                          <span>BAU, till score reaches above 60%</span>
                                        </li>
                                      </ul>
                                      <p>
                                        Note : SOB potential includes only “repeatable spend” , it excludes capex, no-go
                                        /restricted category (food, fuel, medical, coal etc.) spend or one time spend.
                                      </p>
                                    </div>
                                  </td>
                                </tr>
                              </ng-container>
                              <!-- Normal rows -->
                              <ng-template [ngIf]="i !== 0 && i !== 1">
                                <tr>
                                  <td>
                                    <h4>{{ formItem.config.displayName }} :</h4>
                                    <p>{{ formItem.config.description }}</p>
                                  </td>
                                  <td>
                                    <div class="w-p110">
                                      <mat-form-field class="mat-input weightage-input" appearance="outline">
                                        <input matInput [id]="formItem.config.field" type="text" class="input-field"
                                          min="0" pattern="^\d*\.?\d*$"
                                          oninput="validity.valid||(value=''); this.value = this.value.replace(/^0+(\d)/, '$1')"
                                          [(ngModel)]="formItem.config.value" (blur)="onBlur($event, formItem)" />
                                      </mat-form-field>
                                    </div>
                                  </td>
                                  <td><strong>{{ formItem.config.maxValue }}%</strong></td>
                                  <td>{{ formItem.config.remarks }}</td>
                                  <td></td> <!-- Empty cell for normal rows -->
                                </tr>
                              </ng-template>
                            </ng-container>
                          </tbody>
                        </table>
                      </ng-container>

                      <ng-container *ngIf="tab.displayName == 'Customer Profile'">
                        <ul class="customer-profile-list">
                          <li class="lane-grid" *ngFor="let formItem of laneArrayKeys; let i = index"
                            [ngStyle]="{'width': formItem.config.type !== 'table' ? '50%' : (formItem.config.type === 'table' ? '100%' : 'auto')}">
                            <div
                              [ngClass]="{'atom-row': formItem.config.type !== 'table','table-column': formItem.config.type === 'table'}">

                              <!-- table -->
                              <div class="items-start"
                                [ngClass]="{'atom-col-sm-12': formItem.config.type !== 'table','mb-5': formItem.config.type === 'table'}">
                                <!-- table label -->
                                <label *ngIf="formItem.config.type !== 'table'" class="label-1 mt-0"
                                  [ngClass]="{required: formItem.config.optional && checkValidity(formItem.config)}">{{formItem.config.displayName
                                  }}</label>
                                <div *ngIf="formItem.config.type === 'table'" class="atom-row mx-0">
                                  <div class="atom-col-sm-12 justify-between px-0">
                                    <label class="label-1 mt-0"
                                      [ngClass]="{required: formItem.config.optional && checkValidity(formItem.config)}">{{
                                      formItem.config.displayName }}</label>
                                    <!-- Check if "download" button is available -->
                                    <div class="flex">
                                      <div
                                        *ngIf="formItem.config.availableButtons && formItem.config.availableButtons.includes('download')">
                                        <a mat-button class="btn-basic mr-10 download-btn" color="primary"
                                          (click)="downloadTemplate(formItem.config.field, tab.id)"><i
                                            class="ri-download-2-line icon-left"></i> Download</a>
                                      </div>
                                      <!-- Check if "upload" button is available -->
                                      <div
                                        *ngIf="formItem.config.availableButtons && formItem.config.availableButtons.includes('upload')">
                                        <section aria-label="documents" class="cb_fileinput">
                                          <ng-container *ngIf="file == null">
                                            <div draggable="true" class="dragArea upload-sheet-section config"
                                              ngFileDragDrop
                                              (fileDropped)="tableUpload($event, lane.id , tab.id, formItem.config.field)">
                                              <span class="lbl upload-sheet-btn">
                                                <input style="display: none" type="file"
                                                  (change)="tableUpload($event, lane.id, tab.id, formItem.config.field)"
                                                  #fileInput />
                                                <span (click)="fileInput.value = ''; fileInput.click()">
                                                  <i class="ri-upload-2-line mr-10"></i>
                                                  Upload Sheet
                                                </span>
                                              </span>
                                            </div>
                                          </ng-container>
                                          <ng-container *ngIf="file != null">
                                            <div class="file-upload-section upload-file-name-section">
                                              <div class="d-flex flex-column w-100">
                                                <div class="file-uploading-status d-flex justify-content-between">
                                                  <span class="file-name">
                                                    {{ file.name }}
                                                    <a>
                                                      <i class="ri-close-line" (click)="removeSelectedFile()"></i>
                                                    </a>
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </ng-container>
                                        </section>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              
                              <div [ngSwitch]="formItem.config.type"
                                [ngClass]="{'atom-col-sm-12': formItem.config.type !== 'table'}">

                                <ng-container *ngSwitchCase="'string'">
                                  <mat-form-field
                                    *ngIf="formItem.config.field !== 'geographical-presence' && formItem.config.field !== 'relationship-matrix'"
                                    class="mat-input lane-card-input" appearance="outline"
                                    [ngClass]="{ 'error-border': formItem.config.error }">
                                    <input matInput placeholder="{{formItem.config.displayName}}"
                                      [id]="formItem.config.field" type="text" class="input-field"
                                      [disabled]="!formItem.config.fieldEditable" [(ngModel)]="formItem.config.value" />
                                  </mat-form-field>

                                  <div *ngIf="formItem.config.field === 'geographical-presence'"
                                    class="mat-input lane-card-input" appearance="outline">
                                    Total Plants - {{laneTaskIDDetail.totalPlants}}
                                  </div>
                                  <div *ngIf="formItem.config.field === 'relationship-matrix'"
                                    class="mat-input lane-card-input" appearance="outline">
                                    Total Contacts - {{laneTaskIDDetail.totalContacts}}
                                  </div>

                                </ng-container>

                                <mat-form-field *ngSwitchCase="'email'" class="mat-input lane-card-input"
                                  appearance="outline" [ngClass]="{ 'error-border': formItem.config.error }">
                                  <input matInput [id]="formItem.config.field" type="email" name="email" [email]="true"
                                    class="input-field" [(ngModel)]="formItem.config.value"
                                    (input)="validateEmail(formItem.config)" />
                                </mat-form-field>

                                <mat-form-field *ngSwitchCase="'currency'" class="mat-input lane-card-input"
                                  appearance="outline" [ngClass]="{ 'error-border': formItem.config.error }">
                                  <input matInput [id]="formItem.config.field" type="text" class="input-field" min="0"
                                    pattern="^\d*\.?\d*$"
                                    oninput="validity.valid||(value=''); this.value = this.value.replace(/^0+(\d)/, '$1')"
                                    [(ngModel)]="formItem.config.value" />
                                </mat-form-field>

                                <mat-form-field *ngSwitchCase="'number'" class="mat-input lane-card-input"
                                  appearance="outline" [ngClass]="{ 'error-border': formItem.config.error }">
                                  <input type="text" matInput [id]="formItem.config.field" class="input-field" min="0"
                                    oninput="this.value = this.value === '' ? '' : Math.max(parseInt(this.value) || 0, 0)"
                                    [(ngModel)]="formItem.config.value" />

                                </mat-form-field>

                                <mat-form-field *ngSwitchCase="'phone'" class="mat-input lane-card-input"
                                  appearance="outline" [ngClass]="{ 'error-border': formItem.config.error }">
                                  <input matInput [id]="formItem.config.field" *ngIf="boardId != boardIdForCRMContacts"
                                    type="text" class="input-field" pattern="\d*" placeholder="0000000000"
                                    [(ngModel)]="formItem.config.value" />
                                  <input matInput [id]="formItem.config.field" *ngIf="
                                      !isCountryIndia && boardId == boardIdForCRMContacts
                                    " type="text" pattern="\d*" placeholder="0000000000" class="input-field"
                                    [(ngModel)]="formItem.config.value" (input)="
                                      validateLength(formItem, formItem.config.value.length)
                                    " />
                                  <input matInput [id]="formItem.config.field" type="text"
                                    *ngIf="isCountryIndia && boardId == boardIdForCRMContacts" pattern="\d*"
                                    placeholder="0000000000" class="input-field" [(ngModel)]="formItem.config.value"
                                    (input)="validateLength(formItem, 10)" [minLength]="isCountryIndia ? 10 : null"
                                    [maxlength]="isCountryIndia ? 10 : null" />
                                </mat-form-field>

                                <mat-form-field *ngSwitchCase="'dropdown'" appearance="outline" class="mat-select"
                                [ngClass]="{ 'error-border': formItem.config.error }">
                                  <mat-select [id]="formItem.config.field" [(ngModel)]="formItem.config.value">
                                    <mat-option *ngFor="let opt of formItem.config.dataList" [value]="opt">{{ opt
                                      }}</mat-option>
                                  </mat-select>
                                </mat-form-field>

                                <mat-form-field *ngSwitchCase="'boolean'" appearance="outline" class="mat-select"
                                [ngClass]="{ 'error-border': formItem.config.error }">
                                  <span *ngIf="formItem.config.optional" class="mandatory-icon">*</span>
                                  <mat-select [id]="formItem.config.field" [(ngModel)]="formItem.config.value">
                                    <mat-option value="">N/A</mat-option>
                                    <mat-option value="Yes">Yes</mat-option>
                                    <mat-option value="No">No</mat-option>
                                  </mat-select>
                                </mat-form-field>

                                <mat-form-field *ngSwitchCase="'date'" class="mat-input lane-card-input"
                                  appearance="outline" [ngClass]="{ 'error-border': formItem.config.error }">
                                  <input matInput (focus)="effectivePicker.open()" [matDatepicker]="effectivePicker"
                                    placeholder="Select Date" [(ngModel)]="formItem.config.value" />
                                  <mat-datepicker-toggle matSuffix [for]="effectivePicker"></mat-datepicker-toggle>
                                  <mat-datepicker #effectivePicker></mat-datepicker>
                                </mat-form-field>

                                <ng-container *ngSwitchCase="'belongsTo'">
                                  <app-field-autocomplete [id]="formItem.config.field"
                                    class="w-100 lane-card-input mb-025em" [fieldData]="{
                                      config: formItem.config,
                                      isFilter: true,
                                      selectedBoard: boardId
                                    }" [modelRef]="formItem.config.value"
                                    (filterApplied)="setPlantIdAndAccountId(formItem)">
                                  </app-field-autocomplete>
                                </ng-container>

                                <ng-container *ngSwitchCase="'select'">
                                  <ng-container *ngIf="
                                      formItem.subKeys !== 'industry' &&
                                      formItem.subKeys !== 'business-vertical' &&
                                      formItem.subKeys !== 'deal-type' &&
                                      formItem.subKeys !== 'lead-source' &&
                                      formItem.subKeys !== 'new-categories'
                                    ">
                                    <mat-form-field appearance="outline" class="mat-select" *ngIf="
                                        formItem.config.key == 'string' && !shouldDisableField(formItem.config.field)" 
                                        [ngClass]="{ 'error-border': formItem.config.error }">
                                      <mat-select [id]="formItem.config.field" [(ngModel)]="formItem.config.value"
                                        (selectionChange)="
                                          onSelectOption(formItem, formItem.config)
                                        ">
                                        <mat-option *ngFor="
                                            let opt of getOptionList(formItem.config.dataList)
                                          " [value]="opt">{{ opt }}</mat-option>
                                      </mat-select>
                                    </mat-form-field>
                                  </ng-container>

                                  <ng-container *ngIf="formItem.subKeys === 'industry'">
                                    <app-custom-single-select class="w-full" [formItem]="formItem.config"
                                      [searchControl]="searchControlIndustry" [optionList]="industryListSearch"
                                      (selectionChange)="onIndustrySelectionChange($event)"
                                      (searchChange)="onIndustrySearchChange($event)">
                                    </app-custom-single-select>
                                  </ng-container>

                                  <ng-container *ngIf="formItem.subKeys === 'business-vertical'">
                                    <app-custom-single-select class="full-width" [formItem]="formItem.config"
                                      *ngIf="!shouldDisableField(formItem.field)"
                                      [searchControl]="searchControlBusinessVertical"
                                      [optionList]="businessVerticalListSearch" (selectionChange)="
                                        onSelectOptionSelectionChange($event)
                                      " (searchChange)="filterOptionsBusinessVertical($event)">
                                    </app-custom-single-select>
                                  </ng-container>

                                  <ng-container *ngIf="formItem.subKeys === 'deal-type'">
                                    <app-custom-single-select class="full-width" [formItem]="formItem.config"
                                      *ngIf="!shouldDisableField(formItem.field)"
                                      [searchControl]="searchControlDealType" [optionList]="dealTypeListSearch"
                                      (selectionChange)="
                                        onSelectOptionSelectionChange($event)
                                      " (searchChange)="filterOptionsDealType($event)">
                                    </app-custom-single-select>
                                  </ng-container>

                                  <ng-container *ngIf="formItem.subKeys === 'lead-source'">
                                    <app-custom-single-select class="full-width" [formItem]="formItem.config"
                                      *ngIf="!shouldDisableField(formItem.field)"
                                      [searchControl]="searchControlLeadSource" [optionList]="leadSourceListSearch"
                                      (selectionChange)="
                                        onSelectOptionSelectionChange($event)
                                      " (searchChange)="filterOptionsLeadSource($event)">
                                    </app-custom-single-select>
                                  </ng-container>

                                  <ng-container *ngIf="formItem.subKeys === 'new-categories'">
                                    <app-custom-single-select class="full-width" *ngIf="isDealTypeNewCategories"
                                      [formItem]="formItem.config" [searchControl]="searchControlL1Category"
                                      [optionList]="newCategoryListSearch" (selectionChange)="
                                        onSelectOptionSelectionChange($event)
                                      " (searchChange)="filterOptionsL1CategoryList($event)">
                                    </app-custom-single-select>
                                  </ng-container>
                                </ng-container>

                                <mat-form-field *ngSwitchCase="'multiSelect'" appearance="outline" class="mat-select"
                                [ngClass]="{ 'error-border': formItem.config.error }">
                                  <mat-select multiple [id]="formItem.config.field"
                                    [(ngModel)]="formItem.config.values">
                                    <mat-select-trigger>
                                      <span class="test"
                                        *ngIf="formItem.config.values && formItem.config.values.length > 0">{{
                                        formItem.config.values[0] }}</span>
                                      <span class="test2" *ngIf="formItem.config.values &&formItem.config.values > 1"
                                        class="example-additional-selection">
                                        (+ {{ formItem.config.values.length - 1 }} {{ formItem.config.values?.length ===
                                        2 ? "other" : "others"}})
                                      </span>
                                    </mat-select-trigger>
                                    <mat-option *ngFor="let opt of formItem.config.dataList" [value]="opt">{{ opt
                                      }}</mat-option>
                                  </mat-select>
                                </mat-form-field>

                                <ng-container *ngSwitchCase="'textarea'">
                                  <mat-form-field class="mat-input lane-card-input" appearance="outline"
                                  [ngClass]="{ 'error-border': formItem.config.error }">
                                    <textarea matInput [(ngModel)]="formItem.config.value" rows="3">
                                    </textarea>
                                  </mat-form-field>
                                </ng-container>

                                <ng-container *ngSwitchCase="'table'">
                                  <div class="financials-table-overflow mb-30 table-two">
                                    <app-dynamic-table [tableData]="getTableDataSource(formItem.config.field)"
                                      [tableColumns]="formItem.config.tableColumns"></app-dynamic-table>
                                  </div>
                                </ng-container>

                                <!-- <mat-form-field *ngSwitchCase="'belongsTo'" class="mat-input lane-card-input"
                                  appearance="outline" [ngClass]="{ 'error-border': formItem.field == errorField.field }">
                                  <input matInput placeholder="{{formItem.displayName}}" [id]="formItem.field" type="text"
                                    class="input-field" [disabled]="!formItem.fieldEditable"
                                    [(ngModel)]="formItem.value" />
                                </mat-form-field>
                                <mat-form-field *ngSwitchCase="'multiSelect'" class="mat-input lane-card-input"
                                  appearance="outline" [ngClass]="{ 'error-border': formItem.field == errorField.field }">
                                  <input matInput placeholder="{{formItem.displayName}}" [id]="formItem.field" type="text"
                                    class="input-field" [disabled]="!formItem.fieldEditable"
                                    [(ngModel)]="formItem.value" />
                                </mat-form-field>
                                <mat-form-field *ngSwitchCase="'select'" class="mat-input lane-card-input"
                                  appearance="outline" [ngClass]="{ 'error-border': formItem.field == errorField.field }">
                                  <input matInput placeholder="{{formItem.displayName}}" [id]="formItem.field" type="text"
                                    class="input-field" [disabled]="!formItem.fieldEditable"
                                    [(ngModel)]="formItem.value" />
                                </mat-form-field> -->
                              </div>
                            </div>
                          </li>
                        </ul>
                      </ng-container>

                      <ng-container *ngIf="tab.displayName == 'Serviceability'">
                        <div class="serviceability-section">
                          <!-- Filter Section -->
                          <!-- <div class="atom-row filter-row"> -->
                          <!-- Plant Filter -->
                          <!-- <div class="w-p84">
                              <div class="input-group">
                                <mat-form-field appearance="outline" class="mat-select mat-select-search dark-placeholder">
                                  <mat-select [(ngModel)]="selectedPlantsForMeeting" (selectionChange)="onApplyPlants()" #sl2
                                              placeholder="Select Plants" multiple>
                                    <mat-form-field appearance="outline" class="mog3_matinput select-search">
                                      <input type="text" matInput placeholder="Search" [(ngModel)]="searchControlPlants"
                                             (keyup)="filterOptionsPlants($event)"/>
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16">
                                        <path d="M18.031 16.6168L22.3137 20.8995L20.8995 22.3137L16.6168 18.031C15.0769 19.263 13.124 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20 13.124 19.263 15.0769 18.031 16.6168ZM16.0247 15.8748C17.2475 14.6146 18 12.8956 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18C12.8956 18 14.6146 17.2475 15.8748 16.0247L16.0247 15.8748Z"
                                        fill="rgba(111,111,112,1)"
                                      ></path>
                                      </svg>
                                    </mat-form-field>
                                    <div class="select-options">
                                      <mat-option *ngFor="let plant of plantOptions" [value]="plant">{{ plant.name }}</mat-option>
                                    </div>
                                    <div fxLayout="row" fxLayoutAlign="flex-end space-between" class="filter-btn-section">
                                      <button class="filter-btn clear" (click)="clearPlants(); searchControlPlants = ''; selectedPlantsForMeeting = []">Clear</button>
                                    </div>
                                  </mat-select>
                                </mat-form-field>
                              </div> -->
                          <!-- </div> -->
                          <!-- Annual Spend Filter -->
                          <!-- <div class="w-p143">
                              <div class="input-group">
                                <mat-form-field class="mat-select dark-placeholder" appearance="outline">
                                  <mat-select placeholder="Annual Spend">
                                    <mat-option class="mat-option" value="option1" selected>Option 1</mat-option>
                                    <mat-option class="mat-option" value="option2">Option 2</mat-option>
                                    <mat-option class="mat-option" value="option3">Option 3</mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </div>
                            </div>
                          </div> -->
                          <!-- Filter Section End -->

                          <div class="plant-table-overflow">
                            <ul class="customer-profile-list">
                              <li class="lane-grid" *ngFor="let formItem of laneArrayKeys; let i = index"
                                [ngStyle]="{'width': formItem.config.type === 'string' ? columnWidth : (formItem.config.type === 'table' ? '100%' : 'auto')}">
                                <div
                                  [ngClass]="{'atom-row': formItem.config.type === 'string','table-column-serviceability': formItem.config.type === 'table'}">
                                  <div class="items-start"
                                    [ngClass]="{'atom-col-sm-12': formItem.config.type === 'string','mb-5': formItem.config.type === 'table'}">
                                    <label *ngIf="formItem.config.type !== 'table'" class="label-1 mt-0"
                                      [ngClass]="{required: formItem.config.optional && checkValidity(formItem.config)}">{{
                                      formItem.config.displayName }}</label>
                                    <!-- Only for upload and download button -->
                                    <div *ngIf="formItem.config.type === 'table'" class="atom-row mx-0">
                                      <div class="atom-col-sm-12 justify-between px-0">
                                        <!-- <label class="label-1 mt-0" [ngClass]="{required: formItem.config.optional && checkValidity(formItem.config)}">{{ formItem.config.displayName }}</label> -->
                                        <!-- Upload and Download Button -->
                                        <div class="flex">
                                          <div
                                            *ngIf="formItem.config.availableButtons && formItem.config.availableButtons.includes('download')">
                                            <a mat-button class="btn-basic mr-10 download-btn" color="primary"><i
                                                class="ri-download-2-line icon-left"></i> Download</a>
                                          </div>
                                          <div
                                            *ngIf="formItem.config.availableButtons && formItem.config.availableButtons.includes('upload')">
                                            <section aria-label="documents" class="cb_fileinput">
                                              <ng-container *ngIf="file == null">
                                                <div draggable="true" class="dragArea upload-sheet-section config"
                                                  ngFileDragDrop (fileDropped)="tableUpload($event, tab)">
                                                  <span class="lbl upload-sheet-btn">
                                                    <input style="display: none" type="file"
                                                      (change)="tableUpload($event, tab)" #fileInput />
                                                    <span (click)="fileInput.value = ''; fileInput.click()"><i
                                                        class="ri-upload-2-line mr-10"></i> Upload Sheet</span>
                                                  </span>
                                                </div>
                                              </ng-container>
                                              <ng-container *ngIf="file != null">
                                                <div class="file-upload-section upload-file-name-section">
                                                  <div class="d-flex flex-column w-100">
                                                    <div class="file-uploading-status d-flex justify-content-between">
                                                      <span class="file-name">{{ file.name }}<a><i class="ri-close-line"
                                                            (click)="removeSelectedFile()"></i></a></span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </ng-container>
                                            </section>
                                          </div>
                                        </div>
                                        <!-- Upload and Download Button End-->
                                      </div>
                                    </div>
                                  </div>
                                  <div [ngSwitch]="formItem.config.type"
                                    [ngClass]="{'atom-col-sm-12': formItem.config.type === 'string'}">
                                    <mat-form-field *ngSwitchCase="'string'" class="mat-input lane-card-input"
                                      appearance="outline"
                                      [ngClass]="{ 'error-border': formItem.config.error }">
                                      <input matInput placeholder="{{formItem.config.displayName}}"
                                        [id]="formItem.config.field" type="text" class="input-field"
                                        [disabled]="!formItem.config.fieldEditable"
                                        [(ngModel)]="formItem.config.value" />
                                    </mat-form-field>
                                    <ng-container *ngSwitchCase="'table'">
                                      <div class="financials-table-overflow mb-30 table-third">
                                        <app-dynamic-table [tableData]="getTableDataSource(formItem.config.field)"
                                          [tableColumns]="formItem.config.tableColumns"></app-dynamic-table>
                                      </div>
                                    </ng-container>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>

                        </div>
                      </ng-container>

                    </div>
                  </mat-tab>
                </mat-tab-group>
              </ng-container>
            </mat-tab>
          </ng-container>
        </mat-tab-group>
      </div>
      <!--- Account KYC section end --->
    </div>
  </div>
</div>