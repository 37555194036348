import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { params } from '../param';
import { environment } from '../../environments/environment';
import { PagesService } from '../pages/pages.service';
import { SettingService } from '../settings/setting.service';

@Injectable()
export class AuthGuard implements CanActivate {
    isCustomer: boolean;
    userOnBoardData: any;
    getSessionStorage: any;
    onBoardRes: any;
    permission:any;
    readonly URL = environment.credlixURL;

    constructor(private router: Router, public authService: AuthService, public settingService: SettingService,
        private pageSerive: PagesService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authService.getIsCusotmer()) {
            this.isCustomer = true;
        }
        this.getSessionStorage = JSON.parse(localStorage.getItem("USERINFO"))

        if (localStorage.getItem('TOKEN') != null) {
            console.log('auth guard can activate route path',route.routeConfig.path);
                if (route.routeConfig.path == "login" || route.routeConfig.path == "signup" || route.routeConfig.path == 'admin-login') {
                    this.router.navigate(['/']);
                    return false;
                } 
                else {
                    if (route.routeConfig.path == "") {
                        if (this.getSessionStorage.nuphiUser == true) {
                            window.location.href = this.URL + "/nuphi/#/?token=EXIM";
                        }
                        else if(this.getSessionStorage && this.getSessionStorage['access_token'] == null && this.getSessionStorage['account_token']){
                            this.router.navigate(['select-account']);
                        }
                        else if (this.authService.isCredlixUser()) {
                            if (!localStorage.getItem('credlixUserID')) {
                                this.pageSerive.getAnchorList().subscribe(res => {
                                    this.pageSerive.setAnchorList(res['result']);
                                    this.permission = this.getSessionStorage.role.role['permissions'];
                                    if(this.permission.includes('VIEW_BORROWER')){
                                        this.router.navigate(['/supplierLinks']);
                                    }
                                    else if(this.permission.includes('KANBAN')){
                                        this.router.navigate(['/crm-board']);

                                    }
                                
                                })
                            }
                            else if (this.authService.isChannleFinancing()) {
                                this.router.navigate(['/channelFinance']);
                            }
                            else {
                                this.permission = this.getSessionStorage.role.role['permissions'];
                                    if(this.permission.includes('VIEW_BORROWER')){
                                        this.router.navigate(['/supplierLinks']);
                                    }
                                    else if(this.permission.includes('KANBAN')){
                                        this.router.navigate(['/crm-board']);

                                    }
                            }
                        }
                        else if (this.authService.isKanbanUser()) {
                            console.log('auth guard is kanban user')
                            if (this.pageSerive.checkMobileScreen()) {
                                this.router.navigate(['/sop-management']);
                              }else{
                                this.router.navigate(['/crm-board']);
                              }
                            
                        }
                        else if (this.authService.isLenderLeads()) {
                            this.router.navigate(['/leads']);
                        }
                        else if (this.authService.isChannleFinancing()) {
                            if (this.authService.isAnchor()) {
                                this.router.navigate(['/channelFinance']);
                            }
                            else {
                                if(this.getSessionStorage['accountName'] == 'MSAF'){
                                    this.router.navigate(['/financeListing']);
                                }
                                else{
                                    this.settingService.checkGSTINExist().subscribe(res => {
                                        this.onBoardRes = res['result'];
                                        this.settingService.storeOnBoardData(this.onBoardRes);
                                        if (this.onBoardRes['status'] != 'SUBMITTED' && this.onBoardRes.verified != true) {
                                            this.pageSerive.isSubmitted = true;
                                            localStorage.setItem('isSubmitted', 'true');
                                            this.router.navigate(['/OnBoard']);
                                        }
                                        else {
                                            this.router.navigate(['/bankLists']);
                                        }
                                    }, (error) => {
                                        if (error['status'] == 404) {
                                            this.pageSerive.isSubmitted = true;
                                            localStorage.setItem('isSubmitted', 'true');
                                            this.router.navigate(['/OnBoard']);
                                        }
        
                                    });
                                }
                            }
                        }
                        else {
                            if (this.isCustomer) {
                                this.router.navigate(['/supplierLinks']);
                            }
                            else if (this.authService.isSIDUSER()) {
                                this.router.navigate(['/sid/listing/supplier']);
                            }
                            else if(this.authService.isBuyer()){
                                if(this.getSessionStorage['role'] && this.getSessionStorage['role']['masterAccount']
                                && this.getSessionStorage['role']['masterAccount']['isSid']){
                                    this.router.navigate(['/sid/listing/buyerSupplier']);
                                }
                                else{
                                    this.router.navigate(['/earlyPayments']);
                                }
                            }
                            else if(this.authService.isBorrower()){
                                if(this.getSessionStorage['role'] && this.getSessionStorage['role']['masterAccount']
                                && this.getSessionStorage['role']['masterAccount']['isSid']){
                                    this.router.navigate(['/sid/invoiceListing']);
                                }
                                else{
                                    this.router.navigate(['/earlyPayments']);
                                }
                            }
                            else {
                                this.router.navigate(['/earlyPayments']);
                            }
                        }
                    }
                    return true;
                }
        } else {
            if (route.routeConfig.path == "") {
                this.router.navigate(['/auth/login']);
                return true;
            }
            if (route.routeConfig.path == "login" || route.routeConfig.path == "signup") {
                return true;
            }  else if (route.routeConfig.path == "admin-login") {
                return true;
            }else {
                this.router.navigate(['/auth/login']);
                return false;
            }
        }
    }
}