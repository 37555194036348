<section
  class="pb-15 dialog-heading border-bottom emailer-view"
  aria-label="heading"
>
  <div fxLayout="row mb-30 " fxLayoutAlign="space-between center">
    <h1 class="modal-heading-2 text-capitalize mb-0">
      WOW - Jewar Intl Airport
    </h1>
    <button mat-mini-fab class="modal-close-btn" (click)="dialogRef.close()">
      <i class="ri-close-circle-fill"></i>
    </button>
  </div>

</section>
<div class="modal-content">
  <!-- email template starts from table -->
  <!-- <table
    cellpadding="0"
    cellspacing="0"
    style="
      max-width: 600px;
      width: 100%;
      margin: auto;
      background-color: rgb(255, 255, 255);
      border-collapse: collapse;
      border: 1px solid rgb(221, 221, 221);
    "
  >
    <tbody>
      <tr>
        <td align="center">
          <img
            src="https://campaign-image.com/zohocampaigns/1_zc_v1_550211000000060241.png"
            style="width: 100%"
          />
        </td>
      </tr>
      <tr>
        <td align="center">
          <h2
            style="
              margin: 0;
              font-size: 28px;
              color: rgb(0, 0, 0);
              padding-top: 30px;
              padding-bottom: 30px;
            "
          >
            How to transform operational <br />efficiency in Supply Chain
          </h2>
        </td>
      </tr>
      <tr>
        <td align="center">
          <img
            src="https://campaign-image.com/zohocampaigns/2_zc_v1_550211000000060241.jpeg"
            style="width: 100%"
          />
        </td>
      </tr>
      <tr>
        <td align="center">
          <p
            style="
              font-size: 16px;
              color: rgb(51, 51, 51);
              text-align: left;
              padding: 20px 30px;
              line-height: 1.5em;
              margin: 0;
            "
          >
            According to research by Gartner, by the year 2020, more than
            <strong style="color: rgb(0, 0, 0)"
              >50% of the major new business,</strong
            >
            will incorporate some element of IoT within their
            operation-systems. This is a
            <strong style="color: rgb(0, 0, 0)">thirty-fold or 30x</strong>
            increase in physical devices that are internet-connected and will
            alter significantly how a supply-chain operates. The connected
            assets allow you to predict and prevent critical equipment
            failures before it happens.
          </p>
          <p
            style="
              margin: 0;
              text-align: left;
              padding-left: 28px;
              margin-bottom: 8px;
              font-size: 16px;
              color: rgb(51, 51, 51);
            "
          >
            Hence IoT is set to revolutionize supply chains in terms of :
          </p>
          <ul
            style="
              margin: 0;
              text-align: left;
              padding-left: 50px;
              line-height: 1.5em;
              color: rgb(51, 51, 51);
              font-size: 16px;
              margin-bottom: 8px;
            "
          >
            <li>Asset Tracking</li>
            <li>Vendor Management</li>
            <li>Inventory &amp; Forecasting</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td align="center" style="padding-top: 20px; padding-bottom: 10px">
          <a
            href="https://business.moglix.com/5-ways-iot-will-transform-operational-efficiency-supply-chains/"
            style="
              text-decoration: none;
              font-size: 18px;
              color: rgb(42, 93, 176);
              font-weight: 600;
              margin: 0;
              letter-spacing: 1px;
            "
            target="_blank"
            rel="noopener noreferrer"
            zcurl_name="l_0"
            >Click Here to Read More
          </a>
          <div
            style="
              border-top: 4px solid rgb(42, 93, 176);
              width: 175px;
              padding-bottom: 20px;
              margin-top: 5px;
            "
          ></div>
        </td>
      </tr>
      <tr>
        <td
          align="center"
          bgcolor="#f6f6f6"
          style="border-collapse: collapse; padding: 16px 0px 35px 0px"
        >
          <p
            style="
              margin: 0;
              color: rgb(0, 0, 0);
              padding-bottom: 22px;
              font-size: 16px;
              letter-spacing: 1px;
              padding-top: 4px;
            "
          >
            Bring advance <br />
            operational efficiency to your business
          </p>
          <a
            href="https://business.moglix.com/contact-us/"
            style="
              text-decoration: none;
              font-size: 16px;
              color: rgb(255, 255, 255);
              background-color: rgb(219, 35, 46);
              padding: 12px 35px 12px 35px;
              font-weight: 600;
              line-height: 1.5em;
              border-radius: 5px;
              letter-spacing: 1px;
              text-align: center;
            "
            target="_blank"
            rel="noopener noreferrer"
            zcurl_name="l_1"
            >Contact Us Now
          </a>
        </td>
      </tr>
      <tr>
        <td align="center" bgcolor="#333333">
          <p
            style="
              color: rgb(255, 255, 255);
              font-weight: 800;
              font-size: 16px;
              margin: 0;
              padding-top: 25px;
              line-height: 1.5em;
              text-align: center;
            "
          >
            Like Us?
          </p>
          <p
            style="
              color: rgb(255, 255, 255);
              font-weight: 800;
              font-size: 16px;
              margin: 0;
              line-height: 1.5em;
              text-align: center;
            "
          >
            Connect with us
          </p>
        </td>
      </tr>
      <tr>
        <td
          align="center"
          style="
            width: 100%;
            background-color: rgb(51, 51, 51);
            color: rgb(0, 0, 0);
            margin: 0px auto;
            padding-top: 10px;
            padding-bottom: 20px;
          "
        >
          <table
            cellpadding="0"
            cellspacing="0"
            style="width: 50%; padding-bottom: 20px"
          >
            <tbody>
              <tr>
                <td align="center">
                  <a
                    href="https://www.facebook.com/MoglixBusiness/"
                    style="text-decoration: none"
                    target="_blank"
                    rel="noopener noreferrer"
                    zcurl_name="l_2"
                  >
                    <img
                      class="CToWUd"
                      src="https://campaign-image.com/zohocampaigns/3_zc_v1_550211000000060241.png"
                      style="width: 9%; padding-right: 5px"
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/moglix-business/"
                    style="text-decoration: none"
                    target="_blank"
                    rel="noopener noreferrer"
                    zcurl_name="l_3"
                  >
                    <img
                      class="CToWUd"
                      src="https://campaign-image.com/zohocampaigns/4_zc_v1_550211000000060241.png"
                      style="width: 9%; padding-right: 5px"
                    />
                  </a>
                  <a
                    href="https://twitter.com/MoglixBusiness"
                    style="text-decoration: none"
                    target="_blank"
                    rel="noopener noreferrer"
                    zcurl_name="l_4"
                  >
                    <img
                      class="CToWUd"
                      src="https://campaign-image.com/zohocampaigns/5_zc_v1_550211000000060241.png"
                      style="width: 9%; padding-right: 5px"
                    />
                  </a>
                  <a
                    href="https://business.moglix.com/blog/"
                    target="_blank"
                    rel="noopener noreferrer"
                    zcurl_name="l_5"
                  >
                    <img
                      class="CToWUd"
                      src="https://campaign-image.com/zohocampaigns/6_zc_v1_550211000000060241.png"
                      style="width: 9%; padding-right: 5px"
                    />
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <td align="center" bgcolor="#ededed">
          <p
            style="
              font-size: 12px;
              color: rgb(83, 83, 83);
              padding: 20px 33px 15px 33px;
              line-height: 1.5em;
              margin: 0;
            "
          >
            Moglix is a leading B2B commerce company specialized in the
            procurement of industrial products. We extend our expertise in the
            supply chain to resolve problems related to digitization of the
            supply chain, as well as making it GST compliant. The company has
            been funded by marquee investors like Mr. Ratan Tata, Accel
            Partners, World Bank, SAP and Jungle Ventures. With a strong team
            of 500+ employees, our headquarter is based in Singapore, and in
            India, we are operating out from Noida, Gurgaon, Chennai, and
            Pune.
          </p>
          <p
            style="
              font-size: 12px;
              color: rgb(83, 83, 83);
              line-height: 1.5em;
              margin: 0;
              padding-bottom: 10px;
            "
          >
            Operating Office:D-188, sector-10 NOIDA Pin:201301 INDIA<br />
            Headquarter: #04-08/09, Block 79, Ayer Rajah Crescent, Pin: 139955
            SINGAPORE
          </p>
          <p></p>
        </td>
      </tr>
    </tbody>
  </table> -->
  <!-- <div [innerHtml]="https://mogl-zgph.maillist-manage.net/campaigns/SharedCampaignPreview.zc?c=html&cDgs=3z938bfbd6aea1b72aa66203e05ade7ed75e512117779e11eab21ca223c096ed1d"></div>  -->
  <!-- email template ends from table -->
</div>
