<ng-container>
  <div fxLayout="column" fxLayoutAlign="center center">
  <div fxLayout="row" fxLayoutAlign="center center" class=" width-100">
    <p>
      {{title}}
    </p>
  </div>
  <div fxLayout="row" class="pagination-wrapper  width-100" style="margin-top: 2%;" fxLayoutAlign="flex-end center">
    <mat-paginator #paginator [length]="totalRecords" class="custom-pagintor" [pageSize]="pageSize"
      (page)="searcLaneTask()" showFirstLastButtons>
      <!--  (page)="onScrollDown($event, lane?.laneDTO.id, lane['taskList'].length, lane?.totalRecords, lane['taskList'])" -->
    </mat-paginator>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center" class=" width-100">
    <div fxLayout="row" class="section-width width-100">
      <div class="sb_table section-width width-100 ">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 width-100"
           >
          <ng-container *ngFor="let disCol of displayedColumns; let colIndex = index"
            matColumnDef="{{disCol.field}}">
            <th mat-header-cell *matHeaderCellDef class="table-header"  > {{disCol.label}}
            </th>
            <td mat-cell *matCellDef="let element" style="text-align: center; cursor: pointer;" class="table-row"
              (click)="openCardDetails(element)">
              <div>
                <span> {{ getFieldValue(element, disCol.field) }}</span>
              </div>
            </td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumns1; sticky: true">

          </tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns1; let i = index"
            [ngClass]="{ 'active-row': i === selectedTrIndex }">

          </tr>
        </table>
      </div>
    </div>
  </div>
  </div>
</ng-container>

