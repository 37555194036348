
  <div class="d-flex-rw summary-dialog">
    <div class="pb-15 dialog-heading light-grey-bg meeting-list-header" aria-label="heading">
      <h1 class="modal-heading-2 text-capitalize mb-0">{{data.user.name}}'s Next Action items({{data.actionItemCount}})</h1>
      <button mat-mini-fab class="modal-close-btn" (click)="dialogRef.close();"><i class="ri-close-circle-fill"></i></button>
    </div>
  </div>

  <div class="w-100 border-top p-20" *ngFor="let item of actionItemList; let i = index;">
    <div class="chat-thread">
      <h4>Deal: {{item.laneTaskName}}</h4>
      <div class="d-flex justify-content-between">
        <div class="topDeal-name">
          <p class="user-name">{{item?.byUser.name}}</p>
          <p class="user-email">{{item?.byUser.email}}</p>
        </div>
        <p class="created-date">Date: {{item.createdAt | date}}</p>
      </div>
      <span [innerHtml]="item.message" class="main-message"></span>
      <div class="d-flex">
        <p class="created-date my-5">Next Action Date: {{item.nextActionDate | date}}</p>
      </div>
      <span class="comments-label">Added Comment</span>
      <ul *ngIf="!showMoretext" class="chat-tread-list">
        <li *ngFor="let comment of item.comments | slice:0:2; let i=index">
          <div class="d-flex justify-content-between">
            <div class="">
              <p class="user-name">{{comment?.byUser.name}}</p>
              <p class="user-email">{{comment?.byUser.email}}</p>
            </div>
            <p class="created-date">Date: {{comment.createdAt | date}}</p>
          </div>
          <div class="replied-message-section">
            <p class="replied-message" [innerHtml]="comment.message"></p>
          </div>
        </li>
      </ul>
      <ul *ngIf="showMoretext" class="chat-tread-list">
        <li *ngFor="let comment of item.comments">
          <div class="d-flex justify-content-between">
            <div class="">
              <p class="user-name">{{comment?.byUser.name}}</p>
              <p class="user-email">{{comment?.byUser.email}}</p>
            </div>
            <p class="created-date">Date: {{comment.createdAt | date}}</p>
          </div>
          <div class="replied-message-section">
            <p class="replied-message" [innerHtml]="comment.message"></p>
          </div>
        </li>
      </ul>
      <span *ngIf="item.comments.length > 2" (click)="toggleText()" class="comments-label-blue">
        {{ showMoretext ? "View Less" : "View All " + item.comments.length + " Comments" }}
      </span>
      <a class="red-anchor" (click)="toggleCommentArea(i)" *ngIf="selectedIndex != i">Add Comment</a>
      <div class="mt-10" style="width: 100%; padding: 0px 10px;" *ngIf="selectedIndex == i">
        <div fxLayout="row" fxLayoutAlign="start center">
          <quill-editor #elem [id]="i" class="w-100 comments-quill-editor" [modules]="modules"></quill-editor>
        </div>
        <div fxLayout="row" fxLayoutAlign="end center" class="mt-10">
          <button mat-button class="cancel-comment mr-10" (click)="closeCommentArea(i)">Cancel</button>
          <!-- <button class="mog3_primary_flat_btn post-button" >Add</button> -->
          <button class="btn-primary" mat-raised-button (click)="addCommentsOnActionItemOrNote(item, i)" color="Red">Add</button>
        </div>
      </div>
    </div>
    <!-- <hr style="color: #E6E6E6;"> -->
  </div>
  

