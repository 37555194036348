<section>
  <div fxLayout="row" fxLayoutAlign="space-between flex-start">
    <div class="page-header">
      <span class="breadcrumb-text">
        <span
          routerLink="/crm-board"
          class="text-red d-inline-flex"
          style="cursor: pointer"
        >
          Contacts</span
        >
        <i class="ri-arrow-right-s-line"></i>
        {{ displayName }}
      </span>
    </div>
  </div>
  <!-- <div class="float-right-btn-group-fixed">
    <button
      mat-flat-button
      class="mog3_primary_flat_btn add-mom"
      aria-label="Save"
      matTooltip="Refresh"
      matTooltipClass="new-tooltip"
    >
      <i class="ri-refresh-line"></i> Refresh
    </button>
  </div> -->
  <mat-tab-group
    *ngIf="!isLoading && emailList !== null"
    class="tab-2"
    animationDuration="0ms"
    (selectedTabChange)="selectTab($event)"
  >
    <mat-tab label="Sent Mail" (click)="selectTab(0)">
      <div fxLayout="row">
        <div fxFlex="30">
          <div class="meeting-tabs scrollbar-1">
            <div>
              <ul class="meeting-list">
                <li
                  *ngFor="let mail of emailList"
                  [class.active]="mail === viewMail"
                  (click)="onListItemClick(mail)"
                >
                  <div class="d-flex justify-content-between">
                    <h2 *ngIf="mail.subject !== null">{{ mail.subject }}</h2>
                    <h5 class="mb-10">
                      {{ mail.createdDateTime | date : "medium" }}
                    </h5>
                  </div>
                  <div class="email-group">
                    <h3>{{ mail.bodyPreview }}</h3>
                  </div>
                  <h4>To: {{ fromMailId }}</h4>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="border-right-1" fxFlex="50">
          <div class="meeting-detail-group" *ngIf="viewMail !== null">
            <h1>{{ viewMail.subject }}</h1>
            <h5>
              {{ viewMail.createdDateTime | date : "medium" }} |
              {{ viewMail.lastModifiedDateTime | date : "medium" }}
            </h5>
            <div class="meeting-detail">
              <p></p>
              <div [innerHtml]="viewMail.body.content"></div>
              <hr />
            </div>
          </div>
        </div>
        <div fxFlex="20">
          <div class="meeting-member-list">
            <h2>Contacts</h2>
            <div class="list">
              <div class="to-mails" *ngIf="viewMail !== null">
                <h5>To :</h5>
                <ul>
                  <li *ngFor="let toRecipient of toRecipients">
                    <div
                      style="background-color: #b5f2ba; color: #272727"
                      class="user-name-letter"
                    >
                      {{ toRecipient.emailAddress.name.charAt(0) }}
                    </div>
                    <div class="user-info-seciton">
                      <h3>{{ toRecipient.emailAddress.name }}</h3>
                      <h4>{{ toRecipient.emailAddress.address }}</h4>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="cc-mails" *ngIf="viewMail !== null">
                <h5>Cc :</h5>
                <ul>
                  <li *ngFor="let ccRecipient of ccRecipients">
                    <div
                      style="background-color: #b5f2ba; color: #272727"
                      class="user-name-letter"
                    >
                      {{ ccRecipient.emailAddress.name.charAt(0) }}
                    </div>
                    <div class="user-info-seciton">
                      <h3>{{ ccRecipient.emailAddress.name }}</h3>
                      <h4>{{ ccRecipient.emailAddress.address }}</h4>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Received Mail" (click)="selectTab(1)">
      <div fxLayout="row">
        <div fxFlex="30">
          <div class="meeting-tabs scrollbar-1">
            <div>
              <!-- <div class="meeting-date">10:24 AM</div> -->
              <ul class="meeting-list">
                <li
                  class="active"
                  *ngFor="let mail of emailList"
                  [class.active]="mail === viewMail"
                  (click)="onListItemClick(mail)"
                >
                  <div class="d-flex justify-content-between">
                    <h2 *ngIf="mail?.subject">{{ mail.subject }}</h2>
                    <h5 class="mb-10">{{ mail.createdDateTime }}</h5>
                  </div>
                  <div class="email-group">
                    <h3>{{ mail.bodyPreview }}</h3>
                  </div>
                  <h4>To: {{ fromMailId }}</h4>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="border-right-1" fxFlex="50">
          <div class="meeting-detail-group" *ngIf="viewMail !== null">
            <h1>{{ viewMail.subject }}</h1>
            <h5>
              {{ viewMail.createdDateTime }} |
              {{ viewMail.lastModifiedDateTime }}
            </h5>
            <div class="meeting-detail">
              <p></p>
              <div [innerHtml]="viewMail.body.content"></div>
              <hr />
            </div>
          </div>
        </div>
        <div fxFlex="20">
          <div class="meeting-member-list">
            <h2>Contacts</h2>
            <div class="list">
              <div class="to-mails" *ngIf="viewMail !== null">
                <h5>To :</h5>
                <ul>
                  <li *ngFor="let toRecipient of toRecipients">
                    <div
                      style="background-color: #b5f2ba; color: #272727"
                      class="user-name-letter"
                    >
                      {{ toRecipient.emailAddress.name.charAt(0) }}
                    </div>
                    <div class="user-info-seciton">
                      <h3>{{ toRecipient.emailAddress.name }}</h3>
                      <h4>{{ toRecipient.emailAddress.address }}</h4>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="cc-mails" *ngIf="viewMail !== null">
                <h5>Cc :</h5>
                <ul>
                  <li *ngFor="let ccRecipient of ccRecipients">
                    <div
                      style="background-color: #b5f2ba; color: #272727"
                      class="user-name-letter"
                    >
                      {{ ccRecipient.emailAddress.name.charAt(0) }}
                    </div>
                    <div class="user-info-seciton">
                      <h3>{{ ccRecipient.emailAddress.name }}</h3>
                      <h4>{{ ccRecipient.emailAddress.address }}</h4>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
  <div class="empty-div" *ngIf="emailList === null">
    <div fxLayout="row">
      <div fxFlex="30">
        <span>No Email to display.</span>
      </div>
    </div>
  </div>
</section>
