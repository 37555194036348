<div class="dialog-section lane-movement-dialog" *ngIf="toLane != null && fromLane != null">
  <section class="pb-15 dialog-heading border-unset">
    <div class="d-flex justify-content-between mb-15">
      <h1 class="modal-heading-1">Stage Moved</h1>
      <button mat-mini-fab class="modal-close-btn" (click)="closeDialog();"><i
          class="ri-close-circle-fill"></i></button>
    </div>
    <h2 class="modal-heading-2 d-flex font-roboto"><span>{{fromLane.displayName}}</span> <i
        class="ri-arrow-right-line mx-10"></i> <span>{{toLane.displayName}}</span> </h2>
  </section>
  <section class="dialog-body p-0">
    <!-- Hunting at Cold/Lost Deal -->
    <h3 class="modal-heading-3 font-light" *ngIf="boardId===boardIdForCRMDeals && toLane != null && toLane.position > 7">Please select reason from below option</h3>
    <h3 class="modal-heading-3" *ngIf="boardId===boardIdForCRMDeals && toLane != null && toLane.position > 7">Select reason</h3>
    <mat-form-field class="mog3_floatmatinput w-50 " appearance="outline" *ngIf="boardId===boardIdForCRMDeals && toLane != null && toLane.position > 7">
      <mat-select [(ngModel)]="selectedReason">
        <mat-option *ngFor="let reason of moveReasons" [value]="reason">{{reason}}</mat-option>
      </mat-select>
    </mat-form-field>
    <!-- SOB at Cold/Lost Deal -->
    <h3 class="modal-heading-3 font-light" *ngIf="boardId===boardIdForSobOpportunity && toLane != null && toLane.position > 6">Please select reason from below option</h3>
    <h3 class="modal-heading-3" *ngIf="boardId===boardIdForSobOpportunity && toLane != null && toLane.position > 6">Select reason</h3>
    <mat-form-field class="mog3_floatmatinput w-50 " appearance="outline" *ngIf="boardId===boardIdForSobOpportunity && toLane != null && toLane.position > 6">
    <mat-select [(ngModel)]="selectedReason">
      <mat-option *ngFor="let reason of moveReasons" [value]="reason">{{reason}}</mat-option>
    </mat-select>
    </mat-form-field>
    <!-- Hunting KAM Handover -->
    <div *ngIf="boardId===boardIdForCRMDeals && toLane.position === 7">
      <div class="atom-row justify-between my-20">
        <!-- SOB Opportunity Name -->
        <div class="atom-col-sm-6 mb-25">
          <div class="input-group relative">
            <mat-label>Opportunity Name*</mat-label>
            <mat-form-field class="mat-input deep-0" appearance="outline" [ngClass]="{'error-border': errorField.includes('opportunityname')}" >
              <input matInput placeholder="opportunity name" [(ngModel)]="opportunityname" [ngClass]="{'error-border': errorField.indexOf(opportunityname) != -1}"/>
            </mat-form-field>
            <mat-error class="absolute -bottom-17" *ngIf="errorField.includes('opportunityname')">Please Add opportunity name</mat-error>
          </div>


        </div>
        <!-- SOB Account -->
        <div class="atom-col-sm-6 mb-25">
          <div class="input-group relative">
            <mat-label>SOB Account Name/ID*</mat-label>
            <app-field-autocomplete [ngClass]="{'error-border': errorField.includes('sobAccountId')}" #autocompleteComponent [fieldData]="{
              'config' : sobAccountConfigOpportunity,
              'isFilter' : true,
              'withoutAccess':true,
              'selectedHighlight':false
            }" [modelRef]="sobAccountConfigOpportunity.value" (filterApplied)="onAccountFilter(sobAccountConfigOpportunity);">
              </app-field-autocomplete>
              <mat-error class="absolute -bottom-17" *ngIf="errorField.includes('sobAccountId')">Please select SOB account name/id</mat-error>
          </div>

        </div>
        <!-- SOB Plant -->
        <div   class="atom-col-sm-6 mb-25">
          <div class="input-group">
            <mat-label>SOB Plant*</mat-label>
            <mat-form-field appearance="outline" class="mat-select deep-0" >
              <mat-select #sl2 placeholder="Plants" multiple [(ngModel)]="selectedPlantIds" [disabled]="sobregion ==null || sobAccountId==null" [ngClass]="{'error-border': errorField.indexOf(sobSelectedPlantIds) != -1}">
                <mat-form-field class="mat-input select-search" appearance="outline">
                  <input type="text" matInput placeholder="Search" [(ngModel)]="searchControl"
                    (keyup)="filterOptions($event)" />
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="14" height="16">
                    <path
                      d="M18.031 16.6168L22.3137 20.8995L20.8995 22.3137L16.6168 18.031C15.0769 19.263 13.124 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20 13.124 19.263 15.0769 18.031 16.6168ZM16.0247 15.8748C17.2475 14.6146 18 12.8956 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18C12.8956 18 14.6146 17.2475 15.8748 16.0247L16.0247 15.8748Z"
                      fill="rgba(111,111,112,1)"></path>
                  </svg>
                </mat-form-field>
                <div class="custom-panel search-with-options">
                  <mat-option *ngFor="let plant of sobPlantListSearch" [value]="plant.id"> {{
                    plant.name + ' (' + (plant.searchData.plantid ? plant.searchData.plantid : 'N/A') +
                    ')' }}
                  </mat-option>
                </div>
                <div fxLayout="row" fxLayoutAlign="flex-end space-between" class="filter-btn-section">
                  <button class="filter-btn clear" (click)="clearSelections()">Clear</button>
                </div>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <!-- SOB KAM -->
        <div class="atom-col-sm-6 mb-25">
          <div class="input-group relative">
            <mat-label>SOB KAM*</mat-label>
            <app-field-autocomplete #userAutoCompleteComponent [ngClass]="{'error-border': errorField.includes('subUser')}" class="w-100 lane-card-input mb-025em"
                [fieldData]="{ config: sobUserConfig, isFilter: true, 'selectedHighlight':false}"
                [modelRef]="sobUserConfig.value" (filterApplied)="setSobUser(sobUserConfig)">    
            </app-field-autocomplete>
            <mat-error class="absolute -bottom-17" *ngIf="errorField.includes('subUser')">Please select SOB KAM</mat-error>
          </div>
        </div>
        <!-- SOB Region -->
        <div class="atom-col-sm-6 mb-25">
          <div class="input-group">
            <mat-label>Region*</mat-label>
            <mat-form-field appearance="outline" class="mat-select deep-0">
              <mat-select #sl2 placeholder="Region" [(ngModel)]="sobregion" >
                <mat-form-field class="mat-input select-search" appearance="outline">
                  <input type="text" matInput placeholder="Search" [(ngModel)]="searchControlRegionTeam"
                    (keyup)="filterOptionsRegionTeam()" />
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="14" height="16">
                    <path
                      d="M18.031 16.6168L22.3137 20.8995L20.8995 22.3137L16.6168 18.031C15.0769 19.263 13.124 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20 13.124 19.263 15.0769 18.031 16.6168ZM16.0247 15.8748C17.2475 14.6146 18 12.8956 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18C12.8956 18 14.6146 17.2475 15.8748 16.0247L16.0247 15.8748Z"
                      fill="rgba(111,111,112,1)"></path>
                  </svg>
                </mat-form-field>
                <div class="custom-panel search-with-options">
                  <mat-option *ngFor="let region of regionTeamListSearch" [value]="region.id"> {{ region.name
                    }} </mat-option>
                </div>

              </mat-select>
            </mat-form-field>
          </div>


        </div>
        <!-- SOB Business Vertical -->
        <div *ngIf="sobBusinessVerticalConfig" class="atom-col-sm-6 mb-25">
          <div class="input-group">
            <mat-label>SOB Business Vertical*</mat-label>
            <app-custom-single-select [ngClass]="{'error-border': errorField.indexOf(sobbusinessvertical) != -1}" class="full-width" [formItem]="sobBusinessVerticalConfig"
            [searchControl]="searchControlSOBBusinessVertical" [optionList]="sobBusinessVerticalListSearch"
            (searchChange)="filterOptionsBusinessVertical($event)">
          </app-custom-single-select>
          
          </div>
        </div>
        <!-- SOB Opportnity Type -->
        <div *ngIf="sobOpportunityTypeConfig" class="atom-col-sm-6 mb-25">
          <div class="input-group relative">
            <mat-label>Opportunity Type</mat-label>
            <app-custom-single-select [ngClass]="{'error-border': errorField.includes('sobopportunity')}" class="full-width " [formItem]="sobOpportunityTypeConfig"
            [searchControl]="searchControlSOBOpportunityType" [optionList]="sobOpportunityTypeListSearch"
            (selectionChange)="onSelectOptionSelectionChange($event)"
            (searchChange)="filterOptionsOpportunityType($event)">
          </app-custom-single-select>
          <mat-error class="absolute -bottom-17" *ngIf="errorField.includes('sobopportunity')">Please select opportunity type</mat-error>
          </div>
        </div>
        <!-- SOB L1 Category -->
        <div *ngIf="isDealTypeNewCategories && sobL1CategoryConfig" class="atom-col-sm-6 mb-25">
          <div class="input-group relative">
            <mat-label>SOB L1 Category*</mat-label>
            <app-custom-single-select [ngClass]="{'error-border': errorField.includes('sobL1Category')}" class="full-width" 
                [formItem]="sobL1CategoryConfig" [searchControl]="sobSearchControlL1Category"
                [optionList]="sobL1CategoryListSearch" (selectionChange)="onSelectOptionSelectionChange($event)"
                (searchChange)="filterOptionsL1CategoryList($event)">
             </app-custom-single-select>
             <mat-error class="absolute -bottom-17" *ngIf="errorField.includes('sobL1Category')">Please select SOB L1 Category</mat-error>
          </div>
        </div>
      <!-- SOB L2 Category -->
        <div  *ngIf="isNewCategories" class="atom-col-sm-6 mb-25">
          <div class="input-group relative">
            <mat-label>SOB L2 Category*</mat-label>
            <mat-form-field appearance="outline" class="mat-select deep-0" [ngClass]="{'error-border': errorField.includes('sobL2Category')}">
                      <mat-select multiple [(ngModel)]="sobL2CategoryConfig.values" [ngClass]="{'error-border': errorField.indexOf(sobL2Category) != -1}">
                        <mat-select-trigger>
                          <ng-container >
                            <span class="test">{{ sobL2CategoryConfig.values[0] }}</span>
                            <span class="test2" *ngIf="sobL2CategoryConfig.values.length > 1"
                              class="example-additional-selection">
                              (+ {{ sobL2CategoryConfig.values.length - 1 }} {{ sobL2CategoryConfig.values.length === 2 ?
                              "other" : "others" }})
                            </span>
                          </ng-container>
                        </mat-select-trigger>
                        <mat-option *ngFor="let opt of subCategoriesOfNewCategories" [value]="opt">{{ opt
                          }}</mat-option>
                      </mat-select>
           </mat-form-field>
           <mat-error class="absolute -bottom-17" *ngIf="errorField.includes('sobL2Category')">Please select SOB L2 Category</mat-error>
          </div>       
        </div>
        <!-- SOB PO Type -->
        <div *ngIf="sobPoTypeConfig" class="atom-col-sm-6 mb-25">
          <div class="input-group relative">
            <mat-label>PO Type*</mat-label>
            <app-custom-single-select [ngClass]="{'error-border': errorField.includes('sobpotype')}" class="full-width" 
                [formItem]="sobPoTypeConfig" [searchControl]="sobSearchControlPoType"
                [optionList]="sobPoTypeListSearch" (selectionChange)="onSelectOptionSelectionChange($event)"
                (searchChange)="filterOptionsPoTypeList($event)">
             </app-custom-single-select>
             <mat-error class="absolute -bottom-17" *ngIf="errorField.includes('sobpotype')">Please Select P0 type</mat-error>
          </div>
        </div>
      </div>


    </div>
    <div fxLayout="column">
      <div fxLayout="row wrap"  *ngIf="(boardId===boardIdForSobOpportunity || boardId===boardIdForCRMDeals)" fxLayoutAlign="flex-start">
        <!-- Hunting/SOB Business Potential at C2/S2-->
        <div fxFlex="48" fxLayout="column" class="mb-15 mr-15 mt-15" *ngIf="toLane != null && toLane.position === 3">
          <mat-form-field class="mog3_floatmatinput" appearance="outline">
            <mat-label class="head-label"> Business Potential (cr)</mat-label>
            <input matInput type="number" class="input-field" required [(ngModel)]="businessPotential">
          </mat-form-field>          
        </div>
        <!-- Hunting/SOB Realistic Size at C2/S2-->
        <div fxFlex="48" fxLayout="column" class="mb-15 mt-15" *ngIf="toLane != null && toLane.position === 3">
          <mat-form-field class="mog3_floatmatinput" appearance="outline">
            <mat-label class="head-label" *ngIf="boardId!==boardIdForSobOpportunity"> Realistic Deal Size
              (cr)</mat-label>
            <mat-label class="head-label" *ngIf="boardId===boardIdForSobOpportunity"> Incremental Deal Size
              (cr)</mat-label>
            <input matInput type="number" class="input-field" required [(ngModel)]="realisticDealSize">
          </mat-form-field>
        </div>
        <!-- Hunting/SOB Monthly Projection at C3/S3-->
        <div fxFlex="100" fxLayout="column" class="mb-15 mr-15" *ngIf="toLane != null && toLane.position === 4">
          <label class="line-after"><span>6 Month projection ( {{projectionTimeline}})</span></label>
          <div class="projection-inputs">
            <mat-form-field class="mog3_floatmatinput" appearance="outline">
              <mat-label class="head-label">M1 (Cr)*</mat-label>
              <input matInput type="number" class="input-field" [(ngModel)]="m1">
            </mat-form-field>
            <mat-form-field class="mog3_floatmatinput" appearance="outline">
              <mat-label class="head-label">M2 (Cr)*</mat-label>
              <input matInput type="number" class="input-field" [(ngModel)]="m2">
            </mat-form-field>
            <mat-form-field class="mog3_floatmatinput" appearance="outline">
              <mat-label class="head-label">M3 (Cr)*</mat-label>
              <input matInput type="number" class="input-field" [(ngModel)]="m3">
            </mat-form-field>
            <mat-form-field class="mog3_floatmatinput" appearance="outline">
              <mat-label class="head-label">M4 (Cr)*</mat-label>
              <input matInput type="number" class="input-field" [(ngModel)]="m4">
            </mat-form-field>
            <mat-form-field class="mog3_floatmatinput" appearance="outline">
              <mat-label class="head-label">M5 (Cr)*</mat-label>
              <input matInput type="number" class="input-field" [(ngModel)]="m5">
            </mat-form-field>
            <mat-form-field class="mog3_floatmatinput" appearance="outline">
              <mat-label class="head-label">M6 (Cr)*</mat-label>
              <input matInput type="number" class="input-field" [(ngModel)]="m6">
            </mat-form-field>
          </div>
        </div>
        <!-- Hunting/SOB Contacts at C1/S1 -->
        <div class="formfield w-100  mb-10 mt-15" *ngIf="toLane != null && toLane.position == 2">
          <label>Add Contact*</label>
          <mat-form-field class="mog3_matinput chip-list mb-10" appearance="outline">
            <mat-chip-list #chipList>
              <mat-chip class="chip-1" *ngFor="let contact of contacts" [selectable]="selectable"
                [removable]="removable" (removed)="remove(contact)">
                {{contact}}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip>
              <div *ngIf="isLoading" fxLayout="row" style="position: absolute; top: 10%" fxLayoutAlign="center center">
                <mat-spinner strokeWidth="2" [diameter]="60" [color]="red"></mat-spinner>
              </div>
              <input placeholder="Select from contact book" #contactInput [formControl]="contactCtrl"
                [matAutocomplete]="auto" [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                (matChipInputTokenEnd)="add($event)">
              <svg class="contact-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16">
                <path
                  d="M7 2V22H3V2H7ZM9 2H19.0049C20.1068 2 21 2.89821 21 3.9908V20.0092C21 21.1087 20.1074 22 19.0049 22H9V2ZM22 6H24V10H22V6ZM22 12H24V16H22V12ZM15 12C16.1046 12 17 11.1046 17 10C17 8.89543 16.1046 8 15 8C13.8954 8 13 8.89543 13 10C13 11.1046 13.8954 12 15 12ZM12 16H18C18 14.3431 16.6569 13 15 13C13.3431 13 12 14.3431 12 16Z"
                  fill="rgba(57,127,244,1)"></path>
              </svg>
            </mat-chip-list>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
              <mat-option *ngFor="let contact of filteredcontacts | async" [value]="contact">
                {{contact}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="(boardId===boardIdForSobOpportunity || boardId===boardIdForCRMDeals)">
        <!-- Hunting/SOB Probability of Conversion % at C1-C4 / S1-S4 -->
        <div fxFlex="48" fxLayout="column" class=" mr-15" *ngIf="toLane != null && toLane.position < 6 && toLane.position > 2">
          <mat-form-field class="mog3_floatmatinput" appearance="outline">
            <mat-label class="head-label"> Probability of Conversion %</mat-label>
            <input matInput type="number" class="input-field" [(ngModel)]="stageConversion">
          </mat-form-field>
        </div>
        <!-- Hunting/SOB Add New Contact at C1/S1 -->
        <div>
          <button mat-stroked-button class="mog3_secondary_flat_btn" (click)="addNewLead()" *ngIf="toLane != null && toLane.position == 2">
            <i class="ri-add-line"></i>
            <span>Add New Contact</span>
          </button>
        </div>
        <!-- Hunting/SOB move Stage button -->
        <div>
          <button mat-stroked-button class="mog3_secondary_flat_btn mr-20" (click)="closeDialog()"
            color="warn">Cancel</button>
          <button mat-flat-button class="mog3_primary_flat_btn" (click)="moveLaneTask()">
            <span>Update Stage</span>
          </button>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center" class="flex-wrap" *ngIf="boardId===boardIdForSrmSupplier">
        <!-- SRM NDA  at S1 -->
      
         <!-- SRM RFQ ID at S2-->
         <div fxFlex="48" fxLayout="column" class="mb-15 mr-15 mt-15" *ngIf="toLane != null && toLane.position === 3">
          <mat-form-field class="mog3_floatmatinput" appearance="outline">
            <mat-label class="head-label"> RFQ ID</mat-label>
            <input matInput type="text" class="input-field" required [(ngModel)]="rfqID">
          </mat-form-field>          
        </div>
        <!-- SRM PO ID at S3-->
        <div fxFlex="48" fxLayout="column" class="mb-15 mr-15 mt-15" *ngIf="toLane != null && toLane.position === 4">
          <mat-form-field class="mog3_floatmatinput" appearance="outline">
            <mat-label class="head-label"> PO ID</mat-label>
            <input matInput type="text" class="input-field" required [(ngModel)]="poID">
          </mat-form-field>          
        </div>
        <!-- SRM TOD Document  at S4 -->
        <div  fxFlex="100" fxLayoutAlign="center center" fxLayout="column" class=" mr-15 mb-30" *ngIf="toLane != null && toLane.position==5">
          <mat-label class="head-label"> TOD Document</mat-label>
          <button class="btn-outline-primary atom-input-file-btn max-w-unset" color="Red" type="button" mat-stroked-button >
          <ng-container *ngIf="!file">
           
                <div class="atom-select-file" (click)="fileInput.value='';fileInput.click()" draggable="true" ngFileDragDrop (fileDropped)="fileUpload($event)">
                    <input style="display: none" type="file" (change)="fileUpload($event)" #fileInput>
                    <span >
                      <i class="ri-upload-2-line mr-5"></i> Attach
                    </span>
                </div>
          
            </ng-container>
            <ng-container *ngIf="file">
              <div class="file-name-section">
                 <span class="file-name w-full">
                     {{file.name}} 
                 </span>
                 <a>
                   <i class="ri-close-line" (click)="removeSelectedFile()"></i>
                 </a>
               </div>
               <!-- <button class="btn-outline-primary atom-input-file-btn" color="Red" type="button" mat-stroked-button (click)="uploadAttachemet('nda')">
                <span >
                  <i class="ri-upload-2-line mr-5"></i> Upload
                </span>
                </button> -->
            </ng-container>
          </button>
        </div>
        <!-- SRM move Stage button -->
        <div  fxFlex="100" fxLayoutAlign="center center" fxLayout="column" class=" mr-15 mb-30" *ngIf="toLane != null && toLane.position==2">
          <mat-label class="head-label"> Upload NDA</mat-label>
          <button class="btn-outline-primary atom-input-file-btn max-w-unset" color="Red" type="button" mat-stroked-button >
          <ng-container *ngIf="!file">
           
                <div class="atom-select-file" (click)="fileInput.value='';fileInput.click()" draggable="true" ngFileDragDrop (fileDropped)="fileUpload($event)">
                    <input style="display: none" type="file" (change)="fileUpload($event)" #fileInput>
                    <span >
                      <i class="ri-upload-2-line mr-5"></i> Attach
                    </span>
                </div>
          
            </ng-container>
            <ng-container *ngIf="file">
              <div class="file-name-section">
                 <span class="file-name w-full">
                     {{file.name}} 
                 </span>
                 <a>
                   <i class="ri-close-line" (click)="removeSelectedFile()"></i>
                 </a>
               </div>
               <!-- <button class="btn-outline-primary atom-input-file-btn" color="Red" type="button" mat-stroked-button (click)="uploadAttachemet('nda')">
                <span >
                  <i class="ri-upload-2-line mr-5"></i> Upload
                </span>
                </button> -->
            </ng-container>
          </button>
        </div>
        <div fxFlex="100" fxLayout="row" fxLayoutAlign="flex-end center">
         
          <button mat-stroked-button class="mog3_secondary_flat_btn mr-20" (click)="closeDialog()"
            color="warn">Cancel</button>
          <button mat-flat-button class="mog3_primary_flat_btn" (click)="moveLaneTask()">
            <span>Update Stage</span>
          </button>
        </div>
      </div>
    </div>
  </section>
</div>