import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-kam-deals-popup',
  templateUrl: './kam-deals-popup.component.html',
  styleUrls: ['./kam-deals-popup.component.scss']
})
export class KamDealsPopupComponent implements OnInit {

  displayedColumns = ['sheetId', 'customerName', 'totalEffectiveCl', 'timeStamp', 'createdBy'];
  dataSource: Transaction[] = [
    {sheetId: '', customerName: '', totalEffectiveCl:'', timeStamp:'', createdBy:''},
    {sheetId: '', customerName: '', totalEffectiveCl:'', timeStamp:'', createdBy:''},
    {sheetId: '', customerName: '', totalEffectiveCl:'', timeStamp:'', createdBy:''},
    {sheetId: '', customerName: '', totalEffectiveCl:'', timeStamp:'', createdBy:''},
    {sheetId: '', customerName: '', totalEffectiveCl:'', timeStamp:'', createdBy:''},
    {sheetId: '', customerName: '', totalEffectiveCl:'', timeStamp:'', createdBy:''},
    {sheetId: '', customerName: '', totalEffectiveCl:'', timeStamp:'', createdBy:''},
    {sheetId: '', customerName: '', totalEffectiveCl:'', timeStamp:'', createdBy:''},
    {sheetId: '', customerName: '', totalEffectiveCl:'', timeStamp:'', createdBy:''},
  ];
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    console.log("this.data", this.data);
  }

}


export interface Transaction {
  sheetId: any;
  customerName: any;
  totalEffectiveCl:any;
  timeStamp:any;
  createdBy:any;
}
