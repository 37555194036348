import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { KanbanService } from '../../../kanban.service';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { debounceTime, delay, tap, filter, map, takeUntil } from 'rxjs/operators';
import { SettingService } from 'src/app/settings/setting.service';
import { MatDialog } from '@angular/material';
import { CreateLaneTaskComponent } from '../create-lane-task/create-lane-task.component';
import { Constants } from 'src/app/Constants';

@Component({
  selector: 'app-field-autocomplete',
  templateUrl: './field-autocomplete.component.html',
  styleUrls: ['./field-autocomplete.component.scss']
})
export class FieldAutocompleteComponent implements OnInit {
  @Input() fieldData;
  @Input() modelRef;
  @Output() filterApplied = new EventEmitter<string>();
  searchText = "";
  isCreate = false;
  isFilter: any;
  showCloseIcon = false;
  storeSearchResponse = [];
  showListing: boolean = false;
  autoSuggestloading: boolean = false
  sendFilteredReqObj: any;
  currentUser: any;
  error:boolean=false;
  selectedBusinessID:any;
  protected results: any[] = [];
  private destroy$: Subject<void> = new Subject<void>();
  public textServerSideCtrl: FormControl = new FormControl(null);
  public textServerSideFilteringCtrl: FormControl = new FormControl('');
  /** indicate search operation is in progress */
  public searching = false;
  public addData: string = "Search";
  /** list of banks filtered after simulating server side search */
  public filteredServerSideTexts: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();
  constructor(public _kService: KanbanService, public settingService: SettingService, public dialog: MatDialog, private cdRef:ChangeDetectorRef) {

  }
  ngOnChanges(changes: SimpleChanges) {
    console.log('change',changes);
    if (this.fieldData.config.isClear) {
      this.modelRef="";
      this.fieldData.config.value = "";
      this.fieldData.config.icon = "";
      this.fieldData.config.additionalData = {};
      this.filteredServerSideTexts.next([]);
      this.searchText = "";
      this.addData = "Search "+this.fieldData.config.displayName;
      this.resetAccountFilterInCache();
      this.filterApplied.emit("");
    }else{
      if (this.fieldData.config.key == "user" && this.currentUser && (!this.fieldData.config.additionalData || !this.fieldData.config.additionalData.name) && this.fieldData.config.ownerField) {
        console.log('current user',this.currentUser)
        if(this.selectedBusinessID==Constants.businessIdForCRM){
          this.textServerSideFilteringCtrl.setValue(this.currentUser.crmUserName);
          this.addData = this.currentUser.crmUserName;
          this.fieldData.config.value = this.currentUser.crmUserId;  
        }
        else if(this.selectedBusinessID==Constants.businessIdForSOB){
          this.textServerSideFilteringCtrl.setValue(this.currentUser.sobUserName);
          this.addData = this.currentUser.sobUserName;
          this.fieldData.config.value = this.currentUser.sobUserId;  
        }
        else if(this.selectedBusinessID==Constants.businessIdForSRM){
          this.textServerSideFilteringCtrl.setValue(this.currentUser.srmUserName);
          this.addData = this.currentUser.srmUserName;
          this.fieldData.config.value = this.currentUser.srmUserId;  
        }
        // this.isCreate = true;
      }
      else if(this.fieldData.config.additionalData && this.fieldData.config.additionalData.name){
        this.textServerSideFilteringCtrl.setValue(this.fieldData.config.additionalData.name);
        this.addData = this.fieldData.config.additionalData.name;
      }
      else{
        this.addData = "Search "+this.fieldData.config.displayName;
      }
    }
  }
  ngAfterViewChecked(){
    this.cdRef.detectChanges();
  }
  ngOnInit() {
    console.log('ngOnInit',this.fieldData);
    let tmpCurrentUser = JSON.parse(localStorage.getItem("USERINFO"))
    if (tmpCurrentUser) {
      this.currentUser = tmpCurrentUser;
    }
    let currentBusinessID = localStorage.getItem("selectedBusinessId")
    if(currentBusinessID){
      this.selectedBusinessID=currentBusinessID;
    }
    if (this.fieldData.config.key != null && this.fieldData.config.key.trim() != "") {
      if ((this.fieldData.config.type == "belongsTo") && this.fieldData.config && this.fieldData.config.additionalData && this.fieldData.config.additionalData.name) {
        this.textServerSideFilteringCtrl.setValue(this.fieldData.config.additionalData.name);
        this.addData = this.fieldData.config.additionalData.name;
      }
       else if (this.fieldData.config.key == "user" && (!this.fieldData.config.additionalData || !this.fieldData.config.additionalData.name) && this.fieldData.config.ownerField) {
        if(this.selectedBusinessID==Constants.businessIdForCRM){
          this.textServerSideFilteringCtrl.setValue(this.currentUser.crmUserName);
          this.addData = this.currentUser.crmUserName;
          this.fieldData.config.value = this.currentUser.crmUserId;  
        }
        else if(this.selectedBusinessID==Constants.businessIdForSOB){
          this.textServerSideFilteringCtrl.setValue(this.currentUser.sobUserName);
          this.addData = this.currentUser.sobUserName;
          this.fieldData.config.value = this.currentUser.sobUserId;  
        }
        else if(this.selectedBusinessID==Constants.businessIdForSRM){
          this.textServerSideFilteringCtrl.setValue(this.currentUser.srmUserName);
          this.addData = this.currentUser.srmUserName;
          this.fieldData.config.value = this.currentUser.srmUserId;  
        }
        // this.isCreate = true;
      }
      else{
        this.addData = "Search "+this.fieldData.config.displayName;
      }
    }
    this.textServerSideFilteringCtrl.valueChanges
      .pipe(
        filter(search => !!search),
        tap((text) => {
          this.searchText = text;
          this.searching = true
          //return this.searchAuto();
        }),
        takeUntil(this._onDestroy)
      )
      .subscribe(filteredBanks1 => {
        this.searchAuto().subscribe((response) => {
          console.log("s")
          this.searching = false;
          if (this.fieldData.config.key == "userTeam") {
            if (this.searchText == '') {
              this.results = response['result'].filter(bank => bank.name);
              this.filteredServerSideTexts.next(this.results);
            } else {
              var reg = new RegExp("^" + this.searchText, 'igm');
              let others = [];
              let tmp = response['result'].filter(function (term) {
                if (term.name.match(reg)) {
                  return term;
                } else {
                  others.push(term);
                }
              });
              tmp = tmp.concat(others);

              this.results = tmp.filter(bank => bank.name);
              this.filteredServerSideTexts.next(this.results);
            }
          } else {
            // this.results = response['result'].filter(bank => bank.name);
            // this.filteredServerSideTexts.next(this.results);
            //   this.results = response['result'].filter(bank => bank.name);
            //   this.filteredServerSideTexts.next(this.results);
            // } else {
            var reg = new RegExp("^" + this.searchText, 'igm');
            let others = [];
            let tmp = response['result'].filter(function (term) {
              if (term.name.match(reg)) {
                return term;
              } else {
               // others.push(term);
              }
            });
            tmp = tmp.concat(others);
            this.results = tmp.filter(bank => bank.name);
            this.filteredServerSideTexts.next(this.results);
          }
        })
      },
        error => {
          // no errors in our simulated example
          this.searching = false;
          // handle error...
        });

  }
  selectAction(data: any) {
    //  this.addData = "";
    this.fieldData.config.isClear=false;
    this.fieldData.config.value = (this.fieldData.config.key == "user") ? data.id : data.id;
    this.fieldData.config.icon = this.fieldData.config.value;
    this.modelRef = data.name || data.userName;
    this.fieldData.config.additionalData = {
      "name": this.modelRef
    }
    if (this.fieldData.isFilter) {
      this.applyFilter();
    }
  }
  submitSearch() {
    if (this.searchText && this.searchText.trim() == "") {
      this.resetSearchText();
    }

    this.sendFilteredReqObj = {
      "searchText": this.searchText
    }
    this.showListing = false;
  }
  applyFilter() {
    if (this.fieldData && this.fieldData.isFilter) {
      //  this.fieldData.config.value = this.fieldData.config.value
      this.filterApplied.emit(this.fieldData.config);
    }
  }
  clearFilter() {
    this.fieldData.config.isClear=true;
    if (this.fieldData && this.fieldData.isFilter) {
      this.fieldData.config.value = "";
      this.fieldData.config.icon = "";
      this.fieldData.config.additionalData = {};
      this.filteredServerSideTexts.next([]);
      this.searchText = "";
      this.addData = "Search "+this.fieldData.config.displayName;
      this.resetAccountFilterInCache();
      this.filterApplied.emit(null);
    }
  }
  resetAccountFilterInCache() {
    let filterCachedObj: any = {};
    if (localStorage.getItem('appliedFilters')) {
      filterCachedObj = localStorage.getItem('appliedFilters');
      filterCachedObj = JSON.parse(filterCachedObj);
      filterCachedObj["accountConfig"] = null;
      filterCachedObj["accountQuery"] = null;
      localStorage.setItem('appliedFilters', JSON.stringify(filterCachedObj));
    }
  }
  searchAuto() {
    let value = this.searchText;
    let searchType = this.fieldData.config.key;
    // Emit a value to cancel the previous call
    this.destroy$.next();
    if (searchType == "user") {
      let obj = {};
      obj['from'] = 0;
      obj['size'] = 100;
      obj['businessId']=this.fieldData.config.businessId;
      //obj['roleId'] = ["63dcc0ff230c7d007058e926"];
      return this.settingService.getUserDetailsForOwnerDropDown(obj).pipe(
        takeUntil(this.destroy$) // Cancel the previous call when destroy$ emits
      );;
    }
    else if (searchType == "laneTask") {
      let relationKey = this.fieldData.config.relationKey;
      console.log("relationKey", this.fieldData);
      if (relationKey) {
        let obj = {
          "suggest": true,
          "searchTerm": value,
          "sort": {
            "field": "data.realisticdealsizecr",
            "order": "desc"
          },
          "where": {
            "boardId": {
              "type": "search",
              "field": "boardId.raw",
              "value": `${relationKey}`,

            },
            "name": {
              "type": "search",
              "operator": "or",
              "value": [
                {
                  "field": "name",
                  "sub_type": "suggest",
                  "value": value
                }
              ]
            }
          },
          "size": 10
        };
        if(this.fieldData.withoutAccess){
          return this._kService.getLaneTaskWithoutAccess(obj).pipe(
            takeUntil(this.destroy$) // Cancel the previous call when destroy$ emits
          );
        }else{
          return this._kService.getLaneTask(obj).pipe(
            takeUntil(this.destroy$) // Cancel the previous call when destroy$ emits
          );
        }
      } else {
        let obj = {
          "sort": {
            "field": "data.realisticdealsizecr",
            "order": "desc"
          },
          "where": {
            "name": {
              "type": "search",
              "operator": "or",
              "value": [
                {
                  "field": "name",
                  "value": value
                }
              ]
            }
          },
          "size": 10
        };
        if(this.fieldData.withoutAccess){
          return this._kService.getLaneTaskWithoutAccess(obj).pipe(
            takeUntil(this.destroy$) // Cancel the previous call when destroy$ emits
          );
        }else{
          return this._kService.getLaneTask(obj).pipe(
            takeUntil(this.destroy$) // Cancel the previous call when destroy$ emits
          );
        }
      }
    } else if (searchType == "userTeam") {
      return this.settingService.fetchTeams();
    }
  }
  getCardDetails(list) {
    window.open('/#/cardDetails/' + list.id, "_blank");
    this.showListing = false;

  }
  createNewAccount() {
    const dialogRef = this.dialog.open(CreateLaneTaskComponent, {
      disableClose: true,
      width: '40%',
      height: '100vh',
      position: { bottom: '0px', right: '0px' },
      data: {
        "boardId": "63bfa8c3ade7bc466d1c780d",
        "boardName": "Accounts",
        "selectedLane": {"id":"63ce5630a5c09f3f171460b2","name":"accounts","displayName":"Accounts","position":1,
        "board":{"id":"63bfa8c3ade7bc466d1c780d","name":"accounts","createdBy":{"id":null,"uuid":null,"firstName":null,
        "lastName":null,"name":null,"email":null,"phoneNumber":null,"roleId":null,"role":null,"roles":null,"businessAccounts":null,"userRoles":null,"activeLogin":false,"totalCount":0,"designation":null,"countryCode":null,"customUserRoleId":null,"loggedInUserRole":null,"masterAccountId":null,"userTeams":null,"sendMail":true,"uniqueRoleId":null,"verified":false,"deleted":false},
        "status":null,"type":"MOGLIX","isActive":true,"canEdit":false,"isDeleted":false,"watchers":[],"customInfo":null,"buyerDataConfig":null,"createdAt":"2023-01-12T06:29:23.817+0000","updatedAt":"2023-01-12T06:29:23.817+0000"},"watchersId":null,"watchers":[],"type":null,"status":null,"isDeleted":false,"isActive":true,"createdBy":{"id":"63c8deac6210302382b4dc6e","uuid":null,"firstName":null,"lastName":null,"name":"moglix_user1","email":"moglix@credlix.com","phoneNumber":null,"roleId":null,"role":null,"roles":null,
        "businessAccounts":null,"userRoles":null,"activeLogin":false,"totalCount":0,"designation":null,"countryCode":null,"customUserRoleId":null,
        "loggedInUserRole":null,"masterAccountId":null,"userTeams":null,"sendMail":true,"uniqueRoleId":null,"verified":false,"deleted":false},"customInfo":null,"createdAt":"2023-01-23T09:41:04.832+0000",
        "updatedAt":"2023-01-23T09:41:04.832+0000","class":"data-pending"}
      },
      panelClass: 'addnewleadoverlay'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result['res'] && result['res']['result']) {
          if (!this.fieldData.config.additionalData) {
            this.fieldData.config.additionalData = {};
          }
          this.fieldData.config.additionalData.name = result['res']['result'].name;
          this.textServerSideFilteringCtrl.setValue(this.fieldData.config.additionalData.name);
          this.addData = this.fieldData.config.additionalData.name;
          this.fieldData.config.value =  result['res']['result']['id'];
        }
      }
    });
  }
  resetSearchText() {
    this.searchText = "";
    this.showCloseIcon = false;
    this.showListing = false;
  }
  validate(){
    this.error=false;
    console.log('validate field auto for field',this.fieldData.config)
    if(this.fieldData.config.optional && !this.fieldData.config.value){
      this.error=true;
      return false;
    }
    return true;
  }
}