<div
  fxLayout="row"
  class="main_content"
  style="background: #f7f7f7 0% 0% no-repeat padding-box"
>
  <div class="content-sec" fxLayout="column" style="width: 100%">
    <ul class="list-tab">
      <li class="active">Campaign View</li>
      <!-- <li>Campaign View</li> -->
      <!--<li>Accounts</li> -->
    </ul>
  </div>
</div>
<div fxLayout="row" class="board-dashboard border-top-1">
  <section class="section w-100">
    <div class="atom-row m-0">
      <div class="w-p180 mr-10">
        <div class="input-group">
          <mat-form-field class="mat-input icon deep-0" appearance="outline">
            <i class="ri-search-line"></i>
            <input
              placeholder="Enter campaign name"
              matInput
              [(ngModel)]="CampaignName"
              (keyup.enter)="apply()"
              matInput
            />
          </mat-form-field>
        </div>
      </div>

      <div class="w-p140 mr-10">
        <app-custom-multiple-select
          [label]="businessUnitLabal"
          [selectedValues]="selectedBU"
          [availableOptions]="defaultBUSearch"
          [searchControl]="searchControlBU"
          (selectionChange)="onBUSelectionChange($event)"
          (searchChange)="filterOptionsBUForDealsListing($event)"
        >
        </app-custom-multiple-select>
      </div>

      <div class="w-p153 mr-10">
        <app-custom-select
          #customSelectRef
          [fieldData]="{
            config: accountConfigOpportunity,
            isFilter: true
          }"
          (selectedAccountIdsChange)="handleApplySelections($event)"
        >
        </app-custom-select>
      </div>
      <div class="w-p204 mr-10 flex items-center">
      <button
      class="btn-primary mr-10 btn-md" mat-raised-button color="warn"
      (click)="apply()"
    >
   Apply
    </button>
  </div>
  <div class="w-p108 mr-10 flex items-center margin-left-auto">

  
    <button
    class="btn-primary mr-10  btn-md" mat-raised-button color="warn"
      (click)="reload()"
    >
      Re-Sync
      <i class="ri-refresh-line icon-right"></i>
    </button>
  </div>
  <div class="w-p77 mr-10 flex items-center">
    <button (click)="clear()" mat-button mat-button class="btn-basic btn-text-black btn-md"  color="primary">
      Clear All
    </button>
    </div>
    </div>

 

    <!-- <div fxLayout="row" fxLayoutAlign="center space-around" class="filter-sec">
        <div class="formfield">
          <label>Campaign Type</label>
          <mat-form-field appearance="outline" class="mog3-matselect" id="inputdefault">
            <mat-select #sl2 placeholder="campaign type" multiple>
              <mat-form-field class="mog3_matinput select-search" appearance="outline">
                <input type="text" matInput placeholder="Search" />
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="14" height="16">
                  <path
                    d="M18.031 16.6168L22.3137 20.8995L20.8995 22.3137L16.6168 18.031C15.0769 19.263 13.124 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20 13.124 19.263 15.0769 18.031 16.6168ZM16.0247 15.8748C17.2475 14.6146 18 12.8956 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18C12.8956 18 14.6146 17.2475 15.8748 16.0247L16.0247 15.8748Z"
                    fill="rgba(111,111,112,1)"></path>
                </svg>
              </mat-form-field>
              <div class="custom-panel abm-option">
                <mat-option>abtesting</mat-option>
                <mat-option>normal</mat-option>
              </div>
              <div fxLayout="row" fxLayoutAlign="flex-end space-between" class="filter-btn-section">
                <button class="filter-btn clear">Clear</button>
                <button class="filter-btn apply">Apply</button>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
      </div> -->

    <!-- <button (click)="apply();" mat-raised-button class="apply-button">Apply</button> -->
  </section>
</div>
<div fxLayout="column" class="section-width">
  <div
    class="table-overflow mog3_msnsearchtable_sticky abm-table header-vertical-middle"
  >
    <table mat-table matSort [dataSource]="dataSource" class="w-100 table-1">
      <ng-container matColumnDef="CampaignName">
        <th mat-header-cell *matHeaderCellDef>Campaign Name</th>

        <td mat-cell *matCellDef="let element">
          {{ element.campaign_name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="BusinessUnit">
        <th mat-header-cell *matHeaderCellDef>Business Unit</th>

        <td mat-cell *matCellDef="let element">
          {{ element.topicName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Contacts">
        <th mat-header-cell *matHeaderCellDef>Contacts</th>

        <td mat-cell *matCellDef="let element">
          <a (click)="openContactDialog(element)" class="table-title pointer">{{ element.emails_sent_count }}</a>
        </td>
      </ng-container>

      <ng-container matColumnDef="Type">
        <th mat-header-cell *matHeaderCellDef>Type</th>

        <td mat-cell *matCellDef="let element">
          {{ element.Type }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Account">
        <th mat-header-cell *matHeaderCellDef>Account</th>

        <td mat-cell *matCellDef="let element">
          <a
            (click)="openAccountWiseReport(element.campaign_key)"
            class="table-title pointer"
            >{{ element.totalAccounts }}</a
          >
        </td>
      </ng-container>

      <ng-container matColumnDef="StartDate">
        <th mat-header-cell *matHeaderCellDef>Creation Date</th>

        <td mat-cell *matCellDef="let element">
          {{ element.created_time }}
        </td>
      </ng-container>

      <ng-container matColumnDef="progress">
        <th mat-header-cell *matHeaderCellDef>progress</th>

        <td mat-cell *matCellDef="let element">
          {{ element.progress }}
        </td>
      </ng-container>

      <ng-container matColumnDef="EndDate">
        <th mat-header-cell *matHeaderCellDef>Sent Date</th>

        <td mat-cell *matCellDef="let element">
          {{ element.sent_time }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Delivered">
        <th mat-header-cell *matHeaderCellDef>Delivered</th>

        <td mat-cell *matCellDef="let element">
          {{ element.delivered_percent }}
        </td>
      </ng-container>

      <ng-container matColumnDef="OpenRate">
        <th mat-header-cell *matHeaderCellDef>Open Rate</th>

        <td mat-cell *matCellDef="let element">
          {{ element.open_percent }}
        </td>
      </ng-container>

      <ng-container matColumnDef="CTR">
        <th mat-header-cell *matHeaderCellDef>CTR</th>

        <td mat-cell *matCellDef="let element">
          {{ element.unique_clicked_percent }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Bouncerates">
        <th mat-header-cell *matHeaderCellDef>Bounce rates</th>

        <td mat-cell *matCellDef="let element">
          {{ element.bounce_percent }}
        </td>
      </ng-container>

      <ng-container matColumnDef="FromEmail">
        <th mat-header-cell *matHeaderCellDef>Owner</th>

        <td mat-cell *matCellDef="let element">
          {{ element.FromEmail }}
        </td>
      </ng-container>

      <ng-container matColumnDef="View">
        <th mat-header-cell *matHeaderCellDef>View</th>

        <td mat-cell *matCellDef="let element">
          <div
            class="actions-list"
            (click)="openPopup(element.campaign_preview)"
          >
            <i class="ri-eye-line"></i>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></tr>

      <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
    </table>
  </div>

  <div class="pagination-wrapper">
    <mat-paginator
      #paginator
      [length]="totalRecords"
      class="custom-pagintor"
      [pageSize]="pageSize"
      (page)="onPageChange()"
      showFirstLastButtons
    >
      <!--  (page)="onScrollDown($event, lane?.laneDTO.id, lane['taskList'].length, lane?.totalRecords, lane['taskList'])" -->
    </mat-paginator>
  </div>
</div>
