import { Component, OnInit } from '@angular/core';
import { Output } from "@angular/core";
import {EventEmitter
} from '@angular/core';
@Component({
  selector: 'app-sb-contact-info-dialog',
  templateUrl: './sb-contact-info-dialog.component.html',
  styleUrls: ['./sb-contact-info-dialog.component.scss']
})
export class SbContactInfoDialogComponent implements OnInit {
  dataSource: Array<any> = []; 
  @Output() dateFilterApplied = new EventEmitter<string>();
  displayColumns: Array<string> = ['contactName', 'designation','phoneNumber','plant']; 
  accountConfig = {
    "key": "laneTask",
    "field": "account-name",
    "rawValue": null,
    "type": "belongsTo",
    "value": "",
    "optional": true,
    "display": true,
    "displayWhileListing": true,
    "showTotal": false,
    "displayName": "Account Name",
    "dataList": [],
    "values": null,
    "additionalData": null,
    "operationsData": null,
    "operations": null,
    "index": 6,
    "icon": "",
    "relationKey": "63bfa8c3ade7bc466d1c780d",
    "nameField": false
  };
  constructor() {
    this.getPicksList(); 
   }

  ngOnInit() {
  }
  getPicksList() { 

    this.dataSource = [ 
    
    { contactName: 'Contact Name',role:'Promoter', email:'amit@adaniwilmar.com', designation: 'Asst Manager',phoneNumber: '9867435262', plant: 'Prabha Automotive',plantLocation:'Pune' }, 
    { contactName: 'Contact Name',role:'', email:'amit@adaniwilmar.com', designation: 'Asst Manager',phoneNumber: '9867435262', plant: 'Prabha Automotive',plantLocation:'Pune' }, 
    
    
    ]; 
    
    } 
}
