<section aria-label="tags" class="dialog-top" style="background: aliceblue;
margin: 1rem;
height: 90%;">
  <div class="assinBox" fxLayout="row" fxLayoutAlign="center center">
    <!-- <div  fxLayout="row" fxLayoutAlign="center center">
    <label class="label-1">Assigned To:</label>
    </div>
    <div class="d-flex flex-wrap">
      <div class="assignee-info" *ngFor="let list of selectedUserListOptions">
        <div fxLayout="row" fxLayoutAlign="center start" *ngIf="list != null">
          <ng-container >    
              <p  class="comments-label"> {{getAssigneeName(list)}}</p>
          </ng-container>
          <i class="ri-close-line" (click)="deleteSelectedAssignee(list)"></i>
      </div>
      </div>
    </div> -->
    <div fxLayout="row" fxLayoutAlign="center center" class="action-tab-margin" style="width : 80%; color: #1479FC;">
      <div fxLayout="row" fxLayoutAlign="center center" class="comments-icon-div margin-right">
        <mat-icon aria-hidden="false" aria-label="Comments" fontIcon="home" matTooltip="Comments"
          matTooltipClass="new-tooltip" class="material-symbols-outlined comments-icon">manage_accounts</mat-icon>

      </div>
      <div fxLayout="column" fxLayoutAlign="center center" *ngIf="laneTaskIDDetail != null">
        <span class="comments-label"
          *ngIf="laneTaskIDDetail.assignee == null || laneTaskIDDetail.assignee.length == 0">Assigned To </span>
        <ng-container *ngIf="laneTaskIDDetail.assignee != null && laneTaskIDDetail.assignee.length > 0">
          <p class="comments-label"> {{laneTaskIDDetail.assignee[0].user.name}}</p>
        </ng-container>
      </div>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center">
    <mat-form-field class="tag-autocomplete" appearance="fill">
          <mat-label>Change Assignee</mat-label>
          <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto" style="text-transform: capitalize;">
          <mat-autocomplete #auto="matAutocomplete" >
            <mat-option *ngFor="let option of filteredUserList | async" [value]="option" style="text-transform: capitalize;">
              {{option}}
            </mat-option>
          </mat-autocomplete>
      <button mat-icon-button matSuffix style="width: 34px; height: 34px;"
        >
        <mat-icon style="width: 34px;">expand_more</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center">
    <button type="button">Submit</button>
  </div>
</section>