import { Component, OnInit, ViewChild, NgZone, ChangeDetectorRef, QueryList, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { KanbanService } from '../../../../kanban.service';
import { SettingService } from 'src/app/settings/setting.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as lodash from 'lodash';

import { NavigationService } from '../../../navigation-service.service';

import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { take } from 'rxjs/operators';
import 'quill-mention';
import { CommentsTabComponent } from '../../card-details/comments-tab/comments-tab.component';
import { Constants } from 'src/app/Constants';
import { MatSelect } from '@angular/material';
import { ContactInfoComponent } from '../../contact-info/contact-info.component';
import { CreateLaneTaskComponent } from '../create-lane-task.component';

import { ErrorToastComponent } from 'src/app/shared-files/error-toast/error-toast.component';
import { SuccessToastComponent } from 'src/app/shared-files/success-toast/success-toast.component';
import { MatSnackBar } from '@angular/material';
import { WarningToastComponent } from "src/app/shared-files/warning-toast/warning-toast.component";
import { FormArrayExampleComponent } from 'src/app/component/credlix-board/form-array-example/form-array-example.component';
import { FieldAutocompleteComponent } from '../../field-autocomplete/field-autocomplete.component';
@Component({
  selector: 'app-create-lane-task-route',
  templateUrl: './create-lane-task-route.component.html',
  styleUrls: ['./create-lane-task-route.component.scss']
})
export class CreateLaneTaskRouteComponent implements OnInit {
  selectedLane: any;
  currentUser: string;
  optionalLanes = ["C0", "C1"];
  laneTaskIdByUrl: any;
  laneTaskIDDetail: any;
  boardId: string;
  currentTabId: string;
  leadName: string;
  laneTaskConfig: any;
  selectedBoardLane: any;
  boardName: any = "";
  boardLane: any;
  isEditableAccess: any;
  selectedLanePosition: any;
  actionItemData: any = {};
  breakpoint: any;
  _: any = lodash;
  content = ''
  matContent = ''
  data: any;
  boardIdForSobOpportunity: string = Constants.boardIdForSobOpportunity;
  boardIdForCRMDeals: string = Constants.boardIdForCRMDeals;
  isRepeatedRFQFrequency: boolean = false;
  isDealTypeNewCategories: boolean = false;
  isNewCategories: boolean = false;
  subCategoriesOfNewCategories: any;
  selectedRegion: any;
  selectedPlantIds: string[] = [];
  accountPlantList: any = [];
  accountPlantListSearch: any = [];
  regionTeamListSearch: any = [];
  minDateForNextAction: Date = new Date();

  accountId: any;
  plantId: any;

  boardIdForSobPlant: string = Constants.boardIdForSobPlant;
  pageSize = 200;
  pageFrom = 0;
  isLoading: boolean = false;
  pageIndex = -1;
  @ViewChild('sl2') sl2: MatSelect;
  searchControlForPlants = "";
  searchControlFiltered = "";
  filteredList: any = [];
  isSearchKeyHasValue: boolean = false;

  businessVerticalList: any = [];
  regionTeamList: any = [];
  dealTypeList: any = [];
  leadSourceList: any = [];
  newCategoryList: any = [];

  businessVerticalListSearch: any = [];
  // regionTeamListSearch: any = [];
  dealTypeListSearch: any = [];
  leadSourceListSearch: any = [];
  newCategoryListSearch: any = [];
  l2CategoryListSearch: any = [];

  searchControlBusinessVertical = "";
  searchControlRegionTeam = "";
  searchControlDealType = "";
  searchControlLeadSource = "";
  searchControlL1Category = "";
  searchControlL2Category = "";

  contactDetails: any[] = [];
  displayContactList: any[] = [];
  contactBoardLane: any[];
  enableActionItem: boolean = false;
  searchControl = '';
  @ViewChildren(FormArrayExampleComponent) formArrayComponent!: QueryList<FormArrayExampleComponent>;
  @ViewChildren(FieldAutocompleteComponent) fieldAutoComponent!: QueryList<FieldAutocompleteComponent>;
  constructor(private _snackBar: MatSnackBar, public toasterService: ToastrService, public dialog: MatDialog, private cd: ChangeDetectorRef, private activatedRoute: ActivatedRoute, public router: Router, public settingServ: SettingService, public _kService: KanbanService,
    private _ngZone: NgZone, public navigation: NavigationService) {
  }
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  ngOnInit() {
    console.log('ngOnInit')
    let getSessionStorage = JSON.parse(localStorage.getItem("USERINFO"));
    if (getSessionStorage && getSessionStorage['role']) {
      this.currentUser = getSessionStorage['role']['userName'];
    }
    this.activatedRoute.params.subscribe(params => {
      console.log("params", params);
      if (params.id) {
        this.boardId = params.id;
        if (this.boardId == Constants.boardIdForCRMDeals || this.boardId == Constants.boardIdForSobOpportunity) {
          this.enableActionItem = true;
          this.getContactBoardLane();
        }
      }
      if (params.laneId) {
        this.selectedLane = {
          "id": params.laneId
        }
        this.laneTaskIdByUrl = params.laneId;
      }
      if (!this.boardId) {
        this._snackBar.openFromComponent(ErrorToastComponent, {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['atom-toast', 'error'],
          duration: 6000,
          data: "Error Loading Config: No Id Found."
        });
      }
      this.getLaneConfigById();
      this.fetchTeams();
    });
  }
  ngAfterViewChecked() {
    this.cd.detectChanges();
  }

  fetchTeams() {
    this.settingServ.fetchTeams().subscribe(teams => {
      if (teams) {
        this.regionTeamListSearch = teams['result'];
        this.regionTeamList = this.regionTeamListSearch;
      } else {
        this._snackBar.openFromComponent(ErrorToastComponent, {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['atom-toast', 'error'],
          duration: 6000,
          data: "Error while fetching Region/Teams"
        });
      }
    });
  }
  checkValidity(tmp) {
    if ((tmp.field == "realistic-deal-size-cr" || tmp.field == "business-potential-in-cr" || tmp.field == "problem-statement" || tmp.field == "contact")) {
      return false;
    } else {
      return true;
    }
  }
  closeDialog() {
    this.dialog.closeAll()
  }
  getOptionList(list, field) {
    if (!list) {
      return [];
    }
    if (field !== 'revenue-range') {
      list.sort(function (a, b) {
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      });
    }
    return list;
  }
  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }
  formatPaymentType(value) {
    return this._.startCase(value);
  }
  getBoardLaneById() {
    this.setBreakPoint();
    this._kService.getLaneWithUserAccess(this.boardId, '').subscribe(res => {
      this.boardLane = res['result']['levelAccess'];
    });

  }

  onResize(event) {
    //this.breakpoint = (event.target.innerWidth <= 400) ? 1 : 6;
    this.setBreakPoint();
  }
  setBreakPoint() {
    if ((window.innerWidth <= 400)) {
      this.breakpoint = 3
    } else if ((window.innerWidth > 400) && (window.innerWidth <= 768)) {
      this.breakpoint = 3
    } else if ((window.innerWidth > 768) && (window.innerWidth <= 1390)) {
      this.breakpoint = 3;
    } else if ((window.innerWidth > 1390)) {
      this.breakpoint = 3;
    }
  }
  validateEmail(formItem) {

    if (!formItem.value.toString()
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )) {
      formItem.error = true;
    } else {
      formItem.error = false;
    }
  };
  valideateWithDebounce(formItem, allowedLength) {
    this._.debounce(this.validateLength(formItem, allowedLength), 100);
  }
  validateLength(formItem, allowedLength) {
    if (formItem.value == null || formItem.value.toString().trim().length != allowedLength) {
      formItem.error = true;
    } else {
      formItem.error = false;
    }
  }
  validateLaneTask() {
    let kp = [];
    let error = false;
    let errorMessage = null;
    for (let i = 0; i < this.laneTaskConfig.length; i++) {
      let tmp = this.laneTaskConfig[i];
      tmp.error = null;
      if (tmp.display && (!tmp.accessibleLanes || tmp.accessibleLanes.includes(this.laneTaskIdByUrl))) {
        if (tmp.optional && tmp.type == "string") {
          if (!tmp.value || tmp.value.toString().trim() == "") {
            tmp.error = tmp.displayName + ' :  Required';
            error = true;
          }
        }
        else if (tmp.optional && tmp.type == "number") {
          if (!tmp.value || tmp.value.toString().trim() == "") {
            tmp.error = tmp.displayName + ' :  Required';
            error = true;
          }
        }
        else if (tmp.optional && tmp.type == "multiSelect") {
          if (tmp.values == null || tmp.values.length < 1) {
            tmp.error = tmp.displayName + ' :  Required';
            error = true;
          }
        }
        else if (tmp.optional && tmp.type == "belongsTo") {
          if (tmp.field == "plant-name" && this.boardId == Constants.boardIdForSobOpportunity) {
            if (!tmp.values || tmp.values.length === 0) {
              tmp.error = tmp.displayName + ' : Required';
              error = true;
            }
          } else if (tmp.key == "userTeam") {
            if (!tmp.value || tmp.value.toString().trim() == "") {
              tmp.error = tmp.displayName + ' :  Required';
              error = true;
            }
          }
          else if (this.fieldAutoComponent) {
            this.fieldAutoComponent.some(child => {
              if (child.fieldData.config.field == tmp.field) {
                let valid = child.validate();
                console.log('valid check for field', child.fieldData.config.displayName, valid);
                if (!valid) {
                  tmp.error = child.fieldData.config.displayName + ' : Required';
                  error = true;
                  return true;
                }
              }
            });
          }
        }
        else if (tmp.type == "date") {
          if (tmp.optional && (tmp.value == null || tmp.value.toString().trim() == "")) {
            tmp.error = tmp.displayName + ' : Required';
            error = true;
          }
          else if (tmp.field == "closing-date" && tmp.value) {
            const inputDateObj = new Date(tmp.value);
            const currentDate = new Date();
            currentDate.setDate(currentDate.getDate() - 1);
            if (inputDateObj < currentDate) {
              return {
                "message": 'Closing Date is less than the current date',
                "isValid": false
              };
            }
          }
        }
        else if (tmp.type == "currency") {
          if (tmp.optional && this.checkValidity(tmp) && (!tmp.value || tmp.value.toString().trim() == "")) {
            tmp.error = tmp.displayName + ' :  Required';
            error = true;
          }
          if (tmp.field == "realistic-deal-size-cr" && tmp.value && this.boardId !== this.boardIdForSobOpportunity) {
            let ind = this.laneTaskConfig.findIndex((element) => {
              return element.field == "business-potential-in-cr";
            })
            if (ind != -1) {
              let totalPotential = this.laneTaskConfig[ind]
              if (totalPotential.value) {
                let bussPotential = parseFloat(totalPotential.value);
                let dealSize = parseFloat(tmp.value);
                if (dealSize > bussPotential) {
                  tmp.error = tmp.displayName + " : can not be greater than " + totalPotential.displayName;
                }
              }
            }
          }
        }
        else if (tmp.optional && tmp.type == "textarea") {
          if (this.checkValidity(tmp) && (!tmp.value || tmp.value.toString().trim() == "")) {
            tmp.error = tmp.displayName + ' :  Required';
            error = true;
          }
        }
        else if (tmp.optional && tmp.type == "select") {
          if (!tmp.value || tmp.value.toString().trim() == "") {
            tmp.error = tmp.displayName + ' :  Required';
            error = true;
          }
        }
        else if (tmp.optional && tmp.type == "phone") {
          if (!tmp.value || tmp.value.toString().trim() == "" || tmp.value.toString().trim().length != 10) {
            tmp.error = tmp.displayName + " : Minimum 10 Charters Required";
            error = true;
          }
        }
        else if (tmp.optional && tmp.type == "email") {
          if (!tmp.value || tmp.value.toString().trim() == "" || this.validateEmail(tmp)['error']) {
            tmp.error = tmp.displayName + " Invalid Email  : ";
            error = true;
          }
        }
        else if (tmp.optional && tmp.type == "form") {
          if (this.formArrayComponent) {
            this.formArrayComponent.forEach(child => {
              let isValidForm = child.onSubmit();
              console.log('valid check', isValidForm);
              if (isValidForm.trim() != '') {
                tmp.error = isValidForm
                error = true;
                return;
              }
            });
          }
        }
        else {
          tmp.error = null;
        }

        if (tmp.error) {
          if (!errorMessage) {
            errorMessage = tmp.error;
          }
          error = true;
        }
        kp.push(tmp);
      }
    }
    if (error) {
      this.laneTaskConfig = kp;
      return {
        "message": errorMessage,
        "isValid": false
      };
    } else {
      return {
        "message": "",
        "isValid": true
      };
    }
  }
  saveLaneTask() {
    let isValidResp = this.validateLaneTask();
    if (!isValidResp || !isValidResp['isValid']) {
      this._snackBar.openFromComponent(ErrorToastComponent, {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: ['atom-toast', 'error'],
        duration: 6000,
        data: isValidResp.message
      });
      return;
    }

    if (this.enableActionItem && (!this.actionItemData["message"] || !this.actionItemData["nextActionDate"])) {
      this._snackBar.openFromComponent(ErrorToastComponent, {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: ['atom-toast', 'error'],
        duration: 6000,
        data: 'Update the "Action Item" first to proceed further'
      });
      this.showActionItems();
      return;
    }

    console.log("displayContatlist: ", this.displayContactList);
    if (this.displayContactList) {
      const contactList = this.displayContactList.map(contact => contact.contactId);
      this.laneTaskConfig
        .filter(config => config.field === 'contact')
        .forEach(config => (config.values = contactList));
    }
    console.log('laneTaskConfig :', this.laneTaskConfig);
    let obj =
    {
      "laneId": this.selectedLane.id,
      "name": name,
      "pinned": true,
      "leadSource": "SYSTEM",
      "data": this.laneTaskConfig
    }
    if (this.enableActionItem) {
      obj['nextActionItem'] = this.actionItemData
    }

    this._kService.createLaneTask(obj).subscribe(res => {
      this._snackBar.openFromComponent(SuccessToastComponent, {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: ['atom-toast', 'success'],
        duration: 6000,
        data: "Detail Saved Successfully"
      });
      // this.toasterService.success("Detail Saved Successfully");
      if (res["result"] && this.actionItemData["file"] && res["result"]["nextActionItem"]) {
        const uploadData = new FormData();
        uploadData.append('file', this.actionItemData["file"], this.actionItemData["file"].name);
        this._kService.uploadSubDocuments(uploadData, res['result']["nextActionItem"].id, this.actionItemData["fileType"]).subscribe(file => {
          if (file['status'] == 200) {
            this._snackBar.openFromComponent(SuccessToastComponent, {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              panelClass: ['atom-toast', 'success'],
              duration: 6000,
              data: "Attachement uploaded Successfully"
            });
            // this.toasterService.success("Attachement uploaded Successfully");
            this.router.navigate(['/crm-board/']);
          }
        }, err => {
          this._snackBar.openFromComponent(WarningToastComponent, {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: ['atom-toast', 'warning'],
            duration: 6000,
            data: "Attachement did not uploaded!"
          });

          // this.toasterService.warning("Attachement did not uploaded!");
        });
      } else if (res["result"]) {
        this.router.navigate(['/crm-board/']);
      }
    }, err => {
      this._snackBar.openFromComponent(ErrorToastComponent, {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: ['atom-toast', 'error'],
        duration: 6000,
        data: err.error.message
      });

      // this.toasterService.error("Something Went Wrong !");

    });
  }

  getLaneConfigById() {
    this._kService.getLaneBoardConfigByIdDTO(this.boardId).subscribe(res => {
      if (res && res['result'] && res['result']['config']) {
        let result = res['result']['config'];
        result.sort(function (a, b) {
          return a.index - b.index;
        });
        this.laneTaskConfig = result;
        this.boardName = res['result']['boardName'];
        for (const element of this.laneTaskConfig) {
          switch (element.field) {
            case 'new-categories':
              element.optional = true;
              this.newCategoryList = element.dataList;
              this.newCategoryListSearch = element.dataList;
              break;
            case 'business-vertical':
              this.businessVerticalList = element.dataList;
              this.businessVerticalListSearch = element.dataList;
              break;
            case 'lead-source':
              this.leadSourceList = element.dataList;
              this.leadSourceListSearch = element.dataList;

              break;
            case 'deal-type':
              this.dealTypeList = element.dataList;
              this.dealTypeListSearch = element.dataList;

              break;
            // Add more cases if needed

            default:
              // Handle any other cases if needed
              break;
          }
        }
      }
    });
  }

  shouldDisableField(fieldName: string): boolean {
    switch (fieldName) {
      case 'repeat-rf-qs-frequency':
        return !this.isRepeatedRFQFrequency;
      case 'new-categories':
        return !this.isDealTypeNewCategories;
      case 'new-category-list':
        return this.isNewCategories;
      default:
        return false;
    }
  }

  onSelectOption(laneTaskConfig: any, config: any) {
    const fieldName = config.field;

    if (this.boardId === this.boardIdForSobOpportunity) {
      switch (fieldName) {
        case 'po-type':
          this.isRepeatedRFQFrequency = config.value === 'Repeat RFQ';
          const repeatRfQsFrequencyElement = this.laneTaskConfig.find(element => element.field === 'repeat-rf-qs-frequency');

          if (!this.isRepeatedRFQFrequency) {
            if (repeatRfQsFrequencyElement) {
              repeatRfQsFrequencyElement.optional = false;
              repeatRfQsFrequencyElement.value = null;
            }
          } else {
            if (repeatRfQsFrequencyElement) {
              repeatRfQsFrequencyElement.optional = true;
            }
          }

          break;
        case 'deal-type':
          this.isDealTypeNewCategories = config.value === 'New category';

          const newCategoriesElement = this.laneTaskConfig.find(element => element.field === 'new-categories');
          const newCategoryListElement = this.laneTaskConfig.find(element => element.field === 'new-category-list');

          if (this.isDealTypeNewCategories) {
            if (newCategoriesElement) {
              newCategoriesElement.optional = true;
            }
          } else {
            if (newCategoriesElement) {
              newCategoriesElement.optional = false;
              newCategoriesElement.value = null;
            }

            if (newCategoryListElement) {
              newCategoryListElement.optional = false;
              newCategoryListElement.values = null;
            }
          }

          break;
        case 'new-categories':
          let newCategoryValue = config.value;
          this.isNewCategories = true;
          if (newCategoryValue) {
            const newCategoriesListElement = this.laneTaskConfig.find(element => element.field === 'new-category-list');
            newCategoriesListElement.values = null;
            newCategoriesListElement.optional = true;
            this._kService.getL2Categories(newCategoryValue).subscribe(category => {
              this.subCategoriesOfNewCategories = category;
              this.l2CategoryListSearch = this.subCategoriesOfNewCategories
            });
          }
        default:
          break;
      }
    }
    this.searchControlFiltered = '';
    this.filteredList = [];
    this.isSearchKeyHasValue = false;
  }

  showActionItems() {
    const dialogRef = this.dialog.open(CommentsTabComponent, {
      disableClose: false,
      width: '40%',
      height: '100%',
      position: { bottom: '0px', right: '0px' },
      data: {
        laneTask: this.laneTaskIDDetail,
        userList: [],
        type: "action-item",
        title: "Action Items",
        requestfromNewDeal: true
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.actionItemData = {};
      if (result != null && result["actionData"] != null) {
        if (result["actionData"]["commentId"] != null) {
          this.actionItemData["commentId"] = result["actionData"]["commentId"];
        }
        if (result["actionData"]["message"] != null) {
          this.actionItemData["message"] = result["actionData"]["message"];
        }
        if (result["actionData"]["nextActionDate"] != null) {
          this.actionItemData["nextActionDate"] = result["actionData"]["nextActionDate"];
        }
        if (result["actionData"]["file"] != null) {
          this.actionItemData["file"] = result["actionData"]["file"];
        }
        if (result["actionData"]["fileType"] != null) {
          this.actionItemData["fileType"] = result["actionData"]["fileType"];
        }
        if (result["actionData"]["mention"] != null) {
          this.actionItemData["mention"] = result["actionData"]["mention"];
        }
      }
    });
  }

  applyPlantsIds() {
    this.laneTaskConfig.forEach(element => {
      if (element.field === 'plant-name') {
        element.values = this.selectedPlantIds;
      }
    });
  }

  clearSelectedPlantIds() {
    this.selectedPlantIds = [];
    this.laneTaskConfig.forEach(element => {
      if (element.field === 'plant-name') {
        element.values = this.selectedPlantIds;
      }
    });
  }

  getPlantListBasedOnAccount(laneKey: any) {
    if (laneKey.field === 'account-name') {
      const id = laneKey.value;
      if (id != null && id != undefined && id.trim() != "" && this.boardId === this.boardIdForSobOpportunity) {
        this.isLoading = true;
        this.displayContactList = [];
        console.log("making false");
        this.getPlantList();
      }
    }
  }

  getPlantList() {
    const accountElement = this.laneTaskConfig.find(element => element.field === 'account' && element.value !== '' || element.field === 'account-name' && element.value !== '');
    if (accountElement) {
      this.accountId = accountElement.value;
    }
    else {
      this._snackBar.openFromComponent(ErrorToastComponent, {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: ['atom-toast', 'error'],
        duration: 6000,
        data: "Please Select Account First"
      });

      // return this.toasterService.error("Please Select Account First");

    }

    let obj = {
      "where": {
        "boardId": {
          "type": "search",
          "operator": "or",
          "value": [
            {
              "field": "boardId.raw",
              "value": this.boardIdForSobPlant
            }
          ]
        }
      },
      "sort": { "field": "updatedAt", "order": "desc" },

      "from": this.pageFrom,
      "size": this.pageSize
    }
    let tQuery = [];
    tQuery.push({
      "field": "data.account.raw",
      "value": this.accountId
    });
    let query = {
      "query": {
        "nestedWhere": {}
      }
    };
    query['query']['nestedWhere']["account_query"] = {};
    query['query']['nestedWhere']["account_query"]["type"] = "search";
    query['query']['nestedWhere']["account_query"]["operator"] = "or";
    query['query']['nestedWhere']["account_query"]["value"] = tQuery;

    obj['nestedWhere'] = query['query']['nestedWhere'];
    this.getLaneTaskPlantIds(obj);
  }

  getLaneTaskPlantIds(obj) {
    this.isLoading = true;
    this.accountPlantList = [];
    this.accountPlantListSearch = this.accountPlantList;

    this._kService.getLaneTask(obj).subscribe(res => {
      if (res == null) {
        return this._snackBar.openFromComponent(SuccessToastComponent, {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['atom-toast', 'success'],
          duration: 6000,
          data: "No Plants found for selected Customer"
        });


        //return this.toasterService.success("No Plants found for selected Customer");

      }
      else {
        this.accountPlantList = res['result'];
        this.isLoading = false;
        this.accountPlantListSearch = this.accountPlantList;
      }
      this.pageIndex++;
    },
      (error) => {
        this._snackBar.openFromComponent(ErrorToastComponent, {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['atom-toast', 'error'],
          duration: 6000,
          data: "Error while getting Plants"
        });

        // this.toasterService.error("Error while getting Plants")
      }
    );
    // if (this.sl2) {
    //   this.sl2.open();
    // }
  }

  filterOptionsPlants(event) {
    this.accountPlantListSearch = this.accountPlantList;
    const filterValue = event.target.value.toLowerCase();
    this.accountPlantListSearch = this.accountPlantList.filter(option => (option.name).toLowerCase().includes(filterValue));
  }

  filterOptionsBusinessVertical(event: any) {
    this.businessVerticalListSearch = this.businessVerticalList.filter(option => (option).toLowerCase().includes(event));
  }
  filterOptionsRegionTeam() {
    this.regionTeamListSearch = this.regionTeamList.filter(option => (option.name).toLowerCase().includes(this.searchControlRegionTeam));
  }
  filterOptionsDealType(event: any) {
    this.dealTypeListSearch = this.dealTypeList.filter(option => (option).toLowerCase().includes(event));
  }
  filterOptionsLeadSource(event: any) {
    this.leadSourceListSearch = this.leadSourceList.filter(option => (option).toLowerCase().includes(event));
  }
  filterOptionsL1CategoryList(event: any) {
    this.newCategoryListSearch = this.newCategoryList.filter(option => (option).toLowerCase().includes(event));
  }
  filterOptionsL2Categories(event, dataList: any) {
    this.l2CategoryListSearch = this.subCategoriesOfNewCategories.filter(option => (option).toLowerCase().includes(this.searchControlL2Category));
  }
  onDropdownClosed(isOpened: boolean) {
    if (!isOpened) {
      // Dropdown is closed, clear the searchControlFiltered
      this.searchControlFiltered = '';
      this.filteredList = [];
      this.isSearchKeyHasValue = false;
    }
  }
  contactInfo() {
    let contactBoardId = null;
    if (this.boardId === Constants.boardIdForCRMDeals) {
      contactBoardId = Constants.boardIdForCRMContacts;
    }
    else {
      contactBoardId = Constants.boardIdForSobContacts;
    }
    console.log("laneTaskConfig", this.laneTaskConfig);
    this.laneTaskConfig.forEach(element => {
      if (element.field === 'account' || element.field === 'account-name') {
        this.accountId = element.value;
      }
      if (element.field === 'plant' || element.field === 'plant-name') {
        this.plantId = element.values;
      }
    });
    if (this.accountId) {
      this._kService.setIds(this.accountId, '');
      if (this.boardIdForSobOpportunity === this.boardId) {
        // if (this.plantId) {
        const dialogRef = this.dialog.open(ContactInfoComponent, {
          panelClass: 'right-side-dialog',
          data: {
            "boardId": contactBoardId,
            "plantId": this.plantId,
            "displayContactList": this.displayContactList,
            "isCreateLaneTaskTabSelected": true
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          console.log("dialog closed", result);
          if (result && result.length) {
            console.log("displayContactList", result);
            this.displayContactList = result;
          }
        });
        // }
        // else {
        //   this.toasterService.error("Please first select Plant to add contact.");
        // }
      }
      else {
        const dialogRef = this.dialog.open(ContactInfoComponent, {
          panelClass: 'right-side-dialog',
          data: {
            "boardId": contactBoardId,
            "displayContactList": this.displayContactList,
            "isCreateLaneTaskTabSelected": true
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          console.log("dialog closed", result);
          if (result && result.length) {
            console.log("onContactSelectionChange", result);
            this.displayContactList = result;
            console.log("displayContactList displayContactList", this.displayContactList);

          }
        });
      }

    } else {
      this._snackBar.openFromComponent(ErrorToastComponent, {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: ['atom-toast', 'error'],
        duration: 6000,
        data: "Please first select Account to add contact."
      });

      // this.toasterService.error("Please first select Account to add contact.");

    }
  }
  getContactListOfAccount() {
    let boardContactId;
    if (this.boardId === this.boardIdForSobOpportunity) {
      boardContactId = Constants.boardIdForSobContacts;
    }
    else {
      boardContactId = Constants.boardIdForCRMContacts;
    }
    this.contactDetails = [];
    let obj = {
      "where": {
        "boardId": {
          "type": "search",
          "operator": "or",
          "value": [
            {
              "field": "boardId.raw",
              "value": boardContactId
            }
          ]
        }
      },
      "sort": { "field": "updatedAt", "order": "desc" },

      "from": this.pageFrom,
      "size": this.pageSize
    }
    let tQuery = [];
    tQuery.push({
      "field": "data.account.raw",
      "value": this.accountId
    })
    let query = {
      "query": {
        "nestedWhere": {}
      }
    };
    query['query']['nestedWhere']["account_query"] = {};
    query['query']['nestedWhere']["account_query"]["type"] = "search";
    query['query']['nestedWhere']["account_query"]["operator"] = "or";
    query['query']['nestedWhere']["account_query"]["value"] = tQuery;

    obj['nestedWhere'] = query['query']['nestedWhere'];
    console.log("Query", obj);
    this._kService.getLaneTaskContactDetails(obj, this.laneTaskIdByUrl).subscribe(result => {
      this.isLoading = false;
      if (result == null) {
        return;
      }
      this.pageIndex++;
      const contactList = result['result'];;
      for (let index = 0; index < contactList.length; index++) {
        const element = contactList[index];
        if (element.isChecked) {
          this.contactDetails.push(contactList[index])
        }
      }
      const idList = this.contactDetails.map(contact => contact.id.toString());
      console.log("laneTaskConfig", this.laneTaskConfig);
      const contactElement = this.laneTaskConfig.find(element => element.field === 'contact');
      if (contactElement) {
        contactElement.values = idList;
      }
    })
  }
  fetchConactBoardlane(boardId: string) {
    this._kService.getLaneWithUserAccess(boardId, '').subscribe(res => {
      let tmp = [];
      for (let i = 0; i < res['result']['levelAccess'].length; i++) {
        let t = res['result']['levelAccess'][i].laneDTO;
        tmp.push(t);
      }
      this.contactBoardLane = tmp;
    });
  }
  getContactBoardLane() {
    let contactBoardId;
    if (this.boardId === Constants.boardIdForCRMDeals) {
      contactBoardId = Constants.boardIdForCRMContacts;
    } else if (this.boardId === Constants.boardIdForSobOpportunity) {
      contactBoardId = Constants.boardIdForSobContacts;
    }
    this.fetchConactBoardlane(contactBoardId);
  }
  addNewLead() {
    this.dialog.open(CreateLaneTaskComponent, {
      disableClose: false,
      width: '68%',
      height: '100vh',
      position: { bottom: '0px', right: '0px' },
      data: {
        "boardId": Constants.boardIdForCRMContacts,
        "boardName": "contact",
        "selectedLane": this.contactBoardLane[0],
      },
      panelClass: 'addnewleadoverlay'
    });
  }
  addNewLeadOppContact() {
    console.log('addNewLeadOppContact in lane-card - details ::', this.boardId)
    this.dialog.open(CreateLaneTaskComponent, {
      disableClose: false,
      width: '68%',
      height: '100vh',
      position: { bottom: '0px', right: '0px' },
      data: {
        "boardId": Constants.boardIdForSobContacts,
        "boardName": "contact",
        "selectedLane": this.contactBoardLane[0]
      },
      panelClass: 'addnewleadoverlay'
    });
  }
  filterOptionsConfigDataList(formItem: any): void {
    console.log('filterOptionsConfigDataList', formItem);
    const searchTerm = this.searchControl.toLowerCase();
    formItem.filteredOptions = formItem.config.dataList.filter(option =>
      option.toLowerCase().includes(searchTerm)
    );
    if (formItem.filteredOptions.length > 100) {
      formItem.filteredOptions.slice(0, 100);
    }
  }

  getFilteredOptions(formItem: any): string[] {
    // Return filtered options if available, otherwise return all options
    if (formItem.filteredOptions && formItem.filteredOptions.length > 100) {
      formItem.filteredOptions = formItem.filteredOptions.slice(0, 100);
    }
    return formItem.filteredOptions || formItem.dataList.slice(0, 100);
  }

  getSelectionChange(event: any, formItem) {
    console.log('getSelectionChange', event);
    formItem.values = event;
  }

  searchChange(event: any, formItem) {

    console.log('filterOptionsConfigDataList', formItem);

    formItem.filteredOptions = formItem.dataList.filter(option =>
      option.toLowerCase().includes(event.toLowerCase())
    );

    if (formItem.filteredOptions.length > 100) {
      formItem.filteredOptions.slice(0, 100);
    }

    console.log('searchChange event', event)
  }

}
