<div class="w-p330">
  <div class="w-full">
    <div class="flex justify-between add-form-group items-center mt-10">
      <span>Add {{displayName}}*</span>
      <button mat-button class="btn-basic" color="Red">
        <i class="ri-add-fill icon-left"></i>Add Owner
      </button>
    </div>
  </div>
  <ul class="add-form-list">
    <li>
      <div class="add-form-card">
        <div class="w-full mb-5">
          <div class="flex justify-between add-form-group items-center">
            <span>Owner 1 Details</span>
            <button mat-button class="btn-basic" color="Red">
              <i class="ri-delete-bin-6-line icon-left"></i>Delete
            </button>
          </div>
        </div>
        <div class="add-input-group">
          <mat-form-field class="mat-input icon deep-0 mb-5" appearance="outline">
            <i class="ri-user-line"></i>
            <input matInput placeholder="Name" />
          </mat-form-field>

          <mat-form-field class="mat-input icon deep-0 mb-5" appearance="outline">
            <i class="ri-mail-add-line"></i>
            <input matInput placeholder="Email ID" />
          </mat-form-field>

          <mat-form-field class="mat-input icon deep-0 mb-5" appearance="outline">
            <i class="ri-contacts-book-line"></i>
            <input matInput placeholder="Mobile No" />
          </mat-form-field>

          <mat-form-field class="mat-input icon deep-0 mb-5" appearance="outline">
            <i class="ri-user-2-line"></i>
            <input matInput placeholder="Designation" />
          </mat-form-field>
          <mat-form-field class="mat-input icon deep-0 mb-5" appearance="outline">
            <i class="ri-calendar-line"></i>
            <input matInput placeholder="Age" />
          </mat-form-field>

          <!-- <mat-form-field class="mat-input icon deep-0 mb-5" appearance="outline" color="warn">
            <i class="ri-calendar-line"></i>
            <input matInput (focus)="MogDatePicker.open()" [matDatepicker]="MogDatePicker" placeholder="Age" />
            <mat-datepicker-toggle matSuffix [for]="MogDatePicker">
            </mat-datepicker-toggle>
            <mat-datepicker panelClass="atom-datepicker" #MogDatePicker></mat-datepicker>
          </mat-form-field> -->
          <mat-radio-group class="mat-radio-row" aria-label="Set as primary owner">
            <mat-radio-button value="Set as primary owner">Set as primary owner</mat-radio-button>
          </mat-radio-group>

        </div>
      </div>
    </li>
  </ul>
</div>