import { Component, OnInit, OnChanges, ChangeDetectionStrategy, SimpleChanges, ViewChild, Input } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatBottomSheet, MatSnackBar, MatOption, MatPaginator, MatSelect, MatSnackBarVerticalPosition, MatSort, MatTableDataSource } from '@angular/material';
import { KanbanService } from '../../../kanban.service';
import { MatDialog } from '@angular/material/dialog';
import { DealsHistoryViewComponent } from '../deals-history-view/deals-history-view.component';
import * as moment from "moment";
import { SettingService } from 'src/app/settings/setting.service';
import { DealFunnelModalComponent } from '../deal-funnel-modal/deal-funnel-modal.component';
import { Router } from '@angular/router';
import { LastLoginHistoryComponent } from '../last-login-history/last-login-history.component';
import { Constants } from 'src/app/Constants';
import { MoreActionItemsComponent } from './more-action-items/more-action-items.component';
import { MeetingListComponent } from './meeting-list/meeting-list.component';
import { LabelConstants } from 'src/app/LabelConstants';
import { element } from 'protractor';
import { PagesService } from 'src/app/pages/pages.service';
import { DropdownConstants } from 'src/utils/DropdownConstants';
@Component({
  selector: 'app-hunting-listing',
  templateUrl: './hunting-listing.component.html',
  styleUrls: ['./hunting-listing.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class HuntingListingComponent implements OnChanges {
  @Input() inputData;
  board_id: string;
  filteredObj: {}
  dragDisabled = true;
  loading;
  laneTile;
  boardLaneResponseObj: [];
  filterFields: Array<any> = [];
  selectedTrIndex: number = -1;
  leadListing: any = [];
  isLoading: boolean = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  selection = new SelectionModel<any>(true, []);
  dataSource = new MatTableDataSource(this.leadListing);
  boardLane = [];
  boardId: any;
  laneTaskList = [];
  boardLaneResponse: any;
  projectLists = [];
  selectTeams = [];
  selectedRole: any = "Sales Manager";
  selectedStatus: any;
  teamQuery: any;
  teamQueryForHunters: any;
  queryLanes: any[];
  selectedLane: any;
  isEditAccessLane: false;
  pageSize = 20;
  pageFrom = 0;
  lanePageSize = 5;
  lanePageFrom = 0;
  pageIndex = -1;
  totalRecords;
  breakpoint: number;
  @ViewChild(MatSort) sort: MatSort;
  teams: any;
  teamsSearch: any[] = [];
  roles: any = ["All", "Sales Manager", "City Head", "Regional Head", "Lead Hunter"];
  allUserList: any[] = [];
  allUserListSearch: any[] = [];
  selectedUsers: any = [];
  searchText = "";
  passOnData: any;
  laneStats: any;
  currentState: any = {};
  activeSorts = [];
  resetDates: boolean = false;
  durations = [
    {
      "title": "This Month"
    },
    {
      "title": "Last 7 Days",
    },
    {
      "title": "Last 30 Days",
    },
    {
      "title": "Last Month",
    },
    {
      "title": "Last 6 Months",
    }
  ];
  all: any = { "laneId": null, "boardId": null, "accessType": ["EDIT"], "laneDTO": { "id": "all", "name": "all", "displayName": "All", "position": 1, "board": { "id": "63bfa8ccade7bc466d1c780e", "name": "deals", "createdBy": { "id": null, "uuid": null, "firstName": null, "lastName": null, "name": null, "email": null, "phoneNumber": null, "roleId": null, "role": null, "roles": null, "businessAccounts": null, "userRoles": null, "activeLogin": false, "totalCount": 0, "designation": null, "countryCode": null, "customUserRoleId": null, "loggedInUserRole": null, "masterAccountId": null, "userTeams": null, "sendMail": true, "verified": false, "deleted": false, "uniqueRoleId": null }, "status": null, "type": "MOGLIX", "isActive": true, "isDeleted": false, "watchers": [], "customInfo": null, "buyerDataConfig": null, "createdAt": "2023-01-12T06:29:32.800+0000", "updatedAt": "2023-01-12T06:29:32.800+0000" }, "watchersId": null, "watchers": [], "type": null, "status": null, "isDeleted": false, "isActive": true, "createdBy": { "id": "63c8deac6210302382b4dc6e", "uuid": null, "firstName": null, "lastName": null, "name": "moglix_user1", "email": "moglix@credlix.com", "phoneNumber": null, "roleId": null, "role": null, "roles": null, "businessAccounts": null, "userRoles": null, "activeLogin": false, "totalCount": 0, "designation": null, "countryCode": null, "customUserRoleId": null, "loggedInUserRole": null, "masterAccountId": null, "userTeams": null, "sendMail": true, "verified": false, "deleted": false, "uniqueRoleId": null }, "customInfo": null, "createdAt": "2023-01-23T07:39:30.897+0000", "updatedAt": "2023-01-23T07:39:30.897+0000", "class": "data-pending" }, "authorityType": "LANE", "boardName": null, "accessLevel": null };
  displayedColumns: any[] = ['name', 'login', 'business_vertical', 'region', 'user_head', 'total_deals', 'primary_deals', 'secondary_deals', 'active_deals', 'worked_on', 'closing_date_exceeded', 'action_date_exceeded', 'won_deals', 'action', 'status']//,'duration'];

  displayedColumns1: any[] = ['name', 'login', 'business_vertical', 'region', 'user_head']//,'duration'];

  displayedColumns2: any[] = ['name', 'login', 'business_vertical', 'region', 'user_head', 'total_deals', 'primary_deals', 'secondary_deals', 'active_deals', 'worked_on', 'closing_date_exceeded', 'kams_handover', 'action_date_exceeded', 'won_deals', 'action', 'status']; //,'duration'];

  displayConfig = [{ "lane": "63bfae26ade7bc466d1c7812", "displayConfig": [{ "field": "account-name", "label": "Account Name", "index": 1, "icon": "account" }, { "field": "account-owner", "label": "Account Owner", "index": 4, "icon": "person" }, { "field": "industry", "label": "Industry", "index": 3, "icon": "account" }, { "field": "revenue-in-cr", "label": "Revenue (In Cr)", "index": 2, "icon": "account" }, { "field": "parent-account-name", "label": "Parent Account Name", "index": 5, "icon": "account" }] }, { "lane": "63ce5630a5c09f3f171460b2", "displayConfig": [{ "field": "account-name", "label": "Account Name", "index": 1, "icon": "account" }, { "field": "account-owner", "label": "Account Owner", "index": 4, "icon": "person" }, { "field": "industry", "label": "Industry", "index": 3, "icon": "account" }, { "field": "number-of-plants", "label": "Number of Plants", "index": 2, "icon": "account" }, { "field": "parent-account-name", "label": "Parent Account Name", "index": 5, "icon": "account" }] }, { "lane": "63ce39b2a5c09f3f171460a3", "displayConfig": [{ "field": "deal-name", "label": "Deal Name", "index": 1, "icon": "account" }, { "field": "account-name", "label": "Account Name", "index": 2, "icon": "account" }, { "field": "deal-owner", "label": "Deal Owner", "index": 5, "icon": "person" }, { "field": "industry", "label": "Industry", "index": 4, "icon": "account" }, { "field": "realistic-deal-size-in-cr", "label": "Realistic Deal Size (In Cr)", "index": 3, "icon": "account" }, { "field": "parent-account-name", "label": "Parent Account Name", "index": 6, "icon": "account" }] }];

  isReadMore: boolean = true;
  startDate: any;
  endDate: any;
  startUpdatedDate: any;
  endUpdatedDate: any;
  startExceededDate: any;
  endExceededDate: any;
  crmBusinessId = Constants.businessIdForCRM;
  sobBusinessId = Constants.businessIdForSOB;
  switchBusiness: any;
  selectedBusinessId: any;
  searchControlRegion: string = '';
  teamsLabel: string = LabelConstants.teamsLabel;
  huntersLabel: string = LabelConstants.huntersLabel;
  searchControlOwner = '';
  userQuery: any;
  businessVerticalLabel: string = LabelConstants.businessVerticalLabel;
  selectedBusinessVertical = [];

  selected: any;
  displayColumns5: any[] = ['name', 'login', 'business_vertical', 'region', 'user_head', 'conducted_meeting', 'mom', 'upcoming_meetings', 'next_action_items'];
  businessVerticalQuery: { query: { nestedWhere: {}; }; };

  defaultBusinessVertical = DropdownConstants.BusinessFilter;
  defaultBusinessVerticalSearch = DropdownConstants.BusinessFilter;
  searchControlBusinessVertical = '';
  accountConfig = {
    "key": "laneTask",
    "field": "account-name",
    "rawValue": null,
    "type": "belongsTo",
    "value": "",
    "optional": true,
    "display": true,
    "displayWhileListing": true,
    "showTotal": false,
    "displayName": "Account Name",
    "dataList": [],
    "values": null,
    "additionalData": null,
    "operationsData": null,
    "operations": null,
    "index": 6,
    "icon": "",
    "relationKey": Constants.boardIdForCRMAccount,
    "nameField": false
  };
  @ViewChild('cusComComplete') cusComComplete: any;

  constructor(private router: Router, private _kService: KanbanService, public dialog: MatDialog, public settingService: SettingService,public pagesService: PagesService) { }

  ngOnInit() {
    this.selectedBusinessId = this.crmBusinessId;
    this.setDefaultDateRange();
    this.loadTeams();
    this.switchBusiness = this.settingService._ManagementData.subscribe(res => {
      console.log('in Oninit hunter tab switching business', res)
      if (res === 'SOB Management') {
        this.router.navigate(['/crm-board']);
      } else {
        this.selectedBusinessId = this.crmBusinessId;
      }
      // this.getAllRoles(this.selectedBusinessId);
    });
    this.actionTracker();
  }

  actionTracker() {
    // this.dataSource5 = [
    //   {'name':'testing', 'login':'testing', 'business_vertical':'testing', 'region':'testing', 'user_head':'testing', 'ConductedMeeting': '5', 'MOM': '2', 'UpcomingMeetings': '10', 'NextActionItems': 'Proposed Loyalty/ gifting-Ashutosh has mentioned this will be a long… ' },

    // ];
    // console.log("datasource5", this.dataSource5);
  }
  setDefaultDateRange() {
    const startDate = moment().subtract(6, 'days').startOf('day');
    const endDate = moment().endOf('day');

    this.selected = {
      startDate: startDate.toDate(),
      endDate: endDate.toDate()
    };
  }
  ngOnDestroy() {
    console.log('ngOnDestroy on hunter tab');
    this.selected = null;
    // this.switchBusiness.unsubscribe();
  }
  getAllRoles(selectedBusinessId) {
    // console.log('get all role ');
    // this.settingService.fetchAllRoles(selectedBusinessId).subscribe(list => {
    //   this.roles = list['result']
    // });
  }
  sortLaneTasks(sort) {
    const data = this.leadListing.slice();
    if (!sort.active || sort.direction === '') {
      this.leadListing = data;
      return;
    }

    this.leadListing = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'login': return this.compare(a.lastLoginCount, b.lastLoginCount, isAsc);
        case 'deal_size': return this.compare(a.totalSum, b.totalSum, isAsc);
        case 'total_deals': return this.compare(a.totalCount, b.totalCount, isAsc);
        case 'worked_on': return this.compare(a.updatedCount, b.updatedCount, isAsc);
        case 'primary_deals': return this.compare(a.primaryDeal, b.primaryDeal, isAsc);
        case 'secondary_deals': return this.compare(a.secondaryDeal, b.secondaryDeal, isAsc);
        case 'active_deals': return this.compare(a.activeDeal, b.activeDeal, isAsc);
        case 'closing_date_exceeded': return this.compare(a.closingDateExceeded, b.closingDateExceeded, isAsc);
        case 'action_date_exceeded': return this.compare(a.nextActionDateExceeded, b.nextActionDateExceeded, isAsc);
        case 'won_deals': return this.compare(a.wonDeal, b.wonDeal, isAsc);
        default: return 0;
      }
    });
    this.dataSource.data = this.leadListing;
  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  ngOnChanges(changes: SimpleChanges) {
    // if (changes == null || changes.inputData == null || (!changes.inputData.currentValue && !changes.inputData.previousValue) || (changes.inputData.currentValue && !changes.inputData.previousValue)) {
    //   return;
    // }true
    console.log('huntiner listing changes', changes)
    this.boardId = Constants.boardIdForCRMDeals;
    changes.inputData.currentValue.boardId = this.boardId;
    // if (changes.inputData.currentValue.boardId) {
    //   this.boardId = changes.inputData.currentValue.boardId;
    // }
    if (changes.inputData.currentValue.boardData) {
      this.boardLaneResponse = changes.inputData.currentValue.boardData;
      if (changes.inputData.currentValue.selectedLaneIndex != null && changes.inputData.currentValue.selectedLaneIndex > -1) {
        this.selectedLane = changes.inputData.currentValue.boardData.result.levelAccess[changes.inputData.currentValue.selectedLaneIndex];
      }
      let response = this.boardLaneResponse;
      //   this._kService.getLaneWithUserAccess(this.boardId, '', this.lanePageSize, this.lanePageFrom).subscribe(response => {
      let previousBoardLaneLength: number = this.boardLane.length;
      let tmp = response['result']['levelAccess'];
      tmp = Object.values(tmp.reduce((acc, cur) => Object.assign(acc, { [cur.laneDTO.id]: cur }), {}));
      if (tmp && tmp.length > 1 && tmp[0].laneDTO.id != "all") {
        tmp.unshift(this.all);
      }

      this.selectedLane = tmp[0];
      if (this.allUserList && this.allUserList.length) {
        if (this.boardId == "63bfa8ccade7bc466d1c780e") {
          this.passOnData = {
            "teams": this.teams,
            "users": this.allUserList,
            "isDeals": true,
            "selectedLane": this.selectedLane,
            "boardId": this.boardId
          }
        } else {
          this.passOnData = {
            "teams": this.teams,
            "users": this.allUserList,
            "isDeals": false,
            "selectedLane": this.selectedLane,
            "boardId": this.boardId

          }
        }
      }
      this.boardLane = tmp.sort((a, b) => a.laneDTO.position - b.laneDTO.position).map((exemple, index, array) => exemple)
      this.boardLane['totalRecords'] = response['total'];
      let tmpQueryLanes = [];
      tmpQueryLanes.push(this.boardId);

      this.queryLanes = this.selectedLane.laneDTO.id;
      //  this.loadDisplayColumns(changes.inputData.currentValue.boardData.result.levelAccess[0]);
    }
    // if (changes.inputData.currentValue.accessLanes) {
    //   this.boardLane = changes.inputData.currentValue.accessLanes;
    // }
    if (changes.inputData.currentValue.filteredObj) {
      this.filteredObj = changes.inputData.currentValue.filteredObj;
    } else {
      this.filteredObj = {};
    }

    this.laneTaskList = [];
    this.leadListing = [];
    this.dataSource.data = [];
    this.totalRecords = 0;
    this.onScrollDown(false);
  }
  nFormatter(num, digits) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "B" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function (item) {
      return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
  }
  onScrollDown(reset) {
    console.log('onScrollDown team', this.teamQuery)
    console.log('onScrollDown role value', this.selectedRole)
    let query = {
      "where": {
        "boardId": {
          "type": "search",
          "operator": "or",
          "value": [
            {
              "field": "boardId.raw",
              "value": "63bfa8ccade7bc466d1c780e"
            }
          ]
        }
      }
      ,
      "sort": { "field": "updatedAt", "order": "desc" },
      "from": 0,
      "size": this.pageSize
    };
    if (this.teamQueryForHunters) {
      if (!query['nestedWhere']) {
        query['nestedWhere'] = {};
      }
      query['nestedWhere']["team_query"] = this.teamQueryForHunters["team_query"];
      query['regions'] = this.selectTeams.map(team => team.id);
    }

    if (this.userQuery) {
      query['where']["user_query"] = this.userQuery['query']['where']["user_query"];
      query['userIds'] = this.selectedUsers.map(user => user.id);
    }
    if (this.businessVerticalQuery) {
      if (!query['nestedWhere']) {
        query['nestedWhere'] = {};
      }
      console.log("businessVerticalQuery", this.businessVerticalQuery);
      query['nestedWhere']["bs_query"] = this.businessVerticalQuery['query']['nestedWhere']["bs_query"];
      query['businessVerticals'] = this.selectedBusinessVertical;
    }
    if (this.accountQuery) {
      if (!query['nestedWhere']) {
        query['nestedWhere'] = {};
      }
      query['nestedWhere']["account_query"] = this.accountQuery['query']['nestedWhere']["account_query"];
      query['accounts'] = this.customSelectWithMultipleAccounts;
    }

    if (!this.resetDates) { query['filter'] = { "start_date": this.startDate, "end_date": this.endDate } }
    this.isLoading = true;
    this._kService.getLaneTaskUsers(query, this.selectedRole, this.selectedStatus).subscribe(res => {
      let users = res['result'];
      users.sort(function (a, b) {
        if (a.user.name < b.user.name) {
          return -1;
        }
        if (a.user.name > b.user.name) {
          return 1;
        }
        return 0;
      });
      this.laneTaskList = users;
      this.leadListing = users;
      this.dataSource.data = this.leadListing;
      this.totalRecords = res['total'];
      this.isLoading = false;
    });
  }
  onFilterApply($event) {

  }
  openDialog(element) {

    const dialogRef = this.dialog.open(DealsHistoryViewComponent, {

      width: '580px',

      height: '530px',

      panelClass: 'right-side-dialog',

      data: element
    });
  }
  openMeetingDialog(element: any, dialogType: 'momList' | 'upcomingList' | 'conductedMeeting') {
    element.momListDialog = false;
    element.upcomingListDialog = false;
    element.conductedMeetingDialog = false;
    element.filters = {};

    const hunterIds = this.selectedUsers.map(user => user.id);
    if (hunterIds.length > 0) {
      element.filters.hunterIds = hunterIds;
    }

    if (this.startDate) {
      element.filters.startDate = this.startDate;
    }
    else {
      const lastSevenDays = new Date();
      lastSevenDays.setHours(0, 0, 0, 0); // Set time to 00:00:00
      lastSevenDays.setDate(lastSevenDays.getDate() - 6); // Subtract 7 days
      element.filters.startDate = lastSevenDays.getTime();
    }

    if (this.endDate) {
      element.filters.endDate = this.endDate;
    }
    else {
      element.filters.endDate = Date.now();  // Or new Date().getTime()
    }

    const regions = this.selectTeams.map(team => team.id);
    if (regions.length > 0) {
      element.filters.regions = regions;
    }

    const bv = this.selectedBusinessVertical;
    if (bv.length > 0) {
      element.filters.businessVerticals = bv;
    }


    switch (dialogType) {
      case 'momList':
        element.momListDialog = true;
        break;
      case 'upcomingList':
        element.upcomingListDialog = true;
        break;
      case 'conductedMeeting':
        element.conductedMeetingDialog = true;
        break;
    }

    const dialogRef = this.dialog.open(MeetingListComponent, {
      width: '580px',
      height: '530px',
      panelClass: 'right-side-dialog',
      data: element
    });
  }


  moreActionItemsDialog(element) {
    element.filters = {};
    element.momListDialog = true;
    const hunterIds = this.selectedUsers.map(user => user.id);
    if (hunterIds.length > 0) {
      element.filters.hunterIds = hunterIds;
    }
    if (this.startDate) {
      element.filters.startDate = this.startDate;
    } else {
      const lastSevenDays = new Date();
      lastSevenDays.setHours(0, 0, 0, 0); // Set time to 00:00:00
      lastSevenDays.setDate(lastSevenDays.getDate() - 6); // Subtract 7 days
      element.filters.startDate = lastSevenDays.getTime();
    }
    if (this.endDate) {
      element.filters.endDate = this.endDate;
    } else {
      element.filters.endDate = Date.now();  // Or new Date().getTime()
    }
    const regions = this.selectTeams.map(team => team.id);
    if (regions.length > 0) {
      element.filters.regions = regions;
    }
    const bv = this.selectedBusinessVertical;
    if (bv.length > 0) {
      element.filters.businessVerticals = bv;
    }
    const dialogRef = this.dialog.open(MoreActionItemsComponent, {
      width: '580px',
      height: '530px',
      panelClass: ['right-side-dialog', 'more-acion-list-modal'],
      data: element
    });
  }

  onBUSelectionChange(selection: string[]) {
    console.log('onBuselectionChange', selection)
    this.selectedBusinessVertical = selection;
    this.onApply();
  }
  filterOptionsVertical(event) {
    this.defaultBusinessVerticalSearch = this.defaultBusinessVertical.filter(option => (option).toLowerCase().includes(event.toLowerCase()));
  }


  clearAll() {
    console.log("this.fieldData", this.accountConfig);
    this.resetDates = true;
    this.startDate = null;
    this.endDate = null;
    this.teamQuery = null;
    this.teamQueryForHunters = null;
    this.selectTeams = [];
    this.selectedRole = "Sales Manager";
    this.startUpdatedDate = null;
    this.customSelectWithMultipleAccounts = [];
    if (this.cusComComplete) {
      this.cusComComplete.clearSelections();
    }
    this.businessVerticalQuery = null;
    this.endUpdatedDate = null;
    this.startExceededDate = null;
    this.endExceededDate = null;
    this.selectedStatus = null;
    this.selectedUsers = [];
    this.userQuery = null;
    this.searchControlOwner = '';
    this.selectedBusinessVertical = [];
    this.searchControlRegion = '';
    this.accountQuery = null;
    this.searchControlBusinessVertical = '';
    this.onScrollDown(false);
  }
  onApplyDates(event) {
    console.log('onApplyDates on hunters', event)
    this.startDate = Number(moment(event.start_date).format('x'));
    this.endDate = Number(moment(event.end_date).format('x'));
    this.startUpdatedDate = moment(event.start_date);
    this.endUpdatedDate = moment(event.end_date);
    this.startExceededDate = moment(event.start_date).startOf('day').toISOString();
    this.endExceededDate = moment(event.end_date).startOf('day').toISOString();
    this.resetDates = false;
    console.log("endDate", this.startDate);
    console.log("endDate", this.endDate);
    this.onScrollDown(false);

  }
  loadTeams() {
    this.settingService.fetchTeams().subscribe(response => {
      this.teams = response['result'];
      this.teamsSearch = this.teams;
      this.loadUsers();
    });
  }
  loadUsers() {
    const obj = {
      from: 0,
      size: 100,
      businessId: this.selectedBusinessId
    };

    this.settingService.getUserDetailsForOwnerDropDown(obj).subscribe(list => {
      console.log("list", list);

      const userList = list['result'].map(user => {
        const teamList = user.userTeams ? user.userTeams.map(team => team.id) : [];
        return { ...user, userTeams: teamList };
      });

      userList.sort((a, b) => a.name.localeCompare(b.name));

      this.allUserList = userList;
      this.allUserListSearch = this.allUserList;
    });
  }
  resetTeamFilterInCache() {
    this.teamQuery = null;
    this.teamQueryForHunters = null;
  }
  onApply = () => {
    console.log('on apply selectedStatus', this.selectedStatus)
    if (this.selectTeams && this.selectTeams.length) {
      let query = {
        "team_query": {}
      };
      query["team_query"] = {};
      query["team_query"]["type"] = "search";
      query["team_query"]["operator"] = "or";
      let tQuery = [];
      for (let k = 0; k < this.selectTeams.length; k++) {
        tQuery.push({
          "field": "data.regionteam.raw",
          "value": this.selectTeams[k].id
        })
      }
      query["team_query"]["value"] = tQuery;
      this.teamQueryForHunters = query;
      this.inputData['teamQueryForHunters'] = this.teamQueryForHunters;
    }
    else {
      this.inputData['teamQueryForHunters'] = null;
      this.teamQueryForHunters = null;
    }
    if (this.selectedUsers && this.selectedUsers.length > 0) {
      let query = {
        "query": {
          "where": {}
        }
      };
      query['query']['where']["user_query"] = {};
      query['query']['where']["user_query"]["type"] = "search";
      query['query']['where']["user_query"]["operator"] = "or";
      let tQuery = [];
      for (let k = 0; k < this.selectedUsers.length; k++) {
        tQuery.push({
          "field": "assigneeId.raw",
          "value": this.selectedUsers[k].id
        })
      }
      query['query']['where']["user_query"]["value"] = tQuery;
      this.inputData['userFilter'] = this.selectedUsers;
      this.userQuery = query;
      this.inputData['userQuery'] = query;
    }
    else {
      this.userQuery = null;
      this.inputData['userFilter'] = [];
      this.inputData['userQuery'] = null;
    }
    if (this.selectedBusinessVertical && this.selectedBusinessVertical.length > 0) {
      let query = {
        "query": {
          "nestedWhere": {}
        }
      };
      query['query']['nestedWhere']["bs_query"] = {};
      query['query']['nestedWhere']["bs_query"]["type"] = "search";
      query['query']['nestedWhere']["bs_query"]["operator"] = "or";
      let tQuery = [];

      for (let k = 0; k < this.selectedBusinessVertical.length; k++) {
        tQuery.push({
          "field": "data.businessvertical.raw",
          "value": this.selectedBusinessVertical[k]
        })
      }

      query['query']['nestedWhere']["bs_query"]["value"] = tQuery;
      this.businessVerticalQuery = query;
      this.inputData['bsQuery'] = query;
    } else {
      this.businessVerticalQuery = null;
      this.inputData['bsQuery'] = null;
    }

    if (this.accountQuery) {
      this.inputData['account_query'] = this.accountQuery;
    } else {
      this.inputData['account_query'] = null;
    }

    this.inputData['resetFilter'] = false;
    this.onScrollDown(false);
  }
  openDealDialog(element, column) {
    let count: any = 0;
    let sum: any = 0;
    this.inputData['lane'] = column
    this.inputData['count'] = count
    this.inputData['sum'] = sum
    this.inputData['userQuery'] = null;
    this.inputData['primaryUserQuery'] = null;
    this.inputData['secondaryUserQuery'] = null;
    this.inputData['lastUpdatedDateQuery'] = null;
    this.inputData['teamQueryForFunner'] = null;
    this.inputData['teamFilter'] = null;
    this.inputData['closingDateQuery'] = null;
    this.inputData['nextActionDateQuery'] = null;
    if (this.selectTeams && this.selectTeams.length) {
      let query = {
        "query": {
          "nestedWhere": {}
        }
      };
      query['query']['nestedWhere']["team_query"] = {};
      query['query']['nestedWhere']["team_query"]["type"] = "search";
      query['query']['nestedWhere']["team_query"]["operator"] = "or";
      let tQuery = [];
      for (let k = 0; k < this.selectTeams.length; k++) {
        tQuery.push({
          "field": "data.regionteam.raw",
          "value": this.selectTeams[k].id
        })
      }
      query['query']['nestedWhere']["team_query"]["value"] = tQuery;
      this.teamQuery = query;
      this.inputData['teamQuery'] = this.teamQuery;
      this.inputData['teamFilter'] = this.selectTeams;
    }
    if (column === 'totaldeal') {
      let query = {
        "query": {
          "where": {}
        }
      };
      query['query']['where']["user_query"] = {};
      query['query']['where']["user_query"]["type"] = "search";
      query['query']['where']["user_query"]["operator"] = "or";
      let tQuery = [];
      tQuery.push({
        "field": "assigneeId.raw",
        "value": element.user.id
      });
      query['query']['where']["user_query"]["value"] = tQuery;
      this.inputData['userQuery'] = query;
    } else if (column === 'primary') {
      let query = {
        "query": {
          "where": {}
        }
      };
      query['query']['where']["primary_query"] = {};
      query['query']['where']["primary_query"]["type"] = "search";
      query['query']['where']["primary_query"]["operator"] = "or";
      let tQuery = [];
      tQuery.push({
        "field": "assigneeId.raw",
        "value": element.user.id
      });
      query['query']['where']["primary_query"]["value"] = tQuery;
      this.inputData['primaryUserQuery'] = query;
    } else if (column === 'secondary') {
      let query = {
        "query": {
          "where": {},
          "nestedWhere": {}
        }
      };
      query['query']['nestedWhere']["secondary_query"] = {};
      query['query']['nestedWhere']["secondary_query"]["type"] = "search";
      query['query']['nestedWhere']["secondary_query"]["operator"] = "or";
      let tQuery = [];
      tQuery.push({
        "field": "data.secondaryowner.raw",
        "value": element.user.id
      });
      query['query']['nestedWhere']["secondary_query"]["value"] = tQuery;
      this.inputData['secondaryUserQuery'] = query;
    } else if (column === 'activedeal') {
      let query = {
        "query": {
          "where": {}
        }
      };
      query['query']['where']["primary_query"] = {};
      query['query']['where']["primary_query"]["type"] = "search";
      query['query']['where']["primary_query"]["operator"] = "or";
      let tQuery = [];
      tQuery.push({
        "field": "assigneeId.raw",
        "value": element.user.id
      });
      query['query']['where']["primary_query"]["value"] = tQuery;
      this.inputData['primaryUserQuery'] = query;
    } else if (column === 'modifieddeal') {

      let query = {
        "query": {
          "where": {}
        }
      };

      query['query']['where']["primary_query"] = {};
      query['query']['where']["primary_query"]["type"] = "search";
      query['query']['where']["primary_query"]["operator"] = "or";
      this.setUpdatedDate();
      let tQuery = [];
      tQuery.push({
        "field": "assigneeId.raw",
        "value": element.user.id
      });
      query['query']['where']["primary_query"]["value"] = tQuery;
      this.inputData['primaryUserQuery'] = query;
    } else if (column === 'closingDeal') {
      let query = {
        "query": {
          "where": {},
          "nestedWhere": {}
        }
      };

      query['query']['where']["primary_query"] = {};
      query['query']['where']["primary_query"]["type"] = "search";
      query['query']['where']["primary_query"]["operator"] = "or";

      query["data.closingdate"] = {};
      query["data.closingdate"]["type"] = "range";
      query["data.closingdate"]["field"] = "data.closingdate";
      let tQuery = [];
      tQuery.push({
        "field": "assigneeId.raw",
        "value": element.user.id
      });
      query['query']['where']["primary_query"]["value"] = tQuery;
      this.inputData['primaryUserQuery'] = query;

      if (this.startExceededDate) {
        query["data.closingdate"]["start_value"] = moment(this.startExceededDate).startOf('day').toISOString();
      } else {
        query["data.closingdate"]["start_value"] = moment().subtract(6, 'days').startOf('day').toISOString();
      }
      if (this.endExceededDate) {
        query["data.closingdate"]["end_value"] = moment(this.endExceededDate).startOf('day').subtract(10, 'seconds').toISOString();
      } else {
        query["data.closingdate"]["end_value"] = moment().startOf('day').subtract(10, 'seconds').toISOString();
      }
      this.inputData['closingDateQuery'] = query;
    } else if (column === 'nextActionDateDeal') {
      let query = {
        "query": {
          "where": {},
          "nestedWhere": {}
        }
      };

      query['query']['where']["primary_query"] = {};
      query['query']['where']["primary_query"]["type"] = "search";
      query['query']['where']["primary_query"]["operator"] = "or";

      query["data.nextactiondate"] = {};
      query["data.nextactiondate"]["type"] = "range";
      query["data.nextactiondate"]["field"] = "data.nextactiondate";
      let tQuery = [];
      tQuery.push({
        "field": "assigneeId.raw",
        "value": element.user.id
      });
      query['query']['where']["primary_query"]["value"] = tQuery;
      this.inputData['primaryUserQuery'] = query;

      if (this.startExceededDate) {
        query["data.nextactiondate"]["start_value"] = moment(this.startExceededDate).startOf('day').toISOString();
      } else {
        query["data.nextactiondate"]["start_value"] = moment().subtract(6, 'days').startOf('day').toISOString();
      }
      if (this.endExceededDate) {
        query["data.nextactiondate"]["end_value"] = moment(this.endExceededDate).startOf('day').subtract(10, 'seconds').toISOString();
      } else {
        query["data.nextactiondate"]["end_value"] = moment().startOf('day').subtract(10, 'seconds').toISOString();
      }
      this.inputData['nextActionDateQuery'] = query;
    } else if (column === 'wondeal') {
      let query = {
        "query": {
          "where": {}
        }
      };
      query['query']['where']["primary_query"] = {};
      query['query']['where']["primary_query"]["type"] = "search";
      query['query']['where']["primary_query"]["operator"] = "or";
      let tQuery = [];
      tQuery.push({
        "field": "assigneeId.raw",
        "value": element.user.id
      });
      query['query']['where']["primary_query"]["value"] = tQuery;
      this.inputData['primaryUserQuery'] = query;
    }
    else if (column === 'kams_handover') {
      let query = {
        "query": {
          "where": {}
        }
      };
      query['query']['where']["primary_query"] = {};
      query['query']['where']["primary_query"]["type"] = "search";
      query['query']['where']["primary_query"]["operator"] = "or";
      let tQuery = [];
      tQuery.push({
        "field": "assigneeId.raw",
        "value": element.user.id
      });
      query['query']['where']["primary_query"]["value"] = tQuery;
      this.inputData['primaryUserQuery'] = query;
    }
    const dialog = this.dialog.open(DealFunnelModalComponent, {
      width: '650px',
      height: '530px',
      panelClass: 'right-side-dialog',
      data: this.inputData
    });

  }
  setUpdatedDate() {
    let query1 = {
      "query": {
        "where": {}
      }
    };
    query1["updatedAt"] = {};
    query1["updatedAt"]["type"] = "range";
    query1["updatedAt"]["field"] = "updatedAt";
    if (this.startUpdatedDate) {
      query1["updatedAt"]["start_value"] = this.startUpdatedDate;
    } else {
      query1["updatedAt"]["start_value"] = moment().subtract(6, 'days').toISOString();
    }
    if (this.endUpdatedDate) {
      query1["updatedAt"]["end_value"] = this.endUpdatedDate;
    } else {
      query1["updatedAt"]["end_value"] = moment().toISOString();
    }
    this.inputData['lastUpdatedDateQuery'] = query1;
  }
  lastLoginDialog(element: any) {
    this.settingService.setUserId(element);

    const dialogRef = this.dialog.open(LastLoginHistoryComponent, {

      width: '308px',
      panelClass: 'right-side-dialog-small',

    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    });

  }
  onRegionSelectionChange(selection: any) {
    this.selectTeams = selection;
    this.onApply();
  }
  filterOptionsTeams(event) {
    this.teamsSearch = this.teams;
    const filterValue = event.toLowerCase();
    this.teamsSearch = this.teams.filter(option => (option.name).toLowerCase().includes(filterValue));
    console.log('filterOptionsTeams', this.teamsSearch)
  }
  onKAMSelectionChange(selection: any) {
    console.log("selection", selection);
    this.selectedUsers = selection;
    this.onApply();
  }
  filterOptions(event) {
    this.allUserListSearch = this.allUserList;
    const filterValue = event.toLowerCase();
    this.allUserListSearch = this.allUserList.filter(option => (option.name).toLowerCase().includes(filterValue));
  }

  stringToList(text) {
    if (!text) {
      return [];  // Return empty list for empty string
    }
    return text.trim().split(","); // Remove leading/trailing whitespace and split by comma
  }

  accountQuery: any;
  customSelectWithMultipleAccounts: any[] = [];

  handleApplySelections(selectedValues: any, field: string = 'accountname.raw'): void {
    console.log('Selected values:', selectedValues);
    this.customSelectWithMultipleAccounts = selectedValues.map(item => item.id);
    this.onMultipleSelectFilter(this.customSelectWithMultipleAccounts, field);
  }

  onMultipleSelectFilter(ids: string[], field: string): void {
    console.log('onMultipleSelectFilter in chart filter ids', ids)
    if (!ids || ids.length === 0) {
      this.accountQuery = null;
    } else {
      const accountQueries = ids.map(value => {
        return {
          field: `data.${field}`,
          value: value
        };
      });
      let query = {
        "query": {
          nestedWhere: {
            account_query: {
              type: 'search',
              operator: 'or',
              value: accountQueries
            }
          },
        }
      };
      this.accountQuery = query;
    }
    console.log('onMultipleSelectFilter in chart filter acc query', this.accountQuery);
    this.onApply();
  }
}
