import { Component, OnInit, SimpleChanges, ChangeDetectorRef, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { keys } from 'highcharts';
import { ChartFiltersComponent } from '../chart-filters/chart-filters.component';
@Component({
  selector: 'app-hunting-overview',
  templateUrl: './hunting-overview.component.html',
  styleUrls: ['./hunting-overview.component.scss']
})
export class HuntingOverviewComponent implements OnInit {
  @Input() inputData;
  @Input() huntingData;
  @Output() onDrillDown = new EventEmitter<string>();
  stageData : any = {};
  teams: any;
  userList: any;
  breakpoint: number;
  newDeals : number;
  passOnData : any;
  huntingOverview: any;
  realisticDealSize : number=0;
  totalDeals : number=0;
  huntingStats : Array<any> = [];
  @ViewChild(ChartFiltersComponent) chartFilters : ChartFiltersComponent;
  constructor(public cRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.setBreakPoint();
    this.getHuntingStats();
  }
  ngAfterViewInit() {
    this.cRef.detectChanges();
  
  }

  getHuntingStats() {
   // for(let i = 0;i < 2; i++) {
     
      
     
      
     
      

   // }
  }
  
  drillDown (cardType, stage) {
    if (!cardType) {
      cardType  = {};
    }
    let query = {
      "where" : {},
      "nestedWhere" : {}
    };
    if (stage != null) {
      cardType = {
        "label" : stage + " Deals"
      }
      query['where']['laneName.raw'] =  {
        "type" : "search",
        "field" : "laneName.raw",
        "value" :  stage
      };
    }
    if (cardType.label == "Deals Lost")  {
      query['where']['laneName.raw'] =   {
        "type" : "search",
        "field" : "laneName.raw",
        "value" : "Closed-Lost to Competition"
      };
    } else if  (cardType.label == "Cold Leads")  {
       query['where']['laneName.raw'] =  {
        "type" : "search",
        "field" : "laneName.raw",
        "value" : "Closed Lost"
      };
    }  else if  (cardType.label == "Inactive Deals")  {
      query['where']['updatedAt'] =  {
        "field" : "updatedAt",
        "type" : "range",
        "range_type" : "previous",
        "custom" : 31
      }
    } else if  (cardType.label == "Deals expected to close")  {
      query['where']['laneName'] = {
        "type" : "search",
        "field" : "laneName.raw",
        "operator" : "term_or",
        "value" : [
          {
            "field" : "laneName.raw",
            "value" : "C0"
          },
          {
            "field" : "laneName.raw",
            "value" : "C1"
          },
          {
            "field" : "laneName.raw",
            "value" : "C2"
          },
          {
            "field" : "laneName.raw",
            "value" : "C3"
          },
          {
            "field" : "laneName.raw",
            "value" : "C4"
          }
        ]
      }
      query['nestedWhere']['data.closingdate'] =  {
        "field" : "data.closingdate",
        "type" : "range",
        "range_type" : "next",
        "custom" : 31
      };
    }  else if  (cardType.label == "New Deals Added")  {
      query['where']['createdAt'] =  {
        "field" : "createdAt",
        "type" : "range",
        "range_type" : "previous",
        "custom" : 31
      }
    }  else if  (cardType.label == "Deals Won")  {
      query['where']['laneName.raw'] = {
        "type" : "search",
        "field" : "laneName.raw",
        "value" : "First PO after ARC Signing"
      };
      query['nestedWhere']['data.closingdate'] =  {
        "field" : "data.closingdate",
        "type" : "range",
        "range_type" : "previous",
        "custom" : 31
      };
    }
    this.inputData['tabFilterQuery'] = query;
    this.inputData['search'] = cardType;
    this.onDrillDown.emit(this.inputData);
  }
  numDifferentiation(value) {
    let val = Math.abs(value);
    let result = null;
    if (val >= 10000000) {
      result = (val / 10000000).toFixed(2) + ' Cr';
    } else  {
      result = (val).toFixed(2) + ' Cr ';
    }
    return result || val;
  }
  getStageData(key) {
    if ( this.stageData[key]) {
      return this.stageData[key];
    }
    let val = {
      "key" : "N/A",
      "count" : 0,
      "sum" : 0
    };

    if (this.huntingOverview && this.huntingOverview.length ) {
      let tmp = this.huntingOverview.filter(t =>{
        return t.key == key;
      })
      if (tmp && tmp.length) {
        val = tmp[0]
        this.stageData[val.key] = val;
      }
    }
    return val;
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.inputData != null && changes.inputData.currentValue != null && changes.inputData.currentValue.teams) {
      this.teams = changes.inputData.currentValue.teams;
    }
    if (changes.inputData != null && changes.inputData.currentValue != null  && changes.inputData.currentValue.users) {
      this.userList = changes.inputData.currentValue.users;
    }
    if (changes.huntingData != null && changes.huntingData.currentValue != null) {
      this.huntingStats = [];
      this.stageData  = {};
      this.huntingOverview = changes.huntingData.currentValue;
      let tmpNewDeals = 0;
      let tmpWonDeals = 0;
      let closingSoon = 0 ;
      let inactiveDeals = 0;
      let closedLost = 0;
      let closedLostTo = 0;
      this.realisticDealSize = 0;
      let rdealSize = 0;
      for(let i = 0; i< this.huntingOverview.length;i++) {
        rdealSize = rdealSize + this.huntingOverview[i].sum;
        this.totalDeals = this.totalDeals + this.huntingOverview[i].count;
        tmpNewDeals = tmpNewDeals + (this.huntingOverview[i]['new-deal'] || 0);
        tmpWonDeals= tmpWonDeals +  (this.huntingOverview[i]['won-deal'] || 0);
        closingSoon = closingSoon  + (this.huntingOverview[i]['closing-soon'] || 0 );
        inactiveDeals = inactiveDeals + (this.huntingOverview[i]['inactive-deals'] || 0);
        if (this.huntingOverview[i]['key'] == "Closed-Lost to Competition") {
          closedLostTo = this.huntingOverview[i]['count'];
        }
        if (this.huntingOverview[i]['key'] == "Closed Lost") {
          closedLost = this.huntingOverview[i]['count'];
        }
      }
      this.realisticDealSize = rdealSize;

      this.huntingStats.push({
        "label" : "Deals Lost",
        "icon" : "group",
        "value" : closedLostTo,
        "class" : "lane-grid"
      });

      this.huntingStats.push({
        "label" : "Cold Leads",
        "icon" : "group",
        "value" : closedLost,
        "class" : "lane-grid"
      });
      this.huntingStats.unshift({
        "label" : "Inactive Deals",
        "icon" : "group",
        "value" : inactiveDeals,
        "class" : "inactive-deals"
      })
      this.huntingStats.unshift({
        "label" : "Deals expected to close",
        "icon" : "group",
        "value" : closingSoon,
        "class" : "expected-deals"
      });
      this.huntingStats.unshift({
        "label" : "Deals Won",
        "icon" : "group",
        "value" : tmpWonDeals,
        "class" : "deals-won"
      });
      this.huntingStats.unshift({
        "label" : "New Deals Added",
        "icon" : "work",
        "value" : tmpNewDeals,
        "class" : "new-leads"
      });
      
    }
    if (this.teams != null && this.userList != null) {
      this.passOnData = {
        "teams" : this.teams,
        "users" : this.userList
      };
    }
  }
  

  onResize(event) {
    this.setBreakPoint();
  }
  setBreakPoint() {
    if ((window.innerWidth <= 400)) {
      this.breakpoint = 1
    } else if ((window.innerWidth > 400) && (window.innerWidth <= 768)) {
      this.breakpoint = 2
    } else if ((window.innerWidth > 768) && (window.innerWidth <= 1390)) {
      this.breakpoint = 3;
    } else if ((window.innerWidth > 1390)) {
      this.breakpoint = 3;
    }
  }

}
