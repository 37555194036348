<section class="bg-white" aria-label="heading ">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="card-header">
    <div style="width: 40%" class="p-15" fxLayout="row" fxLayoutAlign="start">
      <div class="headLbl font-500">
        <span class="breadcrumb-text">
          <span class="text-red d-inline-flex" style="cursor: pointer" (click)="goback()">
            Campaign
            <i class="ri-arrow-right-s-line"></i>
          </span>
          {{campaignName}}
        </span>
      </div>
    </div>
    <div style="width: 60%" fxLayout="row" fxLayoutAlign="flex-end"></div>
  </div>
  <div class="horizontal-list-section">
    <ul>
      <li class="account-info">
        <span>Campaign Name</span>
        <p>{{campaignName}}</p>
      </li>
      <li class="start-date-info">
        <span>Creation Date</span>
        <p>{{startDate| date}} </p>
      </li>
      <li class="end-date-info">
        <span>Sent Date</span>
        <p>{{senttDate | date}} </p>
      </li>
      <li class="margin-left-auto">
        <button mat-flat-button class="mog3_primary_flat_btn add-deal-btn abm-apply mr-20 mt-10" (click)="reload();">
         
            Re-Sync 
            <i class="ri-refresh-line mr-0 ml-5"></i>
        </button>
      </li>
    </ul>
  </div>
  <div class="abm-account-table">
    <table mat-table class="w-100" [dataSource]="dataSource">
      <ng-container matColumnDef="Contacts">
        <th mat-header-cell *matHeaderCellDef>Contacts</th>

        <td mat-cell *matCellDef="let element">{{ element.totalContacts }}</td>
      </ng-container>

      <ng-container matColumnDef="Account">
        <th mat-header-cell *matHeaderCellDef>Account</th>

        <td mat-cell *matCellDef="let element">{{ element.accountName }}</td>
      </ng-container>

      <ng-container matColumnDef="Delivered">
        <th mat-header-cell *matHeaderCellDef>Delivered</th>

        <td mat-cell *matCellDef="let element">{{ element.delivered }}</td>
      </ng-container>

      <ng-container matColumnDef="OpenRate">
        <th mat-header-cell *matHeaderCellDef>OpenRate</th>

        <td mat-cell *matCellDef="let element">
          {{ element.open }}
        </td>
      </ng-container>

      <ng-container matColumnDef="CTR">
        <th mat-header-cell *matHeaderCellDef>CTR</th>

        <td mat-cell *matCellDef="let element">
          {{ element.clicked }}
        </td>
      </ng-container>

      <ng-container matColumnDef="BounceRates">
        <th mat-header-cell *matHeaderCellDef>Bounce Rates</th>

        <td mat-cell *matCellDef="let element">
          {{ element.bounce }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayColumns;sticky:true;"></tr>

      <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
    </table>
  </div>
</section>