import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingService } from 'src/app/settings/setting.service';
import { NavigationService } from '../k-board/navigation-service.service';

@Component({
  selector: 'app-abm-accounts',
  templateUrl: './abm-accounts.component.html',
  styleUrls: ['./abm-accounts.component.scss']
})
export class AbmAccountsComponent implements OnInit {
  dataSource: Array<any> = [];
  campaignKey: any;
  campaignName: any = "";
  startDate: any = "";
  senttDate: any = "";
  switchBusiness:any;
  displayColumns: Array<string> = ['Contacts', 'Account', 'Delivered', 'OpenRate', 'CTR', 'BounceRates'];
  constructor(private activatedRoute: ActivatedRoute, public settingService: SettingService, public navigation: NavigationService
    , private router: Router) {

  }

  ngOnInit() {
    this.switchBusiness=this.settingService._ManagementData.subscribe(res => {
      console.log('in Oninit abm account switching business',res)      
      if(res==="SOB Management" || res==="CRM Management" ){
        this.router.navigate(['/crm-board/']);
      }
    });

    this.activatedRoute.params.subscribe(params => {
      this.campaignKey = params.id;
    });
    this.load();
  }
  ngOnDestroy(){
    console.log('ngOnDestroy')
    this.switchBusiness.unsubscribe();
  }
  goback() {
    this.router.navigate(['/abm-management']);
  }
  load(){
    this.settingService.fetchAccountWiseCanpaignReport(this.campaignKey).subscribe(response => {
      console.log('response', response)
      if (response && response['result']) {
        this.dataSource = response['result']
        if (this.dataSource) {
          this.campaignName = this.dataSource[0]['campaignName'];
          this.startDate = this.dataSource[0]['creationDate'];
          this.senttDate = this.dataSource[0]['sentDate'];
        }
      }
    });
  }
  reload(){
    this.settingService.reloadAccountWiseCanpaignReport(this.campaignKey).subscribe(response => {
      console.log('response', response)
      if (response && response['result']) {
        this.dataSource = response['result']
        if (this.dataSource) {
          this.campaignName = this.dataSource[0]['campaignName'];
          this.startDate = this.dataSource[0]['creationDate'];
          this.senttDate = this.dataSource[0]['sentDate'];
        }
      }
    });
  }

}
