import { Component, OnInit, HostBinding } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { SettingService } from 'src/app/settings/setting.service';
import { AuthService } from '../../auth.service';
import { Pipe, PipeTransform } from '@angular/core';
import { SupplierDiscountDialogComponent } from '../../supplier-discount-dialog/supplier-discount-dialog.component';
import { MatDialog } from '@angular/material';


@Component({
  selector: 'app-account-authorization',
  templateUrl: './account-authorization.component.html',
  styleUrls: ['./account-authorization.component.scss']
})
export class AccountAuthorizationComponent implements OnInit {
  @HostBinding('class') class = 'hv-100 theme-lighblue-bg content-center';
  getLocalStorage
  userName: string;
  userDetails: string;
  idtoken: any;
  accountName: string;
  logObj = {}
  userDetail: any = [];
  selectedRoleId:any;
  isDefaultSelected:boolean= false;


  constructor(public router: Router, public authService: AuthService,public dialog: MatDialog,
    private settingservice: SettingService) { }

  ngOnInit() {
    this.getLocalStorage = JSON.parse(localStorage.getItem("USERINFO"));
    this.getLocalStorage['access_token'] = null;
    localStorage.removeItem('credlixUserID');
    localStorage.removeItem('isCustomer');
    localStorage.setItem("USERINFO", JSON.stringify(this.getLocalStorage));
    this.userName = this.getLocalStorage['name'],
    this.userDetail = this.getLocalStorage['user_roles'];
    for(let i=0; i<this.userDetail.length;i++){
      if(this.userDetail[i].default == true){
        this.isDefaultSelected= true;
      }
    }

    this.settingservice.selectAccount.next(false);
    if (this.userDetail != null) {
      for(let k=0; k< this.userDetail.length;k++) {
        let detail = this.userDetail[k];
        if (detail.default != null && detail.default == true) {
          this.isDefaultSelected = true;
          break;
        }
      }
    }
  }

  checkin(obj, roleId) {
    this.selectedRoleId= roleId
    if(this.isDefaultSelected && this.selectedRoleId != null){
      this.settingservice.setBydefaultuser(this.selectedRoleId).subscribe(res=>{

      })
    }
    if (this.getLocalStorage['access_token'] != null) {
      this.router.navigate(['/']).then(() => {
        window.location.reload();
      });
    }
    else {
      this.settingservice.multipleAccount(obj).subscribe((data: any) => {
        this.setUserData(data);
        if(data.result != null && data['result']['access_token'] && 
        data.result.role.role.roleType == 'SID' && this.authService.isSIDRoleType()){
          localStorage.setItem('credlixUserID', data.result.role.masterAccount.id);
          this.router.navigate(['/sid/listing/supplier']).then(() => {
            window.location.reload();
          });
        }
        else if(data.result != null && data['result']['access_token'] && 
          data.result.role.role.roleType == 'BORROWER' && this.authService.isSIDRoleType()){
          localStorage.setItem('credlixUserID', data.result.role.masterAccount.id);
          this.router.navigate(['/sid/invoiceListing']).then(() => {
            window.location.reload();
          });
        }
        else if(data.result != null && data['result']['access_token'] && 
          data.result.role.role.roleType == 'BUYER' && this.authService.isSIDRoleType()){
          localStorage.setItem('credlixUserID', data.result.role.masterAccount.id);
          this.router.navigate(['/sid/listing/buyerSupplier']).then(() => {
            window.location.reload();
          });
        }
        else if(data.result != null && data['result']['access_token'] && 
          data.result.role.role.roleType == 'BORROWER' && data.result.lead == true){
          const dialogRef = this.dialog.open(SupplierDiscountDialogComponent, {
            disableClose: true,
            width: '480px',
            height: '300px',
          });
          dialogRef.afterClosed().subscribe((result) => {
            this.router.navigate(['/']).then(() => {
              window.location.reload();
            });
          });
        }
        else {
        this.router.navigate(['/']).then(() => {
          localStorage.setItem('logged_multiple', 'true');
          window.location.reload();
        });
      }
      })
    }
  }
  setUserData(data: any) {
    if (data['status'] == 200) {
      this.userDetails = data['result']['first_name'] + " " + data['result']['last_name']
      this.authService.getUserDetails((data['result']));
      this.authService.storageUserDetails(this.userDetails);
      this.idtoken = data['result']['access_token'];
      this.authService.createSession(this.idtoken);
      this.accountName = this.logObj['email'];
      this.authService.storageAccountName(this.accountName);
    }
  }

  logout() {
    sessionStorage.clear();
    this.authService.destroySession();
    this.router.navigate(['/auth/login'])
      .then(() => {
        window.localStorage.setItem('logged_in', 'false');
        window.location.reload();
      });
  }
  // getSelectedAccount(event){
  //   if(event.checked == true){
  //     this.isDefaultSelected= true;
  //   }

  // }
}
@Pipe({ name: 'removeUnderscorePipe' })
export class RemoveUnderscore implements PipeTransform {
  transform(value: any, args?: any): any {
    return value.replace(/_/g, " ");
  }
}
