<div class="input-group">
<mat-form-field *ngIf='isListOfString' appearance="outline" class="mat-select mat-select-search deep-0" [ngClass]="{'selected-highlight': selectedHighlight}" id="inputdefault">
  <mat-select [panelClass]="'select-panel-class'" #customMultipleSelect placeholder="{{ label }}" [(ngModel)]="selectedValues" multiple (closed)="onSelectDialogClosed()">
    <mat-form-field class="mat-input icon " appearance="outline">
      <i class="ri-search-line"></i>
      <input type="text" matInput placeholder="Search" [(ngModel)]="searchControl" (keyup)="searchChange.emit(searchControl)" (paste)="handlePaste($event)">
      
    </mat-form-field>
    <div class="select-options">
      <span class="select-label" *ngIf="selectedValues.length > 0">
        <span class="select-label-text"> Selected </span>
      </span>
      <mat-option *ngFor="let selected of selectedValues" [value]="selected">{{ selected }}</mat-option>


      <span class="select-label" *ngIf="selectedValues.length > 0">
        <span class="select-label-text"> Others </span>
      </span>
      
      <ng-container *ngFor="let option of availableOptions">
        <mat-option [value]="option" *ngIf="!selectedValues.includes(option)">{{ option }}</mat-option>
      </ng-container>
    </div>
    <mat-select-trigger *ngIf="!valueCountView">
      {{ label }} ({{ selectedValues.length }})
    </mat-select-trigger>

    <mat-select-trigger *ngIf="valueCountView">
      <span class="test" *ngIf="selectedValues && selectedValues.length > 0">{{ selectedValues[0] }}</span>
      <span class="test2" *ngIf="selectedValues && selectedValues.length > 1" class="example-additional-selection">
        (+ {{ selectedValues.length - 1 }} {{ selectedValues?.length === 2 ? "other"  : "others" }})
      </span>
    </mat-select-trigger>

    <div class="select-footer">
      <button class="btn-basic btn-text-black mr-10" mat-button (click)="clearSelection(); customMultipleSelect.close();">Clear</button>
      <button class="btn-primary" mat-raised-button color="warn" (click)="applySelection(); customMultipleSelect.close();">Apply</button>
    </div>
  </mat-select>
</mat-form-field>
</div>

<div class="input-group">
<mat-form-field *ngIf='!isListOfString' appearance="outline" class="mat-select mat-select-search deep-0 selected-highlight" [ngClass]="{'selected-highlight': selectedHighlight}" id="inputdefault">
  <mat-select [panelClass]="'select-panel-class'" #customMultipleSelect placeholder="{{ label }}" [(ngModel)]="selectedValues" multiple (closed)="onSelectDialogClosed()">
    <mat-form-field class="mat-input icon"  appearance="outline">
      <i class="ri-search-line"></i>
      <input type="text" matInput placeholder="Search" [(ngModel)]="searchControl" (keyup)="searchChange.emit(searchControl)">
  
    </mat-form-field>
    <div class="select-options">
      <span class="select-label" *ngIf="selectedValues.length > 0">
        <span class="select-label-text"> Selected </span>
      </span>
      <mat-option *ngFor="let selected of selectedValues" [value]="selected">{{ selected.name }}</mat-option>
      <span class="select-label" *ngIf="selectedValues.length > 0">
        <span class="select-label-text"> Other </span>
      </span>
      <ng-container *ngFor="let option of availableOptions">
        <mat-option [value]="option" *ngIf="!selectedValues.includes(option)">{{ option.name }}</mat-option>
      </ng-container>
    </div>
    <mat-select-trigger *ngIf="!valueCountView">
      {{ label }} ({{ selectedValues.length }})
    </mat-select-trigger>

    <mat-select-trigger *ngIf="valueCountView">
      <span class="test" *ngIf="selectedValues && selectedValues.length > 0">{{ selectedValues[0].name }}</span>
      <span class="test2" *ngIf="selectedValues && selectedValues.length > 1" class="example-additional-selection">
        (+ {{ selectedValues.length - 1 }} {{ selectedValues?.length === 2 ? "other"  : "others" }})
      </span>
    </mat-select-trigger>

    <div class="select-footer">
      <button class="btn-basic btn-text-black mr-10" mat-button  (click)="clearSelection(); customMultipleSelect.close();">Clear</button>
      <button class="btn-primary" mat-raised-button color="warn" (click)="applySelection(); customMultipleSelect.close();">Apply</button>
    </div>
  </mat-select>
</mat-form-field>
</div>