import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { KanbanService } from '../../../kanban.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatTableDataSource } from '@angular/material';
import { CreateLaneTaskComponent } from '../create-lane-task/create-lane-task.component';
import { ToastrService } from 'ngx-toastr';
import { Constants } from 'src/app/Constants';

import { ErrorToastComponent } from 'src/app/shared-files/error-toast/error-toast.component';
import { SuccessToastComponent } from 'src/app/shared-files/success-toast/success-toast.component';
import { MatSnackBar } from '@angular/material';
import { PagesService } from 'src/app/pages/pages.service';
@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss']
})
export class ContactInfoComponent implements OnInit {
  displayColumns: Array<string> = ['Name', 'designation', 'Email', 'MobileNumber'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  pageSize = 200;
  boardIdForCRMContacts = Constants.boardIdForCRMContacts;
  boardIdForSobContacts = Constants.boardIdForSobContacts;
  boardIdForCRMDeals = Constants.boardIdForCRMDeals;
  boardIdForSobOpportunity = Constants.boardIdForSobOpportunity;
  ids: any;
  accountId: any;
  laneId: any;
  isLoading: boolean = true;
  pageIndex = -1;
  pageFrom = 0;
  totalRecords: any;
  leadListing: any = [];
  dataSource = new MatTableDataSource(this.leadListing);
  boardLane = [];
  isChecked: any;
  passedValues: { [key: string]: any } = {};
  contactIds: string[] = [];
  boardId: string = null;
  isCreateLaneTaskTabSelected: boolean = false;

  accountConfig = {
    "key": "laneTask",
    "field": "account-name",
    "rawValue": null,
    "type": "belongsTo",
    "value": "",
    "optional": true,
    "display": true,
    "displayWhileListing": true,
    "showTotal": false,
    "displayName": "Account Name",
    "dataList": [],
    "values": null,
    "additionalData": null,
    "operationsData": null,
    "operations": null,
    "index": 6,
    "icon": "",
    "relationKey": "63bfa8c3ade7bc466d1c780d",
    "nameField": false
  };
  plantId: any[] = [];
  displayContactList: any;
  laneTaskLaneID: any;
  constructor(private _snackBar: MatSnackBar,public _kService: KanbanService, public dialogRef: MatDialogRef<ContactInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog, public toasterService: ToastrService, public pagesService: PagesService) {
    if (this.data.boardId) {
      this.boardId = this.data.boardId;
      if (this.data.displayContactList) {
        this.displayContactList = this.data.displayContactList;
        console.log("displayContactList", this.displayContactList)
      }
      if (this.boardId === Constants.boardIdForSobContacts) {
        this.plantId = this.data.plantId;
      }
      this.isCreateLaneTaskTabSelected = this.data.isCreateLaneTaskTabSelected;
      this.fetchBoardlane(this.boardId);
    }
    if (data.laneTaskLaneID) {
      this.laneTaskLaneID = data.laneTaskLaneID
    }

    this.getPicksList();
  }

  onCancelClick(): void {
    this.dialogRef.close();
    // window.location.reload();
  }

  ngOnInit() {
    console.log("in ngOnit");
  }

  getPicksList() {
    this.ids = this._kService.getIds();
    this.accountId = this.ids['accountId'];
    this.laneId = this.ids['laneTaskId'];
    let obj = {
      "where": {
        "boardId": {
          "type": "search",
          "operator": "or",
          "value": [
            {
              "field": "boardId.raw",
              "value": this.boardId
            }
          ]
        }
      },
      "sort": { "field": "updatedAt", "order": "desc" },

      "from": this.pageFrom,
      "size": this.pageSize
    }
    let tQuery = [];
    tQuery.push({
      "field": "data.account",
      "value": this.accountId
    })
    let query = {
      "query": {
        "nestedWhere": {}
      }
    };
    query['query']['nestedWhere']["account_query"] = {};
    query['query']['nestedWhere']["account_query"]["type"] = "search";
    query['query']['nestedWhere']["account_query"]["operator"] = "or";
    query['query']['nestedWhere']["account_query"]["value"] = tQuery;
    obj['nestedWhere'] = query['query']['nestedWhere'];
    this.getLaneTask(obj);
  }

  getLaneTask(obj) {

    if (!this.isCreateLaneTaskTabSelected) {
      this._kService.getLaneTaskContactDetails(obj, this.laneId).subscribe(res => {
        this.isLoading = false;
        if (res == null) {
          return;
        }
        this.pageIndex++;
        this.totalRecords = res['total'];
        this.dataSource.data = res['result'];;
      });
    } else {
      // this loop is for create lane task page;
      console.log("sdcdsafdsadsaa");
      this._kService.getLaneTask(obj).subscribe(res => {
        console.log("jcsaovbdovdsv");  
        this.isLoading = false;
        this.pagesService.isLoading$.subscribe(res => {
          setTimeout(() => {
            this.isLoading = res;
          }, 0);
        })
        if (res == null) {
          return;
        }
        this.pageIndex++;
        this.totalRecords = res['total'];

        const result = res['result'];;
        const finalFilteredList = result.filter(item => {
          const dataElement = item['data'];
          return dataElement.some(data => data['field'] === 'plant' && !data['optional']);
        });

        console.log("resul", res['result']);
        console.log("finalList", finalFilteredList);
        this.dataSource.data = finalFilteredList;

        if (this.plantId) {
          console.log("calling plantId List");
          let query = {
            "query": {
              "nestedWhere": {}
            }
          };

          let tQuery = [];
          tQuery.push({
            "field": "data.account",
            "value": this.accountId
          })
          query['query']['nestedWhere']["account_query"] = {};
          query['query']['nestedWhere']["account_query"]["type"] = "search";
          query['query']['nestedWhere']["account_query"]["operator"] = "or";
          query['query']['nestedWhere']["account_query"]["value"] = tQuery;

          query['query']['nestedWhere']["plant_query"] = {};
          query['query']['nestedWhere']["plant_query"]["type"] = "search";
          query['query']['nestedWhere']["plant_query"]["operator"] = "or";

          const pQuery = this.plantId.map(element => ({
            field: "data.plant",
            value: element
          }));

          query['query']['nestedWhere']["plant_query"]["value"] = pQuery;
          obj['nestedWhere'] = query['query']['nestedWhere'];
          console.log("before calling obj with plant id obj is", obj);
          this._kService.getLaneTask(obj).subscribe(res => {
            this.isLoading = false;
            if (res == null) {
              return;
            }
            this.pageIndex++;
            this.totalRecords = res['total'];

            const result = res['result'];;
            console.log("pushing reuslt", result);
            this.dataSource.data = this.dataSource.data.concat(result);
            console.log("after pushing result", this.dataSource.data);

            if (this.displayContactList) {
              const contactIds = this.displayContactList.map(contact => contact.contactId);
              this.dataSource.data.forEach(element => {
                if (contactIds.includes(element['id'])) {
                  console.log("isChecked");
                  element['isChecked'] = true;
                }
              });
            }
          });
        }
        if (this.displayContactList) {
          const contactIds = this.displayContactList.map(contact => contact.contactId);
          this.dataSource.data.forEach(element => {
            if (contactIds.includes(element['id'])) {
              console.log("isChecked");
              element['isChecked'] = true;
            }
          });
        }
      });
    }
  }

  fetchBoardlane(laneBoardId: string) {
    this._kService.getLaneWithUserAccess(laneBoardId, '').subscribe(res => {
      this.boardLane = res['result']['levelAccess'].map(access => access.laneDTO);
    });
  }


  addNewLead() {
    const createLaneTaskDialog = this.dialog.open(CreateLaneTaskComponent, {
      disableClose: false,
      width: '68%',
      height: '100vh',
      position: { bottom: '0px', right: '0px' },
      data: {
        "boardId": this.boardIdForCRMContacts,
        "boardName": "contact",
        "selectedLane": this.boardLane[0],
      },
      panelClass: 'addnewleadoverlay'
    });
    createLaneTaskDialog.afterClosed().subscribe(result => {

      this.getPicksList();

    });
  }

  addNewSobContactLead() {
    const createLaneTaskDialog = this.dialog.open(CreateLaneTaskComponent, {
      disableClose: false,
      width: '68%',
      height: '100vh',
      position: { bottom: '0px', right: '0px' },
      data: {
        "boardId": this.boardIdForSobContacts,
        "boardName": "contact",
        "selectedLane": this.boardLane[0]
      },
      panelClass: 'addnewleadoverlay'
    });
    createLaneTaskDialog.afterClosed().subscribe(result => {

      this.getPicksList();

    });
  }

  addAndUpdateContact() {
    for (let index = 0; index < this.dataSource.data.length; index++) {
      const oldData = this.dataSource.data[index];
      if (oldData['isChecked']) {
        const contactIds = this.dataSource.data[index]['id'];
        this.contactIds.push(contactIds);
      }
    }
    if (this.laneTaskLaneID !== Constants.C0 && this.laneTaskLaneID !== Constants.S0) {    
      if (this.contactIds && this.contactIds.length > 0) {
        this.updateContactDetails();
      } else {
        this._snackBar.openFromComponent(ErrorToastComponent, {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['atom-toast', 'error'],
          duration: 6000,
          data: "Please provide at least one contact."
        });

        // this.toasterService.error("Please provide at least one contact.");
      }
    } else {
      console.log("contactIds", this.contactIds, this.laneTaskLaneID);
      this.updateContactDetails();
    }
  }

  updateContactDetails() {
    this._kService.updateContactDetails(this.laneId, this.contactIds).subscribe(
      (result) => {
        if (result['status'] === 200) {
          this._snackBar.openFromComponent(SuccessToastComponent, {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: ['atom-toast', 'success'],
            duration: 6000,
            data: "Contact Updated Successfully"
          });

          // this.toasterService.success("Contact Updated Successfully");
          this.dialogRef.close(result['result']);
        }
      },
      (error) => {
        this._snackBar.openFromComponent(ErrorToastComponent, {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['atom-toast', 'error'],
          duration: 6000,
          data: "Cannot Update Contact Details" + error
        });

        // this.toasterService.error("Cannot Update Contact Details", error);
      }
    );
  }

  addContact() {
    const passData = [];
    for (let index = 0; index < this.dataSource.data.length; index++) {
      const oldData = this.dataSource.data[index];
      if (oldData['isChecked']) {
        console.log("kaakaj", this.dataSource.data[index]);
        const contactIds = this.dataSource.data[index]['id'];
        const displayName = this.dataSource.data[index]['displayName'];
        const designation = this.dataSource.data[index]['searchData']['designation'];
        passData.push({
          "contactId": contactIds,
          "displayName": displayName,
          "designation": designation
        });
      }
    }
    if (this.contactIds !== undefined && this.contactIds !== null) {
      console.log("addding this contact details: ", passData);
      this.dialogRef.close(passData);
    }
  }


  updatePassedValue(isChecked: boolean, element: any) {
    const elementId = element.id;
    for (let index = 0; index < this.dataSource.data.length; index++) {
      const oldData = this.dataSource.data[index];
      if (oldData['id'] == element.id) {
        this.dataSource.data[index]['isChecked'] = isChecked;
      }
    }
  }

}

