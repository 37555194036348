export const DropdownConstants = {
    IndustryFilter: [
        "Airports",
        "Automotive",
        "Automobile, Auto Ancillaries",
        "Capital Goods",
        "Chemicals",
        "Construction",
        "Construction/Infra",
        "Cosmetics",
        "E-Commerce",
        "Energy & Power",
        "FMCD",
        "FMCG",
        "Fashion",
        "Govt / PSU",
        "Homecare",
        "Logistics",
        "Material Handling/Equipements",
        "Metal & Mining",
        "Metals & Commodities",
        "Metro Rail",
        "Oil & Gas",
        "Pharma",
        "Railways",
        "Retail / Consumer products",
        "Solar",
        "Services",
        "Transmission & Distribution",
        "Utility",
        "Others"
    ],
    IndustryFilterVedanta: [
        "Automotive",
        "Chemicals",
        "Electrical",
        "Extrusion",
        "Machinery",
        "Metals",
        "Steel",
        "Defence",
        "Powder",
        "Construction",
        "Others"
    ],

    CompanyTypeVedanta: [
        "Customer",
        "Consignee"
    ],
    TagFilter: [
        "1AdSales",
        "ECOM",
        "FORTUNE500",
        "NAL100",
        "NAL500",
        "NAL1000",
        "SCM",
        "Other"
    ],
    BusinessFilter: [
        "Credlix",
        "Custom Manufacturing",
        "Infra",
        "MaaS",
        "MaaS Fashion",
        "Mid Market",
        "Moglix Direct",
        "MRO",
        "Oil & Gas",
        "Packaging",
        "Raw Material",
        "SCM",
        "UAE",
        "Zoglix",
        "Moglix Online"
    ],
    DealTypeFilter: [
        "Core",
        "Data cleaning",
        "Emerging",
        "One-off",
        "PaaS",
        "VMI"
    ],
    OpportunityTypeFilter: [
        "Capex",
        "Credlix",
        "Digital (Catalog/ Data Cleaning)",
        "Loyalty/ Gifting",
        "MaaS",
        "New category",
        "New Plant",
        "PaaS",
        "Packaging",
        "VC",
        "VMI"
    ],
    LeadSourceList: [
        "Cold Call",
        "Event",
        "Management/Employee Referral",
        "Performance Marketing",
        "Website",
        "Other",
        "E-Mail Marketing",
        "Social Media"
    ]
}