import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/auth/auth.service';
import { KanbanService } from 'src/app/component/credlix-board/kanban.service';
import { PagesService } from 'src/app/pages/pages.service';
import { SuccessToastComponent } from 'src/app/shared-files/success-toast/success-toast.component';
import { MatSnackBar } from '@angular/material';
@Component({
  selector: 'app-upload-mapping',
  templateUrl: './upload-mapping.component.html',
  styleUrls: ['./upload-mapping.component.scss']
})
export class UploadMappingComponent implements OnInit {

  displayedColumns = [];
  buyerInvoices = [];
  displayedColumns1 = [];
  dataSource = new MatTableDataSource(this.buyerInvoices);
  totalRecords: any = 0;
  pageSize: number = 50;
  pageNumber: number = 0;
  fileName: string = '';
  headerDisplay = [];
  headerDisplayConfig = [];
  headerData = [];
  result = {};
  pageHeader: string = '';
  searchKey: string = '';
  finalHeaderDisplay: Map<string, {}> = new Map();
  isBtnDisable: boolean = true;
  successRateCount: any;
  successRate: number = 0;
  progressRate: number = 0;
  selectedRow: string;
  mappedValue: Map<string, boolean> = new Map();
  searchText: any = [];
  anchorDisplayName: string;
  supplierId: string;
  buyerId: string;
  headerDisplayType: any = [{name:'String (Text)',val:'string'},{name:'Number',val:'number'},
  {name:'Double',val:'double'},{name:'Long',val:'long'},{name:'Date',val:'date'}];
  headerType: any = [];
  totalMandatoryVal: number = 0;
  buyerDataConfig:any;
  boardId: any;

  constructor(private _snackBar: MatSnackBar,public dialogRef: MatDialogRef<UploadMappingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private pageSerive: PagesService,
    private toasterService: ToastrService, private authService: AuthService, public _kService: KanbanService) {
    if (data) {
      this.fileName = data['fileName'];
      this.result = data['data']['result'];
      if(data['buyerId']){
        this.supplierId = data['supplierId'];
        this.buyerId = data['buyerId'];
      }
      if(data['boardId']){
      this.boardId= data['boardId'];
      this.buyerDataConfig= JSON.parse(JSON.stringify(data['buyerDataConfig']))
      }
    }
  }

  ngOnInit() {
    this.anchorDisplayName =  this.authService.getAnchorDisplayName();
    let arrConfig = [];
    this.result['header'].map(val => {
      let obj = {
        field: String(val['index']),
        key: val['field']
      }
      arrConfig.push(obj);
      this.headerData.push({ index: val['index'], field: '', cellType: '' });
    })
    this.headerData.push({ index: 'ignore', field: 'ignore', cellType: '' });
    this.dataSource.data = this.result['data'];
    this.dataSource.data.unshift({});
    this.dataSource.data.unshift({});
    this.createHeaderDisplayConfig();
    this.createHeaders(arrConfig);
    this.headerData = this.headerData.map((data, index) => {
      this.headerDisplay.forEach((value) => {
        value.forEach((val) => {
          if (data['index'] == val['index']) {
            data['field'] = val['field'];
            data['cellType'] = val['cellType'];
            data['isSelected'] = true;
          }else if(val['index'] != null && val['index'] != 'ignore'){
            val['isDisable'] = true;
          }
        })
      })
      return data;
    })

  }

  ngAfterViewInit(){
    this.checkSuccessRate();
  }

  createHeaders = (config) => {
    this.displayedColumns1 = [];
    this.displayedColumns = [];
    config.map((each) => {
      this.displayedColumns1.push(each.field);
      each['cell'] = (element: any) => {
        return _.get(element, each.key);
      }
    })
    this.displayedColumns = config;
  }

  createHeaderDisplayConfig() {
    let displayConfig = this.getConfigheaders();
    for(let i=0; i<displayConfig.length ;i++){
      if(displayConfig[i].cellType){
        displayConfig[i].cellType= null;
      }
    }
    if (displayConfig) {
      displayConfig.map(val => {
        if (val['display']) {
          // this.headerDisplay.push(val);
          this.headerDisplayConfig.push(val);
        }
        if(!val['optional']){
          this.totalMandatoryVal = this.totalMandatoryVal + 1;
          if(val['index'] != null){
            this.mappedValue.set(val['field'], true);
          }
          else{
            this.mappedValue.set(val['field'], false)
          }
        }
      })
      // this.headerDisplay.push({index:'ignore',field:'ignore', displayName: "Ignore"});
      this.headerDisplayConfig = this.sortHeaderData(this.headerDisplayConfig);
      this.headerDisplayConfig.push({ index: 'ignore', field: 'ignore', displayName: "Ignore" });
    }
    this.createDisplayConfig();
  }

  sortHeaderData(data){
    data.sort((a,b)=>{
      if(a.displayName < b.displayName) { return -1; }
      if(a.displayName > b.displayName) { return 1; }
      return 0;
    });
    return data;
  }

  getConfigheaders() {
    let displayConfig;
    let importConfig = JSON.parse(localStorage.getItem("importConfig"));
    console.log("upload", this.buyerDataConfig)
      if(this.anchorDisplayName == "MSAF"){
        if (this.data['pageName'] == 'home') {
          this.pageHeader = 'buyerConfig'
          displayConfig = importConfig['buyerConfig'];
        }
        else if (this.data['pageName'] == 'bulk-upload') {
          this.pageHeader = 'buyerLenderConfig'
          displayConfig = importConfig['buyerLenderConfig'];
        }else if(this.data['pageName'] == 'disbursement') {
          this.pageHeader = 'loanPaymentDisbursmentConfig'
          displayConfig = importConfig['loanPaymentDisbursmentConfig'];
        }else if(this.data['pageName'] == 'repayment') {
          this.pageHeader = 'loanPaymentConfig'
          displayConfig = importConfig['loanPaymentConfig'];
        }else if (this.data['pageName'] == 'anchor') {
          this.pageHeader = 'loanInvoiceConfig'
          displayConfig = importConfig['loanInvoiceConfig'];
        }
        else if(this.data['pageName'] == 'kanban'){
          this.pageHeader = 'buyerDataConfig'
          displayConfig = this.buyerDataConfig;
        }
      } else{
        if (this.data['pageName'] == 'home') {
          this.pageHeader = 'borrowerConfig'
          displayConfig = importConfig['borrowerConfig'];
        }else if (this.data['pageName'] == 'anchor' || this.data['pageName'] == 'invoice') {
          this.pageHeader = 'invoiceConfig'
          displayConfig = importConfig['invoiceConfig'];
        }else if(this.data['pageName'] == 'kanban'){
          this.pageHeader = 'buyerDataConfig'
          displayConfig = this.buyerDataConfig;
        }else if (this.data['pageName'] == 'Supplier') {
          this.pageHeader = 'borrowerConfig'
          displayConfig = importConfig['borrowerConfig'];
        }else if(this.data['pageName'] == 'Buyer') {
          this.pageHeader = 'buyerConfig'
          displayConfig = importConfig['buyerConfig'];
        }else if(this.data['pageName'] == 'abfrl') {
          this.pageHeader = 'invoiceMappingConfig'
          displayConfig = importConfig['invoiceMappingConfig'];
        }else if(this.data['pageName'] == 'chola' || this.data['pageName'] == 'data') {
          this.pageHeader = 'utrMappingConfig'
          displayConfig = importConfig['utrMappingConfig'];
        }else if(this.data['pageName'] == 'booking') {
          this.pageHeader = 'invoiceBookingConfig'
          displayConfig = importConfig['invoiceBookingConfig'];
        }else if(this.data['pageName'] == 'markDelayEP') {
          this.pageHeader = 'epDelayConfig'
          displayConfig = importConfig['epDelayConfig'];
        }else if(this.data['pageName'] == 'markCancelEP') {
          this.pageHeader = 'epCancelConfig'
          displayConfig = importConfig['epCancelConfig'];
        }else if(this.data['pageName'] == 'rate') {
          this.pageHeader = 'accountConfig'
          displayConfig = importConfig['accountConfig'];
        }else if(this.data['pageName'] == 'debit') {
          this.pageHeader = 'debitNoteConfig'
          displayConfig = importConfig['debitNoteConfig'];
        }
      else if (this.data['pageName'] == 'Supplier') {
          this.pageHeader = 'borrowerConfig'
          displayConfig = importConfig['borrowerConfig'];
        }
        else if (this.data['pageName'] == 'Buyer') {
          this.pageHeader = 'buyerConfig'
          displayConfig = importConfig['buyerConfig'];  
        }
        else if (this.data['pageName'] == 'tasvaLedger') {
          this.pageHeader = 'tasvaBookingConfig'
          displayConfig = importConfig['tasvaBookingConfig'];
        }
      }
    return displayConfig;
  }

  createDisplayConfig() {
    this.headerData.map((data) => {
      this.headerDisplay.push(JSON.parse(JSON.stringify(this.headerDisplayConfig)));
      this.headerType.push(this.headerDisplayType);
    })

  }

  uploadFile() {
    let masterAccId;
    let getSessionStorage = JSON.parse(localStorage.getItem("USERINFO"));
    let data = this.dataSource.data;
    data.shift();
    this.headerDisplayConfig.pop();
    this.headerDisplayConfig.forEach(data=>{
      this.finalHeaderDisplay.forEach((id,val)=>{
        if(data['index'] == val){
          data['index'] = id['index'];
          data['cellType'] = id['cellType'];
        }
      })
    })
    let importConfig = this.pageHeader;
    let reqObj = {};
    reqObj[importConfig] = this.headerDisplayConfig;
    if(this.authService.isCredlixUser() && this.data['pageName'] != 'kanban'){
      masterAccId = localStorage.getItem('credlixUserID');
    }else if(this.data['pageName'] == 'kanban'){
      masterAccId = getSessionStorage['role']['masterAccount']['id'];
    }

    let url;
    if(this.data['pageName'] == 'kanban'){
    let array= reqObj['buyerDataConfig'];
      url = this._kService.buyerConfigUpdate(array,this.boardId)
    }
    else {
     url= this.pageSerive.updateImportConfig(reqObj);
    }
   url.subscribe(res => {
      if (res['status'] == 200) {
        this.pageSerive.uploadStatusUpdate(this.data['uploadData'], this.data['pageName'], this.anchorDisplayName,this.supplierId, this.buyerId).subscribe(file => {
          if (file['status'] == 200) {
            this.pageSerive.getHeaderList(masterAccId).subscribe(list => {
              this.authService.storageListHeaders(list['result']);
            })
            this._snackBar.openFromComponent(SuccessToastComponent, {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              panelClass: ['atom-toast', 'success'],
              duration: 6000,
              data: "File has been Succesfully Uploaded. You will get an email shortly"
            });
            // this.toasterService.success("File has been Succesfully Uploaded. You will get an email shortly");
          
            this.clearFields();
            this.dialogRef.close(true);
          }
        },error=>{
          this.headerDisplayConfig.push({ index: 'ignore', field: 'ignore', displayName: "Ignore" });
        })
      }
    },error=>{
      this.headerDisplayConfig.push({ index: 'ignore', field: 'ignore', displayName: "Ignore" });
    })
  }

  //to get previous selected value
  openPanel(val, index) {
    this.searchText[index] = '';
    if(val){
      this.selectedRow = this.headerData[index]['index'];
    }else{
      this.selectedRow = undefined;
    }
  }

  changeHeader(headerValIndex, index) {
    if(headerValIndex == 'ignore' && this.selectedRow == undefined){
      console.log({headerValIndex});
    }
    else if(headerValIndex == 'ignore'){
      const headerIndex1 = this.headerDisplayConfig.findIndex(val => val['index'] == index);
      this.headerDisplayConfig[headerIndex1]['index'] = null;
      this.headerDisplay = this.headerDisplay.map((data, id)=>{
        data = data.map(val=>{
          if(val['field'] == this.headerDisplayConfig[headerIndex1]['field'] && val['index'] != 'ignore'){
            val['isDisable'] = false;
            val['index'] = null;
          }
          return val
          })
        return data;
      })
      this.checkSuccessRate();
    }
    else{
      let previousSelectedName;
      let previousSelectedIndex;
      let headerVal;
      let obj ;
      this.headerData[index]['isSelected'] = true;
      this.headerData[index]['index'] = index; 
      if(isNaN(headerValIndex)){
        headerVal = headerValIndex;
        const headerIndex2 = this.headerDisplayConfig.findIndex(val => val['field'] == headerValIndex);
        this.headerDisplayConfig[headerIndex2]['index'] = index;
        if(this.selectedRow != undefined && this.selectedRow != null && this.selectedRow != 'ignore'){
          previousSelectedIndex = this.headerDisplayConfig.findIndex(val => val.index == this.selectedRow && val.field != headerVal)
          previousSelectedName = this.headerDisplayConfig[previousSelectedIndex]['field'];
          this.headerDisplayConfig[previousSelectedIndex]['index'] = null;
          this.headerDisplay[index][previousSelectedIndex]['index'] = null;
        }
        this.headerDisplay[index][headerIndex2]['index'] = index;
        headerValIndex = index;
        obj = {index: index, cellType: this.headerDisplayConfig[headerIndex2]['cellType']};
      }else{
        previousSelectedIndex = this.headerDisplayConfig.findIndex(val => val.index == this.selectedRow)
        previousSelectedName = this.headerDisplayConfig[previousSelectedIndex]['field'];
        this.headerDisplayConfig[previousSelectedIndex]['index'] = null;
        this.headerDisplayConfig[index]['index'] = index;
        headerVal = this.headerDisplayConfig[index]['field']
        obj = {index: index, cellType: this.headerDisplayConfig[index]['cellType']};
      }
      this.finalHeaderDisplay.set(index, obj);
      this.headerDisplay = this.headerDisplay.map((data, id)=>{
        if(this.selectedRow != undefined && this.selectedRow != null && this.selectedRow != 'ignore' && previousSelectedName){
          data = data.map(val=>{
            if(val['field'] == previousSelectedName){
              val['isDisable'] = false;
              val['index'] = null;
            }
          return val
          })
        }
        if(id != index){
          data = data.map(val=>{
            if(val['field'] == headerVal){
              val['index'] = null;
              val['isDisable'] = true;
            }
          return val
          })
        }
        return data;
      })
      this.changeSuccessRate(headerVal);
    }
    this.resetHeaderData(headerValIndex,index);
  }

  changeSuccessRate(headerVal){
    if(this.selectedRow && this.mappedValue.has(this.selectedRow)){
        this.mappedValue.set(this.selectedRow, false);
      }
    if(this.mappedValue.has(headerVal)){
        this.mappedValue.set(headerVal, true);
      }
    this.checkSuccessRate()
  }

  resetHeaderData(headerVal,index){
    if(this.headerData.length !=0){
      this.headerData.map(val=>{
        if(headerVal != 'ignore' && val['index'] == headerVal && val['index'] != index){
          val['field'] = '';
        }
      })
    }
  }

  checkSuccessRate() {
    this.successRate = 0;
    this.progressRate = 0;
    this.mappedValue.forEach((val1, val2)=>{
      if(val1){
        let result = _.filter(this.headerDisplayConfig, (user) => { return (user.field == val2 && user.index != null)});
        if(result.length != 0){
          this.progressRate++;
        }
      }
    })
    this.successRate = this.mappedValue.size;
    this.successRateCount = ((this.progressRate * 100) / this.successRate).toFixed();
    if(this.successRateCount == "100"){
      this.isBtnDisable = false;
    }
    else{
      this.isBtnDisable = true;
    }
  }

  clearFields(){
    this.headerDisplay = [];
    this.headerDisplayConfig = [];
    this.finalHeaderDisplay.clear();
    this.buyerInvoices = [];
    this.displayedColumns = [];
    this.displayedColumns1 = [];
  }

  resetSearchBar(index){
    this.searchText[index] = '';
    this.headerDisplay[index] = this.headerDisplayConfig;
  }

  changeHeaderType(val, index, element){
    let obj = {index: index, cellType: val};
    this.finalHeaderDisplay.set(index, obj);
  }
}
