import { Component, Input, OnInit , Inject, ViewChild} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { PagesService } from 'src/app/pages/pages.service';
import { AuthService } from 'src/app/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { KanbanService } from '../../../../kanban.service';
import { SettingService } from 'src/app/settings/setting.service';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { saveAs } from 'file-saver';
@Component({
  selector: 'app-history-tab',
  templateUrl: './history-tab.component.html',
  styleUrls: ['./history-tab.component.scss']
})
export class HistoryTabComponent implements OnInit {
 @Input() laneTaskDetailsArgs;
 @ViewChild(MatPaginator) paginator: MatPaginator;
 laneTaskIDDetail : any;
 pageNumber: number = 0;
 pageSize : number = 10;
 totalLaneHistory :  number = 0;
 totalRecords : number = 0;
 boardId : string;
 title : string = "";
 subTitle: string;
 fetchTaskHistory : any[] = [];
 dataSource = new MatTableDataSource(this.fetchTaskHistory);
 displayedColumns :  string[] = ["laneTaskId", "operation","oldValue" ,"updatedValue", "remarks" ,"By","when"];
 displayedColumns1 : ["laneTaskId","operation","oldValue" , "updatedValue", "remarks" ,"By", "when"]
  constructor(private formBuilder: FormBuilder, public toasterService: ToastrService, public dialog: MatDialog, private activatedRoute: ActivatedRoute, public router: Router, public settingServ: SettingService, public _kService: KanbanService,
    private pagesService: PagesService, private authService: AuthService,  @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<HistoryTabComponent>) { }

  ngOnInit() {
    console.log("Test " + this.data)
    if (this.data != null && this.data['laneTask'] != null) {
      this.laneTaskIDDetail = this.data['laneTask'];
    } else if (this.laneTaskDetailsArgs != null) {
      this.laneTaskIDDetail = this.laneTaskDetailsArgs;
    }
    if (this.data && this.data['boardId']) {
      this.boardId = this.data['boardId'];
      if (this.boardId) {
        if (this.boardId == "63bfa88dade7bc466d1c780c") {
          this.title = "Contact";
          if (this.laneTaskIDDetail) {
            this.subTitle = "Name Here";
          }
        } else if (this.boardId == "63bfa8c3ade7bc466d1c780d") {
          this.title = "Account";
        } else  {
          this.title = "Deal";
        }
      }
    }
    if (this.laneTaskIDDetail) {
     this.getLaneTaskHistory(false);
    }
  }
  getLaneTaskHistory(reset?) {
    if (reset) {
      this.pageNumber = 0;
    }
    let obj = {
      from: this.paginator.pageIndex,
      size: this.pageSize
    }
    this._kService.getLaneTaskHistory(this.laneTaskIDDetail.id, obj).subscribe(res => {
      this.totalRecords = res['total'];
      this.pageNumber = this.pageNumber;
      if (this.pageNumber != 0) {
        this.fetchTaskHistory = this.fetchTaskHistory.concat(res['result']);
      }
      else {
        this.fetchTaskHistory = res['result'];
      }
      this.dataSource.data = this.fetchTaskHistory;
  
    })
  }
  getHistoryValue (value) {
    if (!value) {
      return "";
    }
    let keys = Object.keys(value);
    return value[keys[0]] || "-";
  }
  getHistoryField (value) {
    if (!value) {
      return "";
    }
    let keys = Object.keys(value);
    return keys[0];
  }
  toLowerCase = (text) => {
    if (!text) {
      return "";
    }
    return text.toLowerCase();
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [day, month, year].join('-');
  }
  downloadSingleDocument(id) {
    this._kService.downloadSingleUploadDocument(id).subscribe(res => {
      this.downloadSheetCommonMethod(res);

    })
  }
  downloadSheetCommonMethod(res) {
    var contentDispositionHeader = res.headers.get('content-disposition');
    let resultDownload = contentDispositionHeader.split(';')[1].trim().split('=')[1];
    let fileName = resultDownload.replace(/"/g, '')
    saveAs(res.body, fileName);
    return fileName;
  }
}
