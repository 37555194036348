import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { KanbanService } from '../../../kanban.service';
import { MatPaginator } from '@angular/material';


@Component({
  selector: 'app-hunting-projections',
  templateUrl: './hunting-projections.component.html',
  styleUrls: ['./hunting-projections.component.scss']
})
export class HuntingProjectionsComponent implements OnInit {
  totalRecords: number;
  pageSize = 20;
  pageIndex = 0;
  huntingDeals: Array<any> = [];
  dataSource = new MatTableDataSource(this.huntingDeals);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  monthlyProgressTotalRecords: number;
  monthlyProgressPageSize = 20;
  monthlyProgressPageIndex = 0;
  monthlyProgressHuntingDeals: Array<any> = [];
  monthlyProgressdataSource = new MatTableDataSource(this.monthlyProgressHuntingDeals);
  @ViewChild(MatPaginator) matPaginator: MatPaginator;
  displayedColumns1 = [];
  displayedColumns = [];
  displayConfig = [{ "field": "deal-name", "label": "Deal Name" }, { "field": "lane", "label": "Stage" }, { "field": "closing-date", "label": "Conversion Date" }, { "field": "realistic-deal-size-cr", "label": "Deal Size (Cr)" }, { "field": "probability-of-conversion", "label": "Probability" }, { "field": "current-cycle-months", "label": "Months in current cycle" }];
  monthlyProgressDisplayedColumns1 = [];
  monthlyProgressDisplayedColumns = [];
  monthlyProgressDisplayConfig = [{ "field": "deal-name", "label": "Deal Name" }, { "field": "lane", "label": "Stage" }, { "field": "closing-date", "label": "Conversion Date" }, { "field": "realistic-deal-size-cr", "label": "Deal Size (Cr)" }, { "field": "probability-of-conversion", "label": "Probability" }, { "field": "current-cycle-months", "label": "Months in current cycle" }];
  monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
  constructor(private _kService: KanbanService) {

  }

  ngOnInit() {
    this.loadDisplayColumns();
    this.loadMonthlyProgressColumns();
    this.loadDeals();
  }

  loadDisplayColumns() {

    let laneDisplayConfig = this.displayConfig;

    if (laneDisplayConfig) {
      let tmpColumn = [];
      for (let k = 0; k < laneDisplayConfig.length; k++) {
        tmpColumn.push(laneDisplayConfig[k].field);
        
      }
      for (let k = 1; k <= 12; k++) {
        tmpColumn.push("month_" + k);
        laneDisplayConfig.push({
          "field": "month_" + k,
          "label": "Month " + k
        })
      }
      this.displayedColumns1 = tmpColumn;
      this.displayedColumns = laneDisplayConfig;

    }
  }
   subtractMonths(date, months) {
    date.setMonth(date.getMonth() - months);
    return date;
  }
  loadMonthlyProgressColumns() {
    let laneDisplayConfig = this.monthlyProgressDisplayConfig;
    
    if (laneDisplayConfig) {
      let tmpColumn = [];
      for (let k = 0; k < laneDisplayConfig.length; k++) {
        tmpColumn.push(laneDisplayConfig[k].field);
      }
      let lastStarting = this.subtractMonths(new Date(), 6);
      let end = ((lastStarting.getMonth()+1) + 11);
      for (let k = (lastStarting.getMonth()+1); k <= end; k++) {
        let ind = k;
        if (ind > 12) {
          ind = ind - 12;
        }
        console.log(ind, this.monthNames[ind-1]);
        let monthName = this.monthNames[ind-1];
        tmpColumn.push("month_" + monthName.toLowerCase());
        laneDisplayConfig.push({
          "field": "month_" +  monthName.toLowerCase(),
          "label": monthName
        });
      }
      this.monthlyProgressDisplayedColumns1 = tmpColumn;
      this.monthlyProgressDisplayedColumns = laneDisplayConfig;

    }
  }
  getFieldValue(ele, field) {
    if (field == "lane") {
      return ele.lane.name;
    }
    let val = "N/A";
    let laneData = ele['data'];
    if (laneData != null) {
      let laneField = laneData.find((ele) => {
        if (ele['field'] == field) {
          return ele;
        }
      });
        if (!laneField) {
          laneData = ele['projectionData'];
          if (laneData != null) {
            laneField = laneData.find((ele) => {
            if (ele['field'] == field) {
              return ele;
            }
          });
        }
      }
      if (laneField && (laneField.type == "string" || laneField.type == "number" || laneField.type == "email" || laneField.type == "phone" || laneField.type == "textarea" ||
        laneField.type == "currency")) {
        val = laneField.value;
      } else if (laneField && (laneField.type == "select")) {
        if ((laneField.key == "string" || laneField.key == "number")) {
          val = laneField.value;
        } else {
          //let searchType = laneField.key == "user" ? "user" : "laneTask";
          val = laneField.additionalData != null ? laneField.additionalData.name : "N/A";
        }
      } else if (laneField && (laneField.type == "belongsTo")) {
        val = laneField.additionalData != null ? laneField.additionalData.name : "N/A";
      } else if (laneField && laneField.type == "date" && laneField.value != "") {
        val = new Date(laneField.value).toDateString();
      }

    }
    if (!val || (val && val.trim() == "")) {
      val = "-";
    }
    return val;
  }
  isLoading = false;
  loadDeals() {
    this._kService.getLaneTask({
      "projectionType": "month_calculation",
      "cycleStartingMonth" : 6,
      "where": {
        "boardId": {
          "type": "search",
          "field": "boardId.raw",
          "value": "63bfa8ccade7bc466d1c780e",
        },
        "data.closingdate": {
          "type": "nested_exists"
        },
      },
      "nestedWhere": {
        "data.probabilityofconversion": {
          "type": "num_range",
          "start_value": 30,
          "end_value": 100
        }
      },
      "nested_sort": [{
        "field": "data.closingdate"
      }, {
        "field": "data.probabilityofconversion"
      }, {
        "field": "data.realisticdealsizecr"
      }],
      "projectionRequired": true,
      "size": this.pageSize,
      "from": this.pageSize * this.paginator.pageIndex
    }).subscribe((response) => {
      this.loadMonthlyProgressDeals();
      if (response && response['result']) {
        // this.huntingOverview = response['result']['termsAgg'];
        if (response == null) {
          return;
        }
        this.isLoading = false;
        this.pageIndex++;
        let result = response['result'];
        this.huntingDeals = result;
        this.dataSource.data = response['result'];
        this.totalRecords = response['total'];

      }

      console.log(response);
    });
  }
  loadMonthlyProgressDeals() {
    this._kService.getLaneTask({
      "projectionType": "month_progress",
      "cycleStartingMonth" : 6,
      "where": {
        "boardId": {
          "type": "search",
          "field": "boardId.raw",
          "value": "63bfa8ccade7bc466d1c780e",
        },
        "data.closingdate": {
          "type": "nested_exists"
        },
      },
      "nestedWhere": {
        "data.probabilityofconversion": {
          "type": "num_range",
          "start_value": 30,
          "end_value": 100
        }
      },
      "nested_sort": [ {
        "field": "data.probabilityofconversion"
      }, {
        "field": "data.realisticdealsizecr"
      }],
      "projectionRequired": true,
      "size": this.monthlyProgressPageSize,
      "from": this.monthlyProgressPageIndex * this.matPaginator.pageIndex
    }).subscribe((response) => {
      if (response && response['result']) {
        // this.huntingOverview = response['result']['termsAgg'];
        if (response == null) {
          return;
        }
        this.isLoading = false;
        
        let result = response['result'];
        this.monthlyProgressHuntingDeals = result;
        this.monthlyProgressdataSource.data = response['result'];
        this.monthlyProgressTotalRecords = response['total'];

      }
      console.log(this.monthlyProgressDisplayedColumns);
      console.log(response);
    });
  }
}
