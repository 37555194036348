<section class="kamHead">
  <div class="topSec">
    <span class="lft"> View Status </span>
    <span class="rht">
      <i class="ri-close-circle-fill"></i>
    </span>
  </div>
  <!-- <section class="kam_filterSection">
    <div class="chart-filter-row" fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="10px">
      <div fxLayout="row" fxFlex="10" fxLayoutAlign="center space-around" class="filter-sec">
        <div class="formfield">
          <mat-form-field appearance="outline" class="mog3-matselect">
            <mat-select placeholder="Stage">
              <div class="custom-panel search-with-options">
                <mat-option value="1">1</mat-option>
                <mat-option value="2">2</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="row" fxFlex="10" fxLayoutAlign="center space-around" class="filter-sec">
        <div class="formfield">
          <mat-form-field appearance="outline" class="mog3-matselect">
            <mat-select placeholder="Region">
              <div class="custom-panel search-with-options">
                <mat-option value="1">1</mat-option>
                <mat-option value="2">2</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="row" fxFlex="10" fxLayoutAlign="center space-around" class="filter-sec">
        <div class="formfield">
          <mat-form-field appearance="outline" class="mog3-matselect">
            <mat-select placeholder="Role">
              <div class="custom-panel search-with-options">
                <mat-option value="1">1</mat-option>
                <mat-option value="2">2</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" fxFlex="10" fxLayoutAlign="center space-around" class="filter-sec">
        <div class="formfield">
          <mat-form-field appearance="outline" class="mog3-matselect">
            <mat-select placeholder="Owner">
              <div class="custom-panel search-with-options">
                <mat-option value="1">1</mat-option>
                <mat-option value="2">2</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="" class="filter-sec">
        <button class="clear_all">Clear All</button>
      </div>
    </div>
  </section> -->
</section>

<section class="kamListing_Wrap">
  <div class="inner-cpsticky">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="sheetId">
        <th mat-header-cell *matHeaderCellDef>
          <span class="no-wrap">Creation Date </span>
        </th>
        <td mat-cell *matCellDef="let element">
          <span class="no-wrap"> {{element.creationDate | date}} </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="customerName">
        <th mat-header-cell *matHeaderCellDef>Deal Name</th>
        <td mat-cell *matCellDef="let element">
          <span class="blue">Hero Motocorp</span><br />
          <span class="fadeTxt">Created 24-3-2023</span><br />
          <span class="fadeTxt">Modified yesterday</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="totalEffectiveCl">
        <th mat-header-cell *matHeaderCellDef>Closing Date</th>
        <td mat-cell *matCellDef="let element">24-3-2023</td>
      </ng-container>

      <ng-container matColumnDef="timeStamp">
        <th mat-header-cell *matHeaderCellDef>Activity</th>
        <td mat-cell *matCellDef="let element">Added : LaneTask : Deals1</td>
      </ng-container>

      <ng-container matColumnDef="createdBy">
        <th mat-header-cell *matHeaderCellDef>Next Action Item</th>
        <td mat-cell *matCellDef="let element">
          Add contact detail<br />
          <span class="yellowTxt">
            Action date: 22-04-2023
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</section>