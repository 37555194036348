<div class="content">

  <div class="d-top">
    <div class="close-icon">
      <mat-icon (click)="dialogRef.close();" class="closeIcn mat-icon notranslate material-icons mat-icon-no-color"
        role="img" aria-hidden="true">
        close
      </mat-icon>
    </div>
    <h5>Before you move</h5>
    <div class="d-flex align-items-center mt-10">
      <div class="badge-1">{{previousLane}}</div>
      <span class="px-10"><i class="ri-arrow-right-line"></i></span>
      <div class="badge-1"> {{currentLane}}</div>
    </div>
  </div>
  <div class="slctOptin" *ngIf="isReasonBtnVisible">

    <div *ngIf="getboardName == 'domestic'">
    <mat-radio-group class="suplierRadbtn" (change)="selectColdLeadReason($event.value)" 
    [(ngModel)]="coldLeadMoveReason" *ngIf="isReasonBtnVisible">
    <mat-radio-button value="COLD_LEAD">Cold Lead</mat-radio-button>
    <mat-radio-button value="REJECTED_DUE_TO_RISK">Rejected due to Risk</mat-radio-button>
  </mat-radio-group>
</div>
<div *ngIf="getboardName == 'exim'">
    <mat-form-field appearance="fill" class="selectearlypaystatus biztype ">
      <mat-label>Reason For Rejection</mat-label>
      <mat-select panelClass="color-flag" multiple (selectionChange)="onSelectReason($event.value)">
       <div class="lftreasn">
        <div class="lft">
          <label>If Credlix Rejected</label>           
        </div> 
        <mat-option *ngFor="let reason of CredlixRejectedReason" [value]="reason"> {{reason}}
        </mat-option>
      </div>
      <div class="rightreason">
        <div class="lft">
          <label>If Client Rejected</label>           
        </div> 
        <mat-option *ngFor="let reason of ClientRejectedReason" [value]="reason">
          {{reason}}
        </mat-option>
      </div>
      </mat-select>
    </mat-form-field>
  </div>
  </div>

  <mat-form-field class="example-full-width" appearance="outline">
    <mat-label>Select Assignee</mat-label>
    <input type="text"
           placeholder="Pick one"
           aria-label="Number"
           matInput
           [formControl]="myControl"
           [(ngModel)]="selectedAssigneId"
           [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option value ='UnAssigned'>UnAssigned</mat-option>
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
        {{option}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

   <!-- <mat-form-field appearance="fill" class="selectearlypaystatus biztype ">
    <mat-label>Select Assignee</mat-label>
    <mat-select panelClass="color-flag" (selectionChange)="onAssigneeChange($event.value)" [(ngModel)]="selectedAssigneId">
      <mat-option *ngFor="let list of assigneeList" [value]="list.userId">
        {{list.userName}} ({{list.userEmail}})
      </mat-option>
    </mat-select>
  </mat-form-field>  -->
  
  <div class="dialogbody" *ngIf="!isReasonBtnVisible">
    <div class="form-row remarks"  >
      <div class="form-left">
        <mat-label>Remarks</mat-label>
      </div>
    </div>

    <div class="form-row">
      <mat-form-field floatLabel="never" class="comment-sec">
        <textarea matInput placeholder="Enter comments " [(ngModel)]="taskRemark"></textarea>
      </mat-form-field>
    </div>
  </div>
 
  <div class="footer-btn">
    <button mat-raised-button class="cnclbtn" (click)="closeDialog()">Cancel</button>
    <button mat-raised-button class="savebtn" (click)="SubmitLaneMove()">Move</button>
  </div>


</div>