<div class="dialog-section" >
  <section class="pb-15 dialog-heading border-unset">
    <div class="d-flex justify-content-between">
      <h1 class="modal-heading-1">Stage Moved</h1>
      <button mat-mini-fab class="modal-close-btn" (click)="closeDialog();"><i
          class="ri-close-circle-fill"></i></button>
    </div>

    <h2 class="modal-heading-2 d-flex account-reject-heading-1"><span>Provide reason for account rejection</span> </h2>
    <h3 class="modal-heading-3 font-light account-reject-heading-2">Please select reason from below
      option</h3>
  
  </section>
  <section class="dialog-body p-0">
    <div fxLayout="column">
      <div fxLayout="row" class="px-20" fxLayoutAlign="flex-start">
        <mat-form-field class="mog3_matinput lane-card-input " appearance="outline">
          <!-- <mat-label class="head-label"> Probability of Conversion %</mat-label> -->
          <input placeholder="Write your comment" matInput type="number" class="input-field" >
        </mat-form-field>
        
      </div>
      <div fxLayout="row" class="px-20 mt-20" fxLayoutAlign="flex-end">
        <button mat-stroked-button class="mog3_secondary_flat_btn mr-20" (click)="closeDialog()"
          color="warn">Cancel</button>
        <button mat-flat-button class="mog3_primary_flat_btn">
          Submit
        </button>
      </div>
    </div>
  </section>
</div>