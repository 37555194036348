<div>
  <div class="d-flex-rw">
    <section class="pb-15 dialog-heading light-grey-bg" aria-label="heading">
      <div fxLayout="row mb-30 " fxLayoutAlign="space-between flex-center">
        <div class="mb-15">
          <h1 class="modal-heading-2 text-capitalize">Contact Info</h1>
        </div>

        <button mat-mini-fab class="modal-close-btn" mat-dialog-close>
          <i class="ri-close-circle-fill"></i>
        </button>
      </div>
    </section>
    <div class="mog3_msnsearchtable_sticky contact-info-table bg-dark-grey bg-grey">

      <table mat-table class="w-100" [dataSource]="dataSource">
        <!-- Name -->
        <ng-container matColumnDef="Name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let element">
              <span class="blue-anchor"> {{element.name}}</span>
          </td>
        </ng-container>

        <!-- designation -->
        <ng-container matColumnDef="Designation">
          <th mat-header-cell *matHeaderCellDef>Designation</th>
          <td mat-cell *matCellDef="let element">
            {{ element.designation}} 
          </td>
        </ng-container>

        <!-- Account -->
        <ng-container matColumnDef="Account">
          <th mat-header-cell *matHeaderCellDef>Account</th>
          <td mat-cell *matCellDef="let element">
            {{ element.account_name }}<br />
          </td>
        </ng-container>

        <!-- Email -->
        <ng-container matColumnDef="Email">
          <th mat-header-cell *matHeaderCellDef>Email</th>
          <td mat-cell *matCellDef="let element">
            {{ element.email }}
          </td>
        </ng-container>

        <!-- Mobile Number -->
        <ng-container matColumnDef="MobileNumber">
          <th mat-header-cell *matHeaderCellDef>Mobile Number</th>
          <td mat-cell *matCellDef="let element">
            {{ element.mobilenumber }}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>

        <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
      </table>
      <div class="empty-div" *ngIf="dataSource.length==0">
        <span>No Contact to display.</span>
      </div>
      <div fxLayout="row" fxLayoutAlign="end center" style="width: 100%">
        <div class="d-flex-rw" style="width: 100%;">
          <div class="pagination-wrapper" style="width: 100%;">
            <mat-paginator #paginator [hidden]="!dataSource.length" [length]="count" [pageIndex]="pageNumber"
              class="custom-pagintor" [pageSize]="pageSize" showFirstLastButtons (page)="load()">
              <!--  (page)="onScrollDown($event, lane?.laneDTO.id, lane['taskList'].length, lane?.totalRecords, lane['taskList'])" -->
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>