import { Component, OnInit, Input, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { KanbanService } from '../../../kanban.service';
import { MatPaginator } from '@angular/material';
import { ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-filtered-deals',
  templateUrl: './filtered-deals.component.html',
  styleUrls: ['./filtered-deals.component.scss']
})
export class FilteredDealsComponent implements OnInit {
  @Input() laneTaskDetailsArgs;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  title: string;
  searchData: any;
  teamQuery: any;
  userQuery: any;
  tabFilterQuery : any;
  closingDateQuery: any;
  createdDateQuery: any;
  bsQuery: any;
  dealtypeQuery: any;
  pageSize = 10;
  pageIndex = 0;
  totalRecords = 0;
  laneTaskList = [];
  leadListing: any = [];
  dataSource = new MatTableDataSource(this.leadListing);
  displayedColumns: any[] = [{ "field": "closing-date", "label": "Closing Date", "index": 1, "icon": "account" }
    , { "field": "deal-owner", "label": "Deal Owner", "index": 1, "icon": "account" }, { "field": "deal-name", "label": "Deal Name", "index": 1, "icon": "account" }, { "field": "lane", "label": "Current Stage", "index": 1, "icon": "account" }, { "field": "realistic-deal-size-cr", "label": "Realistic Deal Size(Cr)", "index": 1, "icon": "account" }, { "field": "region-team", "label": "Region", "index": 1, "icon": "account" }];
  displayedColumns1: any[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private kanbanService: KanbanService) {
    let tmpColumn = [];
    for (let k = 0; k < this.displayedColumns.length; k++) {
      tmpColumn.push(this.displayedColumns[k].field);
    }
    this.displayedColumns1 = tmpColumn;
  }

  ngOnInit() {
    console.log(this.data);
    if (this.data['search'] && this.data['search']['label']) {
      this.title = this.data['search']['label'];
    }
    this.searchData = this.data['search'];
    this.teamQuery = this.data['teamQuery'];
    this.bsQuery = this.data['bsQuery'];
    this.userQuery = this.data['userQuery'];
    this.tabFilterQuery = this.data['tabFilterQuery'];
    this.closingDateQuery = this.data['closingDateQuery'];
    this.createdDateQuery = this.data['createdDateQuery'];
    this.searcLaneTask();
  }
  loadDisplayColumns(id) {
    if (id != null) {
      this.kanbanService.getLaneConfigById(id).subscribe((res) => {
        if (res && res['result'] && res['result']['config']) {
          let config = res['result']['config'];
          let laneDisplayConfig = config.filter((ele) => {
            if (ele['displayWhileListing']) {
              return true
            }
          });
          laneDisplayConfig.sort(function (x, y) {
            return x.index - y.index;
          });
          if (laneDisplayConfig) {
            let tmpColumn = [];
            for (let k = 0; k < laneDisplayConfig.length; k++) {
              tmpColumn.push(laneDisplayConfig[k].field);
            }
            tmpColumn.push("lane")
            //tmpColumn.push("action");
            this.displayedColumns1 = tmpColumn;
            this.displayedColumns = laneDisplayConfig;

          }
        }
      });
    }
  }
  isLoading = false;
  getFieldValue(ele, field) {
    if (field == "lane") {
      return ele.lane.name;
    }
    let val = "N/A";
    let laneData = ele['data'];
    if (laneData != null) {
      let laneField = laneData.find((ele) => {
        if (ele['field'] == field) {
          return ele;
        }
      });
      if (laneField && (laneField.type == "string" || laneField.type == "number" || laneField.type == "email" || laneField.type == "phone" || laneField.type == "textarea" ||
        laneField.type == "currency")) {
        val = laneField.value;
      } else if (laneField && (laneField.type == "select")) {
        if ((laneField.key == "string" || laneField.key == "number")) {
          val = laneField.value;
        } else {
          //let searchType = laneField.key == "user" ? "user" : "laneTask";
          val = laneField.additionalData != null ? laneField.additionalData.name : "N/A";
        }
      } else if (laneField && (laneField.type == "belongsTo")) {
        val = laneField.additionalData != null ? laneField.additionalData.name : "N/A";
      } else if (laneField && laneField.type == "date" && laneField.value != "") {
        val = new Date(laneField.value).toISOString();
      }

    }
    if (!val || (val && val.trim() == "")) {
      val = "-";
    }
    return val;
  }
  searcLaneTask() {
    let query = {
      "where": {
      },
      "size": this.pageSize,
      "from": this.paginator.pageIndex * this.pageSize
    };
    if (this.teamQuery) {
      if (!query['nestedWhere']) {
        query['nestedWhere'] = {};
      }
      query['nestedWhere']["team_query"] = this.teamQuery;
    }
    if (this.bsQuery) {
      if (!query['nestedWhere']) {
        query['nestedWhere'] = {};
      }
      query['nestedWhere']["bs_query"] = this.bsQuery;
    }
    if (this.dealtypeQuery) {
      if (!query['nestedWhere']) {
        query['nestedWhere'] = {};
      }
      query['nestedWhere']["dealtype_query"] = this.dealtypeQuery;
    }
    if (this.userQuery) {
      query['where']["user_query"] = this.userQuery;
    }
    if (this.createdDateQuery) {
      query['where']["createdAt"] = this.createdDateQuery['createdAt'];
    }

    if (this.closingDateQuery) {
      if (!query['nestedWhere']) {
        query['nestedWhere'] = {};
      }
      query['nestedWhere']["data.closingdate"] = this.closingDateQuery["data.closingdate"];
    }

    if (this.tabFilterQuery) {
      let keys = Object.keys(this.tabFilterQuery);
      for(let i=0; i< keys.length; i++) {
        let clauseKey = keys[i];
        console.log(keys[i],this.tabFilterQuery[keys[i]]);
        let queryObj = this.tabFilterQuery[keys[i]];
        if (queryObj) {
          let queryKeys = Object.keys(queryObj);
          if (queryKeys.length) {
            for(let k = 0; k < queryKeys.length; k++) {
              let qKey = queryKeys[k];
              if (!query[clauseKey]) {
                query[clauseKey] = {};
              }
              query[clauseKey][qKey] = queryObj[qKey];
            }
          }
        }
      }
    }

    this.kanbanService.getLaneTask(query).subscribe((response) => {
      if (response && response['result']) {
        // this.huntingOverview = response['result']['termsAgg'];
        if (response == null) {
          return;
        }
        this.isLoading = false;
        this.pageIndex++;
        this.totalRecords = response['total'];
        this.laneTaskList = response['result'];
        this.dataSource.data = response['result'];;
      }
      console.log(response);
    });
  }
}
