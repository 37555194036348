import { Component, Input, OnInit } from '@angular/core';
import { Constants } from 'src/app/Constants';

@Component({
  selector: 'app-dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrls: ['./dynamic-table.component.scss']
})
export class DynamicTableComponent implements OnInit {
  // Input to receive data from parent component
  @Input() tableData: any[] = [];

  // Define table headers
  // Input to receive data from parent component -- default are set
  @Input() tableColumns: any[] = [];
  displayedColumns: string[] = [];
  subHeaders: string[] = [];
  customerProfileSubLaneId: any = Constants.customerProfileSubLaneId;
  selectedSubLaneId: string = null;
  constructor() { }

  ngOnInit() {
    let selectedSubLaneId = localStorage.getItem("selectedSubLaneId");
    if (selectedSubLaneId != null) {
      this.selectedSubLaneId = selectedSubLaneId;
    }
    // console.log("Datasource", this.tableData);
    // console.log('Dynamic Table tableColumns', this.tableColumns);
    for (let index = 0; index < this.tableColumns.length; index++) {
      const element = this.tableColumns[index];
      if (element['subHeaders']) {
        this.subHeaders = element['subHeaders'];
      }

    }
    this.displayedColumns = this.tableColumns.map(column => column.field);
  }
}
