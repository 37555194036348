import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { CredlixBoardRoutingModule } from './credlix-board-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule, MatInputModule, MatTableModule } from '@angular/material';
import { QuillModule } from 'ngx-quill'
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { DndListModule } from 'ngx-drag-and-drop-lists';
import { SharedModulesModule } from 'src/app/shared-files/shared-modules/shared-modules.module';
import { KBoardComponent } from './k-board/k-board.component';
import { BoardLaneComponent } from './k-board/board-lane/board-lane.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { LanesListComponent } from './k-board/lanes-list/lanes-list.component';
import { LaneMoveComponent } from './k-board/lanes-list/lane-move/lane-move.component';
import { CardDetailsComponent } from './k-board/board-lane/card-details/card-details.component';
import { SelectAutocompleteModule } from 'mat-select-autocomplete';
import { KBoardFiltersComponent } from './k-board/k-board-filters/k-board-filters.component';
import { AddNewLeadComponent } from './k-board/add-new-lead/add-new-lead.component';
import { KUserManagementComponent } from './k-user-management/k-user-management.component';
import { MatMenuModule } from '@angular/material/menu';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { DirectiveModule } from 'src/app/directive/directive.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { MatNativeDateModule } from '@angular/material';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { UploadPanjivaDialogComponent } from './k-board/board-lane/upload-panjiva-dialog/upload-panjiva-dialog.component';
import { UnderwritingComponent } from './k-board/board-lane/underwriting/underwriting.component';
import { BriefBuyerComponent } from './k-board/board-lane/underwriting/brief-buyer/brief-buyer.component';
import { TradingHistoryComponent } from './k-board/board-lane/underwriting/trading-history/trading-history.component';
import { ProductDetailsComponent } from './k-board/board-lane/underwriting/product-details/product-details.component';
import { MentionModule } from 'angular-mentions';
import { MatSelectModule } from '@angular/material';
import { NgFileDragDropModule } from 'ng-file-drag-drop';
import { MatSortModule } from '@angular/material';
import { UploadMappingComponent } from 'src/app/settings/upload-mapping/upload-mapping/upload-mapping.component';
import { DeimalNumberDirective } from './deimal-number.directive';
import { MatDialogModule } from '@angular/material/dialog';
import { BulkGtDialogComponent } from './k-board/bulk-gt-dialog/bulk-gt-dialog.component';
import { MatTabsModule } from '@angular/material/tabs';
import { TermsheetlistingComponent } from './termsheetlisting/termsheetlisting.component';
import { HistoryTabComponent } from './k-board/board-lane/card-details/history-tab/history-tab.component';
import { CommentsTabComponent } from './k-board/board-lane/card-details/comments-tab/comments-tab.component';
import { LaneTaskUsersTabComponent } from './k-board/board-lane/card-details/lane-task-users-tab/lane-task-users-tab.component';
import { LaneTaskDocumentsTabComponent } from './k-board/board-lane/card-details/lane-task-documents-tab/lane-task-documents-tab.component';
import { ViewLaneComponent } from './k-board/board-lane/view-lane/view-lane.component';
import { LaneCardDetailsComponent } from './k-board/board-lane/lane-card-details/lane-card-details.component';
import { FieldAutocompleteComponent } from './k-board/board-lane/field-autocomplete/field-autocomplete.component';
import { CreateLaneTaskComponent } from './k-board/board-lane/create-lane-task/create-lane-task.component';
import { TaskDashboardComponent } from './k-board/task-dashboard/task-dashboard.component';
import { TopDealsComponent } from './k-board/task-dashboard/top-deals/top-deals.component';
import { HuntingOverviewComponent } from './k-board/task-dashboard/hunting-overview/hunting-overview.component';
import { StageTimeComponent } from './k-board/task-dashboard/stage-time/stage-time.component';
import { ChartFiltersComponent } from './k-board/task-dashboard/chart-filters/chart-filters.component';
import { FilteredDealsComponent } from './k-board/task-dashboard/filtered-deals/filtered-deals.component';
import { DatePickerCustomComponent } from './k-board/task-dashboard/chart-filters/date-picker-custom/date-picker-custom.component';
import { HuntingProjectionsComponent } from './k-board/task-dashboard/hunting-projections/hunting-projections.component';
import { CreateLaneTaskRouteComponent } from './k-board/board-lane/create-lane-task/create-lane-task-route/create-lane-task-route.component';
import { NavigationService } from './k-board/navigation-service.service';
import { LaneMovementComponent } from './k-board/board-lane/lane-movement/lane-movement.component';
import { HuntingListingComponent } from './k-board/task-dashboard/hunting-listing/hunting-listing.component';
import { DealsHistoryViewComponent } from './k-board/task-dashboard/deals-history-view/deals-history-view.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { CrmDatePickerComponent } from './crm-date-picker/crm-date-picker.component';
import { DealFunnelModalComponent } from './k-board/task-dashboard/deal-funnel-modal/deal-funnel-modal.component';
import { AccountRejectComponent } from './k-board/board-lane/account-reject/account-reject.component';
import { ContactInfoComponent } from './k-board/board-lane/contact-info/contact-info.component';
import { AccountInfoComponent } from './k-board/board-lane/account-info/account-info.component';
import { SopManagementComponent } from './sop-management/sop-management.component';
import { PlanInfoDialogComponent } from './k-board/sb-plan-info-dialog/plan-info-dialog.component';
import { SbContactInfoDialogComponent } from './k-board/sb-contact-info-dialog/sb-contact-info-dialog.component';
import { SbKamActivityDialogComponent } from './k-board/sb-kam-activity-dialog/sb-kam-activity-dialog.component';
import { LastLoginHistoryComponent } from './k-board/task-dashboard/last-login-history/last-login-history.component';
import { BulkUploadComponent } from './k-board/bulk-upload/bulk-upload.component';
import { EmailCommunicationComponent } from './email-communication/email-communication.component';
import { EmailerViewComponent } from './emailer-view/emailer-view.component';
import { AbmAccountsComponent } from './abm-accounts/abm-accounts.component';
import { AbmContactsComponent } from './abm-contacts/abm-contacts.component';
import { NewMeetingComponent } from './new-meeting/new-meeting.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from "@angular/platform-browser";
import { RfqWonConfirmationComponent } from './k-board/board-lane/rfq-won-confirmation/rfq-won-confirmation.component';
import { KamListingComponent } from './k-board/task-dashboard/kam-listing/kam-listing.component';
import { KamDealsPopupComponent } from './k-board/task-dashboard/kam-listing/kam-deals-popup/kam-deals-popup.component';
import { CustomSelectComponent } from './k-board/board-lane/custom-select/custom-select.component';
import { CustomMultipleSelectComponent } from './k-board/task-dashboard/chart-filters/custom-multiple-select/custom-multiple-select.component';
import { CustomSingleSelectComponent } from './k-board/task-dashboard/chart-filters/custom-single-select/custom-single-select.component';
import { MeetingListComponent } from './k-board/task-dashboard/hunting-listing/meeting-list/meeting-list.component';
import { MoreActionItemsComponent } from './k-board/task-dashboard/hunting-listing/more-action-items/more-action-items.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { DynamicTableComponent } from './k-board/board-lane/dynamic-table/dynamic-table.component';
import { AddFormComponent } from './add-form/add-form.component';
import { FormArrayExampleComponent } from './form-array-example/form-array-example.component';
import {MatChipsModule} from '@angular/material/chips';
@NgModule({
  declarations: [CrmDatePickerComponent, KBoardComponent, BoardLaneComponent, LanesListComponent, LaneMoveComponent, CardDetailsComponent, KBoardFiltersComponent, AddNewLeadComponent, KUserManagementComponent, UploadPanjivaDialogComponent, UnderwritingComponent, BriefBuyerComponent, TradingHistoryComponent, ProductDetailsComponent, DeimalNumberDirective, BulkGtDialogComponent, TermsheetlistingComponent,HistoryTabComponent,CommentsTabComponent, LaneTaskUsersTabComponent, LaneTaskDocumentsTabComponent, ViewLaneComponent, LaneCardDetailsComponent, FieldAutocompleteComponent, CreateLaneTaskComponent, TaskDashboardComponent, TopDealsComponent, HuntingOverviewComponent, StageTimeComponent, ChartFiltersComponent, FilteredDealsComponent, DatePickerCustomComponent, HuntingProjectionsComponent, CreateLaneTaskRouteComponent, LaneMovementComponent, HuntingListingComponent, DealsHistoryViewComponent, CrmDatePickerComponent, DealFunnelModalComponent, SopManagementComponent, PlanInfoDialogComponent, SbContactInfoDialogComponent, SbKamActivityDialogComponent, AccountRejectComponent, ContactInfoComponent, AccountInfoComponent, LastLoginHistoryComponent, BulkUploadComponent, EmailCommunicationComponent, EmailerViewComponent, AbmAccountsComponent, AbmContactsComponent, NewMeetingComponent, RfqWonConfirmationComponent, KamListingComponent, KamDealsPopupComponent, CustomSelectComponent, CustomMultipleSelectComponent, CustomSingleSelectComponent, MeetingListComponent, MoreActionItemsComponent, DynamicTableComponent, AddFormComponent,FormArrayExampleComponent],
  imports: [
    CommonModule,
    CredlixBoardRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    DirectiveModule,
    MatFormFieldModule,
    MatInputModule,
    DndListModule,
    Ng2SearchPipeModule,
    InfiniteScrollModule,
    SharedModulesModule,
    DragDropModule, MatMenuModule,
    SelectAutocompleteModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatMomentModule,
    MatNativeDateModule,
    MentionModule,
    NgFileDragDropModule,
    MatDialogModule,
    MatTabsModule,
    MatGridListModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    MatInputModule,
    NgxMatSelectSearchModule,
    HighchartsChartModule,
    BrowserAnimationsModule,
    BrowserModule,
    MatSnackBarModule,
    MatChipsModule,
    QuillModule.forRoot()
  ],
  exports:[CustomMultipleSelectComponent,CustomSelectComponent],
  entryComponents:[LanesListComponent, UploadPanjivaDialogComponent, AddNewLeadComponent, LaneMoveComponent,KBoardFiltersComponent,BulkGtDialogComponent,CommentsTabComponent,LaneTaskUsersTabComponent,HistoryTabComponent,LaneTaskDocumentsTabComponent,ViewLaneComponent,CreateLaneTaskComponent,KUserManagementComponent, FilteredDealsComponent,LaneMovementComponent,DealsHistoryViewComponent,DealFunnelModalComponent,PlanInfoDialogComponent,SbContactInfoDialogComponent,SbKamActivityDialogComponent,AccountRejectComponent,ContactInfoComponent,AccountInfoComponent,LastLoginHistoryComponent,BulkUploadComponent,EmailerViewComponent,NewMeetingComponent,RfqWonConfirmationComponent,KamDealsPopupComponent,CustomSelectComponent,CustomMultipleSelectComponent,CustomSingleSelectComponent,MeetingListComponent, MoreActionItemsComponent ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [NavigationService, DatePipe],
  //exports:[DragDropModule]
})
export class CredlixBoardModule { }
